import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  feedNutrientsForAnalysis,
  targetDeltaInitialValueLow,
  targetDeltaInitialValueHigh,
  acceptableDeltaInitialValueHigh,
  acceptableDeltaInitialValueLow,
  forageColours,
  concentrateColours,
  supplementColours,
  feedNutrientsForTable,
} from '../utils/globals';

const nutrientKeys = feedNutrientsForAnalysis.map((nut) => nut.key);

export const AppDataContext = React.createContext({
  farmData: [],
  setFarmData: () => {},
  itemIdFarm: null,
  setItemIdFarm: () => {},
  itemIdHorse: null,
  setItemIdHorse: () => {},
  horseData: [],
  setHorseData: () => {},
  currentPage: '',
  setCurrentPage: () => {},
  feedItemList: [],
  setFeedItemList: () => {},
  recommendedRationList: [],
  setRecommendedRationList: () => {},
  horseDataForCalculation: {},
  setHorseDataForCalculation: () => {},
  nutSuppGraphChartType: 'standard',
  setNutSuppGraphChartType: () => {},
  nutrientKeysToShow: nutrientKeys,
  setNutrientKeysToShow: () => {},
  targetDeltaLow: targetDeltaInitialValueLow,
  setTargetDeltaLow: () => {},
  targetDeltaHigh: targetDeltaInitialValueHigh,
  setTargetDeltaHigh: () => {},
  acceptableDeltaLow: acceptableDeltaInitialValueLow,
  setAcceptableDeltaLow: () => {},
  acceptableDeltaHigh: acceptableDeltaInitialValueHigh,
  setAcceptableDeltaHigh: () => {},
  visibleAddHorseModal: false,
  setVisibleAddHorseModal: () => {},
  maximumCap: null,
  setMaximumCap: () => {},
  generateReportFor: null,
  setGenerateReportFor: () => {},
  horseDietInLoadRation: undefined,
  setHorseDietInLoadRation: () => {},
  checkLoadRation: undefined,
  setCheckLoadRation: () => {},
  isNextButtonClicked: false,
  setIsNextButtonClicked: () => {},
  loadRationCheckBox: false,
  setLoadRationCheckBox: () => {},
  idOfHorseInLoadRation: undefined,
  setIdOfHorseInLoadRation: () => {},
  idOfDiet: undefined,
  setIdOfDiet: () => {},
  allFarmHorses: undefined,
  setAllFarmHorses: () => {},
  idOfFarmForLoadRation: undefined,
  setIdOfFarmForLoadRation: () => {},
});

const useAppData = () => useContext(AppDataContext);

export const AppDataProvider = ({ children }) => {
  const [farmData, setFarmData] = useState([]);
  const [itemIdFarm, setItemIdFarm] = useState(undefined);
  const [itemIdHorse, setItemIdHorse] = useState(undefined);
  const [horseData, setHorseData] = useState([]);
  const [currentPage, setCurrentPage] = useState('');
  const [feedItemList, setFeedItemList] = useState([]);
  const [recommendedRationList, setRecommendedRationList] = useState([]);
  const [horseDataForCalculation, setHorseDataForCalculation] = useState({});
  const [nutSuppGraphChartType, setNutSuppGraphChartType] = useState('standard');
  const [nutrientKeysToShow, setNutrientKeysToShow] = useState(nutrientKeys);
  const [targetDeltaLow, setTargetDeltaLow] = useState(targetDeltaInitialValueLow);
  const [targetDeltaHigh, setTargetDeltaHigh] = useState(targetDeltaInitialValueHigh);
  const [acceptableDeltaLow, setAcceptableDeltaLow] = useState(acceptableDeltaInitialValueLow);
  const [acceptableDeltaHigh, setAcceptableDeltaHigh] = useState(acceptableDeltaInitialValueHigh);
  const [visibleAddHorseModal, setVisibleAddHorseModal] = useState(false);
  const [maximumCap, setMaximumCap] = useState(null);
  const [generateReportFor, setGenerateReportFor] = useState(null);
  const [horseDietInLoadRation, setHorseDietInLoadRation] = useState(undefined);
  const [checkLoadRation, setCheckLoadRation] = useState(undefined);
  const [isNextButtonClicked, setIsNextButtonClicked] = useState(false);
  const [loadRationCheckBox, setLoadRationCheckBox] = useState(false);
  const [idOfHorseInLoadRation, setIdOfHorseInLoadRation] = useState(undefined);
  const [idOfDiet, setIdOfDiet] = useState(undefined);
  const [idOfFarmForLoadRation, setIdOfFarmForLoadRation] = useState(undefined);
  const [allFarmHorses, setAllFarmHorses] = useState(undefined);

  const resetRationAnalyzer = () => {
    setItemIdFarm(undefined);
    setItemIdHorse(undefined);
    setHorseData([]);
    setCurrentPage('');
    setFeedItemList([]);
    setRecommendedRationList([]);
    setHorseDataForCalculation({});
    setNutSuppGraphChartType('standard');
    setNutrientKeysToShow(nutrientKeys);
    setTargetDeltaLow(targetDeltaInitialValueLow);
    setTargetDeltaHigh(targetDeltaInitialValueHigh);
    setAcceptableDeltaLow(acceptableDeltaInitialValueLow);
    setAcceptableDeltaHigh(acceptableDeltaInitialValueHigh);
    setVisibleAddHorseModal(false);
    setMaximumCap(null);
    setGenerateReportFor(null);
    setIdOfHorseInLoadRation(undefined);
    setHorseDietInLoadRation(undefined);
    setCheckLoadRation(undefined);
    setIsNextButtonClicked(false);
    setLoadRationCheckBox(false);
    setIdOfDiet(undefined);
    setIdOfFarmForLoadRation(undefined);
    setAllFarmHorses(undefined);
  };

  const keyGroup2 = ['cp', 'lys', 'ca', 'p', 'mg', 'cl', 'k', 'na', 's', 'sugar', 'starch'];
  const keyGroup3 = ['cu', 'i', 'fe', 'mn', 'se', 'zn', 'co', 'thi', 'ribo'];
  const concCalculate = (key, dailySupplied, totalAmount, userSettings) => {
    if (key === 'percentDM') {
      if (userSettings.uom === 'imperial') {
        return `${((dailySupplied / 0.453592 / totalAmount) * 100).toFixed(2)} %`;
      }
      return `${((dailySupplied / (totalAmount * 0.453592)) * 100).toFixed(2)} %`;
    }
    if (keyGroup2.includes(key)) {
      if (userSettings.uom === 'imperial') {
        return `${((dailySupplied / (totalAmount * 0.453592)) * 0.1).toFixed(2)} %`;
      }
      return `${((dailySupplied / totalAmount) * 0.1).toFixed(2)} %`;
    }
    if (keyGroup3.includes(key)) {
      if (userSettings.uom === 'imperial') {
        return `${(dailySupplied / (totalAmount * 0.453592)).toFixed(2)} ppm`;
      }
      return `${(dailySupplied / totalAmount).toFixed(2)} ppm`;
    }
    return '-';
  };

  const createKeysAndDataForGraph = (rationCalculationsData = null, finalNutrientKeys = [], chartType = 'standard') => {
    let dataForGraph = [];
    let dataKeyForGraph = [];

    if (rationCalculationsData) {
      // Create dataKeys for bars in the graph
      let forageCounter = 0;
      let concentrateCounter = 0;
      let supplementCounter = 0;
      const dataKeyForForage = [];
      const dataKeyForConcentrate = [];
      const dataKeyForSupplement = [];

      rationCalculationsData.percentFedOfReqd.forEach((feed) => {
        if (feed.type === 'forage') {
          forageCounter += 1;
          dataKeyForForage.push({ name: feed.name, color: forageColours[forageCounter], id: feed.id });
        } else if (feed.type === 'concentrate') {
          concentrateCounter += 1;
          dataKeyForConcentrate.push({ name: feed.name, color: concentrateColours[concentrateCounter], id: feed.id });
        } else {
          supplementCounter += 1;
          dataKeyForSupplement.push({ name: feed.name, color: supplementColours[supplementCounter], id: feed.id });
        }
      });
      dataKeyForGraph = [...dataKeyForForage, ...dataKeyForConcentrate, ...dataKeyForSupplement];

      // Data for graph
      feedNutrientsForAnalysis
        .filter((nutrient) => nutrient.key !== 'sugar' && nutrient.key !== 'starch')
        .forEach((nutrient) => {
          const eachObjectOfGraph = {
            key: nutrient.key,
            name: nutrient.xAxisLabelName,
          };
          rationCalculationsData.percentFedOfReqd.forEach((feed) => {
            eachObjectOfGraph[feed.id] =
              feed[nutrient.key] === Infinity || isNaN(feed[nutrient.key])
                ? 0
                : parseFloat(feed[nutrient.key].toFixed(2));
          });
          dataForGraph.push(eachObjectOfGraph);
        });

      // Filter nutrients
      dataForGraph = dataForGraph.filter(
        (data) => finalNutrientKeys.includes(data.key) && data.key !== 'sugar' && data.key !== 'starch',
      );

      if (chartType === '100%') {
        dataForGraph = dataForGraph.map((entry) => {
          const objEntriesArr = Object.entries(entry);
          let sum = 0;
          objEntriesArr.forEach((element) => {
            const [elementKey, elementValue] = element;
            if (elementKey !== 'name' && elementKey !== 'key') {
              sum += elementValue || 0;
            }
          });
          const returnObj = {};
          objEntriesArr.forEach((element) => {
            const [elementKey, elementValue] = element;
            if (elementKey === 'name' || elementKey === 'key') {
              returnObj[elementKey] = elementValue;
            } else {
              let ev = 0;
              if (sum === 0) {
                ev = 0;
              } else {
                ev = (elementValue / sum) * 100;
              }
              returnObj[elementKey] = Math.ceil(ev);
            }
          });
          return returnObj;
        });
      }
    }
    return [dataForGraph, dataKeyForGraph];
  };

  const dataForRatiosTableData = (currentRationCalculations) => {
    const dataSource = [];

    feedNutrientsForTable.forEach((nutrient) => {
      dataSource.push({
        key: nutrient.key,
        nutrientName: nutrient.nutrientName,
        units: nutrient.unit,
        dailyRequired: currentRationCalculations.horseNutrientRequirement[nutrient.rCalcKey],
        dailySupplied: currentRationCalculations.amountFedPerNutrientTotal[nutrient.key],
        percentRequired:
          currentRationCalculations.percentTotalFedOfReqd[nutrient.key] === Infinity
            ? 0
            : currentRationCalculations.percentTotalFedOfReqd[nutrient.key],
      });
    });

    const pValue = dataSource.find((data) => data.key === 'p');
    const caValue = dataSource.find((data) => data.key === 'ca');
    const znValue = dataSource.find((data) => data.key === 'zn');
    const cuValue = dataSource.find((data) => data.key === 'cu');

    const displayValue = (firstValue, secondValue) => {
      if (firstValue && secondValue) {
        return `${(firstValue / secondValue).toFixed(2)}:1`;
      }
      if (secondValue === 0 && firstValue) {
        return '-';
      }
      return 0;
    };

    return [
      {
        ratio: 'Ca:P',
        value: displayValue(
          Number(`${caValue ? caValue.dailySupplied : 0}`),
          Number(`${pValue ? pValue.dailySupplied : 0}`),
        ),
      },
      {
        ratio: 'Zn:Cu',
        value: displayValue(
          Number(`${znValue ? znValue.dailySupplied : 0}`),
          Number(`${cuValue ? cuValue.dailySupplied : 0}`),
        ),
      },
    ];
  };

  return (
    <AppDataContext.Provider
      value={{
        farmData,
        setFarmData,
        itemIdFarm,
        setItemIdFarm,
        itemIdHorse,
        setItemIdHorse,
        horseData,
        setHorseData,
        currentPage,
        setCurrentPage,
        feedItemList,
        setFeedItemList,
        recommendedRationList,
        setRecommendedRationList,
        horseDataForCalculation,
        setHorseDataForCalculation,
        nutSuppGraphChartType,
        setNutSuppGraphChartType,
        nutrientKeysToShow,
        setNutrientKeysToShow,
        targetDeltaLow,
        setTargetDeltaLow,
        targetDeltaHigh,
        setTargetDeltaHigh,
        acceptableDeltaLow,
        setAcceptableDeltaLow,
        acceptableDeltaHigh,
        setAcceptableDeltaHigh,
        visibleAddHorseModal,
        setVisibleAddHorseModal,
        maximumCap,
        setMaximumCap,
        generateReportFor,
        setGenerateReportFor,
        horseDietInLoadRation,
        setHorseDietInLoadRation,
        checkLoadRation,
        setCheckLoadRation,
        isNextButtonClicked,
        setIsNextButtonClicked,
        loadRationCheckBox,
        setLoadRationCheckBox,
        idOfHorseInLoadRation,
        setIdOfHorseInLoadRation,
        idOfDiet,
        setIdOfDiet,
        concCalculate,
        createKeysAndDataForGraph,
        dataForRatiosTableData,
        resetRationAnalyzer,
        allFarmHorses,
        setAllFarmHorses,
        idOfFarmForLoadRation,
        setIdOfFarmForLoadRation,
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
};

AppDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default useAppData;
