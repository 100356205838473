import React, { useState, useEffect, useRef } from 'react';
import {
  Radio,
  Button,
  message,
  Select,
  Col,
  Row,
  InputNumber,
  Form,
  Table,
  Divider,
  Popconfirm,
  Icon,
  Modal,
  Checkbox,
} from 'antd';
import PropTypes from 'prop-types';
import Heading from './Heading';
import { firebaseDb } from '../utils/firebase';
import FeedForm from '../forms/FeedForm';
import './AddFeedByRationAnalyzer.scss';
import './ButtonColor.scss';
import useAuth from '../utils/auth';

const { Option, OptGroup } = Select;
const { Column } = Table;

const renderSelectOption = (item) => (
  <Option key={item.id} value={item.id}>
    {`${item.name}${item.code ? ` (${item.code})` : ''}`}
  </Option>
);

const renderSelectOptionDiet = (item) => (
  <Option key={item.id} value={item.id}>
    {item.name}
  </Option>
);

const AddForageByRationAnalyzer = (props) => {
  const textInputRef = useRef(null);
  const [radioOptionSelected, setRadioOptionSelected] = useState(1);
  const {
    onClickNextOfForage,
    onClickPreviousOfForage,
    form,
    addItemInForageList,
    feedItemList,
    deleteItemFormList,
    updateItemInList,
    idOfSelectLoadRationHorse,
    horseDataForLoadExisitingRation,
    horseDietInLoadRation,
    selectHorseDietByLoadRation,
    checkLoadRation,
    setCheckLoadRation,
    onchangeCheckBox,
    loadRationCheckBox,
    idOfHorseInLoadRation,
    idOfDiet,
    farmData,
    idOfFarmForLoadRation,
    setIdOfFarmForLoadRation,
    onChangeSelectFarmOfLoadRation,
  } = props;

  const { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue, setFields } = form;
  const [feedData, setFeedData] = useState([]);
  const [itemId, setItemId] = useState(undefined);
  const [itemIdInModal, setItemIdInModal] = useState(null);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [visibleAddForageModal, setVisibleAddForageModal] = useState(false);
  const [radioFeedType, setRadioFeedType] = useState('forage');

  const { userSettings, convertPoundToKg, convertGramToOz, convertOzToGram, convertKgToPound } = useAuth();

  useEffect(() => {
    firebaseDb.collection('Feed').onSnapshot((querySnapshot) => {
      const newFeedData = [];
      querySnapshot.forEach((doc) => {
        newFeedData.push({ id: doc.id, ...doc.data() });
      });
      setFeedData(newFeedData);
    });
  }, []);

  const blurInput = () => {
    textInputRef.current.blur();
  };

  const onChangeSelect = (value) => {
    setItemId(value);
  };

  let feedItemListInSelect = [];
  if (getFieldValue('feedType') === 'forage') {
    feedItemListInSelect = feedData.filter((entry) => entry.type === 'forage');
  } else if (getFieldValue('feedType') === 'concentrate') {
    feedItemListInSelect = feedData.filter((entry) => entry.type === 'concentrate');
  } else if (getFieldValue('feedType') === 'supplement') {
    feedItemListInSelect = feedData.filter((entry) => entry.type === 'supplement');
  }

  let selectedItemToEdit = null;
  if (itemToEdit && itemToEdit.id) {
    selectedItemToEdit = feedData.find((entry) => entry.id === itemToEdit.id);
  }

  let selectedItemToEditamount = null;
  if (itemToEdit && itemToEdit.id) {
    selectedItemToEditamount = feedItemList.find((entry) => entry.id === itemToEdit.id);
  }

  const handleAddToRation = (e) => {
    e.preventDefault();
    blurInput();
    validateFields((err, values) => {
      let amountValue = 0;
      if (userSettings.uom === 'metric') {
        if (values.unit && values.unit === 'oz/day') {
          amountValue = convertGramToOz(values.amount);
        } else {
          amountValue = convertKgToPound(values.amount);
        }
      } else {
        amountValue = values.amount;
      }
      if (!err) {
        addItemInForageList({ ...values, amount: amountValue }, feedData);
        resetFields();
      }
    });
  };

  const handleEditForageList = (item) => {
    setItemToEdit(item);
  };

  const handleDeleteForageList = (itemforDelete) => {
    deleteItemFormList(itemforDelete);
  };

  const handleSaveButton = () => {
    blurInput();
    validateFields((error, value) => {
      let amountValue = 0;
      if (userSettings.uom === 'metric') {
        if (value.unit && value.unit === 'oz/day') {
          amountValue = convertGramToOz(value.amount);
        } else {
          amountValue = convertKgToPound(value.amount);
        }
      } else {
        amountValue = value.amount;
      }
      if (!error) {
        updateItemInList({ ...value, amount: amountValue }, itemToEdit);
        setItemToEdit(null);
        resetFields();
      } else {
        message.error(error);
        resetFields();
      }
    });
  };

  const onChangeRadio = (e) => {
    setRadioOptionSelected(e.target.value);
  };

  const handleCancelButton = () => {
    setItemToEdit(null);
    resetFields();
  };

  const addItem = () => {
    setVisibleAddForageModal(true);
    const label = getFieldValue('feedType');
    setRadioFeedType(label);
  };

  const handleModalCancel = () => {
    setVisibleAddForageModal(false);
    setItemIdInModal(undefined);
  };

  const onChangeSelectInModal = (value) => {
    setItemIdInModal(value);
  };

  let initialValueForForm = null;
  if (itemIdInModal) {
    initialValueForForm = feedData.find((entry) => entry.id === itemIdInModal);
  }

  const convertAmountValue = (value, unit) => {
    if (userSettings.uom === 'metric') {
      if (unit === 'lbs/day') {
        return convertPoundToKg(value);
      }
      if (unit === 'oz/day') {
        return convertOzToGram(value);
      }
      return null;
    }
    return value;
  };

  const onChangeSelectHorse = (value) => {
    idOfSelectLoadRationHorse(value);
  };

  const onChangeSelectFarm = (value) => {
    onChangeSelectFarmOfLoadRation(value);
  };

  const onChangeSelectHorseDiet = (value) => {
    selectHorseDietByLoadRation(value);
  };

  const renderAmountValueInTable = (record) => {
    if (userSettings.uom === 'metric') {
      if (record.unit && record.unit === 'lbs/day') {
        return `${convertPoundToKg(record.amount).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} kgs`;
      }
      if (record.unit && record.unit === 'oz/day') {
        return `${convertOzToGram(record.amount).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} grams`;
      }
      return null;
    }
    return `${record.amount.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} ${record.unit === 'lbs/day' ? 'lbs' : 'oz'}`;
  };

  feedData.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  feedItemListInSelect.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  return (
    <div style={{ paddingLeft: 35, paddingBottom: 35, paddingRight: 35, fontSize: 14 }}>
      <Checkbox
        onChange={onchangeCheckBox}
        checked={loadRationCheckBox}
        style={{ marginBottom: 15, color: 'rgba(0, 0, 0, 0.85)' }}
      >
        Load ration from existing diet
      </Checkbox>
      <div style={{ marginBottom: 20, marginLeft: 5 }}>
        <span style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: 14 }}> Select a Farm: </span>
        <Select
          showSearch
          style={{ width: 300 }}
          placeholder="Start typing the name of Farm"
          optionFilterProp="children"
          onChange={onChangeSelectFarm}
          value={idOfFarmForLoadRation}
          disabled={!checkLoadRation || farmData.length === 0}
        >
          {farmData.map((farm) => {
            return (
              <Option key={farm.id} value={farm.id}>
                {farm.name}
              </Option>
            );
          })}
        </Select>
      </div>
      {checkLoadRation &&
      (Array.isArray(farmData) && farmData.length > 0) &&
      (Array.isArray(horseDataForLoadExisitingRation) && horseDataForLoadExisitingRation.length > 0) ? (
        <div style={{ marginBottom: 30 }}>
          <span style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: 14 }}>Select a Horse: </span>
          <Select
            showSearch
            style={{ width: 300, marginRight: 30 }}
            placeholder="Start typing the name of Horse"
            optionFilterProp="children"
            onChange={onChangeSelectHorse}
            value={idOfHorseInLoadRation}
          >
            {horseDataForLoadExisitingRation.map((horse) => {
              return (
                <Option key={horse.id} value={horse.id}>
                  {horse.name}
                </Option>
              );
            })}
          </Select>
          {checkLoadRation &&
          (Array.isArray(horseDataForLoadExisitingRation) && horseDataForLoadExisitingRation.length > 0) &&
          (Array.isArray(horseDietInLoadRation) && horseDietInLoadRation.length > 0) ? (
            <>
              <span style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: 14 }}>Select a Diet: </span>
              <Select
                showSearch
                style={{ width: 300 }}
                placeholder="Select a diet"
                optionFilterProp="children"
                onChange={onChangeSelectHorseDiet}
                value={idOfDiet}
              >
                <OptGroup label="Current Diets">
                  {horseDietInLoadRation
                    ? horseDietInLoadRation
                        .filter((item) => item.rationType === 'current')
                        .map((item) => renderSelectOptionDiet(item))
                    : null}
                </OptGroup>
                <OptGroup label="Recommended Diets">
                  {horseDietInLoadRation
                    ? horseDietInLoadRation
                        .filter((item) => item.rationType === 'recommended')
                        .map((item) => renderSelectOptionDiet(item))
                    : null}
                </OptGroup>
              </Select>
            </>
          ) : null}
        </div>
      ) : null}
      {horseDietInLoadRation && horseDietInLoadRation.length === 0 && checkLoadRation ? (
        <div
          style={{
            fontSize: 17,
            marginBottom: 20,
            color: '#CE0E2D',
          }}
        >
          Oops! No diets have been built and saved for this Horse.
        </div>
      ) : null}
      {checkLoadRation && horseDataForLoadExisitingRation && horseDataForLoadExisitingRation.length === 0 ? (
        <div
          style={{
            fontSize: 17,
            marginBottom: 20,
            color: '#CE0E2D',
          }}
        >
          Oops! No horse is added by you.
        </div>
      ) : null}

      <Heading headingName="Ration Information - Add Ration Item" />

      <div className="ration-analyzer-container">
        <Modal
          onCancel={handleModalCancel}
          title={`Add New ${radioFeedType.charAt(0).toUpperCase() + radioFeedType.slice(1)}`}
          visible={visibleAddForageModal}
          footer={false}
          formIsInModal="farmIsInModal"
          width="100%"
        >
          <div>
            <Radio.Group onChange={onChangeRadio} value={radioOptionSelected}>
              <Radio value={1}>Add individual components</Radio>
              <Radio value={2}>{`Use values from existing ${radioFeedType} item`}</Radio>
            </Radio.Group>
            {radioOptionSelected === 2 ? (
              <div style={{ marginTop: 30, display: 'flex', justifyContent: 'center' }}>
                <span className="select-item-name">{`Select ${radioFeedType} item :`}</span>
                <Select
                  showSearch
                  style={{ width: 300 }}
                  placeholder={`Select ${radioFeedType} item`}
                  optionFilterProp="children"
                  onChange={onChangeSelectInModal}
                  value={itemIdInModal}
                >
                  <OptGroup label="Purina Products">
                    {feedData
                      .filter((item) => item.category === 'purinaProduct')
                      .map((item) => renderSelectOption(item))}
                  </OptGroup>
                  <OptGroup label="Book Values">
                    {feedData.filter((item) => item.category === 'bookValues').map((item) => renderSelectOption(item))}
                  </OptGroup>

                  <OptGroup label="Individual Ingredients">
                    {feedData
                      .filter((item) => item.category === 'individualIngredient')
                      .map((item) => renderSelectOption(item))}
                  </OptGroup>

                  <OptGroup label="Custom Entries">
                    {feedData.filter((item) => item.category === 'customEntry').map((item) => renderSelectOption(item))}
                  </OptGroup>

                  <OptGroup label="Other Commercial Products">
                    {feedData
                      .filter((item) => item.category === 'otherCommercialProduct')
                      .map((item) => renderSelectOption(item))}
                  </OptGroup>

                  <OptGroup label="Uncategorized">
                    {feedData
                      .filter((item) => item.category === 'uncategorized' || !item.category)
                      .map((item) => renderSelectOption(item))}
                  </OptGroup>
                </Select>
              </div>
            ) : null}
            <FeedForm
              feedType={radioFeedType}
              labelName={radioFeedType.charAt(0).toUpperCase() + radioFeedType.slice(1)}
              mode="addFromModal"
              initialValueForForm={initialValueForForm}
              handleModalCancel={() => {
                handleModalCancel();
              }}
            />
          </div>
        </Modal>
      </div>

      <div>
        <Form onSubmit={itemToEdit ? handleSaveButton : handleAddToRation}>
          <Form.Item label="Select feed type:" style={{ marginRight: 40 }}>
            {getFieldDecorator('feedType', {
              initialValue: selectedItemToEdit ? selectedItemToEdit.type : 'forage',
              rules: [{ required: true, message: `Please enter Amount and try again` }],
            })(
              <Radio.Group
                disabled={selectedItemToEdit}
                onChange={() => {
                  resetFields(['feedId', 'amount', 'unit']);
                }}
              >
                <Radio value="forage">Forage</Radio>
                <Radio value="concentrate">Concentrate</Radio>
                <Radio value="supplement">Supplement</Radio>
              </Radio.Group>,
            )}
          </Form.Item>
          <div style={{ display: 'flex' }}>
            <Form.Item label="Select feed item:" style={{ marginRight: 40 }}>
              {getFieldDecorator('feedId', {
                initialValue: selectedItemToEdit
                  ? `${selectedItemToEdit.name} ${selectedItemToEdit.code ? ` (${selectedItemToEdit.code})` : ''}`
                  : undefined,
                rules: [{ required: true, message: `Please Select Feed Item and try again` }],
              })(
                <Select
                  showSearch
                  style={{ width: 300 }}
                  placeholder="Start typing the name of Feed"
                  optionFilterProp="children"
                  onChange={onChangeSelect}
                  disabled={selectedItemToEdit}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{ padding: '4px 8px', cursor: 'pointer' }}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={addItem}
                        role="button"
                        tabIndex="0"
                        onKeyPress={addItem}
                      >
                        <Icon type="plus" /> ADD NEW FEED
                      </div>
                    </div>
                  )}
                >
                  <OptGroup label="Purina Products">
                    {feedItemListInSelect
                      .filter((item) => item.category === 'purinaProduct')
                      .map((item) => renderSelectOption(item))}
                  </OptGroup>

                  <OptGroup label="Book Values">
                    {feedItemListInSelect
                      .filter((item) => item.category === 'bookValues')
                      .map((item) => renderSelectOption(item))}
                  </OptGroup>

                  <OptGroup label="Individual Ingredients">
                    {feedItemListInSelect
                      .filter((item) => item.category === 'individualIngredient')
                      .map((item) => renderSelectOption(item))}
                  </OptGroup>

                  <OptGroup label="Custom Entries">
                    {feedItemListInSelect
                      .filter((item) => item.category === 'customEntry')
                      .map((item) => renderSelectOption(item))}
                  </OptGroup>

                  <OptGroup label="Other Commercial Products">
                    {feedItemListInSelect
                      .filter((item) => item.category === 'otherCommercialProduct')
                      .map((item) => renderSelectOption(item))}
                  </OptGroup>

                  <OptGroup label="Uncategorized">
                    {feedItemListInSelect
                      .filter((item) => item.category === 'uncategorized' || !item.category)
                      .map((item) => renderSelectOption(item))}
                  </OptGroup>
                </Select>,
              )}
            </Form.Item>
            {getFieldValue('feedType') === 'supplement' ? (
              <Form.Item label="Amount" style={{ width: 400 }}>
                {getFieldDecorator('amount', {
                  initialValue: selectedItemToEditamount
                    ? convertAmountValue(selectedItemToEditamount.amount, selectedItemToEditamount.unit)
                    : undefined,
                  rules: [{ required: true, message: `Please enter Amount and try again` }],
                })(
                  <InputNumber
                    min={0}
                    precision={3}
                    ref={textInputRef}
                    onPressEnter={itemToEdit ? handleSaveButton : handleAddToRation}
                  />,
                )}
                &nbsp;
                {getFieldDecorator('unit', {
                  initialValue: selectedItemToEditamount ? selectedItemToEditamount.unit : 'lbs/day',
                })(
                  <Radio.Group>
                    <Radio value="lbs/day"> {userSettings.uom === 'metric' ? <>kgs/day</> : <>lbs/day</>} </Radio>
                    <Radio value="oz/day"> {userSettings.uom === 'metric' ? <>grams/day</> : <>oz/day</>} </Radio>
                  </Radio.Group>,
                )}
              </Form.Item>
            ) : (
              <Form.Item label="Amount" style={{ width: 400 }}>
                {getFieldDecorator('amount', {
                  initialValue: selectedItemToEditamount
                    ? convertAmountValue(selectedItemToEditamount.amount, selectedItemToEditamount.unit)
                    : undefined,
                  rules: [{ required: true, message: `Please enter Amount and try again` }],
                })(
                  <InputNumber
                    min={0}
                    precision={3}
                    ref={textInputRef}
                    onPressEnter={itemToEdit ? handleSaveButton : handleAddToRation}
                  />,
                )}
                &nbsp; {userSettings.uom === 'metric' ? <>kgs/day</> : <>lbs/day</>}
              </Form.Item>
            )}

            {itemToEdit === null ? (
              <Form.Item style={{ marginRight: 40 }}>
                <Button className="button-color" htmlType="submit" onClick={handleAddToRation}>
                  Add to Ration
                </Button>
              </Form.Item>
            ) : (
              <Form.Item style={{ marginRight: 40 }}>
                <Button style={{ marginRight: 10 }} className="button-color" onClick={handleSaveButton}>
                  Save
                </Button>
                <Button className="button-color" onClick={handleCancelButton}>
                  Cancel
                </Button>
              </Form.Item>
            )}
          </div>
        </Form>

        <div>
          <div className="added-feed-item-list">Added Ration Item List</div>
          <Table dataSource={feedItemList} bordered pagination={false} rowKey="id">
            <Column
              title="Item Type"
              dataIndex="type"
              key="type"
              render={(text, record) => {
                return <div style={{ textTransform: 'capitalize' }}>{record.type}</div>;
              }}
            />
            <Column
              title="Item Name"
              dataIndex="name"
              key="name"
              render={(text, record) => {
                return record.name;
              }}
            />
            <Column
              title="Code"
              dataIndex="code"
              key="code"
              align="center"
              render={(text, record) => {
                return record.code ? record.code : <div>-</div>;
              }}
            />
            <Column
              title={
                <div>
                  <div style={{ marginBottom: 5 }}>Amount</div>
                  <div>(per day)</div>
                </div>
              }
              dataIndex="amount"
              key="amount"
              align="center"
              render={(text, record) => {
                return renderAmountValueInTable(record);
              }}
            />
            <Column
              title="Actions"
              dataIndex="action"
              key="action"
              align="center"
              render={(text, record) => {
                let itemType = 'Forage';
                if (record.type === 'concentrate') itemType = 'Concentrate';
                if (record.type === 'supplement') itemType = 'Supplement';
                return (
                  <div>
                    <Button
                      icon="edit"
                      className="button-color"
                      onClick={() => {
                        handleEditForageList(record);
                      }}
                    >
                      Edit
                    </Button>
                    <Divider type="vertical" style={{ width: 2, height: 20, background: '#e8e8e8' }} />
                    <Popconfirm
                      title={`Delete ${itemType}. Are you sure?`}
                      onConfirm={() => {
                        handleDeleteForageList(record);
                      }}
                      okText="Yes"
                      icon={<Icon type="warning" style={{ color: '#CE0E2D' }} />}
                      cancelText="No"
                    >
                      <Button icon="delete" className="button-delete">
                        Delete
                      </Button>
                    </Popconfirm>
                  </div>
                );
              }}
            />
          </Table>
        </div>
      </div>
      <div>
        <div id="previousbutton">
          <Button
            className="button-color"
            onClick={() => {
              onClickPreviousOfForage();
            }}
          >
            Previous
          </Button>
        </div>
        <div id="nextbutton">
          <Button
            className="analyze-btn"
            onClick={() => {
              onClickNextOfForage();
            }}
            size="large"
          >
            Analyze Ration
          </Button>
        </div>
      </div>
    </div>
  );
};

AddForageByRationAnalyzer.propTypes = {
  farmData: PropTypes.array,
  onClickPreviousOfForage: PropTypes.func,
  onClickNextOfForage: PropTypes.func,
  addItemInForageList: PropTypes.func.isRequired,
  feedItemList: PropTypes.array.isRequired,
  deleteItemFormList: PropTypes.func.isRequired,
  updateItemInList: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
  }).isRequired,
  idOfSelectLoadRationHorse: PropTypes.func.isRequired,
  horseDataForLoadExisitingRation: PropTypes.array.isRequired,
  horseDietInLoadRation: PropTypes.array,
  selectHorseDietByLoadRation: PropTypes.func,
  checkLoadRation: PropTypes.bool,
  setCheckLoadRation: PropTypes.func.isRequired,
  onchangeCheckBox: PropTypes.func,
  loadRationCheckBox: PropTypes.bool.isRequired,
  idOfHorseInLoadRation: PropTypes.string,
  idOfDiet: PropTypes.string,
  idOfFarmForLoadRation: PropTypes.string,
  setIdOfFarmForLoadRation: PropTypes.func,
  onChangeSelectFarmOfLoadRation: PropTypes.func,
};

AddForageByRationAnalyzer.defaultProps = {
  onClickPreviousOfForage: null,
  onClickNextOfForage: null,
  horseDietInLoadRation: undefined,
  farmData: [],
  selectHorseDietByLoadRation: null,
  onchangeCheckBox: null,
  checkLoadRation: null,
  idOfHorseInLoadRation: undefined,
  idOfDiet: undefined,
  idOfFarmForLoadRation: undefined,
  setIdOfFarmForLoadRation: null,
  onChangeSelectFarmOfLoadRation: null,
};

export default Form.create({ name: 'AddForageByRationAnalyzer' })(AddForageByRationAnalyzer);
