import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Icon, message, Select, Table, Divider } from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { firebaseDb } from '../utils/firebase';
import './ViewFarmPage.scss';
import Heading from '../components/Heading';
import '../components/ButtonColor.scss';
import FarmDataInfo from '../components/FarmDataInfo';
import useAuth from '../utils/auth';
import logger from '../utils/helpers/logger';

const { Option } = Select;
const { Column } = Table;

const AllHorsePage = (props) => {
  const { history, match, farmId, farmData } = props;
  const [horseData, setHorseData] = useState([]);
  const [tableLoader, setTableLoader] = useState(true);
  const [deleteHorseId, setDeleteHorseId] = useState(true);
  const { userSettings, user, convertPoundToKg, setIdFromAuth } = useAuth();

  let selectedFarmData = null;
  if (farmId) {
    selectedFarmData = farmData.find((entry) => entry.id === farmId);
  }

  useEffect(() => {
    firebaseDb
      .collection('Farm')
      .doc(farmId)
      .collection('horses')
      .where('createdBy', '==', user.uid)
      .get()
      .then((querySnapshot) => {
        const newHorseData = [];
        querySnapshot.forEach((doc) => {
          newHorseData.push({ id: doc.id, ...doc.data() });
        });
        setTableLoader(false);
        setHorseData(newHorseData);
      })
      .catch((error) => {
        message.error(error.message);
        setTableLoader(false);
        logger(error);
      });
  }, []);

  const handleDelete = (dataToDelete) => {
    firebaseDb
      .collection('Farm')
      .doc(selectedFarmData.id)
      .collection('horses')
      .doc(dataToDelete.id)
      .delete()
      .then(() => {
        message.success(`Horse "${dataToDelete.name}" is successfully deleted!`);
        setHorseData(horseData.filter((item) => item.id !== dataToDelete.id));
      })
      .catch((error) => {
        message.error('Error in deleting the item!!');
        logger(error);
      });
  };

  const onClickHorseName = (horse) => {
    const obj = {};
    if (horse) {
      obj.horseInfo = horse;
      obj.farmItemId = farmId;
    }
    history.push(`${match.url}/view`, obj);

    // setIdFromAuth(obj);
  };

  return (
    <div style={{ padding: 20 }}>
      {selectedFarmData ? (
        <div>
          <FarmDataInfo selectedFarmData={selectedFarmData} showAllFieldTogether hideHeader />
          <Heading headingName="Horse Summary" />
          {horseData ? (
            <Table dataSource={horseData} bordered pagination={false} loading={tableLoader} rowKey="id">
              <Column
                title="Horse Name"
                dataIndex="name"
                key="name"
                align="left"
                render={(text, record) => {
                  return (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      role="button"
                      tabIndex="0"
                      onClick={() => {
                        onClickHorseName(record);
                      }}
                      onKeyPress={() => {}}
                    >
                      {record.name}
                    </a>
                  );
                }}
              />
              <Column
                title="Age"
                dataIndex="age"
                key="age"
                align="center"
                render={(text, record) => {
                  if (record.age === null) {
                    return <div>-</div>;
                  }
                  if (record.lifeStage === 'Growing') {
                    if (record.age <= 24) {
                      return `${record.age} months `;
                    }
                    return `${Math.floor(record.age / 12)} years ${record.age % 12} months   `;
                  }
                  return `${record.age > 12 ? Math.floor(record.age / 12) : 1} years`;
                }}
              />
              <Column
                title={userSettings.uom === 'metric' ? 'Body Weight (kgs)' : 'Body Weight (lbs)'}
                dataIndex="bodyWeight"
                key="bodyWeight"
                render={(text, record) => {
                  return convertPoundToKg(record.bodyWeight).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                }}
              />
              <Column
                title="Breed"
                dataIndex="breed"
                key="breed"
                render={(text, record) => {
                  return <div style={{ textTransform: 'capitalize' }}>{record.breed}</div>;
                }}
              />
              <Column
                title="Life Stage"
                dataIndex="lifeStage"
                key="lifeStage"
                render={(text, record) => {
                  return <div style={{ textTransform: 'capitalize' }}>{record.lifeStage}</div>;
                }}
              />
              <Column
                title="Life Stage Level"
                dataIndex="maintenanceLevel"
                key="maintenanceLevel"
                align="center"
                render={(text, record) => {
                  if (record.maintenanceLevel === null) {
                    return <div>-</div>;
                  }
                  return `${record.maintenanceLevel === 'Intense' ? 'Very Heavy' : record.maintenanceLevel}`;
                }}
              />
              <Column
                title="Options"
                key="Options"
                render={(text, record) => {
                  return (
                    <div>
                      <Button
                        icon="edit"
                        className="button-color"
                        onClick={() => {
                          history.push(`horses/${farmId}/${record.id}/edit`);
                        }}
                      >
                        Edit
                      </Button>
                      <Divider type="vertical" style={{ width: 2, height: 20, background: '#e8e8e8' }} />
                      <Popconfirm
                        title="Delete Horse. Are you sure?"
                        onConfirm={() => {
                          setDeleteHorseId(record.id);
                          handleDelete(record);
                        }}
                        okText="Yes"
                        icon={<Icon type="warning" style={{ color: '#CE0E2D' }} />}
                        cancelText="No"
                      >
                        <Button icon="delete" loading={record.id === deleteHorseId} className="button-delete">
                          Delete
                        </Button>
                      </Popconfirm>
                    </div>
                  );
                }}
              />
            </Table>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

AllHorsePage.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  farmId: PropTypes.string.isRequired,
  farmData: PropTypes.array.isRequired,
};

export default withRouter(AllHorsePage);
