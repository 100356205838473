import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Checkbox, Col, Drawer, Row, Input } from 'antd';
import { feedNutrientsForAnalysis, feedNutrientsForTable } from '../utils/globals';
import '../style.scss';
import useAuth from '../utils/auth';
import useAppData from '../contexts/appDataContext';
import './NutrientsSuppliedTable.scss';

const { Column } = Table;

const NutrientsSuppliedTable = (props) => {
  const {
    currentRationCalculationsData,
    hideHeader,
    recommendedRationCalculationsData,
    compare,
    nutrientKeys,
    setNutrientKeys,
    colorlessInGenReportPage,
    comments,
    setComments,
    totalAmount,
    totalAmountRecommend,
    compareGenReport,
    ratiosTable,
    setRatiosTable,
    showratioTableCheck,
    recommendedComments,
    setRecommendedComments,
  } = props;
  const { concCalculate } = useAppData();
  const [visibleDrawerForSuppliedGraph, setVisibleDrawerForSuppliedGraph] = useState(false);
  const { userSettings } = useAuth();
  const filteredNutrients = [];
  if (nutrientKeys.length !== 0) {
    feedNutrientsForTable.filter((nutrient) => {
      nutrientKeys.forEach((eachNut) => {
        if (nutrient.key === eachNut) {
          filteredNutrients.push(nutrient);
        }
      });
      return null;
    });
  } else {
    feedNutrientsForTable.forEach((nut) => {
      filteredNutrients.push(nut);
    });
  }

  const ratioTable = (rationCalculationsData) => {
    const dataSourceForRatioTable = [];

    feedNutrientsForTable.forEach((nutrient) => {
      dataSourceForRatioTable.push({
        key: nutrient.key,
        nutrientName: nutrient.nutrientName,
        units: nutrient.unit,
        dailySupplied: rationCalculationsData.amountFedPerNutrientTotal[nutrient.key],
        dailyRequired: rationCalculationsData.horseNutrientRequirement[nutrient.rCalcKey],
        percentRequired:
          rationCalculationsData.percentTotalFedOfReqd[nutrient.key] === Infinity
            ? 0
            : rationCalculationsData.percentTotalFedOfReqd[nutrient.key],
        comment: rationCalculationsData.acceptableRangesComments[nutrient.key],
      });
    });

    const pValue = dataSourceForRatioTable.find((data) => data.key === 'p');
    const caValue = dataSourceForRatioTable.find((data) => data.key === 'ca');
    const znValue = dataSourceForRatioTable.find((data) => data.key === 'zn');
    const cuValue = dataSourceForRatioTable.find((data) => data.key === 'cu');
    const displayValue = (firstValue, secondValue) => {
      if (firstValue && secondValue) {
        return `${(firstValue / secondValue).toFixed(2)}:1`;
      }
      if (secondValue === 0 && firstValue) {
        return '-';
      }
      return 0;
    };

    return [
      {
        key: 1,
        ratios: 'Ca:P',
        values: displayValue(
          Number(`${caValue ? caValue.dailySupplied : 0}`),
          Number(`${pValue ? pValue.dailySupplied : 0}`),
        ),
      },
      {
        key: 2,
        ratios: 'Zn:Cu',
        values: displayValue(
          Number(`${znValue ? znValue.dailySupplied : 0}`),
          Number(`${cuValue ? cuValue.dailySupplied : 0}`),
        ),
      },
    ];
  };

  const dataSource = [];

  filteredNutrients.forEach((nutrient) => {
    dataSource.push({
      key: nutrient.key,
      nutrientName: nutrient.nutrientName,
      units: nutrient.unit,
      dailySupplied: currentRationCalculationsData.amountFedPerNutrientTotal[nutrient.key],
      dailyRequired: currentRationCalculationsData.horseNutrientRequirement[nutrient.rCalcKey],
      percentRequired:
        currentRationCalculationsData.percentTotalFedOfReqd[nutrient.key] === Infinity
          ? 0
          : currentRationCalculationsData.percentTotalFedOfReqd[nutrient.key],
      comment: currentRationCalculationsData.acceptableRangesComments[nutrient.key],
    });
  });

  const dataSourceForRecommend = [];

  if (compare === true) {
    filteredNutrients.forEach((nutrient) => {
      dataSourceForRecommend.push({
        key: nutrient.key,
        nutrientName: nutrient.nutrientName,
        units: nutrient.unit,
        dailyRequired: recommendedRationCalculationsData.horseNutrientRequirement[nutrient.rCalcKey],
        dailySupplied: recommendedRationCalculationsData.amountFedPerNutrientTotal[nutrient.key],
        percentRequired:
          recommendedRationCalculationsData.percentTotalFedOfReqd[nutrient.key] === Infinity
            ? 0
            : recommendedRationCalculationsData.percentTotalFedOfReqd[nutrient.key],
        comment: recommendedRationCalculationsData.acceptableRangesComments[nutrient.key],
      });
    });
  }

  useEffect(() => {
    if (comments && setComments) {
      const obj = {};
      dataSource.forEach((data) => {
        if (data.key === 'sugar' || data.key === 'starch') {
          obj[data.key] = '';
        } else {
          obj[data.key] = data.comment;
        }
      });
      setComments(obj);
    }
    if (recommendedComments && setRecommendedComments) {
      const objRec = {};
      dataSourceForRecommend.forEach((data) => {
        if (data.key === 'sugar' || data.key === 'starch') {
          objRec[data.key] = '';
        } else {
          objRec[data.key] = data.comment;
        }
      });
      setRecommendedComments(objRec);
    }
  }, []);

  const showDrawer = () => {
    setVisibleDrawerForSuppliedGraph(true);
  };

  const onCloseDrawer = () => {
    setVisibleDrawerForSuppliedGraph(false);
  };

  const onChangeCheckBoxEnable = (checkedValues) => {
    setNutrientKeys(checkedValues);
  };

  const handleEditComment = (e, key) => {
    setComments({ ...comments, [key]: e.target.value });
  };

  const handleEditRecommendedComment = (e, key) => {
    setRecommendedComments({ ...recommendedComments, [key]: e.target.value });
  };

  const columns = [
    {
      title: 'Ratios',
      dataIndex: 'ratios',
      key: 'ratios',
    },
    {
      title: 'Values',
      dataIndex: 'values',
      key: 'values',
    },
  ];

  return (
    <>
      <Drawer
        width={320}
        title="Options"
        placement="right"
        onClose={onCloseDrawer}
        visible={visibleDrawerForSuppliedGraph}
      >
        <div style={{ fontSize: 14, color: 'black', marginTop: 20, marginBottom: 5 }}>Nutrients</div>
        <Checkbox.Group style={{ width: '100%' }} value={nutrientKeys} onChange={onChangeCheckBoxEnable}>
          <Row>
            {feedNutrientsForAnalysis.map((nutrient) => {
              if (nutrient.key !== 'percentDM') {
                return (
                  <Col key={nutrient.key} span={8} style={{ marginTop: 5 }}>
                    <Checkbox value={nutrient.key}>{nutrient.xAxisLabelName}</Checkbox>
                  </Col>
                );
              }
              return null;
            })}
          </Row>
        </Checkbox.Group>
      </Drawer>
      {compare ? (
        <div id="nutSuppCompareTables">
          {hideHeader ? null : (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ fontSize: 18, color: 'black', paddingLeft: 7 }}>
                  Daily Nutrients Supplied in Total Ration
                </div>
                <Button
                  icon="setting"
                  onClick={showDrawer}
                  style={{ background: 'none', color: '#1890ff', border: 'none' }}
                >
                  Options
                </Button>
              </div>
              <hr style={{ marginTop: 1, marginBottom: 20 }} />
            </>
          )}
          {compareGenReport ? null : (
            <>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  icon="setting"
                  onClick={showDrawer}
                  style={{
                    background: 'none',
                    color: '#1890ff',
                    border: 'none',
                  }}
                >
                  Options
                </Button>
              </div>
              <hr style={{ marginTop: 1, marginBottom: 20 }} />
            </>
          )}

          <div style={{ fontWeight: 600, textAlign: 'center', paddingBottom: 5, fontSize: 18 }}>Current Ration</div>
          <Table
            rowClassName={(record) => {
              if (colorlessInGenReportPage) {
                return null;
              }
              if (record.key === 'sugar') {
                return null;
              }
              if (record.key === 'starch') {
                return null;
              }
              if (record.comment === 'Target') {
                return 'target-row-color';
              }
              if (record.comment === 'Acceptable') {
                return 'acceptable-row-color';
              }
              if (record.comment === 'Excessive') {
                return 'row-color';
              }
              if (record.comment === 'Deficient') {
                return 'row-color';
              }
              return null;
            }}
            size="middle"
            dataSource={dataSource}
            bordered
            rowKey="id"
            pagination={{ pageSize: 9 }}
          >
            <Column
              title="Nutrient"
              dataIndex="nutrientName"
              key="nutrientName"
              render={(text, record) => {
                return record.nutrientName;
              }}
            />
            <Column
              title="Units"
              dataIndex="units"
              key="units"
              align="center"
              render={(text, record) => {
                if (userSettings.uom === 'imperial' && record.key === 'percentDM') {
                  return 'lbs';
                }
                return record.units;
              }}
            />
            <Column
              title="Daily Supplied"
              dataIndex="dailySupplied"
              key="dailySupplied"
              align="center"
              render={(text, record) => {
                if (record.key === 'percentDM' && userSettings.uom === 'imperial') {
                  return (record.dailySupplied / 0.453592).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                }
                return record.dailySupplied.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              }}
            />
            <Column
              title="Daily Required"
              dataIndex="dailyRequired"
              key="dailyRequired"
              align="center"
              render={(text, record) => {
                if (record.key === 'percentDM' && userSettings.uom === 'imperial') {
                  return (record.dailyRequired / 0.453592).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                }
                if (record.key === 'sugar' || record.key === 'starch') {
                  return '-';
                }
                return record.dailyRequired.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              }}
            />
            <Column
              title="% of Required"
              dataIndex="percentRequired"
              key="percentRequired"
              align="center"
              render={(text, record) => {
                if (record.key === 'sugar' || record.key === 'starch') {
                  return '-';
                }
                return record.percentRequired.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              }}
            />
            <Column
              title="Concentration"
              dataIndex="concentration"
              key="concentration"
              align="center"
              render={(text, record) => {
                return concCalculate(record.key, record.dailySupplied, totalAmount, userSettings);
              }}
            />
            <Column
              title="Comments"
              dataIndex="comment"
              key="comment"
              render={(text, record) => {
                if (comments && setComments) {
                  return (
                    <Input
                      value={comments[record.key]}
                      onChange={(e) => {
                        handleEditComment(e, record.key);
                      }}
                    />
                  );
                }
                if (record.key === 'sugar' || record.key === 'starch') {
                  return '-';
                }
                return record.comment;
              }}
            />
          </Table>
          <div style={{ fontWeight: 600, textAlign: 'center', paddingBottom: 5, fontSize: 18 }}>Recommended Ration</div>
          <Table
            rowClassName={(record) => {
              if (colorlessInGenReportPage) {
                return null;
              }
              if (record.key === 'sugar') {
                return null;
              }
              if (record.key === 'starch') {
                return null;
              }
              if (record.comment === 'Target') {
                return 'target-row-color';
              }
              if (record.comment === 'Acceptable') {
                return 'acceptable-row-color';
              }
              if (record.comment === 'Excessive') {
                return 'row-color';
              }
              if (record.comment === 'Deficient') {
                return 'row-color';
              }
              return null;
            }}
            size="middle"
            dataSource={dataSourceForRecommend}
            bordered
            rowKey={(record) => record.id}
            pagination={{ pageSize: 9 }}
          >
            <Column
              title="Nutrient "
              dataIndex="nutrientName"
              key="nutrientName"
              render={(text, record) => {
                return record.nutrientName;
              }}
            />
            <Column
              title="Units"
              dataIndex="units"
              key="units"
              align="center"
              render={(text, record) => {
                if (userSettings.uom === 'imperial' && record.key === 'percentDM') {
                  return 'lbs';
                }
                return record.units;
              }}
            />
            <Column
              title="Daily Supplied"
              dataIndex="dailySupplied"
              key="dailySupplied"
              align="center"
              render={(text, record) => {
                if (record.key === 'percentDM' && userSettings.uom === 'imperial') {
                  return (record.dailySupplied * 2.205).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                }
                return record.dailySupplied.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              }}
            />

            <Column
              title="Daily Required"
              dataIndex="dailyRequired"
              key="dailyRequired"
              align="center"
              render={(text, record) => {
                if (record.key === 'percentDM' && userSettings.uom === 'imperial') {
                  return (record.dailyRequired * 2.205).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                }
                if (record.key === 'sugar' || record.key === 'starch') {
                  return '-';
                }
                return record.dailyRequired.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              }}
            />

            <Column
              title="% of Required"
              dataIndex="percentRequired"
              key="percentRequired"
              align="center"
              render={(text, record) => {
                if (record.key === 'sugar' || record.key === 'starch') {
                  return '-';
                }
                return record.percentRequired.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              }}
            />
            <Column
              title="Concentration"
              dataIndex="concentration"
              key="concentration"
              align="center"
              render={(text, record) => {
                return concCalculate(record.key, record.dailySupplied, totalAmountRecommend, userSettings);
              }}
            />
            <Column
              title="Comments"
              dataIndex="comment"
              key="comment"
              render={(text, record) => {
                if (comments && setComments) {
                  return (
                    <Input
                      value={recommendedComments[record.key]}
                      onChange={(e) => {
                        handleEditRecommendedComment(e, record.key);
                      }}
                    />
                  );
                }
                if (record.key === 'sugar' || record.key === 'starch') {
                  return '-';
                }
                return record.comment;
              }}
            />
          </Table>
          {showratioTableCheck ? (
            <>
              <Checkbox
                checked={ratiosTable}
                onChange={(e) => {
                  setRatiosTable(e.target.checked);
                }}
                style={{ paddingLeft: 7, marginBottom: 10, marginTop: 20 }}
              >
                <span style={{ fontSize: 18, color: 'black' }}>Ratios</span>
              </Checkbox>
              <hr style={{ marginTop: 1, marginBottom: 20 }} />
            </>
          ) : null}
          {compare && !showratioTableCheck ? (
            <>
              <div style={{ fontSize: 18, color: 'black', paddingLeft: 7 }}>Ratios</div>
              <hr style={{ marginTop: 1, marginBottom: 20 }} />
            </>
          ) : null}
          <div style={{ fontWeight: 600, textAlign: 'center', paddingBottom: 5, fontSize: 18 }}>Current Ration</div>
          <div className="ratio-table">
            <Table
              style={{ width: '30%' }}
              size="middle"
              columns={columns}
              bordered
              dataSource={ratioTable(currentRationCalculationsData)}
              pagination={false}
            />
          </div>
          <div style={{ marginTop: 40, fontWeight: 600, textAlign: 'center', paddingBottom: 5, fontSize: 18 }}>
            Recommended Ration
          </div>
          <div className="ratio-table">
            <Table
              style={{ width: '30%' }}
              size="middle"
              columns={columns}
              bordered
              dataSource={ratioTable(recommendedRationCalculationsData)}
              pagination={false}
            />
          </div>
        </div>
      ) : (
        <div>
          {hideHeader ? null : (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ fontSize: 18, color: 'black', paddingLeft: 7 }}>
                  Daily Nutrients Supplied in Total Ration
                </div>
                <Button
                  icon="setting"
                  onClick={showDrawer}
                  style={{ background: 'none', color: '#1890ff', border: 'none' }}
                >
                  Options
                </Button>
              </div>
              <hr style={{ marginTop: 1, marginBottom: 20 }} />
            </>
          )}

          <Table
            rowClassName={(record) => {
              if (colorlessInGenReportPage) {
                return null;
              }
              if (record.key === 'sugar') {
                return null;
              }
              if (record.key === 'starch') {
                return null;
              }
              if (record.comment === 'Target') {
                return 'target-row-color';
              }
              if (record.comment === 'Acceptable') {
                return 'acceptable-row-color';
              }
              if (record.comment === 'Excessive') {
                return 'row-color';
              }
              if (record.comment === 'Deficient') {
                return 'row-color';
              }
              return null;
            }}
            size="middle"
            dataSource={dataSource}
            pagination={false}
            bordered
            rowKey={(record) => record.id}
          >
            <Column
              title="Nutrient"
              dataIndex="nutrientName"
              key="nutrientName"
              render={(text, record) => {
                return record.nutrientName;
              }}
            />
            <Column
              title="Units"
              dataIndex="units"
              key="units"
              align="center"
              render={(text, record) => {
                if (userSettings.uom === 'imperial' && record.key === 'percentDM') {
                  return 'lbs';
                }
                return record.units;
              }}
            />
            <Column
              title="Daily Supplied"
              dataIndex="dailySupplied"
              key="dailySupplied"
              align="center"
              render={(text, record) => {
                if (record.key === 'percentDM' && userSettings.uom === 'imperial') {
                  return (record.dailySupplied * 2.205).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                }
                return record.dailySupplied.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              }}
            />
            <Column
              title="Daily Required"
              dataIndex="dailyRequired"
              key="dailyRequired"
              align="center"
              render={(text, record) => {
                if (record.key === 'percentDM' && userSettings.uom === 'imperial') {
                  return (record.dailyRequired * 2.205).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                }
                if (record.key === 'sugar' || record.key === 'starch') {
                  return '-';
                }
                return record.dailyRequired.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              }}
            />

            <Column
              title="% of Required"
              dataIndex="percentRequired"
              key="percentRequired"
              align="center"
              render={(text, record) => {
                if (record.key === 'sugar' || record.key === 'starch') {
                  return '-';
                }
                return record.percentRequired.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              }}
            />
            <Column
              title="Concentration"
              dataIndex="concentration"
              key="concentration"
              align="center"
              render={(text, record) => {
                return concCalculate(record.key, record.dailySupplied, totalAmount, userSettings);
              }}
            />
            <Column
              title="Comments"
              dataIndex="comment"
              key="comment"
              render={(text, record) => {
                if (comments && setComments) {
                  return (
                    <Input
                      value={comments[record.key]}
                      onChange={(e) => {
                        handleEditComment(e, record.key);
                      }}
                    />
                  );
                }
                if (record.key === 'sugar' || record.key === 'starch') {
                  return '-';
                }
                return record.comment;
              }}
            />
          </Table>
          {showratioTableCheck ? (
            <>
              <Checkbox
                checked={ratiosTable}
                onChange={(e) => {
                  setRatiosTable(e.target.checked);
                }}
                style={{ paddingLeft: 7, marginBottom: 10, marginTop: 20 }}
              >
                <span style={{ fontSize: 18, color: 'black' }}>Ratios</span>
              </Checkbox>
              <hr style={{ marginTop: 1, marginBottom: 20 }} />
            </>
          ) : null}
          <div className="ratio-table">
            <Table
              style={{ width: '30%' }}
              size="middle"
              columns={columns}
              bordered
              dataSource={ratioTable(currentRationCalculationsData)}
              pagination={false}
            />
          </div>
        </div>
      )}
    </>
  );
};

NutrientsSuppliedTable.propTypes = {
  recommendedRationCalculationsData: PropTypes.object,
  hideHeader: PropTypes.bool,
  currentRationCalculationsData: PropTypes.object,
  compare: PropTypes.bool,
  colorlessInGenReportPage: PropTypes.bool,
  nutrientKeys: PropTypes.array,
  setNutrientKeys: PropTypes.func,
  comments: PropTypes.object,
  setComments: PropTypes.func,
  totalAmount: PropTypes.number.isRequired,
  totalAmountRecommend: PropTypes.number.isRequired,
  compareGenReport: PropTypes.bool,
  ratiosTable: PropTypes.bool.isRequired,
  setRatiosTable: PropTypes.func.isRequired,
  showratioTableCheck: PropTypes.bool.isRequired,
  recommendedComments: PropTypes.object,
  setRecommendedComments: PropTypes.func,
};

NutrientsSuppliedTable.defaultProps = {
  hideHeader: false,
  currentRationCalculationsData: {},
  compare: false,
  recommendedRationCalculationsData: null,
  nutrientKeys: [],
  colorlessInGenReportPage: false,
  comments: {},
  setComments: null,
  compareGenReport: false,
  setNutrientKeys: null,
  recommendedComments: {},
  setRecommendedComments: null,
};

export default NutrientsSuppliedTable;
