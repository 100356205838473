import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './GraphOfNutrientSupplied.scss';
import { Button, Checkbox, Col, Drawer, Radio, Row, InputNumber } from 'antd';
import { feedNutrientsForAnalysis } from '../utils/globals';
import './DrawerDesign.scss';
import NutrientSuppliedBarChart from './NutrientSuppliedBarChart';
import useAppData from '../contexts/appDataContext';

const GraphOfNutrientSupplied = (props) => {
  const {
    currentRationCalculationsData,
    hideHeader,
    compare,
    recommendedRationCalculationsData,
    nutSuppGraphChartType,
    setNutSuppGraphChartType,
    nutSuppGraphNutrients,
    setNutSuppGraphNutrients,
    maximumCap,
    setMaximumCap,
    compareGenReport,
  } = props;

  const { createKeysAndDataForGraph } = useAppData();
  const [visibleDrawerForSuppliedGraph, setVisibleDrawerForSuppliedGraph] = useState(false);

  const showDrawer = () => {
    setVisibleDrawerForSuppliedGraph(true);
  };

  const onCloseDrawer = () => {
    setVisibleDrawerForSuppliedGraph(false);
  };

  const onChangeRadioDrawer = (e) => {
    setNutSuppGraphChartType(e.target.value);
  };

  const onChangeCheckBoxEnable = (checkedValues) => {
    setNutSuppGraphNutrients(checkedValues);
  };

  const [finalDataForGraph, dataKeyForGraph] = createKeysAndDataForGraph(
    currentRationCalculationsData,
    nutSuppGraphNutrients.filter((nutrient) => nutrient !== 'sugar' && nutrient !== 'starch'),
    nutSuppGraphChartType,
  );

  const [finalDataForRecommendGraph, dataKeyForRecommendGraph] = createKeysAndDataForGraph(
    recommendedRationCalculationsData,
    nutSuppGraphNutrients.filter((nutrient) => nutrient !== 'sugar' && nutrient !== 'starch'),
    nutSuppGraphChartType,
  );

  const handleMaxCap = (value) => {
    setMaximumCap(value);
  };

  return (
    <>
      <Drawer
        width={320}
        title="Options"
        placement="right"
        onClose={onCloseDrawer}
        visible={visibleDrawerForSuppliedGraph}
      >
        <div style={{ fontSize: 14, color: 'black', marginTop: 0, marginBottom: 5 }}>Chart Type</div>
        <hr style={{ marginTop: 0, marginBottom: 20, color: '#e8e8e8' }} />
        <Radio.Group onChange={onChangeRadioDrawer} value={nutSuppGraphChartType}>
          <Radio value="standard">Standard Stacked</Radio>
          <Radio value="100%">100% Stacked</Radio>
        </Radio.Group>
        <div style={{ fontSize: 14, color: 'black', marginTop: 20, marginBottom: 5 }}>Max cap on y-axis</div>
        <hr style={{ marginTop: 0, marginBottom: 20, color: '#e8e8e8' }} />
        <InputNumber disabled={nutSuppGraphChartType === '100%'} onChange={handleMaxCap} value={maximumCap} />
        <div style={{ fontSize: 14, color: 'black', marginTop: 20, marginBottom: 5 }}>Nutrients</div>
        <hr style={{ marginTop: 0, marginBottom: 20, color: '#e8e8e8' }} />
        <Checkbox.Group style={{ width: '100%' }} value={nutSuppGraphNutrients} onChange={onChangeCheckBoxEnable}>
          <Row>
            {feedNutrientsForAnalysis.map((nutrient) => {
              if (nutrient.key !== 'percentDM') {
                return (
                  <Col
                    key={nutrient.key}
                    span={8}
                    style={
                      nutrient.key === 'starch' || nutrient.key === 'sugar'
                        ? { marginTop: 5, display: 'none' }
                        : { marginTop: 5 }
                    }
                  >
                    <Checkbox value={nutrient.key}>{nutrient.xAxisLabelName}</Checkbox>
                  </Col>
                );
              }
              return null;
            })}
          </Row>
        </Checkbox.Group>
      </Drawer>

      {compare ? (
        <div>
          {/* Header */}
          {hideHeader ? null : (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ fontSize: 18, color: 'black', paddingLeft: 7 }}>
                  Nutrients supplied as % of daily requirement
                </div>
                <Button
                  icon="setting"
                  onClick={showDrawer}
                  style={{ background: 'none', color: '#1890ff', border: 'none' }}
                >
                  Options
                </Button>
              </div>
              <hr style={{ marginTop: 1, marginBottom: 20 }} />
            </>
          )}
          {compareGenReport ? null : (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ fontSize: 18, color: 'black', paddingLeft: 7 }} />
                <Button
                  icon="setting"
                  onClick={showDrawer}
                  style={{ background: 'none', color: '#1890ff', border: 'none' }}
                >
                  Options
                </Button>
              </div>
              <hr style={{ marginTop: 1, marginBottom: 20 }} />
            </>
          )}

          {/* Graph */}
          <div style={{ padding: 20, marginBottom: 5 }}>
            <div style={{ textAlign: 'center', fontWeight: 600, fontSize: 18 }}>Current Ration</div>
            <NutrientSuppliedBarChart
              dataKeys={dataKeyForGraph}
              data={finalDataForGraph}
              compare={compare}
              chartType={nutSuppGraphChartType}
              margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
              maximumCap={maximumCap}
            />
          </div>

          {/* Graph */}
          <div style={{ padding: 20, marginBottom: 50 }}>
            <div style={{ textAlign: 'center', fontWeight: 600, fontSize: 18 }}>Recommended Ration</div>
            <NutrientSuppliedBarChart
              dataKeys={dataKeyForRecommendGraph}
              data={finalDataForRecommendGraph}
              compare={compare}
              chartType={nutSuppGraphChartType}
              margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
              maximumCap={maximumCap}
            />
          </div>
        </div>
      ) : (
        <div>
          {/* Header */}
          {hideHeader ? null : (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ fontSize: 18, color: 'black', paddingLeft: 7, marginBottom: 0 }}>
                  Nutrients supplied as % of daily requirement
                </div>
                <Button
                  icon="setting"
                  onClick={showDrawer}
                  style={{ background: 'none', color: '#1890ff', border: 'none' }}
                >
                  Options
                </Button>
              </div>
              <hr style={{ marginTop: 1, marginBottom: 20 }} />
            </>
          )}

          {/* Graph */}
          <div style={{ paddingTop: 20, paddingBottom: 20, marginBottom: 50 }}>
            <NutrientSuppliedBarChart
              dataKeys={dataKeyForGraph}
              data={finalDataForGraph}
              compare={compare}
              chartType={nutSuppGraphChartType}
              margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
              maximumCap={maximumCap}
            />
          </div>
        </div>
      )}
    </>
  );
};

GraphOfNutrientSupplied.propTypes = {
  nutSuppGraphChartType: PropTypes.string.isRequired,
  setNutSuppGraphChartType: PropTypes.func.isRequired,
  nutSuppGraphNutrients: PropTypes.array.isRequired,
  setNutSuppGraphNutrients: PropTypes.func.isRequired,
  currentRationCalculationsData: PropTypes.object.isRequired,
  hideHeader: PropTypes.bool,
  recommendedRationCalculationsData: PropTypes.object,
  compare: PropTypes.bool,
  maximumCap: PropTypes.number,
  setMaximumCap: PropTypes.func,
  compareGenReport: PropTypes.bool,
};

GraphOfNutrientSupplied.defaultProps = {
  hideHeader: false,
  compare: false,
  recommendedRationCalculationsData: null,
  maximumCap: null,
  setMaximumCap: null,
  compareGenReport: false,
};

export default GraphOfNutrientSupplied;
