import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PropTypes from 'prop-types';
import CreateFarmPage from './CreateFarmPage';
import AllFarmsPage from './AllFarmsPage';
import EditFarmPage from './EditFarmPage';
import ViewFarmPage from './ViewFarmPage';

const FarmLayoutPage = (props) => {
  const { match, location, history } = props;

  let selectedIndex = 0;

  if (location.pathname.includes('edit')) {
    selectedIndex = 3;
  }
  if (location.pathname.includes('create')) {
    selectedIndex = 2;
  }
  if (location.pathname.includes('view')) {
    selectedIndex = 1;
  }

  const handleTabchange = (value) => {
    if (value !== selectedIndex && value === 0) {
      history.push(`${match.url}`);
    }
    if (value !== selectedIndex && value === 1) {
      history.push(`${match.url}/view`);
    }
    if (value !== selectedIndex && value === 2) {
      history.push(`${match.url}/create`);
    }
    if (value !== selectedIndex && value === 3 && match.params.farmid !== undefined) {
      history.push(`${match.url}/${match.params.farmid}/edit`);
    }
  };

  return (
    <Tabs selectedIndex={selectedIndex} onSelect={(value) => handleTabchange(value)}>
      <TabList style={{ padding: '0px 35px' }}>
        <Tab>View All Farms</Tab>
        <Tab>View Farm Profile</Tab>
        <Tab>Add new Farm</Tab>
        <Tab disabled>Edit Farm Profile</Tab>
      </TabList>
      <TabPanel>
        <Route path={`${match.url}`} component={AllFarmsPage} />
      </TabPanel>
      <TabPanel>
        <Route path={`${match.url}/:farmid`} component={ViewFarmPage} />
      </TabPanel>
      <TabPanel>
        <Route path={`${match.url}/create`} component={CreateFarmPage} />
      </TabPanel>
      <TabPanel>
        <Route path={`${match.url}/:farmid/edit`} component={EditFarmPage} />
      </TabPanel>
    </Tabs>
  );
};

FarmLayoutPage.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(FarmLayoutPage);
