import React, { useState, useEffect } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Row, Col, Button, Popconfirm, Icon, message, Select, Table, Divider } from 'antd';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PropTypes from 'prop-types';
import CreateHorsePage from './CreateHorsePage';
import AllHorsePage from './AllHorsePage';
import EditHorsePage from './EditHorsePage';
import ViewHorsePage from './ViewHorsePage';
import useAuth from '../utils/auth';
import { firebaseDb } from '../utils/firebase';
import logger from '../utils/helpers/logger';

const { Option } = Select;

const HorseLayoutPage = (props) => {
  const { match, location, history } = props;
  const [farmId, setFarmId] = useState(undefined);
  const [farmData, setFarmData] = useState([]);
  const { idFromAuth, user } = useAuth();

  useEffect(() => {
    firebaseDb
      .collection('Farm')
      .where('createdBy', '==', user.uid)
      .get()
      .then((querySnapshot) => {
        const newFarmData = [];
        querySnapshot.forEach((doc) => {
          newFarmData.push({ id: doc.id, ...doc.data() });
        });
        setFarmData(newFarmData);
      })
      .catch((error) => {
        message.error(error.message);
        logger(error);
      });
  }, []);

  let selectedIndex = 0;

  if (location.pathname.includes('edit')) {
    selectedIndex = 3;
  }
  if (location.pathname.includes('create')) {
    selectedIndex = 2;
  }
  if (location.pathname.includes('view')) {
    selectedIndex = 1;
  }

  const handleTabchange = (value) => {
    // setIdFromAuth({});
    if (value !== selectedIndex && value === 0) {
      history.push(`${match.url}`);
    }
    if (value !== selectedIndex && value === 1) {
      history.push(`${match.url}/view`);
    }
    if (value !== selectedIndex && value === 2) {
      history.push(`${match.url}/create`);
    }
    if (value !== selectedIndex && value === 3 && match.params.horseid !== undefined) {
      history.push(`${match.url}/${match.params.horseid}/edit`);
    }
  };

  const handleChangeSelectFarm = (value) => {
    setFarmId(value);
  };

  return (
    <div>
      <Row style={{ padding: 10, paddingTop: 0, paddingLeft: 16 }}>
        <Col offset={2} span={14}>
          <span className="farm-select">Select a Farm:</span>
          <Select
            showSearch
            style={{ width: 350 }}
            placeholder="Search for Farm by Name/City/State/Zipcode"
            optionFilterProp="data"
            onChange={handleChangeSelectFarm}
            value={farmId}
          >
            {farmData.map((item) => (
              <Option key={item.id} value={item.id} data={`${item.name}_${item.city}_${item.state}_${item.zip}`}>
                {`${item.name}`}
              </Option>
            ))}
          </Select>
          <span className="help-text-select-farm">Select a farm to see its Horses</span>
        </Col>
        {farmId ? (
          <Col span={2}>
            <Button
              size="large"
              icon="plus"
              className="button-color"
              onClick={() => {
                history.push('/horses/create');
              }}
            >
              Add New Horse
            </Button>
          </Col>
        ) : null}
      </Row>
      {farmId ? (
        <Tabs selectedIndex={selectedIndex} onSelect={(value) => handleTabchange(value)}>
          <TabList style={{ padding: '0px 35px' }}>
            <Tab>View All Horses</Tab>
            <Tab>View Horse Profile</Tab>
            <Tab>Add new Horse</Tab>
            <Tab disabled>Edit Horse Profile</Tab>
          </TabList>
          <TabPanel>
            <Route path={`${match.url}`} component={() => <AllHorsePage farmId={farmId} farmData={farmData} />} />
          </TabPanel>
          <TabPanel>
            <Route
              path={`${match.url}/:horseid`}
              component={() => (
                <ViewHorsePage itemIdFarm={idFromAuth ? idFromAuth.farmItemId : farmId} farmData={farmData} />
              )}
            />
          </TabPanel>
          <TabPanel>
            <Route
              path={`${match.url}/create`}
              component={() => <CreateHorsePage farmId={farmId} farmData={farmData} />}
            />
          </TabPanel>
          <TabPanel>
            <Route path={`${match.url}/:farmid/:horseid/edit`} component={EditHorsePage} />
          </TabPanel>
        </Tabs>
      ) : null}
    </div>
  );
};

HorseLayoutPage.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(HorseLayoutPage);
