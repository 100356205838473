import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const firebaseConfig = {
  development: {
    apiKey: 'AIzaSyAVo2K31_5_b7hWEyjbxJmfnLH5f8MW7Ao',
    authDomain: 'ecp25-dev.firebaseapp.com',
    databaseURL: 'https://ecp25-dev.firebaseio.com',
    projectId: 'ecp25-dev',
    storageBucket: 'ecp25-dev.appspot.com',
    messagingSenderId: '271129523591',
    appId: '1:271129523591:web:9c52ea22d762f05a340f22',
  },
  staging: {
    apiKey: 'AIzaSyDiI9LeUb23B7xodFiqXAmw1k8ZK4Bpe20',
    authDomain: 'ecp25-staging.firebaseapp.com',
    databaseURL: 'https://ecp25-staging.firebaseio.com',
    projectId: 'ecp25-staging',
    storageBucket: 'ecp25-staging.appspot.com',
    messagingSenderId: '644418912242',
    appId: '1:644418912242:web:92b4269c277c4f360cf56b',
  },
  production: {
    apiKey: 'AIzaSyCvr28Sb4-A9mXk3sEQD8c_qKbNKi7WrGg',
    authDomain: 'ecp25-production.firebaseapp.com',
    databaseURL: 'https://ecp25-production.firebaseio.com',
    projectId: 'ecp25-production',
    storageBucket: 'ecp25-production.appspot.com',
    messagingSenderId: '590432642200',
    appId: '1:590432642200:web:937c18a85de8a5f3e35ced',
  },
};

export const app = firebase.initializeApp(firebaseConfig[process.env.REACT_APP_ENVIRONMENT || 'development']);

export const firebaseStorage = firebase.storage();

export const firebaseDb = firebase.firestore();

export const firebaseAuth = firebase.auth();

export const firebaseFunc = firebase.functions();

export default firebase;
