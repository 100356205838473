import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { firebaseDb } from './firebase';

export const AuthContext = React.createContext({
  user: {
    uid: null,
  },
  setUser: () => {},
  userSettings: null,
  updatePasswordOnLogin: null,
  setUserSettings: () => {},
  setUpdatePasswordOnLogin: () => {},
  userRole: '',
  setUserRole: () => {},
});

const useAuth = () => useContext(AuthContext);

const calculateUnreadCount = (querySnapshot, userDoc) => {
  let newUnreadCount = 0;
  const { notificationLastReadAt } = userDoc.data();

  querySnapshot.forEach((doc) => {
    const notificationData = doc.data();
    if (
      notificationLastReadAt &&
      notificationLastReadAt.seconds &&
      notificationData &&
      notificationData.createdAt &&
      notificationData.createdAt.seconds &&
      notificationData.createdAt.seconds > notificationLastReadAt.seconds
    ) {
      newUnreadCount++;
    }
  });
  return newUnreadCount;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({
    uid: null,
  });
  const [userSettings, setUserSettings] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [idFromAuth, setIdFromAuth] = useState(null);
  const [updatePasswordOnLogin, setUpdatePasswordOnLogin] = useState(null);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

  console.log('unreadNotificationsCount', unreadNotificationsCount);

  useEffect(() => {
    if (user && user.uid) {
      return firebaseDb
        .collection('Notification')
        .orderBy('createdAt', 'desc')
        .limit(50)
        .onSnapshot(async (querySnapshot) => {
          const userDoc = await firebaseDb
            .collection('User')
            .doc(user.uid)
            .get();
          setUnreadNotificationsCount(calculateUnreadCount(querySnapshot, userDoc));
        });
    }
    return () => {};
  }, [user.uid]);

  useEffect(() => {
    if (user && user.uid) {
      return firebaseDb
        .collection('User')
        .doc(user.uid)
        .onSnapshot(async (userDoc) => {
          const querySnapshot = await firebaseDb
            .collection('Notification')
            .orderBy('createdAt', 'desc')
            .limit(50)
            .get();
          setUnreadNotificationsCount(calculateUnreadCount(querySnapshot, userDoc));
        });
    }
    return () => {};
  }, [user.uid]);

  const convertKgToPound = (value) => {
    if (userSettings.uom === 'metric') {
      const v = value / 0.453592;
      return v;
    }
    return value;
  };

  const convertCmToInch = (value) => {
    if (userSettings.uom === 'metric') {
      const v = value / 2.54;
      return v;
    }
    return value;
  };

  const convertPoundToKg = (value) => {
    if (userSettings.uom === 'metric') {
      const v = value * 0.453592;
      return v;
    }
    return value;
  };

  const convertInchToCm = (value) => {
    if (userSettings.uom === 'metric') {
      const v = value * 2.54;
      return v;
    }
    return value;
  };

  const convertOzToGram = (value) => {
    if (userSettings.uom === 'metric') {
      const v = value * 28.3495;
      return v;
    }
    return value;
  };

  const convertGramToOz = (value) => {
    if (userSettings.uom === 'metric') {
      const v = value / 28.3495;
      return v;
    }
    return value;
  };

  const convertGramToKg = (value) => {
    const v = value / 1000;
    return v;
  };

  const convertOzToLbs = (value) => {
    const v = value / 16;
    return v;
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        userSettings,
        setUserSettings,
        setUpdatePasswordOnLogin,
        updatePasswordOnLogin,
        convertKgToPound,
        convertCmToInch,
        convertPoundToKg,
        convertInchToCm,
        convertOzToGram,
        convertGramToOz,
        convertGramToKg,
        convertOzToLbs,
        idFromAuth,
        setIdFromAuth,
        userRole,
        setUserRole,
        unreadNotificationsCount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default useAuth;
