import React from 'react';
import { hot } from 'react-hot-loader/root';
import 'antd/dist/antd.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './utils/auth';
import { AppDataProvider } from './contexts/appDataContext';
import App from './App';

const Root = () => {
  return (
    <AuthProvider>
      <AppDataProvider>
        <Router>
          <App />
        </Router>
      </AppDataProvider>
    </AuthProvider>
  );
};

export default hot(Root);
