import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Select, message, Popconfirm, Icon } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { firebaseDb } from '../utils/firebase';
import './ViewFeedComponent.scss';
import { feedNutrients } from '../utils/globals';
import Heading from './Heading';
import logger from '../utils/helpers/logger';
import './ButtonColor.scss';

const { Option, OptGroup } = Select;

const renderSelectOption = (item) => (
  <Option key={item.id} value={item.id}>
    {`${item.name}${item.code ? ` (${item.code})` : ''}`}
  </Option>
);

const ViewFeedComponent = (props) => {
  const { history, feedItem, labelName } = props;
  const [feedData, setFeedData] = useState([]);
  const [itemId, setItemId] = useState(undefined);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const onChangeSelect = (value) => {
    setItemId(value);
  };

  useEffect(() => {
    firebaseDb
      .collection('Feed')
      .where('type', '==', `${feedItem}`)
      .get()
      .then((querySnapshot) => {
        const newFeedData = [];
        querySnapshot.forEach((doc) => {
          newFeedData.push({ id: doc.id, ...doc.data() });
        });
        setFeedData(newFeedData);
      })
      .catch((error) => {
        message.error(error.message);
        logger(error);
      });
  }, []);

  const handleAddClick = () => {
    if (feedItem === 'forage') {
      history.push('/feeds/forages/create');
    }
    if (feedItem === 'concentrate') {
      history.push('/feeds/concentrates/create');
    }
    if (feedItem === 'supplement') {
      history.push('/feeds/supplements/create');
    }
  };

  const handleUploadClick = () => {
    if (feedItem === 'forage') {
      history.push('/feeds/forages/bulkUpload');
    }
    if (feedItem === 'concentrate') {
      history.push('/feeds/concentrates/bulkUpload');
    }
    if (feedItem === 'supplement') {
      history.push('/feeds/supplements/bulkUpload');
    }
  };

  const handleEdit = (editId) => {
    if (feedItem === 'forage') {
      history.push(`/feeds/forages/${editId}/edit`);
    }
    if (feedItem === 'concentrate') {
      history.push(`/feeds/concentrates/${editId}/edit`);
    }
    if (feedItem === 'supplement') {
      history.push(`/feeds/supplements/${editId}/edit`);
    }
  };

  let selectedFeedData = null;
  if (itemId) {
    selectedFeedData = feedData.find((entry) => entry.id === itemId);
  }

  const handleDelete = () => {
    setLoadingDelete(true);
    firebaseDb
      .collection('Feed')
      .doc(itemId)
      .delete()
      .then(() => {
        message.success(`${labelName} "${selectedFeedData.name}" is successfully deleted!`);
        setFeedData(feedData.filter((item) => item.id !== itemId));
        setItemId(undefined);
        setLoadingDelete(false);
      })
      .catch((error) => {
        message.error('Error in deleting the item!!');
        logger(error);
        setLoadingDelete(false);
      });
  };

  const handlePopupCancel = (e) => {
    message.error(`${labelName} "${selectedFeedData.name}" is not deleted`);
    setLoadingDelete(false);
  };

  const categoryOfFeed = () => {
    if (selectedFeedData.category === 'purinaProduct') {
      return 'Purina Product';
    }
    if (selectedFeedData.category === 'individualIngredient') {
      return 'Individual Ingredient';
    }
    if (selectedFeedData.category === 'customEntry') {
      return 'Custom Entry';
    }
    if (selectedFeedData.category === 'otherCommercialProduct') {
      return 'Other Commercial Product';
    }
    if (selectedFeedData.category === 'uncategorized') {
      return 'Uncategorized';
    }
    if (selectedFeedData.category === 'bookValues') {
      return 'Book Values';
    }
    return null;
  };

  feedData.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  return (
    <div>
      <Row>
        <Col span={16}>
          <div className="select-container">
            <span className="select-item-name">{`Select ${labelName} item`}:</span>
            <Select
              showSearch
              style={{ width: 300 }}
              placeholder={`Select ${labelName} item`}
              optionFilterProp="children"
              onChange={onChangeSelect}
              value={itemId}
            >
              <OptGroup label="Purina Products">
                {feedData.filter((item) => item.category === 'purinaProduct').map((item) => renderSelectOption(item))}
              </OptGroup>
              <OptGroup label="Book Values">
                {feedData.filter((item) => item.category === 'bookValues').map((item) => renderSelectOption(item))}
              </OptGroup>

              <OptGroup label="Individual Ingredients">
                {feedData
                  .filter((item) => item.category === 'individualIngredient')
                  .map((item) => renderSelectOption(item))}
              </OptGroup>

              <OptGroup label="Custom Entries">
                {feedData.filter((item) => item.category === 'customEntry').map((item) => renderSelectOption(item))}
              </OptGroup>

              <OptGroup label="Other Commercial Products">
                {feedData
                  .filter((item) => item.category === 'otherCommercialProduct')
                  .map((item) => renderSelectOption(item))}
              </OptGroup>

              <OptGroup label="Uncategorized">
                {feedData
                  .filter((item) => item.category === 'uncategorized' || !item.category)
                  .map((item) => renderSelectOption(item))}
              </OptGroup>
            </Select>
          </div>
          <div className="select-message">{`Select a ${feedItem} item to see its composition`}</div>
        </Col>
        <Col span={3}>
          <Button className="button-color" size="large" icon="plus" onClick={handleAddClick}>
            Add New
          </Button>
        </Col>
        <Col span={5}>
          <Button className="button-color" size="large" icon="upload" onClick={handleUploadClick}>
            Upload Spreadsheet
          </Button>
        </Col>
      </Row>
      {selectedFeedData ? (
        <div style={{ paddingTop: 40 }}>
          <div className="feed-heading">
            <Col span={16}>
              <div className="feed-name-code">
                <span>
                  {`${labelName} Name: `}
                  <span>{selectedFeedData.name}</span>
                </span>
                {selectedFeedData.code ? (
                  <span>
                    {`${labelName} Code: `}
                    <span style={{ textTransform: 'none' }}>{selectedFeedData.code}</span>
                  </span>
                ) : null}

                <span>
                  {`${labelName} Category: `}
                  <span style={{ textTransform: 'capitalize' }}>{categoryOfFeed()}</span>
                </span>
              </div>
            </Col>
            <Col span={8} style={{ display: 'flex' }}>
              <div style={{ marginRight: 30 }}>
                <Button
                  className="button-color"
                  icon="edit"
                  onClick={() => {
                    handleEdit(itemId);
                  }}
                >
                  Edit Item
                </Button>
              </div>
              <div>
                <Popconfirm
                  title={`Delete ${labelName} item. Are you sure?`}
                  onConfirm={() => {
                    handleDelete(itemId);
                  }}
                  onCancel={handlePopupCancel}
                  okText="Yes"
                  cancelText="No"
                  icon={<Icon type="warning" style={{ color: '#CE0E2D' }} />}
                >
                  <Button className="button-delete" icon="delete" loading={loadingDelete}>
                    Delete Item
                  </Button>
                </Popconfirm>
              </div>
            </Col>
          </div>
          <Heading headingName="Item Composition (DM basis)" />
          <Row className="nutrients">
            {feedNutrients.map((feed) => {
              const name = feed.key;
              return (
                <Col className="nutrient" key={feed.key}>
                  <Col className="nutrient-name">{feed.label}:</Col>
                  <Col className="nutrient-value">
                    {isNaN(selectedFeedData[name])
                      ? (0.0).toFixed(feed.precision)
                      : selectedFeedData[name].toFixed(feed.precision)}
                  </Col>
                </Col>
              );
            })}
          </Row>
        </div>
      ) : null}
    </div>
  );
};

ViewFeedComponent.propTypes = {
  feedItem: PropTypes.oneOf(['forage', 'concentrate', 'supplement']).isRequired,
  history: PropTypes.object.isRequired,
  labelName: PropTypes.string.isRequired,
};

export default withRouter(ViewFeedComponent);
