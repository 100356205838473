import React, { useState } from 'react';
import { Button, Upload, Icon, message } from 'antd';
import XLSX from 'xlsx';
import PropTypes from 'prop-types';
import SpreadSheetPreview from './SpreadSheetPreview';
import { feedNutrients } from '../utils/globals';
import './ButtonColor.scss';

const { Dragger } = Upload;

const BulkUploadComponent = (props) => {
  const { href, typeOfData, nameOfButton, columnName } = props;
  const [feedToCreate, setFeedToCreate] = useState([]);
  const [fileName, setFileName] = useState('');
  const [errors, setErrors] = useState(false);

  const onAddClickEmptyData = () => {
    setFeedToCreate([]);
  };

  return (
    <div>
      {feedToCreate.length > 0 ? (
        <div>
          <SpreadSheetPreview
            onAddClickEmptyData={onAddClickEmptyData}
            dataToShow={feedToCreate}
            nameOfFile={fileName}
            typeOfData={typeOfData}
            nameOfButton={nameOfButton}
            columnName={columnName}
            errors={errors}
            setErrors={setErrors}
          />
        </div>
      ) : (
        <div>
          <Button href={href} className="button-color" icon="download">
            Download template file
          </Button>
          <div className="support-file">
            Supported file types are: <span>.xls &amp; .xlsx</span>
          </div>
          <div style={{ width: 500 }}>
            <Dragger
              beforeUpload={(file) => {
                setFileName(file.name);
                if (
                  file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
                  file.type !== 'application/vnd.ms-excel'
                ) {
                  message.error('You can upload only .xls or .xlsx file');
                } else {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetDataToJson = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
                      header: ['category', 'name', 'code', ...feedNutrients.map((entry) => entry.key)],
                      defval: 0,
                    });

                    sheetDataToJson.forEach((item) => {
                      if (item.name === 0 || item.category === 0) {
                        return '';
                      }
                      return null;
                    });
                    const newFeedToCreateList = [];
                    sheetDataToJson.forEach((item, index) => {
                      const newItem = { ...item };
                      if (!item.category && !item.name) {
                        setErrors(true);
                      } else if (!item.category) {
                        setErrors(true);
                      } else if (!item.name) {
                        setErrors(true);
                      }
                      newFeedToCreateList.push(newItem);
                    });
                    setFeedToCreate(newFeedToCreateList);
                  };
                  reader.readAsArrayBuffer(file);
                }
                return false;
              }}
              fileList={[]}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </Dragger>
          </div>
        </div>
      )}
    </div>
  );
};

BulkUploadComponent.propTypes = {
  href: PropTypes.string.isRequired,
  typeOfData: PropTypes.string.isRequired,
  nameOfButton: PropTypes.string.isRequired,
  columnName: PropTypes.string.isRequired,
};

export default BulkUploadComponent;
