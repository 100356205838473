import React, { useEffect, useState } from 'react';
import { Table, Button, Divider, Popconfirm, Icon, message } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { firebaseDb } from '../utils/firebase';
import Heading from '../components/Heading';
import '../components/ButtonColor.scss';
import useAuth from '../utils/auth';
import logger from '../utils/helpers/logger';

const { Column } = Table;

const AllFarmsPage = (props) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [farmData, setFarmsData] = useState([]);
  const [deleteFarmId, setDeleteFarmId] = useState(null);
  const { history } = props;
  useEffect(() => {
    firebaseDb.collection('Farm').onSnapshot((querySnapshot) => {
      const farm = [];
      querySnapshot.forEach((doc) => {
        if (user.uid === doc.data().createdBy) {
          farm.push({ id: doc.id, ...doc.data() });
        }
      });
      setFarmsData(farm);
      setLoading(false);
    });
  }, []);

  const handleDelete = (record) => {
    firebaseDb
      .collection('Farm')
      .doc(record.id)
      .delete()
      .then(() => {
        message.success(`Farm "${record.name}" is successfully deleted!`);
        setFarmsData(farmData.filter((item) => item.id !== record.id));
        setDeleteFarmId(null);
      })
      .catch((error) => {
        message.error('Error in deleting the item!!');
        logger(error);
        setDeleteFarmId(null);
      });
  };

  return (
    <div style={{ padding: 35 }}>
      <Heading headingName="Farms Summary" />
      <Table
        dataSource={farmData}
        scroll={{ x: 'max-content' }}
        bordered
        pagination={false}
        loading={loading}
        rowKey="id"
      >
        <Column
          title="Farm Name"
          dataIndex="name"
          key="name"
          align="left"
          render={(text, record) => {
            return record.name;
          }}
        />
        <Column
          title="Manager Name"
          dataIndex="managerName"
          key="managerName"
          render={(text, record) => {
            return <div style={{ textTransform: 'capitalize' }}>{record.managerName}</div>;
          }}
        />
        <Column
          title="Manager Email ID"
          dataIndex="managerEmail"
          key="managerEmail"
          render={(text, record) => {
            return record.managerEmail;
          }}
        />
        <Column
          title="Manager Phone"
          dataIndex="managerPhone"
          key="managerPhone"
          render={(text, record) => {
            return record.managerMobile;
          }}
        />
        <Column
          title="Sales Rep name"
          dataIndex="salesRepName"
          key="salesRepName"
          render={(text, record) => {
            return <div style={{ textTransform: 'capitalize' }}>{record.salesRepName}</div>;
          }}
        />
        <Column
          title="Sales Rep email ID"
          dataIndex="salesRepEmail"
          key="salesRepEmail"
          render={(text, record) => {
            return record.salesRepEmail;
          }}
        />
        <Column
          title="Options"
          key="Options"
          render={(text, record) => {
            return (
              <div>
                <Button
                  icon="edit"
                  className="button-color"
                  onClick={() => {
                    history.push(`/farms/${record.id}/edit`);
                  }}
                >
                  Edit
                </Button>
                <Divider type="vertical" style={{ width: 2, height: 20, background: '#e8e8e8' }} />
                <Popconfirm
                  title="Delete Farm. Are you sure?"
                  onConfirm={() => {
                    setDeleteFarmId(record.id);
                    handleDelete(record);
                  }}
                  okText="Yes"
                  icon={<Icon type="warning" style={{ color: '#CE0E2D' }} />}
                  cancelText="No"
                >
                  <Button icon="delete" loading={record.id === deleteFarmId} className="button-delete">
                    Delete
                  </Button>
                </Popconfirm>
              </div>
            );
          }}
        />
      </Table>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
        <Link to="/farms/create">
          <Button size="large" className="button-color" icon="plus">
            Add New Farm
          </Button>
        </Link>
      </div>
    </div>
  );
};

AllFarmsPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AllFarmsPage;
