import React, { useState } from 'react';
import { Form, Row, Col, Input, Icon, Button, Card } from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { firebaseAuth } from '../utils/firebase';
import logo from '../assests/images/LatestLogo.jpg';
import './LoginPage.scss';
import '../components/ButtonColor.scss';
import useAuth from '../utils/auth';
import logger from '../utils/helpers/logger';

const LoginPage = (props) => {
  const [signInLoading, setSignInLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [resetError, setResetError] = useState('');
  const { form, history } = props;
  const { getFieldValue, resetFields, setFields } = form;
  const { updatePasswordOnLogin } = useAuth();

  const handleSubmit = (e) => {
    setSignInLoading(true);
    e.preventDefault();
    // Reset error messages
    setResetError('');
    const values = form.getFieldsValue();
    setFields({
      email: {
        value: values.email,
        errors: null,
      },
      password: {
        value: values.password,
        errors: null,
      },
    });

    firebaseAuth.signInWithEmailAndPassword(values.email, values.password).catch((error) => {
      logger(error);
      if (error.code === 'auth/user-not-found' || error.code === 'auth/invalid-email') {
        setFields({
          email: {
            errors: [new Error('The entered Email is incorrect')],
          },
        });
        resetFields();
      }
      if (error.code === 'auth/wrong-password') {
        setFields({
          password: {
            errors: [new Error('The entered Password is incorrect')],
          },
        });
        resetFields('password');
      }
      setSignInLoading(false);
    });
  };

  const handleReset = (e) => {
    setResetLoading(true);
    e.preventDefault();
    // Reset error messages
    setResetError('');
    const values = form.getFieldsValue();
    setFields({
      email: {
        value: values.email,
        errors: null,
      },
      password: {
        value: values.password,
        errors: null,
      },
    });

    firebaseAuth
      .sendPasswordResetEmail(values.email)
      .then(function() {
        setResetError('success');
        setResetLoading(false);
      })
      .catch(function(error) {
        logger(error);
        if (error.code === 'auth/user-not-found' || error.code === 'auth/invalid-email') {
          setFields({
            email: {
              errors: [new Error('The entered Email is incorrect')],
            },
          });
          resetFields();
          setResetLoading(false);
        } else {
          setResetError(error);
          setResetLoading(false);
        }
      });
  };

  // Condition for enable and disable sign in button and reset password

  const enabledSignIn = getFieldValue('email') !== undefined && getFieldValue('password') !== undefined;
  const enableReset = getFieldValue('email') !== undefined;

  const errorIndicationOnClickReset = () => {
    if (resetError === 'success') {
      return 'An email has been sent to your Email ID. Please follow the instructions in it to reset your password.';
    }
    if (resetError.code === 'auth/user-not-found' || resetError.code === 'auth/invalid-email') {
      return 'The entered Email is incorrect';
    }
    if (resetError) return resetError.message;
    return '';
  };

  return (
    <Row>
      <Col span={8} offset={8}>
        <Card className="login-card">
          <div className="upper-half-login">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={logo} alt="purinaEquine" width="320px" />
            </div>
          </div>
          <div>
            <hr />
          </div>
          <div className="lower-half-login">
            <h2 style={{ fontSize: 23 }}>Sign In</h2>
          </div>
          <Form className="login-form">
            <Form.Item style={{ width: '100%', marginBottom: 5 }}>
              {form.getFieldDecorator('email')(
                <Input
                  placeholder="Please enter your Email"
                  prefix={<Icon type="mail" style={{ color: '#c7bfbf' }} />}
                />,
              )}
            </Form.Item>
            <Form.Item style={{ width: '100%', marginBottom: 9 }}>
              {form.getFieldDecorator('password')(
                <Input
                  type="password"
                  prefix={<Icon type="lock" style={{ color: '#c7bfbf' }} />}
                  placeholder="Please enter your Password"
                />,
              )}
            </Form.Item>
            <div style={{ color: resetError === 'success' ? '#0039FF' : '#CE0E2D', paddingBottom: 51 }}>
              {errorIndicationOnClickReset()}
            </div>
            <Form.Item style={{ width: '100%' }}>
              <Button
                className="button-color"
                htmlType="submit"
                style={{ width: '100%', fontSize: 18, color: !enabledSignIn ? 'grey' : 'white' }}
                disabled={!enabledSignIn}
                loading={signInLoading}
                onClick={(e) => {
                  console.log('updatePasswordOnLogin in click', updatePasswordOnLogin);
                  if (updatePasswordOnLogin) {
                    history.push('/updatePassword');
                    handleSubmit(e);
                  } else {
                    handleSubmit(e);
                  }
                }}
              >
                Sign In
              </Button>
            </Form.Item>
            <Form.Item className="reset-form-item">
              <Button
                className="reset-button"
                type="button"
                disabled={!enableReset}
                style={{
                  color: !enableReset ? 'grey' : '#0039FF',
                }}
                onClick={handleReset}
                loading={resetLoading}
              >
                <a>Reset Password</a>
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

LoginPage.propTypes = {
  form: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Form.create({ name: 'LoginPage' })(LoginPage));
