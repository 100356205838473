export const reportsApiBaseUrl = 'https://api.ecp25.eumentis.com';
// export const reportsApiBaseUrl = 'http://localhost:3000';

export const feedNutrients = [
  {
    label: 'DM (% as Fed)',
    key: 'percentDM',
    step: 0.1,
    max: 100,
    rCalcKey: 'DMreq',
    nutrientName: 'Dry Matter (DM)',
    unit: 'kg',
    xAxisLabelName: 'DM',
    precision: 1,
    acceptableDeltaLow: 80,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'DE (Mcal/kg)',
    key: 'de',
    step: 0.01,
    rCalcKey: 'bodyME',
    nutrientName: 'Digestible Energy (DE)',
    unit: 'Mcal',
    xAxisLabelName: 'DE',
    precision: 2,
    acceptableDeltaLow: 80,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'CP (%)',
    key: 'cp',
    step: 0.1,
    max: 100,
    rCalcKey: 'bodyCP',
    nutrientName: 'Crude Protein (CP)',
    unit: 'g',
    xAxisLabelName: 'CP',
    precision: 1,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'Lys (%)',
    key: 'lys',
    step: 0.01,
    max: 100,
    rCalcKey: 'bodyLys',
    nutrientName: 'Lysine (Lys)',
    unit: 'g',
    xAxisLabelName: 'Lys',
    precision: 2,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'Fat (%)',
    key: 'fat',
    step: 0.1,
    max: 100,
    rCalcKey: 'bodyFat',
    nutrientName: 'Fat (Fat)',
    unit: 'g',
    xAxisLabelName: 'Fat',
    precision: 1,
    acceptableDeltaLow: 80,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'NDF (%)',
    key: 'ndf',
    step: 0.1,
    max: 100,
    rCalcKey: 'bodyNDF',
    nutrientName: 'NDF (NDF)',
    unit: 'g',
    xAxisLabelName: 'NDF',
    precision: 1,
    acceptableDeltaLow: 80,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'ADF (%)',
    key: 'adf',
    step: 0.1,
    max: 100,
    rCalcKey: 'bodyADF',
    nutrientName: 'ADF (ADF)',
    unit: 'g',
    xAxisLabelName: 'ADF',
    precision: 1,
    acceptableDeltaLow: 80,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'Ash (%)',
    key: 'ash',
    step: 0.1,
    max: 100,
    rCalcKey: 'bodyAsh',
    nutrientName: 'Ash (Ash)',
    unit: 'g',
    xAxisLabelName: 'Ash',
    precision: 1,
    acceptableDeltaLow: 80,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'Ca (%)',
    key: 'ca',
    step: 0.01,
    max: 100,
    rCalcKey: 'bodyCA',
    nutrientName: 'Calcium (Ca)',
    unit: 'g',
    xAxisLabelName: 'Ca',
    precision: 2,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 750,
  },
  {
    label: 'P (%)',
    key: 'p',
    step: 0.01,
    max: 100,
    rCalcKey: 'bodyPhos',
    nutrientName: 'Phosphorous (P)',
    unit: 'g',
    xAxisLabelName: 'P',
    precision: 2,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'Mg (%)',
    key: 'mg',
    step: 0.01,
    max: 100,
    rCalcKey: 'bodyMg',
    nutrientName: 'Magnesium (Mg)',
    unit: 'g',
    xAxisLabelName: 'Mg',
    precision: 2,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'Cl (%)',
    key: 'cl',
    step: 0.01,
    max: 100,
    rCalcKey: 'bodyCl',
    nutrientName: 'Chlorine (Cl)',
    unit: 'g',
    xAxisLabelName: 'Cl',
    precision: 2,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'K (%)',
    key: 'k',
    step: 0.01,
    max: 100,
    rCalcKey: 'bodyK',
    nutrientName: 'Potassium (K)',
    unit: 'g',
    xAxisLabelName: 'K',
    precision: 2,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 1000,
  },
  {
    label: 'Na (%)',
    key: 'na',
    step: 0.01,
    max: 100,
    rCalcKey: 'bodyNa',
    nutrientName: 'Sodium (Na)',
    unit: 'g',
    xAxisLabelName: 'Na',
    precision: 2,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'S (%)',
    key: 's',
    step: 0.01,
    max: 100,
    rCalcKey: 'bodyS',
    nutrientName: 'Sulphur (S)',
    unit: 'g',
    xAxisLabelName: 'S',
    precision: 2,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'Cu (mg/kg)',
    key: 'cu',
    step: 0.1,
    rCalcKey: 'bodyCu',
    nutrientName: 'Copper (Cu)',
    unit: 'mg',
    xAxisLabelName: 'Cu',
    precision: 1,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'I (mg/kg)',
    key: 'i',
    step: 0.01,
    rCalcKey: 'bodyI',
    nutrientName: 'Iodine (I)',
    unit: 'mg',
    xAxisLabelName: 'I',
    precision: 2,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'Fe (mg/kg)',
    key: 'fe',
    step: 1,
    rCalcKey: 'bodyFe',
    nutrientName: 'Iron (Fe)',
    unit: 'mg',
    xAxisLabelName: 'Fe',
    precision: 0,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 1000,
  },
  {
    label: 'Mn (mg/kg)',
    key: 'mn',
    step: 1,
    rCalcKey: 'bodyMn',
    nutrientName: 'Manganese (Mn)',
    unit: 'mg',
    xAxisLabelName: 'Mn',
    precision: 0,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'Se (mg/kg)',
    key: 'se',
    step: 0.01,
    rCalcKey: 'bodySe',
    nutrientName: 'Selenium (Se)',
    unit: 'mg',
    xAxisLabelName: 'Se',
    precision: 2,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'Zn (mg/kg)',
    key: 'zn',
    step: 1,
    rCalcKey: 'bodyZn',
    nutrientName: 'Zinc (Zn)',
    unit: 'mg',
    xAxisLabelName: 'Zn',
    precision: 0,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'Co (mg/kg)',
    key: 'co',
    step: 0.01,
    rCalcKey: 'bodyCo',
    nutrientName: 'Cobalt (Co)',
    unit: 'mg',
    xAxisLabelName: 'Co',
    precision: 2,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'Vit A (IU/lb)',
    key: 'vitA',
    step: 0.1,
    rCalcKey: 'bodyVitA',
    nutrientName: 'Vitamin A (Vit A)',
    unit: 'IU',
    xAxisLabelName: 'Vit A',
    precision: 1,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'Vit D (IU/lb)',
    key: 'vitD',
    step: 0.1,
    rCalcKey: 'bodyVitD',
    nutrientName: 'Vitamin D (Vit D)',
    unit: 'IU',
    xAxisLabelName: 'Vit D',
    precision: 1,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 1000,
  },
  {
    label: 'Vit C (IU/lb)',
    key: 'vitC',
    step: 0.1,
    rCalcKey: 'bodyVitC',
    nutrientName: 'Vitamin C (Vit C)',
    unit: 'IU',
    xAxisLabelName: 'Vit C',
    precision: 1,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 1000,
  },
  {
    label: 'Vit E (IU/lb)',
    key: 'vitE',
    step: 0.1,
    rCalcKey: 'bodyVitE',
    nutrientName: 'Vitamin E (Vit E)',
    unit: 'IU',
    xAxisLabelName: 'Vit E',
    precision: 1,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 1000,
  },
  {
    label: 'Thi (mg/kg)',
    key: 'thi',
    step: 0.1,
    rCalcKey: 'bodyThi',
    nutrientName: 'Thiamin (Thi)',
    unit: 'mg',
    xAxisLabelName: 'Thi',
    precision: 1,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 1000,
  },
  {
    label: 'Ribo (mg/kg)',
    key: 'ribo',
    step: 0.1,
    rCalcKey: 'bodyRibo',
    nutrientName: 'Riboflavin (Ribo)',
    unit: 'mg',
    xAxisLabelName: 'Ribo',
    precision: 1,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 1000,
  },
  {
    label: 'Sugar (%)',
    key: 'sugar',
    step: 0.01,
    max: 100,
    rCalcKey: 'bodySugar',
    nutrientName: 'Sugar',
    unit: 'g',
    xAxisLabelName: 'Sugar',
    precision: 2,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
  {
    label: 'Starch (%)',
    key: 'starch',
    step: 0.01,
    max: 100,
    rCalcKey: 'bodyStarch',
    nutrientName: 'Starch',
    unit: 'g',
    xAxisLabelName: 'Starch',
    precision: 2,
    acceptableDeltaLow: 95,
    targetDeltaLow: 100,
    targetDeltaHigh: 300,
    acceptableDeltaHigh: 500,
  },
];

const nutrientNotInAnalysis = ['percentDM', 'ndf', 'adf', 'fat', 'ash', 'vitC'];

const nutrientNotInTable = ['ndf', 'adf', 'fat', 'ash', 'vitC'];

export const feedNutrientsForAnalysis = feedNutrients.filter(
  (nutrient) => !nutrientNotInAnalysis.includes(nutrient.key),
);

export const feedNutrientsForTable = feedNutrients.filter((nutrient) => !nutrientNotInTable.includes(nutrient.key));

export const dateFormat = 'DD-MM-YYYY';

export const forageColours = [
  '#CCFF00',
  '#82C725',
  '#13745D',
  '#94D51C',
  '#258253',
  '#A7E313',
  '#38904A',
  '#B9F109',
  '#4A9E41',
  '#CCFF00',
];

export const concentrateColours = [
  '#11DFFF',
  '#0913FF',
  '#0FCBFA',
  '#053DDA',
  '#0EB6F6',
  '#0329D5',
  '#0CA2F1',
  '#0214D1',
  '#0B8EEC',
  '#0000CC',
];

export const supplementColours = [
  '#FFFF00',
  '#FFA71D',
  '#FFF204',
  '#FF9A21',
  '#FFE608',
  '#FF8D26',
  '#FFD90D',
  '#FF812A',
  '#FFCC11',
  '#FF742E',
];

export const addressFormat = (farmInfo) => {
  const address = ['addressLine1', 'addressLine2', 'city', 'state', 'zip'];
  const addressSend = [];
  address.forEach((add) => {
    if (farmInfo && farmInfo[add]) {
      addressSend.push(farmInfo[add]);
    }
  });
  return addressSend.join(', ');
};

export const targetDeltaInitialValueLow = {};
export const targetDeltaInitialValueHigh = {};
export const acceptableDeltaInitialValueLow = {};
export const acceptableDeltaInitialValueHigh = {};
feedNutrients.forEach((nutrient) => {
  targetDeltaInitialValueLow[nutrient.key] = nutrient.targetDeltaLow;
  targetDeltaInitialValueHigh[nutrient.key] = nutrient.targetDeltaHigh;
  acceptableDeltaInitialValueLow[nutrient.key] = nutrient.acceptableDeltaLow;
  acceptableDeltaInitialValueHigh[nutrient.key] = nutrient.acceptableDeltaHigh;
});

export default feedNutrients;
