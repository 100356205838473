import React, { useEffect, useState } from 'react';
import { Breadcrumb, Spin, message } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import FeedForm from '../forms/FeedForm';
import { firebaseDb } from '../utils/firebase';
import Heading from '../components/Heading';
import logger from '../utils/helpers/logger';

const EditConcentratePage = (props) => {
  const { match } = props;
  const [itemToEdit, setItemToEdit] = useState(null);

  const currentItemId = match.params.id;

  useEffect(() => {
    firebaseDb
      .collection('Feed')
      .doc(currentItemId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setItemToEdit(doc.data());
        } else {
          message.error('No such document!');
        }
      })
      .catch((error) => {
        message.error(error.message);
        logger(error);
      });
  }, []);

  return (
    <div className="create-page-container">
      <Breadcrumb className="breadcrumb">
        <Breadcrumb.Item>
          <Link style={{ color: '#0071cc' }} to="/feeds/forages">
            Feed Library
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link style={{ color: '#0071cc' }} to="/feeds/concentrates">
            Concentrates
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit Concentrate</Breadcrumb.Item>
      </Breadcrumb>
      <Heading headingName="Edit Concentrate Item" />
      {itemToEdit ? (
        <FeedForm
          feedType="concentrate"
          labelName="Concentrate"
          mode="edit"
          currentItemId={currentItemId}
          initialValueForForm={itemToEdit}
        />
      ) : (
        <Spin />
      )}
    </div>
  );
};

EditConcentratePage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default EditConcentratePage;
