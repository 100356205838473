import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import Heading from '../components/Heading';
import './BulkUploadPage.scss';
import BulkUploadComponent from '../components/BulkUploadComponent';

const BulkUploadSupplementPage = () => {
  return (
    <div style={{ padding: '15px 45px' }}>
      <Breadcrumb style={{ paddingBottom: 30 }}>
        <Breadcrumb.Item>
          <Link style={{ color: '#0071cc' }} to="/feeds/forages">
            Feed Library
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link style={{ color: '#0071cc' }} to="/feeds/supplements">
            Supplements
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Bulk Add Supplements</Breadcrumb.Item>
      </Breadcrumb>
      <Heading headingName="Bulk add Supplement items" />
      <BulkUploadComponent
        typeOfData="supplement"
        columnName="Supplement"
        nameOfButton="Add Supplements Items"
        href="https://firebasestorage.googleapis.com/v0/b/ecp25-production.appspot.com/o/supplementUploadTemplate.xlsx?alt=media&token=8d9d445d-9229-491e-af54-d2e71511a99e"
      />
    </div>
  );
};

export default BulkUploadSupplementPage;
