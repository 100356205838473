import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PropTypes from 'prop-types';
import ForagePage from './ForagePage';
import CreateForagePage from './CreateForagePage';
import CreateConcentratePage from './CreateConcentratePage';
import ConcentratePage from './ConcentratePage';
import CreateSupplementPage from './CreateSupplementPage';
import SupplementPage from './SupplementPage';
import EditConcentratePage from './EditConcentratePage';
import EditForagePage from './EditForagePage';
import EditSupplementPage from './EditSupplementPage';
import BulkUploadForagePage from './BulkUploadForagePage';
import BulkUploadConcentratePage from './BulkUploadConcentratePage';
import BulkUploadSupplementPage from './BulkUploadSupplementPage';

const tabroutes = {
  0: 'forages',
  1: 'concentrates',
  2: 'supplements',
};

const FeedLayoutPage = (props) => {
  const { match, location, history } = props;

  let selectedIndex = 0;

  if (location.pathname.includes('concentrates')) {
    selectedIndex = 1;
  }
  if (location.pathname.includes('supplements')) {
    selectedIndex = 2;
  }

  return (
    <Tabs selectedIndex={selectedIndex} onSelect={(value) => history.push(`/feeds/${tabroutes[value]}`)}>
      <TabList style={{ padding: '0px 35px' }}>
        <Tab>Forages</Tab>
        <Tab>Concentrates</Tab>
        <Tab>Supplements</Tab>
      </TabList>
      <TabPanel>
        <Switch>
          <Route path={`${match.url}/forages/:id/edit`} component={EditForagePage} />
          <Route path={`${match.url}/forages/bulkUpload`} component={BulkUploadForagePage} />
          <Route path={`${match.url}/forages/create`} component={CreateForagePage} />
          <Route path={`${match.url}/forages`} component={ForagePage} />
        </Switch>
      </TabPanel>
      <TabPanel>
        <Switch>
          <Route path={`${match.url}/concentrates/:id/edit`} component={EditConcentratePage} />
          <Route path={`${match.url}/concentrates/bulkUpload`} component={BulkUploadConcentratePage} />
          <Route path={`${match.url}/concentrates/create`} component={CreateConcentratePage} />
          <Route path={`${match.url}/concentrates`} component={ConcentratePage} />
        </Switch>
      </TabPanel>
      <TabPanel>
        <Switch>
          <Route path={`${match.url}/supplements/:id/edit`} component={EditSupplementPage} />
          <Route path={`${match.url}/supplements/bulkUpload`} component={BulkUploadSupplementPage} />
          <Route path={`${match.url}/supplements/create`} component={CreateSupplementPage} />
          <Route path={`${match.url}/supplements`} component={SupplementPage} />
        </Switch>
      </TabPanel>
    </Tabs>
  );
};

FeedLayoutPage.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default FeedLayoutPage;
