import React, { useState, useEffect } from 'react';
import LoadingPage from './pages/LoadingPage';
import { firebaseAuth, firebaseDb } from './utils/firebase';
import useAuth from './utils/auth';
import './style.scss';
import LoginPage from './pages/LoginPage';
import Routes from './Routes';
import UpdatePasswordPage from './pages/UpdatePasswordPage';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const { setUser, setUserSettings, setUpdatePasswordOnLogin, updatePasswordOnLogin, setUserRole } = useAuth();

  // Api calling for user authentication
  useEffect(() => {
    const unregisterAuthStateChangedObserver = firebaseAuth.onAuthStateChanged(async (user) => {
      if (user) {
        const userData = await firebaseDb
          .collection('User')
          .doc(user.uid)
          .get();
        setUserSettings(userData.data().settings);
        setUpdatePasswordOnLogin(userData.data().updatePasswordOnLogin);
        setUserRole(userData.data().role);
        setLoading(false);
        setUser(user);
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        setLoading(false);
      }
    });
    return () => {
      unregisterAuthStateChangedObserver();
    };
  }, []);

  // Condition for loader

  if (loading) return <LoadingPage />;

  // When user is not logged in

  if (!isLoggedIn) {
    return <LoginPage />;
  }

  if (isLoggedIn && updatePasswordOnLogin) {
    return <UpdatePasswordPage />;
  }

  // When user is logged in and loader is false

  return <Routes />;
};
export default App;
