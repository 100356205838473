/* eslint-disable */

export default (horse) => {
  const adgk = horse.averageDailyGain / 2.205; // user input

  const agem = horse.age;
  const bwk = horse.bodyWeight / 2.205;
  const DMreq = (horse.dryMatterIntakeLevel * bwk) / 100;
  const lifeStage = horse.lifeStage;
  const maintainanceLevel = horse.maintenanceLevel;

  let horse_inx;
  if (lifeStage === 'Adult Maintenance') {
    horse_inx = 1;
  } else if (lifeStage === 'Stallion') {
    horse_inx = 2;
  } else if (lifeStage === 'Working/Training') {
    horse_inx = 3;
  } else if (lifeStage === 'Lactating') {
    horse_inx = 4;
  } else if (lifeStage === 'Pregnant') {
    horse_inx = 5;
  } else if (lifeStage === 'Growing') {
    horse_inx = 6;
  }
  // console.log('horse_inx', horse_inx);
  let maint_inx;
  if (horse_inx === 1) {
    if (maintainanceLevel === 'Low Metabolism') {
      maint_inx = 1;
    } else if (maintainanceLevel === 'Average Metabolism') {
      maint_inx = 2;
    } else if (maintainanceLevel === 'High Metabolism') {
      maint_inx = 3;
    }
  }
  // console.log(' maint_inx', maint_inx);

  let stal_inx;
  if (maintainanceLevel === 'Breeding' && horse_inx === 2) {
    stal_inx = 1;
  } else if (maintainanceLevel === 'Non-Breeding' && horse_inx === 2) {
    stal_inx = 2;
  }
  // console.log('stal_inx', stal_inx);

  let work_inx;
  if (maintainanceLevel === 'Light' && horse_inx === 3) {
    work_inx = 1;
  } else if (maintainanceLevel === 'Moderate' && horse_inx === 3) {
    work_inx = 2;
  } else if (maintainanceLevel === 'Heavy' && horse_inx === 3) {
    work_inx = 3;
  } else if (maintainanceLevel === 'Intense' && horse_inx === 3) {
    work_inx = 4;
  }
  // console.log('work_inx', work_inx);

  let lact_inx;
  if (maintainanceLevel === 'Month 1' && horse_inx === 4) {
    lact_inx = 1;
  } else if (maintainanceLevel === 'Month 2' && horse_inx === 4) {
    lact_inx = 2;
  } else if (maintainanceLevel === 'Month 3' && horse_inx === 4) {
    lact_inx = 3;
  } else if (maintainanceLevel === 'Month 4' && horse_inx === 4) {
    lact_inx = 4;
  } else if (maintainanceLevel === 'Month 5' && horse_inx === 4) {
    lact_inx = 5;
  } else if (maintainanceLevel === 'Month 6' && horse_inx === 4) {
    lact_inx = 6;
  }
  // console.log('lact_inx', lact_inx);

  let preg_inx;
  if (maintainanceLevel === 'Early gestation (months 1-4)' && horse_inx === 5) {
    preg_inx = 1;
  } else if (maintainanceLevel === '5th Month' && horse_inx === 5) {
    preg_inx = 2;
  } else if (maintainanceLevel === '6th Month' && horse_inx === 5) {
    preg_inx = 3;
  } else if (maintainanceLevel === '7th Month' && horse_inx === 5) {
    preg_inx = 4;
  } else if (maintainanceLevel === '8th Month' && horse_inx === 5) {
    preg_inx = 5;
  } else if (maintainanceLevel === '9th Month' && horse_inx === 5) {
    preg_inx = 6;
  } else if (maintainanceLevel === '10th Month' && horse_inx === 5) {
    preg_inx = 7;
  } else if (maintainanceLevel === '11th Month' && horse_inx === 5) {
    preg_inx = 8;
  }

  // console.log('preg_inx', preg_inx);

  let growex_inx;
  if (agem <= 12) {
    growex_inx = 1;
  } else {
    if (maintainanceLevel === 'None' && horse_inx === 6) {
      growex_inx = 1;
    } else if (maintainanceLevel === 'Light' && horse_inx === 6) {
      growex_inx = 2;
    } else if (maintainanceLevel === 'Moderate' && horse_inx === 6) {
      growex_inx = 3;
    } else if (maintainanceLevel === 'Heavy' && horse_inx === 6) {
      growex_inx = 4;
    } else if (maintainanceLevel === 'Intense' && horse_inx === 6) {
      growex_inx = 5;
    }
  }
  // console.log('growex_inx', growex_inx);

  // Preg parameters
  let month_gest;

  if (preg_inx === 1) {
    month_gest = 4;
  } else if (preg_inx === 2) {
    month_gest = 5;
  } else if (preg_inx === 3) {
    month_gest = 6;
  } else if (preg_inx === 4) {
    month_gest = 7;
  } else if (preg_inx === 5) {
    month_gest = 8;
  } else if (preg_inx === 6) {
    month_gest = 9;
  } else if (preg_inx === 7) {
    month_gest = 10;
  } else if (preg_inx === 8) {
    month_gest = 11;
  }
  // console.log('month_gest', month_gest);
  const GestDay = month_gest * 30.4;
  // console.log('GestDay', GestDay);
  const PUMass = (-0.0135 + 0.00009 * GestDay) * bwk;
  // console.log('PUMass', PUMass);
  const Birthwt = 0.097 * bwk;
  // console.log('Birthwt', Birthwt);
  const FetalMass = 0.0000001 * Math.pow(GestDay, 3.5512) * 0.01 * Birthwt;
  // console.log('FetalMass', FetalMass);
  let bwkpreg;
  if (month_gest < 5) {
    bwkpreg = bwk;
  } else {
    bwkpreg = bwk + (FetalMass + PUMass) * 1.25;
  }
  // console.log('bwkpreg', bwkpreg);
  const FetalGain = 0.00000035512 * Math.pow(GestDay, 2.5512) * 0.01 * Birthwt + 0.00009;
  // console.log('FetalGain', FetalGain);

  //Lact parameters
  let MilkVals;
  if (lact_inx === 1) {
    MilkVals = 0.0326;
  } else if (lact_inx === 2) {
    MilkVals = 0.0324;
  } else if (lact_inx === 3) {
    MilkVals = 0.0299;
  } else if (lact_inx === 4) {
    MilkVals = 0.0271;
  } else if (lact_inx === 5) {
    MilkVals = 0.0244;
  } else if (lact_inx === 6) {
    MilkVals = 0.0218;
  }

  // console.log('MilkVals', MilkVals);
  const MilkProd = MilkVals * bwk;
  // console.log('MilkProd', MilkProd);

  //DE requirement(Mcal / d)
  // main.fin <- c(0.0303,0.0333,0.0363)[maint.inx]

  const deReqAdlutMaint = [
    { label: 'Low Metabolism', key: 'Low Metabolism', value: 0.0303 },
    { label: 'Average Metabolism', key: 'Average Metabolism', value: 0.0333 },
    { label: 'High Metabolism', key: 'High Metabolism', value: 0.0363 },
  ];
  let main_fin;
  deReqAdlutMaint.forEach((item) => {
    if (item.key === maintainanceLevel) {
      main_fin = item.value;
    }
  });
  // console.log('main_fin', main_fin);

  //stal.fin <- c(0.0363*1.2,0.0363)[stal.inx]
  const deReqStal = [
    { label: 'Breeding', key: 'Breeding', value: 0.0363 * 1.2 },
    { label: 'Non-Breeding', key: 'Non-Breeding', value: 0.0363 },
  ];
  let stal_fin;
  deReqStal.forEach((item) => {
    if (item.key === maintainanceLevel) {
      stal_fin = item.value;
    }
  });
  // console.log('stal_fin', stal_fin);

  // work.fin <- c(1.2*0.0333,1.4*0.0333,1.6*0.0333,1.9*0.0363)[work.inx]
  const deReqWork = [
    { label: 'Light ', key: 'Light', value: 1.2 * 0.0333 },
    { label: 'Moderate ', key: 'Moderate', value: 1.4 * 0.0333 },
    { label: 'Heavy ', key: 'Heavy', value: 1.6 * 0.0333 },
    { label: 'Intense ', key: 'Intense', value: 1.9 * 0.0363 },
  ];
  let work_fin;
  deReqWork.forEach((item) => {
    if (item.key === maintainanceLevel) {
      work_fin = item.value;
    }
  });
  // console.log('work_fin', work_fin);
  let lact_fin;
  if (bwk <= 700) {
    lact_fin = (0.0333 * bwk + (MilkProd * 10 * 50) / (1000 * 0.6)) / bwk;
  } else {
    lact_fin = (0.0363 * bwk + (MilkProd * 10 * 50) / (1000 * 0.6)) / bwk;
  }
  // console.log('lact_fin', lact_fin);
  let preg_fin;
  if (month_gest < 5) {
    preg_fin = 0.0333;
  } else {
    preg_fin =
      (0.0333 * bwkpreg + 0.0333 * 2 * (FetalMass + PUMass) + (0.03 * FetalGain * 9.4 + 0.2 * FetalGain * 5.6) / 0.6) /
      bwk;
  }
  // console.log('preg_fin', preg_fin);

  let val;
  if (growex_inx === 1) {
    val = 1;
  } else if (growex_inx === 2) {
    val = 1.2;
  } else if (growex_inx === 3) {
    val = 1.4;
  } else if (growex_inx === 4) {
    val = 1.6;
  } else if (growex_inx === 5) {
    val = 1.9;
  }

  // console.log('power value', Math.pow(agem, -0.145));
  // grow.fin < - ((56.5 * agem ^ -0.145) / 1000 * c(1, 1.2, 1.4, 1.6, 1.9)[growex.inx] * bwk + (1.99 + 1.21 * agem - 0.021 * agem ^ 2) * adgk) / bwk
  const grow_fin =
    (((56.5 * Math.pow(agem, -0.145)) / 1000) * val * bwk + (1.99 + 1.21 * agem - 0.021 * Math.pow(agem, 2)) * adgk) /
    bwk;
  // console.log('grow_fin', grow_fin);
  let bw_mult;
  if (horse_inx === 1) {
    bw_mult = main_fin;
  } else if (horse_inx === 2) {
    bw_mult = stal_fin;
  } else if (horse_inx === 3) {
    bw_mult = work_fin;
  } else if (horse_inx === 4) {
    bw_mult = lact_fin;
  } else if (horse_inx === 5) {
    bw_mult = preg_fin;
  } else if (horse_inx === 6) {
    bw_mult = grow_fin;
  }
  // console.log('bw_mult', bw_mult);
  const bodyME = bw_mult * bwk;
  // console.log('bodyME', bodyME);

  // // Crude protein requirement (g/d)
  // // main.finCP = c(1.08, 1.26, 1.44)[maint.inx]
  const crudeReqAdlutMaint = [
    { label: 'Low Metabolism', key: 'Low Metabolism', value: 1.08 },
    { label: 'Average Metabolism', key: 'Average Metabolism', value: 1.26 },
    { label: 'High Metabolism', key: 'High Metabolism', value: 1.44 },
  ];
  let main_finCP;
  crudeReqAdlutMaint.forEach((item) => {
    if (item.key === maintainanceLevel) {
      main_finCP = item.value;
    }
  });
  // console.log('main_finCP', main_finCP);

  // stal.finCP < - c(1.44 + 0.0025 * 7.8 * 2 / 0.79 + 0.089, 1.44)[stal.inx]
  const crudeReqStal = [
    { label: 'Breeding', key: 'Breeding', value: 1.44 + (0.0025 * 7.8 * 2) / 0.79 + 0.089 },
    { label: 'Non-Breeding', key: 'Non-Breeding', value: 1.44 },
  ];
  let stal_finCP;
  crudeReqStal.forEach((item) => {
    if (item.key === maintainanceLevel) {
      stal_finCP = item.value;
    }
  });
  // console.log('stal_finCP', stal_finCP);

  // work.finCP <- c(1.26+0.089+0.0025*7.8*2/.79,1.26+0.177+0.005*7.8*2/.79,1.26+0.266+0.01*7.8*2/.79,1.26+0.354+0.02*7.8*2/.79)[work.inx]
  const CPReqWork = [
    { label: 'Light ', key: 'Light', value: 1.26 + 0.089 + (0.0025 * 7.8 * 2) / 0.79 },
    { label: 'Moderate ', key: 'Moderate', value: 1.26 + 0.177 + (0.005 * 7.8 * 2) / 0.79 },
    { label: 'Heavy ', key: 'Heavy', value: 1.26 + 0.266 + (0.01 * 7.8 * 2) / 0.79 },
    { label: 'Intense ', key: 'Intense', value: 1.26 + 0.354 + (0.02 * 7.8 * 2) / 0.79 },
  ];
  let work_finCP;
  CPReqWork.forEach((item) => {
    if (item.key === maintainanceLevel) {
      work_finCP = item.value;
    }
  });
  const lact_finCP = (1.44 * bwk + MilkProd * 50) / bwk;
  //preg.finCP < - if (month.gest < 5) { 1.26 } else { (1.26 * bwkpreg + FetalGain * 1000 * 2 * 0.2 / 0.79) / bwk }
  let preg_finCP;
  if (month_gest < 5) {
    preg_finCP = 1.26;
  } else {
    preg_finCP = (1.26 * bwkpreg + (FetalGain * 1000 * 2 * 0.2) / 0.79) / bwk;
  }
  // console.log('preg_finCP ', preg_finCP);

  let growSweatValue;
  if (growex_inx === 1) {
    growSweatValue = 0;
  } else if (growex_inx === 2) {
    growSweatValue = 0.0025;
  } else if (growex_inx === 3) {
    growSweatValue = 0.005;
  } else if (growex_inx === 4) {
    growSweatValue = 0.01;
  } else if (growex_inx === 5) {
    growSweatValue = 0.02;
  }
  const grow_sweat = growSweatValue * bwk;
  // console.log('grow_sweat', grow_sweat);

  let value1;
  if (agem < 6.5) {
    value1 = 0.5;
  } else if (agem < 8.5) {
    value1 = 0.45;
  } else if (agem < 10.5) {
    value1 = 0.4;
  } else if (agem < 11.5) {
    value1 = 0.35;
  } else if (agem < 11.5) {
    value1 = 0.35;
  } else {
    value1 = 0.3;
  }
  const valto = (adgk * 1000 * 0.2) / 0.79 / value1;
  const value2 = (adgk * 1000 * 0.2) / 0.3 / 0.79 + (grow_sweat * 7.8 * 2) / 0.79 + 0.089 * bwk;
  const value3 = (adgk * 1000 * 0.2) / 0.3 / 0.79 + (grow_sweat * 7.8 * 2) / 0.79 + 0.177 * bwk;
  const value4 = (adgk * 1000 * 0.2) / 0.3 / 0.79 + (grow_sweat * 7.8 * 2) / 0.79 + 0.266 * bwk;
  const value5 = (adgk * 1000 * 0.2) / 0.3 / 0.79 + (grow_sweat * 7.8 * 2) / 0.79 + 0.354 * bwk;
  let valueSelectedFromGRowCP;

  if (growex_inx === 1) {
    valueSelectedFromGRowCP = valto;
  } else if (growex_inx === 2) {
    valueSelectedFromGRowCP = value2;
  } else if (growex_inx === 3) {
    valueSelectedFromGRowCP = value3;
  } else if (growex_inx === 4) {
    valueSelectedFromGRowCP = value4;
  } else if (growex_inx === 5) {
    valueSelectedFromGRowCP = value5;
  }
  // console.log(valueSelectedFromGRowCP);
  const grow_finCP = (1.44 * bwk + valueSelectedFromGRowCP) / bwk;
  // console.log('grow_finCP', grow_finCP);
  let bw_multCP;
  if (horse_inx === 1) {
    bw_multCP = main_finCP;
  } else if (horse_inx === 2) {
    bw_multCP = stal_finCP;
  } else if (horse_inx === 3) {
    bw_multCP = work_finCP;
  } else if (horse_inx === 4) {
    bw_multCP = lact_finCP;
  } else if (horse_inx === 5) {
    bw_multCP = preg_finCP;
  } else if (horse_inx === 6) {
    bw_multCP = grow_finCP;
  }
  // console.log('bw_multCP', bw_multCP);
  const bodyCP = bw_multCP * bwk;
  // console.log('bodyCP', bodyCP);

  // #Lysine requirement(g / d)
  const main_finLys = 0.043;
  // console.log('main_finLys', main_finLys);
  const stal_finLys = 0.043;
  const work_finLys = 0.043;
  // console.log('work_finLys', work_finLys);
  const lact_finLys = (0.043 * 1.44 * bwk + MilkProd * 3.3) / bodyCP;
  // console.log('lact_finLys', lact_finLys);
  const preg_finLys = 0.043;
  // console.log('preg_finLys', preg_finLys);
  const grow_finLys = 0.043;
  // console.log('grow_finLys', grow_finLys);
  let bw_multLys;
  if (horse_inx === 1) {
    bw_multLys = main_finLys;
  } else if (horse_inx === 2) {
    bw_multLys = stal_finLys;
  } else if (horse_inx === 3) {
    bw_multLys = work_finLys;
  } else if (horse_inx === 4) {
    bw_multLys = lact_finLys;
  } else if (horse_inx === 5) {
    bw_multLys = preg_finLys;
  } else if (horse_inx === 6) {
    bw_multLys = grow_finLys;
  }
  // console.log('bw_multLys', bw_multLys);
  const bodyLys = bw_multLys * bodyCP;
  // console.log('bodyLys', bodyLys);

  //Calcium requirement(g / d)
  const main_finCA = 0.04 * bwk;
  // console.log('main_finCA', main_finCA);
  // stal.finCA < - c(0.06, 0.04)[stal.inx] * bwk
  const calciumReqStal = [
    { label: 'Breeding', key: 'Breeding', value: 0.06 },
    { label: 'Non-Breeding', key: 'Non-Breeding', value: 0.04 },
  ];
  let stal_finCA;
  calciumReqStal.forEach((item) => {
    if (item.key === maintainanceLevel) {
      stal_finCA = item.value * bwk;
    }
  });
  // console.log('stal_finCA', stal_finCA);
  // work.finCA < - c(0.06, 0.07, 0.08, 0.08)[work.inx] * bwk

  const calciumReqWork = [
    { label: 'Light ', key: 'Light', value: 0.06 },
    { label: 'Moderate ', key: 'Moderate', value: 0.07 },
    { label: 'Heavy ', key: 'Heavy', value: 0.08 },
    { label: 'Intense ', key: 'Intense', value: 0.08 },
  ];
  let work_finCA;
  calciumReqWork.forEach((item) => {
    if (item.key === maintainanceLevel) {
      work_finCA = item.value * bwk;
    }
  });
  // console.log('work_finCA', work_finCA);
  let lact_finCA;
  if (lact_inx < 4) {
    lact_finCA = 0.04 * bwk;
  } else {
    lact_finCA = 0.04 * bwk + (MilkProd * 0.8) / 0.5;
  }
  // console.log('lact_finCA', lact_finCA);

  // preg.finCA < - c(main.finCA, main.finCA, main.finCA, 0.056 * bwkpreg, 0.056 * bwkpreg, 0.072 * bwkpreg, 0.072 * bwkpreg, 0.072 * bwkpreg)[preg.inx]
  const calciumReqPreg = [
    {
      label: 'Early gestation(months 1-4)',
      key: 'Early gestation (months 1-4)',
      value: main_finCA,
    },
    { label: '5th Month', key: '5th Month', value: main_finCA },
    { label: '6th Month', key: '6th Month', value: main_finCA },
    { label: '7th Month', key: '7th Month', value: 0.056 * bwkpreg },
    { label: '8th Month', key: '8th Month', value: 0.056 * bwkpreg },
    { label: '9th Month', key: '9th Month', value: 0.072 * bwkpreg },
    { label: '10th Month', key: '10th Month', value: 0.072 * bwkpreg },
    { label: '11th Month', key: '11th Month', value: 0.072 * bwkpreg },
  ];

  let preg_finCA;
  calciumReqPreg.forEach((item) => {
    if (item.key === maintainanceLevel) {
      preg_finCA = item.value;
    }
  });
  // console.log('preg_finCA', preg_finCA);
  const grow_finCA = 0.072 * bwk + 32 * adgk;
  // console.log('grow_finCA', grow_finCA);
  let bw_multCA;
  if (horse_inx === 1) {
    bw_multCA = main_finCA;
  } else if (horse_inx === 2) {
    bw_multCA = stal_finCA;
  } else if (horse_inx === 3) {
    bw_multCA = work_finCA;
  } else if (horse_inx === 4) {
    bw_multCA = lact_finCA;
  } else if (horse_inx === 5) {
    bw_multCA = preg_finCA;
  } else if (horse_inx === 6) {
    bw_multCA = grow_finCA;
  }
  const bodyCA = bw_multCA;
  // console.log('bodyCA', bodyCA);

  //Phosphorus requirement(g / d)(combined and PMI)
  const main_finPhos = 0.028 * bwk;
  // console.log('main_finPhos', main_finPhos);
  const phosReqStal = [
    { label: 'Breeding', key: 'Breeding', value: 0.036 },
    { label: 'Non-Breeding', key: 'Non-Breeding', value: 0.028 },
  ];
  let stal_finPhos;
  phosReqStal.forEach((item) => {
    if (item.key === maintainanceLevel) {
      stal_finPhos = item.value * bwk;
    }
  });
  // console.log('stal_finPhos', stal_finPhos);

  //work_finPhos < - c(0.036, 0.042, 0.058, 0.058)[work.inx] * bwk

  const phosReqWork = [
    { label: 'Light ', key: 'Light', value: 0.036 },
    { label: 'Moderate ', key: 'Moderate', value: 0.042 },
    { label: 'Heavy ', key: 'Heavy', value: 0.058 },
    { label: 'Intense ', key: 'Intense', value: 0.058 },
  ];
  let work_finPhos;
  phosReqWork.forEach((item) => {
    if (item.key === maintainanceLevel) {
      work_finPhos = item.value * bwk;
    }
  });
  // console.log('work_finPhos', work_finPhos);

  let lact_finPhos;
  if (bwk < 600) {
    if (lact_inx < 4) {
      lact_finPhos = (0.01 * bwk + 0.04 * bwk * 0.75) / 0.45;
    } else {
      lact_finPhos = (0.01 * bwk + 0.03 * bwk * 0.5) / 0.45;
    }
  } else {
    if (lact_inx < 4) {
      lact_finPhos = (0.01 * bwk + 0.03 * bwk * 0.75) / 0.45;
    } else {
      lact_finPhos = (0.01 * bwk + 0.02 * bwk * 0.5) / 0.45;
    }
  }
  //preg_finPhos < - c(main_finPhos, main_finPhos, main_finPhos, 0.04 * bwkpreg, 0.04 * bwkpreg, 0.0525 * bwkpreg, 0.0525 * bwkpreg, 0.0525 * bwkpreg)[preg.inx]
  // console.log('lact_finPhos', lact_finPhos);
  const phosReqPreg = [
    {
      label: 'Early gestation(months 1-4)',
      key: 'Early gestation (months 1-4)',
      value: main_finPhos,
    },
    { label: '5th Month', key: '5th Month', value: main_finPhos },
    { label: '6th Month', key: '6th Month', value: main_finPhos },
    { label: '7th Month', key: '7th Month', value: 0.04 * bwkpreg },
    { label: '8th Month', key: '8th Month', value: 0.04 * bwkpreg },
    { label: '9th Month', key: '9th Month', value: 0.0525 * bwkpreg },
    { label: '10th Month', key: '10th Month', value: 0.0525 * bwkpreg },
    { label: '11th Month', key: '11th Month', value: 0.0525 * bwkpreg },
  ];
  let preg_finPhos;
  phosReqPreg.forEach((item) => {
    if (item.key === maintainanceLevel) {
      preg_finPhos = item.value;
    }
  });
  // console.log('preg_finPhos', preg_finPhos);

  const grow_finPhos = (0.072 * bwk + 17.8 * adgk) * 1.07;
  let bw_multPhos;

  if (horse_inx === 1) {
    bw_multPhos = main_finPhos;
  } else if (horse_inx === 2) {
    bw_multPhos = stal_finPhos;
  } else if (horse_inx === 3) {
    bw_multPhos = work_finPhos;
  } else if (horse_inx === 4) {
    bw_multPhos = lact_finPhos;
  } else if (horse_inx === 5) {
    bw_multPhos = preg_finPhos;
  } else if (horse_inx === 6) {
    bw_multPhos = grow_finPhos;
  }
  // console.log('bw_multPhos', bw_multPhos);
  const bodyPhos = bw_multPhos;
  // console.log('bodyPhos', bodyPhos);

  //Phosphorus requirement(g / d)(NRC only)
  let lact_finPhosNRC;
  if (lact_inx < 4) {
    lact_finPhosNRC = (0.01 / 0.45) * bwk + (MilkProd * 0.75) / 0.45;
  } else {
    lact_finPhosNRC = (0.01 / 0.45) * bwk + (MilkProd * 0.5) / 0.45;
  }
  // console.log('lact_finPhosNRC', lact_finPhosNRC);

  const grow_finPhosNRC = 0.04 * bwk + 17.8 * adgk;
  // console.log('grow_finPhosNRC', grow_finPhosNRC);
  //bodyPhosNRC < - c(main.finPhos, stal.finPhos, work.finPhos, lact.finPhosNRC, preg.finPhos, grow.finPhosNRC)[horse.inx]
  let bodyPhosNRC;
  if (horse_inx === 1) {
    bodyPhosNRC = main_finPhos;
  } else if (horse_inx === 2) {
    bodyPhosNRC = stal_finPhos;
  } else if (horse_inx === 3) {
    bodyPhosNRC = work_finPhos;
  } else if (horse_inx === 4) {
    bodyPhosNRC = lact_finPhosNRC;
  } else if (horse_inx === 5) {
    bodyPhosNRC = preg_finPhos;
  } else if (horse_inx === 6) {
    bodyPhosNRC = grow_finPhosNRC;
  }
  // console.log('bodyPhosNRC', bodyPhosNRC);

  //Mg requirement(g / d)
  const main_finMg = 0.015 * bwk;
  // console.log('main_finMg', main_finMg);
  const mgReqStal = [
    { label: 'Breeding', key: 'Breeding', value: 0.019 },
    { label: 'Non-Breeding', key: 'Non-Breeding', value: 0.015 },
  ];
  // let stal_finMg; < - c(0.019, 0.015)[stal.inx] * bwk
  let stal_finMg;
  mgReqStal.forEach((item) => {
    if (item.key === maintainanceLevel) {
      stal_finMg = item.value * bwk;
    }
  });
  // console.log('stal_finMg', stal_finMg);
  // /work_finMg < - c(0.019, 0.023, 0.03, 0.03)[work.inx] * bwk
  const MgReqWork = [
    { label: 'Light ', key: 'Light', value: 0.019 },
    { label: 'Moderate ', key: 'Moderate', value: 0.023 },
    { label: 'Heavy ', key: 'Heavy', value: 0.03 },
    { label: 'Intense ', key: 'Intense', value: 0.03 },
  ];
  let work_finMg;
  MgReqWork.forEach((item) => {
    if (item.key === maintainanceLevel) {
      work_finMg = item.value * bwk;
    }
  });
  // console.log('work_finMg', work_finMg);
  let wrkmgvalue;
  if (lact_inx < 6) {
    wrkmgvalue = 0.09 / 0.4;
  } else {
    wrkmgvalue = 0.045 / 0.4;
  }
  const lact_finMg = 0.015 * bwk + MilkProd + wrkmgvalue;
  // console.log('lact_finMg', lact_finMg);

  let preg_finMg;
  if (month_gest < 7) {
    preg_finMg = 0.015 * bwkpreg;
  } else if (month_gest < 9) {
    preg_finMg = 0.0152 * bwkpreg;
  } else {
    preg_finMg = 0.0153 * bwkpreg;
  }
  let grow_finMg;
  if (growex_inx === 1) {
    grow_finMg = 0.015 * bwk + 1.25 * adgk;
  } else {
    grow_finMg = 0.03 * bwk;
  }
  // console.log('preg_finMg', preg_finMg);
  let bodyMg;
  if (horse_inx === 1) {
    bodyMg = main_finMg;
  } else if (horse_inx === 2) {
    bodyMg = stal_finMg;
  } else if (horse_inx === 3) {
    bodyMg = work_finMg;
  } else if (horse_inx === 4) {
    bodyMg = lact_finMg;
  } else if (horse_inx === 5) {
    bodyMg = preg_finMg;
  } else if (horse_inx === 6) {
    bodyMg = grow_finMg;
  }
  // console.log('bodyMg', bodyMg);

  //#K requirement(g / d)
  const main_finK = 0.05 * bwk;
  //stal_fink < - 0.05 * bwk + 1.4 / 0.5 * bwk * c(0.0025, 0)[stal.inx]
  // console.log('main_finK', main_finK);

  const kReqStal = [
    { label: 'Breeding', key: 'Breeding', value: 0.0025 },
    { label: 'Non-Breeding', key: 'Non-Breeding', value: 0 },
  ];
  let kreqval;
  kReqStal.forEach((item) => {
    if (item.key === maintainanceLevel) {
      kreqval = item.value;
    }
  });
  const stal_finK = 0.05 * bwk + (1.4 / 0.5) * bwk * kreqval;
  // console.log('stal_finK', stal_finK);

  const kReqWork = [
    { label: 'Light ', key: 'Light', value: 0.0025 },
    { label: 'Moderate ', key: 'Moderate', value: 0.005 },
    { label: 'Heavy ', key: 'Heavy', value: 0.01 },
    { label: 'Intense ', key: 'Intense', value: 0.02 },
  ];
  let workfinkval;
  kReqWork.forEach((item) => {
    if (item.key === maintainanceLevel) {
      workfinkval = item.value;
    }
  });

  const work_finK = (0.05 + (1.4 / 0.5) * workfinkval) * bwk;
  // console.log('work_fink', work_finK);

  let lactfinkval;
  if (lact_inx < 4) {
    lactfinkval = 0.7 / 0.5;
  } else {
    lactfinkval = 0.4 / 0.5;
  }
  const lact_finK = 0.05 * bwk + MilkProd + lactfinkval;
  // console.log('lact_finK', lact_finK);
  let preg_finK;
  if (month_gest < 9) {
    preg_finK = 0.05 * bwkpreg;
  } else {
    preg_finK = 0.0517 * bwkpreg;
  }
  // console.log('preg_finK', preg_finK);
  const grow_finK = 0.05 * bwk + 3 * adgk + (1.4 / 0.5) * grow_sweat;
  // console.log('grow_finK', grow_finK);

  let bodyK;
  if (horse_inx === 1) {
    bodyK = main_finK;
  } else if (horse_inx === 2) {
    bodyK = stal_finK;
  } else if (horse_inx === 3) {
    bodyK = work_finK;
  } else if (horse_inx === 4) {
    bodyK = lact_finK;
  } else if (horse_inx === 5) {
    bodyK = preg_finK;
  } else if (horse_inx === 6) {
    bodyK = grow_finK;
  }
  // console.log('bodyK', bodyK);
  //Na requirement(g / d)
  const main_finNa = 0.02 * bwk;
  // console.log('main_finNa', main_finNa);

  const NaReqStal = [
    { label: 'Breeding', key: 'Breeding', value: 0.0025 * 3.1 },
    { label: 'Non-Breeding', key: 'Non-Breeding', value: 0 },
  ];
  let Nareqval;
  NaReqStal.forEach((item) => {
    if (item.key === maintainanceLevel) {
      Nareqval = item.value;
    }
  });

  const stal_finNa = (0.02 + Nareqval) * bwk;
  // console.log('main_finNa', main_finNa);

  //work.finNa < - (0.02 + 3.1 * c(0.0025, 0.005, 0.01, 0.02)[work.inx]) * bwk
  const NaReqWork = [
    { label: 'Light ', key: 'Light', value: 0.0025 },
    { label: 'Moderate ', key: 'Moderate', value: 0.005 },
    { label: 'Heavy ', key: 'Heavy', value: 0.01 },
    { label: 'Intense ', key: 'Intense', value: 0.02 },
  ];
  let workNaval;
  NaReqWork.forEach((item) => {
    if (item.key === maintainanceLevel) {
      workNaval = item.value;
    }
  });
  const work_finNa = (0.02 + 3.1 * workNaval) * bwk;

  // console.log('work_finNa', work_finNa);

  let lactNaVal;
  if (lact_inx < 4) {
    lactNaVal = 0.17;
  } else {
    lactNaVal = 0.14;
  }
  const lact_finNa = 0.02 * bwk + MilkProd + lactNaVal;
  // console.log('lact_finNa', lact_finNa);

  let preg_finNa;
  if (month_gest < 9) {
    preg_finNa = 0.02 * bwkpreg;
  } else {
    preg_finNa = 0.022 * bwkpreg;
  }
  // console.log('preg_finNa', preg_finNa);

  const grow_finNa = 0.02 * bwk + 1 * adgk + 3.1 * grow_sweat;
  // console.log('grow_finNa', grow_finNa);

  let bodyNa;
  if (horse_inx === 1) {
    bodyNa = main_finNa;
  } else if (horse_inx === 2) {
    bodyNa = stal_finNa;
  } else if (horse_inx === 3) {
    bodyNa = work_finNa;
  } else if (horse_inx === 4) {
    bodyNa = lact_finNa;
  } else if (horse_inx === 5) {
    bodyNa = preg_finNa;
  } else if (horse_inx === 6) {
    bodyNa = grow_finNa;
  }
  // console.log('bodyNa', bodyNa);

  // #S requirement(g / d)
  const main_finS = DMreq * 1000 * 0.0015;
  // console.log('main_finS', main_finS);
  const stal_finS = DMreq * 1000 * 0.0015;
  // console.log('stal_finS', stal_finS);
  const work_finS = DMreq * 1000 * 0.0015;
  // console.log('work_finS', work_finS);
  const lact_finS = DMreq * 1000 * 0.0015;
  // console.log('lact_finS', lact_finS);
  const preg_finS = DMreq * 1000 * 0.0015;
  // console.log('preg_finS', preg_finS);
  const grow_finS = DMreq * 1000 * 0.0015;
  // console.log('grow_finS', grow_finS);
  let bodyS;
  if (horse_inx === 1) {
    bodyS = main_finS;
  } else if (horse_inx === 2) {
    bodyS = stal_finS;
  } else if (horse_inx === 3) {
    bodyS = work_finS;
  } else if (horse_inx === 4) {
    bodyS = lact_finS;
  } else if (horse_inx === 5) {
    bodyS = preg_finS;
  } else if (horse_inx === 6) {
    bodyS = grow_finS;
  }
  // console.log('bodyS', bodyS);

  // #CL requirement(g / d)
  const main_finCl = 0.08 * bwk;
  // console.log('main_finCl', main_finCl);
  const ClReqStal = [
    { label: 'Breeding', key: 'Breeding', value: 0.0025 },
    { label: 'Non-Breeding', key: 'Non-Breeding', value: 0 },
  ];
  let Clreqval;
  ClReqStal.forEach((item) => {
    if (item.key === maintainanceLevel) {
      Clreqval = item.value;
    }
  });

  const stal_finCl = (0.08 + 5.3 * Clreqval) * bwk;
  // console.log('stal_finCl', stal_finCl);
  const ClReqWork = [
    { label: 'Light ', key: 'Light', value: 0.0025 },
    { label: 'Moderate ', key: 'Moderate', value: 0.005 },
    { label: 'Heavy ', key: 'Heavy', value: 0.01 },
    { label: 'Intense ', key: 'Intense', value: 0.02 },
  ];
  let workClval;
  ClReqWork.forEach((item) => {
    if (item.key === maintainanceLevel) {
      workClval = item.value;
    }
  });
  const work_finCl = (0.08 + 5.3 * workClval) * bwk;
  // console.log('work_finCl', work_finCl);
  const lact_finCl = 0.091 * bwk;
  // console.log('lact_finCl', lact_finCl);
  let preg_finCl;
  if (month_gest < 9) {
    preg_finCl = 0.08 * bwkpreg;
  } else {
    preg_finCl = 0.082 * bwkpreg;
  }
  // console.log('preg_finCl', preg_finCl);

  let grow_finCl;
  if (growex_inx === 1) {
    if (agem < 6.5) {
      grow_finCl = 0.093 * bwk;
    } else if (agem < 11.5) {
      grow_finCl = 0.085 * bwk;
    } else {
      grow_finCl = 0.0825 * bwk;
    }
  } else {
    grow_finCl = 0.0825 * bwk + 5.3 * grow_sweat;
  }

  let bodyCl;
  if (horse_inx === 1) {
    bodyCl = main_finCl;
  } else if (horse_inx === 2) {
    bodyCl = stal_finCl;
  } else if (horse_inx === 3) {
    bodyCl = work_finCl;
  } else if (horse_inx === 4) {
    bodyCl = lact_finCl;
  } else if (horse_inx === 5) {
    bodyCl = preg_finCl;
  } else if (horse_inx === 6) {
    bodyCl = grow_finCl;
  }
  // console.log('bodyME', bodyME);
  // console.log('bodyCP', bodyCP);
  // console.log('bodyLys', bodyLys);
  // console.log('bodyCA', bodyCA);
  // console.log('bodyPhos', bodyPhos);
  // console.log('bodyPhosNRC', bodyPhosNRC);
  // console.log('bodyMg', bodyMg);
  // console.log('bodyK', bodyK);
  // console.log('bodyNa', bodyNa);
  // console.log('bodyS', bodyS);

  // console.log('bodyCl', bodyCl);

  //#Co requirement(mg / d)
  const main_finCo = DMreq * 0.05;
  const stal_finCo = DMreq * 0.05;
  const work_finCo = DMreq * 0.05;
  const lact_finCo = DMreq * 0.05;
  const preg_finCo = DMreq * 0.05;
  const grow_finCo = DMreq * 0.05;

  let bodyCo;
  if (horse_inx === 1) {
    bodyCo = main_finCo;
  } else if (horse_inx === 2) {
    bodyCo = stal_finCo;
  } else if (horse_inx === 3) {
    bodyCo = work_finCo;
  } else if (horse_inx === 4) {
    bodyCo = lact_finCo;
  } else if (horse_inx === 5) {
    bodyCo = preg_finCo;
  } else if (horse_inx === 6) {
    bodyCo = grow_finCo;
  }
  // console.log('bodyCo', bodyCo);

  // #Cu requirement(mg / d)
  const main_finCu = 0.2 * bwk;
  let stal_finCu;
  if (stal_inx === 1) {
    stal_finCu = DMreq * 10;
  } else {
    stal_finCu = 0.2 * bwk;
  }
  const work_finCu = DMreq * 10;
  const lact_finCu = 0.25 * bwk;
  let preg_finCu;
  if (month_gest < 9) {
    preg_finCu = 0.2 * bwkpreg;
  } else {
    preg_finCu = 0.25 * bwkpreg;
  }
  const grow_finCu = 0.25 * bwk;
  let bodyCu;
  if (horse_inx === 1) {
    bodyCu = main_finCu;
  } else if (horse_inx === 2) {
    bodyCu = stal_finCu;
  } else if (horse_inx === 3) {
    bodyCu = work_finCu;
  } else if (horse_inx === 4) {
    bodyCu = lact_finCu;
  } else if (horse_inx === 5) {
    bodyCu = preg_finCu;
  } else if (horse_inx === 6) {
    bodyCu = grow_finCu;
  }
  // console.log('bodyCu', bodyCu);

  //#I requirement(mg / d)
  const main_finI = DMreq * 0.35;
  const stal_finI = DMreq * 0.35;
  const work_finI = DMreq * 0.35;
  const lact_finI = DMreq * 0.35;
  let preg_finI;
  if (month_gest < 9) {
    preg_finI = DMreq * 0.35;
  } else {
    preg_finI = DMreq * 0.4;
  }
  const grow_finI = DMreq * 0.35;
  let bodyI;
  if (horse_inx === 1) {
    bodyI = main_finI;
  } else if (horse_inx === 2) {
    bodyI = stal_finI;
  } else if (horse_inx === 3) {
    bodyI = work_finI;
  } else if (horse_inx === 4) {
    bodyI = lact_finI;
  } else if (horse_inx === 5) {
    bodyI = preg_finI;
  } else if (horse_inx === 6) {
    bodyI = grow_finI;
  }
  // console.log('bodyI', bodyI);

  //#Fe requirement(mg / d)
  const main_finFe = DMreq * 40;
  const stal_finFe = DMreq * 40;
  const work_finFe = DMreq * 40;
  const lact_finFe = DMreq * 50;
  let preg_finFe;
  if (month_gest < 9) {
    preg_finFe = DMreq * 40;
  } else {
    preg_finFe = DMreq * 50;
  }
  const grow_finFe = DMreq * 50;
  let bodyFe;
  if (horse_inx === 1) {
    bodyFe = main_finFe;
  } else if (horse_inx === 2) {
    bodyFe = stal_finFe;
  } else if (horse_inx === 3) {
    bodyFe = work_finFe;
  } else if (horse_inx === 4) {
    bodyFe = lact_finFe;
  } else if (horse_inx === 5) {
    bodyFe = preg_finFe;
  } else if (horse_inx === 6) {
    bodyFe = grow_finFe;
  }
  // console.log('bodyFe', bodyFe);

  //Mn requirement(mg / d)
  const main_finMn = DMreq * 40;
  const stal_finMn = DMreq * 40;
  const work_finMn = DMreq * 40;
  const lact_finMn = DMreq * 40;
  const preg_finMn = DMreq * 40;
  const grow_finMn = DMreq * 40;
  let bodyMn;
  if (horse_inx === 1) {
    bodyMn = main_finMn;
  } else if (horse_inx === 2) {
    bodyMn = stal_finMn;
  } else if (horse_inx === 3) {
    bodyMn = work_finMn;
  } else if (horse_inx === 4) {
    bodyMn = lact_finMn;
  } else if (horse_inx === 5) {
    bodyMn = preg_finMn;
  } else if (horse_inx === 6) {
    bodyMn = grow_finMn;
  }
  // console.log('bodyMn', bodyMn);

  const main_finZn = DMreq * 40;
  const stal_finZn = DMreq * 40;
  const work_finZn = DMreq * 40;
  const lact_finZn = DMreq * 40;
  const preg_finZn = DMreq * 40;
  const grow_finZn = DMreq * 40;
  let bodyZn;
  if (horse_inx === 1) {
    bodyZn = main_finZn;
  } else if (horse_inx === 2) {
    bodyZn = stal_finZn;
  } else if (horse_inx === 3) {
    bodyZn = work_finZn;
  } else if (horse_inx === 4) {
    bodyZn = lact_finZn;
  } else if (horse_inx === 5) {
    bodyZn = preg_finZn;
  } else if (horse_inx === 6) {
    bodyZn = grow_finZn;
  }
  // console.log('bodyZn', bodyZn);

  const main_finSe = DMreq * 0.1;
  const stal_finSe = DMreq * 0.1;
  const work_finSe = DMreq * 0.1;
  const lact_finSe = DMreq * 0.1;
  const preg_finSe = DMreq * 0.1;
  const grow_finSe = DMreq * 0.1;
  let bodySe;
  if (horse_inx === 1) {
    bodySe = main_finSe;
  } else if (horse_inx === 2) {
    bodySe = stal_finSe;
  } else if (horse_inx === 3) {
    bodySe = work_finSe;
  } else if (horse_inx === 4) {
    bodySe = lact_finSe;
  } else if (horse_inx === 5) {
    bodySe = preg_finSe;
  } else if (horse_inx === 6) {
    bodySe = grow_finSe;
  }
  // console.log('bodySe', bodySe);

  const main_finVitD = 6.6 * bwk;
  const stal_finVitD = 6.6 * bwk;
  const work_finVitD = 6.6 * bwk;
  const lact_finVitD = 6.6 * bwk;
  const preg_finVitD = 6.6 * bwkpreg;
  let grow_finVitD;
  if (growex_inx === 1 && agem < 11.5) {
    if (agem < 6.5) {
      grow_finVitD = 22.2 * bwk;
    } else {
      grow_finVitD = 17.4 * bwk;
    }
  } else {
    grow_finVitD = 20 * bwk;
  }
  let bodyVitD;
  if (horse_inx === 1) {
    bodyVitD = main_finVitD;
  } else if (horse_inx === 2) {
    bodyVitD = stal_finVitD;
  } else if (horse_inx === 3) {
    bodyVitD = work_finVitD;
  } else if (horse_inx === 4) {
    bodyVitD = lact_finVitD;
  } else if (horse_inx === 5) {
    bodyVitD = preg_finVitD;
  } else if (horse_inx === 6) {
    bodyVitD = grow_finVitD;
  }
  // console.log('bodyVitD', bodyVitD);

  //#VitE requirement(IU / d)
  const main_finVitE = 1 * bwk;
  let stal_finVitE;
  if (stal_inx === 1) {
    stal_finVitE = 1.6 * bwk;
  } else {
    stal_finVitE = 1 * bwk;
  }
  const woekReqfinVitE = [
    { label: 'Light ', key: 'Light', value: 1.6 },
    { label: 'Moderate ', key: 'Moderate', value: 1.8 },
    { label: 'Heavy ', key: 'Heavy', value: 2 },
    { label: 'Intense ', key: 'Intense', value: 2 },
  ];
  let work_finVitE;
  woekReqfinVitE.forEach((item) => {
    if (item.key === maintainanceLevel) {
      work_finVitE = item.value * bwk;
    }
  });
  const lact_finVitE = 2 * bwk;
  const preg_finVitE = 1 * bwkpreg;
  const grow_finVitE = 2 * bwk;

  let bodyVitE;
  if (horse_inx === 1) {
    bodyVitE = main_finVitE;
  } else if (horse_inx === 2) {
    bodyVitE = stal_finVitE;
  } else if (horse_inx === 3) {
    bodyVitE = work_finVitE;
  } else if (horse_inx === 4) {
    bodyVitE = lact_finVitE;
  } else if (horse_inx === 5) {
    bodyVitE = preg_finVitE;
  } else if (horse_inx === 6) {
    bodyVitE = grow_finVitE;
  }
  // console.log('bodyVitE', bodyVitE);

  // #VitA requirement (IUx1000/d)
  // main.finVitA <- 30*bwk
  // stal.finVitA <- c(45,30)[stal.inx]*bwk
  // work.finVitA <- 45*bwk
  // lact.finVitA <- 60*bwk
  // preg.finVitA <- 60*bwkpreg
  // grow.finVitA <- 45*bwk

  const main_finVitA = 30 * bwk;
  let stal_finVitA;
  if (stal_inx === 1) {
    stal_finVitA = 45 * bwk;
  } else {
    stal_finVitA = 30 * bwk;
  }
  const work_finVitA = 45 * bwk;
  const lact_finVitA = 60 * bwk;
  const preg_finVitA = 60 * bwkpreg;
  const grow_finVitA = 45 * bwk;
  let bodyVitA;
  if (horse_inx === 1) {
    bodyVitA = main_finVitA;
  } else if (horse_inx === 2) {
    bodyVitA = stal_finVitA;
  } else if (horse_inx === 3) {
    bodyVitA = work_finVitA;
  } else if (horse_inx === 4) {
    bodyVitA = lact_finVitA;
  } else if (horse_inx === 5) {
    bodyVitA = preg_finVitA;
  } else if (horse_inx === 6) {
    bodyVitA = grow_finVitA;
  }
  // console.log('bodyVitA', bodyVitA);

  const main_finThi = 0.06 * bwk;
  const stal_finThi = 0.06 * bwk; // This value is not declared in NRC

  const woekReqfinThi = [
    { label: 'Light ', key: 'Light', value: 0.06 },
    { label: 'Moderate ', key: 'Moderate', value: 0.113 },
    { label: 'Heavy ', key: 'Heavy', value: 0.125 },
    { label: 'Intense ', key: 'Intense', value: 0.125 },
  ];
  let work_finThi;
  woekReqfinThi.forEach((item) => {
    if (item.key === maintainanceLevel) {
      work_finThi = item.value * bwk;
    }
  });
  const lact_finThi = 0.075 * bwk;
  const preg_finThi = 0.06 * bwkpreg;
  const grow_finThi = 0.075 * bwk;

  let bodyThi;
  if (horse_inx === 1) {
    bodyThi = main_finThi;
  } else if (horse_inx === 2) {
    bodyThi = stal_finThi;
  } else if (horse_inx === 3) {
    bodyThi = work_finThi;
  } else if (horse_inx === 4) {
    bodyThi = lact_finThi;
  } else if (horse_inx === 5) {
    bodyThi = preg_finThi;
  } else if (horse_inx === 6) {
    bodyThi = grow_finThi;
  }
  // console.log('bodyThi', bodyThi);

  // Ribo Requirement (mg / d)

  const main_finRibo = 0.04 * bwk;
  const stal_finRibo = 0.04 * bwk; //  This value is not declared in NRC

  const woekReqfinRibo = [
    { label: 'Light ', key: 'Light', value: 0.04 },
    { label: 'Moderate ', key: 'Moderate', value: 0.04 },
    { label: 'Heavy ', key: 'Heavy', value: 0.05 },
    { label: 'Intense ', key: 'Intense', value: 0.05 },
  ];
  let work_finRibo;
  woekReqfinRibo.forEach((item) => {
    if (item.key === maintainanceLevel) {
      work_finRibo = item.value * bwk;
    }
  });
  const lact_finRibo = 0.05 * bwk;
  const preg_finRibo = 0.04 * bwkpreg;
  const grow_finRibo = 0.05 * bwk;

  let bodyRibo;
  if (horse_inx === 1) {
    bodyRibo = main_finRibo;
  } else if (horse_inx === 2) {
    bodyRibo = stal_finRibo;
  } else if (horse_inx === 3) {
    bodyRibo = work_finRibo;
  } else if (horse_inx === 4) {
    bodyRibo = lact_finRibo;
  } else if (horse_inx === 5) {
    bodyRibo = preg_finRibo;
  } else if (horse_inx === 6) {
    bodyRibo = grow_finRibo;
  }
  // console.log('bodyRibo', bodyRibo);

  // Calculation not available
  const bodyFat = 0;
  const bodyNDF = 0;
  const bodyADF = 0;
  const bodyAsh = 0;
  const bodyVitC = 0;

  return {
    DMreq: DMreq || 0,
    bodyME: bodyME || 0,
    bodyCP: bodyCP || 0,
    bodyLys: bodyLys || 0,
    bodyFat: bodyFat || 0,
    bodyNDF: bodyNDF || 0,
    bodyADF: bodyADF || 0,
    bodyAsh: bodyAsh || 0,
    bodyCA: bodyCA || 0,
    bodyPhos: bodyPhosNRC || 0,
    bodyMg: bodyMg || 0,
    bodyCl: bodyCl || 0,
    bodyK: bodyK || 0,
    bodyNa: bodyNa || 0,
    bodyS: bodyS || 0,
    bodyCu: bodyCu || 0,
    bodyI: bodyI || 0,
    bodyFe: bodyFe || 0,
    bodyMn: bodyMn || 0,
    bodySe: bodySe || 0,
    bodyZn: bodyZn || 0,
    bodyCo: bodyCo || 0,
    bodyVitA: bodyVitA || 0,
    bodyVitD: bodyVitD || 0,
    bodyVitC: bodyVitC || 0,
    bodyThi: bodyThi || 0,
    bodyRibo: bodyRibo || 0,
    bodyVitE: bodyVitE || 0,
  };
};
