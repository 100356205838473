import * as Sentry from '@sentry/react';

/**
 * Function logs an error message to the console and captures the exception using Sentry.
 * @param error - It can be any type of error object, such as an Error instance or a custom error object.
 */
const logger = (error) => {
  console.error(error);
  Sentry.captureException(error);
};

export default logger;
