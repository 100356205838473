import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Bar, BarChart, Legend, XAxis, YAxis, ReferenceLine } from 'recharts';

const ticks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];

class AcceptableRangeBarChart extends Component {
  render() {
    const { data, compare, margin } = this.props;
    const height = compare ? 300 : 600;
    const legendTopValues = compare ? [10, 34, 54, 76, 100] : [43, 123, 205, 287, 368];

    return (
      <BarChart width={1200} height={height} data={data} margin={margin}>
        <XAxis
          dataKey="name"
          interval={0}
          orientation="bottom"
          label={{ value: 'Nutrients', position: 'insideBottom', offset: -16 }}
          padding={{ top: 20, bottom: 20 }}
          tick={{ fontSize: 12 }}
        />
        <YAxis
          domain={[0, 25]}
          allowDataOverflow
          ticks={ticks}
          minTickGap={0}
          tickLine={false}
          tickSize={0}
          interval={0}
          hide
        />
        <Legend
          align="left"
          layout="vertical"
          verticalAlign="top"
          content={({ payload }) => {
            const newPayload = JSON.parse(JSON.stringify(payload)).reverse();
            return newPayload.map((entry, index) => {
              return (
                <div
                  key={`${entry.value}-${index + 1}`}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    position: 'relative',
                    top: legendTopValues[index],
                    height: 24,
                    fontSize: 12,
                  }}
                >
                  <div style={{ width: 15, height: 15, backgroundColor: entry.color, marginRight: 5 }} />
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: 12,
                    }}
                  >
                    {entry.value}
                  </p>
                </div>
              );
            });
          }}
        />
        <Bar dataKey="deficient" stackId="a" fill="#D22008" name="Deficient" />
        <Bar dataKey="acceptableBelowTarget" stackId="a" fill="#116DE2" name="Acceptable" />
        <Bar dataKey="target" stackId="a" fill="#1BD70F" name="Target" />
        <Bar dataKey="acceptableAboveTarget" stackId="a" fill="#116DE2" name="Acceptable" />
        <Bar dataKey="excessive" stackId="a" fill="#D22008" name="Excessive" />
        {ticks.map((no) => (
          <ReferenceLine key={no} y={no} stroke="white" strokeWidth={compare ? 1.5 : 2} />
        ))}
      </BarChart>
    );
  }
}

AcceptableRangeBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  margin: PropTypes.object,
  compare: PropTypes.bool,
};

AcceptableRangeBarChart.defaultProps = {
  margin: {},
  compare: false,
};

export default AcceptableRangeBarChart;
