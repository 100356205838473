import React, { useState } from 'react';
import { Radio, Button, message } from 'antd';
import useAuth from '../utils/auth';
import Heading from '../components/Heading';
import { firebaseDb } from '../utils/firebase';
import './SettingsPage.scss';
import ChangePasswordForm from '../forms/ChangePasswordForm';
import '../components/ButtonColor.scss';
import logger from '../utils/helpers/logger';

const SettingsPage = () => {
  const { userSettings, setUserSettings, user } = useAuth();
  const [valueOfUoM, setValueOfUoM] = useState('imperial');
  const [saveloading, setSaveLoading] = useState(false);

  const handleSave = () => {
    setSaveLoading(true);
    firebaseDb
      .collection('User')
      .doc(user.uid)
      .update({
        settings: {
          uom: valueOfUoM,
        },
      })
      .then(() => {
        message.success('UoM setting has been successfully updated!');
        setUserSettings({ uom: valueOfUoM });
        setSaveLoading(false);
      })
      .catch((error) => {
        message.error(error.message);
        setSaveLoading(false);
        logger(error);
      });
  };

  const onUoMChange = (e) => {
    setValueOfUoM(e.target.value);
  };

  return (
    <div className="setting-container">
      <div>
        <Heading headingName="UoM" />
        <div className="uom-container">
          <div className="uom-heading">Please choose the unit system for measurement in application</div>
          <Radio.Group onChange={onUoMChange} defaultValue={userSettings.uom}>
            <Radio value="imperial">Imperial</Radio>
            <Radio value="metric">Metric</Radio>
          </Radio.Group>
          <Button className="button-color" loading={saveloading} onClick={handleSave} style={{ marginLeft: 65 }}>
            Save
          </Button>
        </div>
      </div>
      <div className="modify-password-container">
        <Heading headingName="Modify Password" />
        <ChangePasswordForm />
      </div>
    </div>
  );
};

export default SettingsPage;
