import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import Heading from './Heading';
import useAuth from '../utils/auth';

const { Column } = Table;

const TotalDailyRation = (props) => {
  const { dietDataforTable, hideHeader } = props;
  const { userSettings, convertOzToGram, convertPoundToKg, convertOzToLbs } = useAuth();

  let sumOfAmount = 0;
  dietDataforTable.forEach((i) => {
    let amountInLbs = 0;
    if (i.unit === 'oz/day') {
      amountInLbs = i.amount * 0.0625;
    } else {
      amountInLbs = i.amount;
    }
    sumOfAmount += amountInLbs;
  });

  const renderAmountValueInTable = (record) => {
    if (userSettings.uom === 'metric') {
      if (record.unit && record.unit === 'lbs/day') {
        return `${convertPoundToKg(record.amount).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} kgs`;
      }
      if (record.unit && record.unit === 'oz/day') {
        return `${convertOzToGram(record.amount).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} grams`;
      }
      return null;
    }
    return `${
      record.amount
        ? record.amount.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : null
    } ${record.unit === 'lbs/day' ? 'lbs' : 'oz'}`;
  };

  let totalAmount = 0;
  dietDataforTable.forEach((item) => {
    if (item.unit === 'oz/day') {
      totalAmount += convertOzToLbs(item.amount);
    } else {
      totalAmount += item.amount;
    }
  });

  return (
    <div className="container" style={{ marginBottom: 30, marginTop: 20 }}>
      {hideHeader ? null : <Heading headingName="Ration List" />}
      <Table
        dataSource={[
          ...dietDataforTable,
          {
            totalAmountKey: 'Total Amount',
            totalAmountValue: `${
              userSettings.uom === 'metric'
                ? `${convertPoundToKg(totalAmount).toFixed(2)} `
                : `${totalAmount.toFixed(2)} `
            }`,
          },
        ]}
        bordered
        pagination={false}
        rowKey={(record) => `${record.name}${record.code}${record.category}`}
      >
        <Column
          title="Ration"
          dataIndex="type"
          key="type"
          render={(text, row, index) => {
            if (index < dietDataforTable.length) {
              return <div style={{ textTransform: 'capitalize' }}>{row.type}</div>;
            }
            return {
              props: {
                colSpan: 4,
              },
            };
          }}
        />
        <Column
          title="Item Code"
          dataIndex="code"
          key="code"
          render={(text, row, index) => {
            if (index < dietDataforTable.length) {
              return row.code ? row.code : <div>-</div>;
            }
            return {
              children: (
                <div style={{ fontWeight: 'bold', fontSize: 15, textAlign: 'right' }}>
                  {row.totalAmountKey}
                  {userSettings.uom === 'metric' ? ' (kgs)' : ' (lbs)'}:
                </div>
              ),
            };
          }}
        />
        <Column
          title="Item Name"
          dataIndex="name"
          key="name"
          render={(value, row, index) => {
            if (index < dietDataforTable.length) {
              return row.name;
            }
            return {
              children: (
                <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 15 }}>{row.totalAmountValue}</div>
              ),
            };
          }}
        />
        <Column
          title="Item Category"
          dataIndex="category"
          key="category"
          render={(value, row, index) => {
            const obj = {
              children: null,
              props: {},
            };
            if (index === dietDataforTable.length) {
              obj.props.colSpan = 0;
            }
            if (row.category === 'purinaProduct') {
              obj.children = 'Purina Product';
            }
            if (row.category === 'individualIngredient') {
              obj.children = 'Individual Ingredient';
            }
            if (row.category === 'customEntry') {
              obj.children = 'Custom Entry';
            }
            if (row.category === 'otherCommercialProduct') {
              obj.children = 'Other Commercial Product';
            }
            if (row.category === 'bookValues') {
              obj.children = 'Book Values';
            }
            if (row.category === 'uncategorized') {
              obj.children = 'Uncategorized';
            }
            return obj;
          }}
        />
        <Column
          title="Amount (per day)"
          dataIndex="amount"
          key="amount"
          render={(value, row, index) => {
            const obj = {
              children: renderAmountValueInTable(row),
              props: {},
            };
            if (index === dietDataforTable.length) {
              obj.props.colSpan = 0;
            }
            return obj;
          }}
        />
        <Column
          title="% Of Total"
          dataIndex="percentOfTotal"
          key="percentOfTotal"
          align="center"
          render={(value, row, index) => {
            let amountInLbs = 0;
            const obj = {
              children: null,
              props: {},
            };
            if (index === dietDataforTable.length) {
              obj.props.colSpan = 0;
            }
            if (row.unit === 'oz/day') {
              amountInLbs = row.amount * 0.0625;
            } else {
              amountInLbs = row.amount;
            }
            const amountIs = (amountInLbs * 100) / sumOfAmount;
            obj.children = amountIs.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            return obj;
          }}
        />
      </Table>
    </div>
  );
};

TotalDailyRation.propTypes = {
  dietDataforTable: PropTypes.array.isRequired,
  hideHeader: PropTypes.bool,
};

TotalDailyRation.defaultProps = {
  hideHeader: false,
};

export default TotalDailyRation;
