import React, { useEffect, useState } from 'react';
import { Button, Table, message, Popconfirm, Divider, Icon } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './AllUsersPage.scss';
import Heading from '../components/Heading';
import { firebaseDb, firebaseFunc } from '../utils/firebase';
import '../components/ButtonColor.scss';
import logger from '../utils/helpers/logger';

const { Column } = Table;

const AllUsersPage = (props) => {
  const { history } = props;
  const [loading, setLoading] = useState(true);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [usersData, setusersData] = useState([]);

  useEffect(() => {
    firebaseDb.collection('User').onSnapshot((querySnapshot) => {
      const userData = [];
      querySnapshot.forEach((doc) => {
        userData.push({ id: doc.id, ...doc.data() });
        setLoading(false);
      });
      setusersData(userData);
    });
  }, []);

  const handleDelete = (user) => {
    const name = user.firstName;
    const deleteUser = firebaseFunc.httpsCallable('deleteUser');
    deleteUser({ userId: user.id })
      .then(() => {
        message.success(`User ${name} is successfully deleted!`);
        const newdata = usersData.filter((item) => item.id !== user.id);
        setusersData(newdata);
        setDeleteUserId(null);
      })
      .catch((error) => {
        message.error(error.message);
        setDeleteUserId(null);
        logger(error);
      });
  };

  return (
    <div style={{ padding: '15px 45px' }}>
      <div className="add-new-button">
        <Button className="button-color" size="large">
          <Link to="/users/create">Add New</Link>
        </Button>
      </div>
      <div>
        <Heading headingName="Current Users" />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Table dataSource={usersData} bordered pagination={false} loading={loading} rowKey="id">
          <Column
            title="Name"
            dataIndex="name"
            key="name"
            align="left"
            render={(text, record) => {
              const nameOfUser = `${record.salutation} ${record.firstName} ${record.lastName}`;
              return nameOfUser;
            }}
          />
          <Column
            title="Email"
            dataIndex="email"
            key="email"
            align="center"
            render={(text, record) => {
              return record.email;
            }}
          />
          <Column
            title="Role"
            dataIndex="role"
            key="role"
            align="center"
            render={(text, record) => {
              return <div style={{ textTransform: 'capitalize' }}>{record.role}</div>;
            }}
          />
          <Column
            title="Options"
            key="Options"
            align="center"
            render={(text, record) => {
              return (
                <div>
                  <Button
                    icon="edit"
                    className="button-color"
                    onClick={() => {
                      history.push(`/users/${record.id}/edit`);
                    }}
                  >
                    Edit
                  </Button>
                  <Divider type="vertical" style={{ width: 2, height: 20, background: '#e8e8e8' }} />
                  <Popconfirm
                    title="Delete User. Are you sure?"
                    onConfirm={() => {
                      setDeleteUserId(record.id);
                      handleDelete(record);
                    }}
                    okText="Yes"
                    icon={<Icon type="warning" style={{ color: '#CE0E2D' }} />}
                    cancelText="No"
                  >
                    <Button icon="delete" loading={record.id === deleteUserId} className="button-delete">
                      Delete
                    </Button>
                  </Popconfirm>
                </div>
              );
            }}
          />
        </Table>
      </div>
    </div>
  );
};

AllUsersPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AllUsersPage;
