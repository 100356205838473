import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import '../pages/History/history.css';
import Heading from './Heading';
import { addressFormat } from '../utils/globals';

const GeneralInformation = (props) => {
  const { farmInfo, horseInfo } = props;

  return (
    <div style={{ marginTop: 20, marginBottom: 20 }} className="container">
      <Heading headingName="General Information" />
      <Row>
        <Col span={12}>
          <Row>
            <Col span={12} className="eachCol labelCol">
              Horse Name:
            </Col>
            <Col span={12} className="eachCol">
              <div style={{ textTransform: 'capitalize' }}>{horseInfo.name}</div>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={12} className="eachCol labelCol">
              Farm Name:
            </Col>
            <Col span={12} className="eachCol">
              <div style={{ textTransform: 'capitalize' }}>{farmInfo.name}</div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {farmInfo.managerName || farmInfo.managerEmail || farmInfo.managerMobile ? (
          <Col span={12}>
            {farmInfo.managerName ? (
              <Row>
                <Col span={12} className="eachCol labelCol">
                  Manager Name:
                </Col>
                <Col span={12} className="eachCol">
                  <div style={{ textTransform: 'capitalize' }}>{farmInfo.managerName}</div>
                </Col>
              </Row>
            ) : null}
            {farmInfo.managerEmail ? (
              <Row>
                <Col span={12} className="eachCol labelCol">
                  Manager Email:
                </Col>
                <Col span={12} className="eachCol">
                  {farmInfo.managerEmail}
                </Col>
              </Row>
            ) : null}
            {farmInfo.managerMobile ? (
              <Row>
                <Col span={12} className="eachCol labelCol">
                  Manager Phone:
                </Col>
                <Col span={12} className="eachCol">
                  {farmInfo.managerMobile}
                </Col>
              </Row>
            ) : null}
          </Col>
        ) : null}

        {farmInfo.addressLine1 ||
        farmInfo.addressLine2 ||
        farmInfo.city ||
        farmInfo.state ||
        farmInfo.zip ||
        farmInfo.salesRepName ||
        farmInfo.salesRepEmail ? (
          <Col span={12}>
            {farmInfo.addressLine1 || farmInfo.addressLine2 || farmInfo.city || farmInfo.state || farmInfo.zip ? (
              <Row>
                <Col span={12} className="eachCol labelCol">
                  Address:
                </Col>
                <Col span={12} className="eachCol">
                  {addressFormat(farmInfo)}
                </Col>
              </Row>
            ) : null}
            {farmInfo.salesRepName ? (
              <Row>
                <Col span={12} className="eachCol labelCol">
                  Sales Rep Name:
                </Col>
                <Col span={12} className="eachCol">
                  <div style={{ textTransform: 'capitalize' }}>{farmInfo.salesRepName}</div>
                </Col>
              </Row>
            ) : null}
            {farmInfo.salesRepEmail ? (
              <Row>
                <Col span={12} className="eachCol labelCol">
                  Sales Rep Email:
                </Col>
                <Col span={12} className="eachCol">
                  {farmInfo.salesRepEmail}
                </Col>
              </Row>
            ) : null}
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

GeneralInformation.propTypes = {
  farmInfo: PropTypes.object.isRequired,
  horseInfo: PropTypes.object.isRequired,
};

export default GeneralInformation;
