import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import ViewFeedComponent from '../components/ViewFeedComponent';

const ConcentratePage = () => {
  return (
    <div style={{ padding: '15px 45px' }}>
      <Breadcrumb style={{ paddingBottom: 30 }}>
        <Breadcrumb.Item>
          <Link style={{ color: '#0071cc' }} to="/feeds/forages">
            Feed Library
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Concentrates</Breadcrumb.Item>
      </Breadcrumb>
      <ViewFeedComponent feedItem="concentrate" labelName="Concentrate" />
    </div>
  );
};

export default ConcentratePage;
