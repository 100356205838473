import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Input, Radio, Button, message, Row, Col } from 'antd';
import { firebaseFunc } from '../utils/firebase';
import '../pages/AllUsersPage.scss';
import '../components/ButtonColor.scss';
import logger from '../utils/helpers/logger';

const UserForm = (props) => {
  const [loadingSubmitButton, setLoadingSubmitButton] = useState(false);
  const { form, history, mode, userToBeEdit, currentUserId } = props;
  const { getFieldDecorator, validateFields, resetFields } = form;

  const handleCancel = () => {
    resetFields();
    history.push('/users');
  };

  const handleSubmit = (e) => {
    setLoadingSubmitButton(true);
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const valuesToSubmit = values;
        if (mode === 'editUser') valuesToSubmit.userId = currentUserId;

        const userOperation = firebaseFunc.httpsCallable(mode === 'editUser' ? 'updateUser' : 'createUser');

        userOperation(valuesToSubmit)
          .then(() => {
            resetFields();
            message.success(
              mode === 'createUser' ? 'User profile is succesfully added!' : 'User profile is succesfully updated!',
            );
            setLoadingSubmitButton(false);
            history.push('/users');
          })
          .catch((error) => {
            logger(error);
            resetFields();
            message.error(error.message);
            setLoadingSubmitButton(false);
          });
      } else {
        setLoadingSubmitButton(false);
      }
    });
  };

  return (
    <div style={{ padding: 60 }}>
      <Form>
        <Form.Item label="Salutation" labelCol={{ span: 6 }} wrapperCol={{ span: 6 }}>
          {getFieldDecorator('salutation', {
            initialValue: userToBeEdit ? userToBeEdit.salutation : undefined,
            rules: [{ required: true, message: 'Please select Salutation and try again' }],
          })(
            <Radio.Group>
              <Radio value="Dr.">Dr.</Radio>
              <Radio value="Ms.">Ms.</Radio>
              <Radio value="Mr.">Mr.</Radio>
            </Radio.Group>,
          )}
        </Form.Item>
        <Form.Item label="User First Name" labelCol={{ span: 6 }} wrapperCol={{ span: 6 }}>
          {getFieldDecorator('firstName', {
            initialValue: userToBeEdit ? userToBeEdit.firstName : undefined,
            rules: [{ required: true, message: 'Please enter User First Name and try again' }],
          })(<Input placeholder="Enter First Name of User" />)}
        </Form.Item>
        <Form.Item label="User Last Name" labelCol={{ span: 6 }} wrapperCol={{ span: 6 }}>
          {getFieldDecorator('lastName', {
            initialValue: userToBeEdit ? userToBeEdit.lastName : undefined,
            rules: [{ required: true, message: 'Please enter User Last Name and try again' }],
          })(<Input placeholder="Enter Last Name of User" />)}
        </Form.Item>
        <Form.Item
          className="email-id"
          label="Email ID"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 6 }}
          extra="This Email will be used for login"
        >
          {getFieldDecorator('email', {
            initialValue: userToBeEdit ? userToBeEdit.email : undefined,
            rules: [
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please enter Email and try again',
              },
            ],
          })(<Input placeholder="Enter Email of User" />)}
        </Form.Item>

        <Form.Item label="Role" labelCol={{ span: 6 }} wrapperCol={{ span: 6 }}>
          {getFieldDecorator('role', {
            initialValue: userToBeEdit ? userToBeEdit.role : 'nutritionist',
            rules: [{ required: true, message: 'Please select Role of User and try again' }],
          })(
            <Radio.Group name="radiogroup">
              <Radio value="admin">Admin</Radio>
              <Radio value="nutritionist">Nutritionist</Radio>
            </Radio.Group>,
          )}
        </Form.Item>
        <Row>
          <Col offset={6}>
            <Form.Item>
              <Button
                className="button-color"
                style={{ marginRight: 20 }}
                loading={loadingSubmitButton}
                onClick={handleSubmit}
              >
                {mode === 'createUser' ? 'Add New' : 'Save'}
              </Button>
              <Button className="button-color" onClick={handleCancel}>
                Cancel
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

UserForm.propTypes = {
  mode: PropTypes.string.isRequired,
  currentUserId: PropTypes.string,
  userToBeEdit: PropTypes.object,
  history: PropTypes.object.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
  }).isRequired,
};

UserForm.defaultProps = {
  userToBeEdit: {},
  currentUserId: '',
};

export default withRouter(Form.create({ name: 'UserForm' })(UserForm));
