import React, { useEffect, useState } from 'react';
import { message, Spin } from 'antd';
import PropTypes from 'prop-types';
import UserForm from '../forms/UserForm';
import { firebaseDb } from '../utils/firebase';

const EditUserPage = (props) => {
  const { match } = props;
  const [userToBeEdit, setUserToBeEdit] = useState(null);

  const currentUserId = match.params.userid;

  useEffect(() => {
    firebaseDb
      .collection('User')
      .doc(currentUserId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setUserToBeEdit(doc.data());
        } else {
          message.error('No such user!');
        }
      });
  }, []);

  if (userToBeEdit) {
    return <UserForm mode="editUser" userToBeEdit={userToBeEdit} currentUserId={currentUserId} />;
  }

  return <Spin style={{ display: 'flex', justifyContent: 'center', marginTop: 60 }} />;
};

EditUserPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default EditUserPage;
