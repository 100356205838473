import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Select, message, Button } from 'antd';
import { firebaseDb } from '../../utils/firebase';
import CurrentDietsHistoryPage from './CurrentDietsHistoryPage';
import RecommendedDietsHistoryPage from './RecommendedDietsHistoryPage';
import {
  feedNutrientsForAnalysis,
  acceptableDeltaInitialValueLow,
  acceptableDeltaInitialValueHigh,
  targetDeltaInitialValueLow,
  targetDeltaInitialValueHigh,
} from '../../utils/globals';
import useAuth from '../../utils/auth';
import CompareDietHistoryPage from './CompareDietHistoryPage';
import GenerateReportPage from '../../components/GenerateReportPage';
import logger from '../../utils/helpers/logger';

const nutrientKeys = feedNutrientsForAnalysis.map((nut) => nut.key);

const { Option } = Select;

const HorseGrowthDietHistoryPage = () => {
  const { user, convertOzToLbs, convertPoundToKg, userSettings } = useAuth();
  const [activeTab, setActiveTab] = useState(0);
  const [farmList, setFarmList] = useState([]);
  const [horseList, setHorseList] = useState([]);
  const [dietDataOfSelectedHorse, setDietDataOfSelectedHorse] = useState(null);
  const [currentFarmId, setCurrentFarmId] = useState(undefined);
  const [currentHorseId, setCurrentHorseId] = useState(undefined);
  const [currentRationType, setCurrentRationType] = useState(null);
  const [recommendRationType, setRecommendRationType] = useState(null);
  const [recordOfSelectedDiet, setRecordOfSelectedDiet] = useState(null);
  const [nutSuppGraphChartType, setNutSuppGraphChartType] = useState('standard');
  const [nutrientKeysToShow, setNutrientKeysToShow] = useState(nutrientKeys);
  const [targetDeltaLow, setTargetDeltaLow] = useState(targetDeltaInitialValueLow);
  const [targetDeltaHigh, setTargetDeltaHigh] = useState(targetDeltaInitialValueHigh);
  const [acceptableDeltaLow, setAcceptableDeltaLow] = useState(acceptableDeltaInitialValueLow);
  const [acceptableDeltaHigh, setAcceptableDeltaHigh] = useState(acceptableDeltaInitialValueHigh);
  const [compareDietOfHorse, setCompareDietOfHorse] = useState([]);
  const [generateReportPage, setGenerateReportPage] = useState('');
  const [maximumCap, setMaximumCap] = useState(null);
  const [viewId, setViewId] = useState(null);

  const updateTargetDeltaLowForNutrient = (value, nutrientKey) => {
    setTargetDeltaLow({
      ...targetDeltaLow,
      [nutrientKey]: value,
    });
  };

  const updateTargetDeltaHighForNutrient = (value, nutrientKey) => {
    setTargetDeltaHigh({
      ...targetDeltaHigh,
      [nutrientKey]: value,
    });
  };

  const updateAcceptableDeltaLowForNutrient = (value, nutrientKey) => {
    setAcceptableDeltaLow({
      ...acceptableDeltaLow,
      [nutrientKey]: value,
    });
  };

  const updateAcceptableDeltaHighForNutrient = (value, nutrientKey) => {
    setAcceptableDeltaHigh({
      ...acceptableDeltaHigh,
      [nutrientKey]: value,
    });
  };

  useEffect(() => {
    firebaseDb
      .collection('Farm')
      .where('createdBy', '==', user.uid)
      .get()
      .then((querySnapshot) => {
        const farms = [];
        querySnapshot.forEach((doc) => {
          farms.push({ id: doc.id, ...doc.data() });
        });
        setFarmList(farms);
      })
      .catch((error) => {
        message.error(error.message);
        logger(error);
      });
  }, []);

  const setCurrentFarmAndGetHorses = (farmId) => {
    setCurrentFarmId(farmId);
    setCurrentHorseId(undefined);
    setHorseList([]);
    firebaseDb
      .collection('Farm')
      .doc(farmId)
      .collection('horses')
      .where('createdBy', '==', user.uid)
      .get()
      .then((querySnapshot) => {
        const horses = [];
        querySnapshot.forEach((doc) => {
          horses.push({ id: doc.id, ...doc.data() });
        });
        setHorseList(horses);
      })
      .catch((error) => {
        message.error(error.message);
        logger(error);
      });
  };

  const handleHorseSelect = (v) => {
    setCurrentHorseId(v);
    setRecordOfSelectedDiet(null);
    firebaseDb
      .collection('HorseDiet')
      .where('horseId', '==', v)
      .get()
      .then((querySnapshot) => {
        const horseDiet = [];
        querySnapshot.forEach((doc) => {
          horseDiet.push({ id: doc.id, ...doc.data() });
        });
        setDietDataOfSelectedHorse(horseDiet);
        setCurrentRationType(horseDiet.filter((diet) => diet.rationType === 'current'));
        setRecommendRationType(horseDiet.filter((diet) => diet.rationType === 'recommended'));
      })
      .catch((error) => {
        message.error(error.message);
        logger(error);
      });
    firebaseDb
      .collection('CompareDiet')
      .where('horseId', '==', v)
      .get()
      .then((querySnapshot) => {
        const horseCompareDiet = [];
        querySnapshot.forEach((doc) => {
          horseCompareDiet.push({ id: doc.id, ...doc.data() });
        });
        setCompareDietOfHorse(horseCompareDiet);
      })
      .catch((error) => {
        message.error(error.message);
        logger(error);
      });
  };

  const onClickDeleteButton = (record, setDeleteDietId, label) => {
    firebaseDb
      .collection('HorseDiet')
      .doc(record.id)
      .delete()
      .then(() => {
        message.success(`${label} is successfully deleted!`);
        if (label === 'Current Diet') {
          setCurrentRationType(currentRationType.filter((item) => item.id !== record.id));
        } else {
          setRecommendRationType(recommendRationType.filter((item) => item.id !== record.id));
        }
        setDeleteDietId(null);
        setRecordOfSelectedDiet(null);
      })
      .catch((error) => {
        message.error('Error in deleting the item!!');
        logger(error);
        setDeleteDietId(null);
      });
  };

  const onClickDeleteButtonOfCompare = (record, setDeleteDietIdCompare) => {
    firebaseDb
      .collection('CompareDiet')
      .doc(record.id)
      .delete()
      .then(() => {
        message.success('Compare Diet is successfully deleted!');
        setCompareDietOfHorse(compareDietOfHorse.filter((item) => item.id !== record.id));
        setDeleteDietIdCompare(null);
        setRecordOfSelectedDiet(null);
      })
      .catch((error) => {
        message.error('Error in deleting the item!!');
        logger(error);
        setDeleteDietIdCompare(null);
      });
  };

  let farmInfo = null;
  if (farmList.length) {
    farmInfo = farmList.find((farm) => farm.id === currentFarmId);
  }

  let horseInfo = null;
  if (horseList.length) {
    horseInfo = horseList.find((horse) => horse.id === currentHorseId);
  }

  let scrollTop = 0;
  if (currentRationType && recommendRationType && compareDietOfHorse) {
    if (currentRationType.length >= 3 && recommendRationType.length >= 3 && compareDietOfHorse.length >= 3) {
      scrollTop = 850;
    }
    if (currentRationType.length >= 2 && recommendRationType.length >= 2 && compareDietOfHorse.length >= 2) {
      scrollTop = 750;
    }
    if (currentRationType.length >= 1 && recommendRationType.length >= 1 && compareDietOfHorse.length >= 1) {
      scrollTop = 650;
    }
    if (currentRationType.length >= 0 && recommendRationType.length >= 0 && compareDietOfHorse.length >= 0) {
      scrollTop = 550;
    }
  }

  const selectedOneDietOfHorse = (record, type) => {
    if (type === 'viewButton') {
      setViewId(record.id);
    }
    setRecordOfSelectedDiet(record);
    setTargetDeltaHigh(record.targetDeltaHigh);
    setTargetDeltaLow(record.targetDeltaLow);
    setAcceptableDeltaHigh(record.acceptableDeltaHigh);
    setAcceptableDeltaLow(record.acceptableDeltaLow);
    setNutrientKeysToShow(record.selectedNutrients);
    setNutSuppGraphChartType(record.chartType);
    setMaximumCap(record.maximumCap);
    setTimeout(() => {
      setViewId(null);
      window.scroll({
        top: scrollTop,
        left: 0,
        behavior: 'smooth',
      });
    }, 300);
  };

  const handleSaveOfUpdateDietName = (value, recordRow, setSaveButtonLoader, setDietModal, dataBaseName) => {
    setSaveButtonLoader(true);
    firebaseDb
      .collection(dataBaseName)
      .doc(recordRow.id)
      .update({
        updatedAt: new Date(),
        updatedBy: user.uid,
        name: value.dietName,
      })
      .then(() => {
        message.success('Diet name has been successfully updated');
        setDietModal(false);
        setSaveButtonLoader(false);
      })
      .catch((er) => {
        message.error(er.message);
        setDietModal(false);
        setSaveButtonLoader(false);
        logger(er);
      });
    if (dataBaseName === 'HorseDiet') {
      dietDataOfSelectedHorse.map((diet) => {
        if (recordRow.id === diet.id) {
          const tempDiet = diet;
          tempDiet.name = value.dietName;
          return tempDiet;
        }
        return diet;
      });
    } else {
      compareDietOfHorse.map((diet) => {
        if (recordRow.id === diet.id) {
          const tempDiet = diet;
          tempDiet.name = value.dietName;
          return tempDiet;
        }
        return diet;
      });
    }
  };

  const totalAmountOfCurrentList = () => {
    let totalAmount = 0;
    if (recordOfSelectedDiet && recordOfSelectedDiet.rationData) {
      recordOfSelectedDiet.rationData.forEach((item) => {
        if (item.unit === 'oz/day') {
          totalAmount += convertOzToLbs(item.amount);
        } else {
          totalAmount += item.amount;
        }
      });
      return totalAmount;
    }
    if (generateReportPage === 'compare' && recordOfSelectedDiet.currentRationData) {
      recordOfSelectedDiet.currentRationData.forEach((item) => {
        if (item.unit === 'oz/day') {
          totalAmount += convertOzToLbs(item.amount);
        } else {
          totalAmount += item.amount;
        }
      });
      return totalAmount;
    }
    return null;
  };

  const totalAmountOfRecommendList = () => {
    let totalAmountRecommend = 0;
    if (recordOfSelectedDiet.recommendedRationData) {
      recordOfSelectedDiet.recommendedRationData.forEach((item) => {
        if (item.unit === 'oz/day') {
          totalAmountRecommend += convertOzToLbs(item.amount);
        } else {
          totalAmountRecommend += item.amount;
        }
      });
      return totalAmountRecommend;
    }
    return null;
  };

  return (
    <div>
      {generateReportPage && recordOfSelectedDiet ? (
        <GenerateReportPage
          onClickGoBack={() => {
            setGenerateReportPage('');
          }}
          compareGenerateReport={generateReportPage}
          recommendedItemList={
            generateReportPage === 'compare'
              ? recordOfSelectedDiet.recommendedRationData
              : recordOfSelectedDiet.rationData
          }
          horseDataForCalculation={recordOfSelectedDiet.horseData}
          feedItemList={
            generateReportPage === 'compare' ? recordOfSelectedDiet.currentRationData : recordOfSelectedDiet.rationData
          }
          selectedFarmData={farmInfo}
          targetDeltaLow={targetDeltaLow}
          targetDeltaHigh={targetDeltaHigh}
          setTargetDeltaLow={setTargetDeltaLow}
          setTargetDeltaHigh={setTargetDeltaHigh}
          acceptableDeltaLow={acceptableDeltaLow}
          acceptableDeltaHigh={acceptableDeltaHigh}
          setAcceptableDeltaLow={setAcceptableDeltaLow}
          setAcceptableDeltaHigh={setAcceptableDeltaHigh}
          accRangeGraphNutrients={nutrientKeysToShow}
          setAccRangeGraphNutrients={setNutrientKeysToShow}
          nutSuppGraphChartType={nutSuppGraphChartType}
          setNutSuppGraphChartType={setNutSuppGraphChartType}
          nutSuppGraphNutrients={nutrientKeysToShow}
          setNutSuppGraphNutrients={setNutrientKeysToShow}
          maximumCap={maximumCap}
          totalAmount={
            userSettings.uom === 'metric' ? convertPoundToKg(totalAmountOfCurrentList()) : totalAmountOfCurrentList()
          }
          totalAmountRecommend={
            userSettings.uom === 'metric'
              ? convertPoundToKg(totalAmountOfRecommendList())
              : totalAmountOfRecommendList()
          }
        />
      ) : (
        <Tabs
          selectedIndex={activeTab}
          onSelect={(v) => {
            setGenerateReportPage('');
            setRecordOfSelectedDiet(null);
            setActiveTab(v);
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20, marginLeft: 35 }}>
            <div className="select-boxes-wrap">
              <div style={{ margin: 20 }}>
                <span style={{ marginRight: 10, color: 'black' }}>Select a Farm: </span>
                <Select
                  showSearch
                  style={{ width: 350 }}
                  placeholder="Search for Farm by Name/City/State/Zipcode"
                  optionFilterProp="data"
                  onChange={(v) => setCurrentFarmAndGetHorses(v)}
                  value={currentFarmId}
                >
                  {farmList.map((farm) => (
                    <Option key={farm.id} value={farm.id} data={`${farm.name}_${farm.city}_${farm.state}_${farm.zip}`}>
                      {farm.name}
                    </Option>
                  ))}
                </Select>
              </div>
              {currentFarmId && horseList.length ? (
                <div style={{ margin: 18 }}>
                  <span style={{ color: 'black', marginRight: 7 }}>Select a Horse: </span>
                  <Select
                    showSearch
                    style={{ width: 350 }}
                    placeholder="Start typing the Name of Horse"
                    optionFilterProp="data"
                    onChange={handleHorseSelect}
                    value={currentHorseId}
                  >
                    {horseList.map((horse) => (
                      <Option key={horse.id} value={horse.id} data={`${horse.name}`}>
                        {horse.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              ) : null}
            </div>
          </div>
          {currentHorseId ? (
            <TabList style={{ padding: '0px 35px' }}>
              <Tab>Current Diets</Tab>
              <Tab>Recommended Diets</Tab>
              <Tab>Compared Diets</Tab>
            </TabList>
          ) : null}
          <TabPanel>
            {currentHorseId ? (
              <div>
                <CurrentDietsHistoryPage
                  onClickDeleteButton={onClickDeleteButton}
                  currentRationType={currentRationType}
                  farmInfo={farmInfo}
                  horseInfo={horseInfo}
                  currentHorseId={currentHorseId}
                  recordOfSelectedDiet={recordOfSelectedDiet}
                  nutSuppGraphChartType={nutSuppGraphChartType}
                  setNutSuppGraphChartType={setNutSuppGraphChartType}
                  nutrientKeysToShow={nutrientKeysToShow}
                  setNutrientKeysToShow={setNutrientKeysToShow}
                  acceptableDeltaLow={acceptableDeltaLow}
                  acceptableDeltaHigh={acceptableDeltaHigh}
                  targetDeltaLow={targetDeltaLow}
                  targetDeltaHigh={targetDeltaHigh}
                  setAcceptableDeltaLow={updateAcceptableDeltaLowForNutrient}
                  setAcceptableDeltaHigh={updateAcceptableDeltaHighForNutrient}
                  setTargetDeltaLow={updateTargetDeltaLowForNutrient}
                  setTargetDeltaHigh={updateTargetDeltaHighForNutrient}
                  selectedOneDietOfHorse={selectedOneDietOfHorse}
                  handleSaveOfUpdateDietName={handleSaveOfUpdateDietName}
                  maximumCap={maximumCap}
                  setMaximumCap={setMaximumCap}
                  totalAmount={
                    userSettings.uom === 'metric'
                      ? convertPoundToKg(totalAmountOfCurrentList())
                      : totalAmountOfCurrentList()
                  }
                  viewId={viewId}
                />
                {recordOfSelectedDiet ? (
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                    <Button
                      size="large"
                      className="button-color"
                      onClick={() => {
                        setGenerateReportPage('current');
                      }}
                    >
                      Generate Report
                    </Button>
                  </div>
                ) : null}
              </div>
            ) : null}
            {currentHorseId && horseList.length !== 0 && currentRationType && currentRationType.length === 0 ? (
              <div style={{ textAlign: 'center', fontSize: 17, marginTop: 40, color: '#CE0E2D' }}>
                Oops! No diets have been built and saved for this Horse.
              </div>
            ) : null}
          </TabPanel>
          <TabPanel>
            {currentHorseId ? (
              <div>
                <RecommendedDietsHistoryPage
                  onClickDeleteButton={onClickDeleteButton}
                  recommendRationType={recommendRationType}
                  farmInfo={farmInfo}
                  horseInfo={horseInfo}
                  currentHorseId={currentHorseId}
                  recordOfSelectedDiet={recordOfSelectedDiet}
                  selectedOneDietOfHorse={selectedOneDietOfHorse}
                  nutSuppGraphChartType={nutSuppGraphChartType}
                  setNutSuppGraphChartType={setNutSuppGraphChartType}
                  nutrientKeysToShow={nutrientKeysToShow}
                  setNutrientKeysToShow={setNutrientKeysToShow}
                  acceptableDeltaLow={acceptableDeltaLow}
                  acceptableDeltaHigh={acceptableDeltaHigh}
                  targetDeltaLow={targetDeltaLow}
                  targetDeltaHigh={targetDeltaHigh}
                  setAcceptableDeltaLow={updateAcceptableDeltaLowForNutrient}
                  setAcceptableDeltaHigh={updateAcceptableDeltaHighForNutrient}
                  setTargetDeltaLow={updateTargetDeltaLowForNutrient}
                  setTargetDeltaHigh={updateTargetDeltaHighForNutrient}
                  handleSaveOfUpdateDietName={handleSaveOfUpdateDietName}
                  maximumCap={maximumCap}
                  setMaximumCap={setMaximumCap}
                  totalAmount={
                    userSettings.uom === 'metric'
                      ? convertPoundToKg(totalAmountOfCurrentList())
                      : totalAmountOfCurrentList()
                  }
                  viewId={viewId}
                />
                {recordOfSelectedDiet ? (
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                    <Button
                      size="large"
                      className="button-color"
                      onClick={() => {
                        setGenerateReportPage('recommended');
                      }}
                    >
                      Generate Report
                    </Button>
                  </div>
                ) : null}
              </div>
            ) : null}
            {currentHorseId && horseList.length !== 0 && recommendRationType && recommendRationType.length === 0 ? (
              <div style={{ textAlign: 'center', fontSize: 17, marginTop: 40, color: '#CE0E2D' }}>
                Oops! No diets have been built and saved for this Horse.
              </div>
            ) : null}
          </TabPanel>
          <TabPanel>
            {currentHorseId ? (
              <div>
                <CompareDietHistoryPage
                  onClickDeleteButtonOfCompare={onClickDeleteButtonOfCompare}
                  compareDietOfHorse={compareDietOfHorse}
                  farmInfo={farmInfo}
                  horseInfo={horseInfo}
                  currentHorseId={currentHorseId}
                  recordOfSelectedDiet={recordOfSelectedDiet}
                  nutSuppGraphChartType={nutSuppGraphChartType}
                  setNutSuppGraphChartType={setNutSuppGraphChartType}
                  nutrientKeysToShow={nutrientKeysToShow}
                  setNutrientKeysToShow={setNutrientKeysToShow}
                  acceptableDeltaLow={acceptableDeltaLow}
                  acceptableDeltaHigh={acceptableDeltaHigh}
                  targetDeltaLow={targetDeltaLow}
                  targetDeltaHigh={targetDeltaHigh}
                  setAcceptableDeltaLow={updateAcceptableDeltaLowForNutrient}
                  setAcceptableDeltaHigh={updateAcceptableDeltaHighForNutrient}
                  setTargetDeltaLow={updateTargetDeltaLowForNutrient}
                  setTargetDeltaHigh={updateTargetDeltaHighForNutrient}
                  selectedOneDietOfHorse={selectedOneDietOfHorse}
                  handleSaveOfUpdateDietName={handleSaveOfUpdateDietName}
                  maximumCap={maximumCap}
                  setMaximumCap={setMaximumCap}
                  viewId={viewId}
                />
                {recordOfSelectedDiet ? (
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
                    <Button
                      size="large"
                      className="button-color"
                      onClick={() => {
                        setGenerateReportPage('compare');
                      }}
                    >
                      Generate Report
                    </Button>
                  </div>
                ) : null}
              </div>
            ) : null}
            {currentHorseId && horseList.length !== 0 && currentRationType && currentRationType.length === 0 ? (
              <div style={{ textAlign: 'center', fontSize: 17, marginTop: 40, color: '#CE0E2D' }}>
                Oops! No diets have been built and saved for this Horse.
              </div>
            ) : null}
          </TabPanel>
        </Tabs>
      )}
    </div>
  );
};
export default HorseGrowthDietHistoryPage;
