import React, { useState } from 'react';
import { Table, Button, message, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import './SpreadSheetPreview.scss';
import { firebaseDb } from '../utils/firebase';
import useAuth from '../utils/auth';
import { feedNutrients } from '../utils/globals';
import './ButtonColor.scss';
import Heading from './Heading';

const { Column } = Table;

const SpreadSheetPreview = (props) => {
  const { user } = useAuth();
  const {
    dataToShow,
    nameOfFile,
    onAddClickEmptyData,
    typeOfData,
    nameOfButton,
    columnName,
    errors,
    setErrors,
  } = props;
  const [loadingAddButton, setLoadingAddButton] = useState(false);

  const dataForTable = dataToShow.filter((element, index) => index !== 0);

  const handleAddClick = () => {
    const firestoreBatch = firebaseDb.batch();
    setLoadingAddButton(true);
    dataForTable.forEach((element) => {
      const newValues = {};
      Object.entries(element).forEach((entry) => {
        const [key, value] = entry;
        if (value) {
          newValues[key] = value;
        } else if (key === 'code' || key === 'name' || key === 'category') {
          newValues[key] = null;
        } else {
          newValues[key] = 0;
        }
      });

      const newAddBulkRef = firebaseDb.collection('Feed').doc();
      firestoreBatch.set(newAddBulkRef, {
        type: `${typeOfData}`,
        createdAt: new Date(),
        createdBy: user.uid,
        ...newValues,
      });
    });

    firestoreBatch.commit().then(() => {
      message.success(`All ${typeOfData} items have been successfully added`);
      setLoadingAddButton(false);
      onAddClickEmptyData();
    });
  };

  return (
    <div className="spreadSheet-table-container">
      <div className="file-name-label">
        Selected File name:<span>{nameOfFile}</span>
      </div>
      <div style={{ fontStyle: 'italic', marginBottom: 20 }}>
        <span style={{ color: 'black' }}>NOTE: </span>
        Feed category should be one of Purina Product, Individual Ingredient, Custom Entry, Other Commercial Product,
        Uncategorized
      </div>
      {errors ? (
        <div style={{ color: '#CE0E2D', fontSize: 15, fontStyle: 'oblique', marginBottom: 10 }}>
          <Icon type="warning" /> Please fill the required fields and re-upload the sheet.
        </div>
      ) : null}
      <Heading headingName="Item Composition (DM basis)" />
      <Table dataSource={dataForTable} scroll={{ x: 'max-content' }} rowKey="id" bordered>
        <Column
          title={`${columnName} Category`}
          dataIndex="category"
          key="category"
          render={(text, record) => {
            if (record.category === 'purinaProduct') {
              return 'Purina Product';
            }
            if (record.category === 'individualIngredient') {
              return 'Individual Ingredient';
            }
            if (record.category === 'customEntry') {
              return 'Custom Entry';
            }
            if (record.category === 'otherCommercialProduct') {
              return 'Other Commercial Product';
            }
            if (record.category === 'uncategorized') {
              return 'Uncategorized';
            }
            if (record.category === 'bookValues') {
              return 'Book Values';
            }
            return (
              <div style={{ color: '#CE0E2D', fontSize: 12 }}>
                <Icon type="exclamation-circle" style={{ marginRight: 5 }} />
                Required
              </div>
            );
          }}
        />
        <Column
          className="nameOfFeed"
          title={`${columnName} Name`}
          dataIndex="name"
          key="name"
          render={(text, record) => {
            return (
              <div>
                {record.name ? (
                  record.name
                ) : (
                  <div style={{ color: '#CE0E2D', fontSize: 12 }}>
                    <Icon type="exclamation-circle" style={{ marginRight: 5 }} />
                    Required
                  </div>
                )}
              </div>
            );
          }}
        />
        <Column
          title={`${columnName} Code`}
          dataIndex="code"
          key="code"
          align="center"
          render={(text, record) => {
            return <div>{record.code ? record.code : <div>-</div>}</div>;
          }}
        />
        {feedNutrients.map((entry) => (
          <Column
            style={{ width: 30 }}
            title={entry.label}
            dataIndex={entry.key}
            key={entry.key}
            render={(text, record) => {
              const data = entry.key;
              return record[data] ? record[data] : 0;
            }}
          />
        ))}
      </Table>

      <div className="buttons">
        <Button
          style={{ marginRight: 10 }}
          className="button-color"
          disabled={!dataForTable || errors}
          onClick={handleAddClick}
          loading={loadingAddButton}
        >
          {`${nameOfButton}`}
        </Button>
        <Button
          className="cancel-button"
          className="button-color"
          onClick={() => {
            onAddClickEmptyData();
            setErrors(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

SpreadSheetPreview.propTypes = {
  dataToShow: PropTypes.array.isRequired,
  nameOfFile: PropTypes.string.isRequired,
  typeOfData: PropTypes.string.isRequired,
  columnName: PropTypes.string.isRequired,
  nameOfButton: PropTypes.string.isRequired,
  onAddClickEmptyData: PropTypes.func.isRequired,
  errors: PropTypes.bool,
  setErrors: PropTypes.func,
};

SpreadSheetPreview.defaultProps = {
  errors: false,
  setErrors: null,
};

export default withRouter(SpreadSheetPreview);
