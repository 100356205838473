import React, { useState } from 'react';
import axios from 'axios';
import { Button, Table, Affix, Modal, Form, message } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import GraphOfNutrientSupplied from './GraphOfNutrientSupplied';
import AcceptableRangeGraph from './AcceptableRangeGraph';
import './RationAnalysisReport.scss';
import rationCalculations from '../utils/rationCalculations';
import NutrientsSuppliedTable from './NutrientsSuppliedTable';
import './ButtonColor.scss';
import DietNameSaveForm from '../forms/DietNameSaveForm';
import useAuth from '../utils/auth';
import useAppData from '../contexts/appDataContext';
import { feedNutrientsForTable } from '../utils/globals';
import logger from '../utils/helpers/logger';

const { Column } = Table;

const RationAnalysisReport = (props) => {
  const {
    onClickPrevious,
    currentRationList,
    horseDataForCalculation,
    onClickEditRationButton,
    goToRecommendRationPage,
    handleSaveOfHorseDiet,
    targetDeltaLow,
    targetDeltaHigh,
    setTargetDeltaLow,
    setTargetDeltaHigh,
    acceptableDeltaLow,
    acceptableDeltaHigh,
    setAcceptableDeltaLow,
    setAcceptableDeltaHigh,
    accRangeGraphNutrients,
    setAccRangeGraphNutrients,
    nutSuppGraphChartType,
    setNutSuppGraphChartType,
    nutSuppGraphNutrients,
    setNutSuppGraphNutrients,
    onClickGenerateReport,
    maximumCap,
    setMaximumCap,
    totalAmount,
  } = props;
  const [saveButtonLoader, setSaveButtonLoader] = useState(false);
  const [currentDietModal, setCurrentDietModal] = useState(false);
  const [modalOfBackButton, setModalOfBackButton] = useState(false);
  const [isUploadHorseMQDataButtonLoading, setIsUploadHorseMQDataButtonLoading] = useState(false);
  const { userSettings, convertOzToGram, convertPoundToKg, convertOzToLbs } = useAuth();
  const { resetRationAnalyzer, createKeysAndDataForGraph } = useAppData();

  const currentRationCalculations = rationCalculations({
    rationList: currentRationList,
    horseData: horseDataForCalculation,
    targetDeltaLow,
    targetDeltaHigh,
    acceptableDeltaLow,
    acceptableDeltaHigh,
  });

  // const to store nutrient keys for the nutrients graph
  const nutrientKeys = ['percentDM', ...nutSuppGraphNutrients];

  const handleSaveRation = (value) => {
    const rationType = 'current';
    handleSaveOfHorseDiet(value.dietName, rationType, setSaveButtonLoader, setCurrentDietModal);
  };

  const renderAmountValueInTable = (record) => {
    if (userSettings.uom === 'metric') {
      if (record.unit && record.unit === 'lbs/day') {
        return `${convertPoundToKg(record.amount).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} kgs`;
      }
      if (record.unit && record.unit === 'oz/day') {
        return `${convertOzToGram(record.amount).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} grams`;
      }
      return null;
    }
    return `${
      record.amount
        ? record.amount.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : null
    } ${record.unit === 'lbs/day' ? 'lbs' : 'oz'}`;
  };

  // function to generate and upload JSON data for Horse MQ
  const onClickUploadHorseMQData = async () => {
    setIsUploadHorseMQDataButtonLoading(true);
    // extracting data for nutrients_supplied_graph
    const [finalDataForGraph, dataKeyForGraph] = createKeysAndDataForGraph(
      currentRationCalculations,
      nutSuppGraphNutrients.filter((nutrient) => nutrient !== 'sugar' && nutrient !== 'starch'),
      nutSuppGraphChartType,
    );

    // const to store the nutrients_supplied_graph data json file
    const nutrientsSuppliedGraph = [];

    finalDataForGraph.forEach((data) => {
      nutrientsSuppliedGraph.push({
        key: data.key,
        name: data.name,
        nutrients: dataKeyForGraph.map((key) => ({
          id: key.id,
          name: key.name,
          value: data[key.id],
        })),
      });
    });

    // extracting data for daily_nutrients_table in report
    // const to store filtered nutrient's daily nutrients showing in the table
    const dailyNutrientsTable = [];

    if (nutrientKeys.length !== 0) {
      feedNutrientsForTable.filter((nutrient) => {
        nutrientKeys.forEach((eachNut) => {
          if (nutrient.key === eachNut) {
            dailyNutrientsTable.push({
              key: nutrient.key,
              nutrientName: nutrient.nutrientName,
              units: nutrient.unit,
              dailySupplied: currentRationCalculations.amountFedPerNutrientTotal[nutrient.key],
              dailyRequired: currentRationCalculations.horseNutrientRequirement[nutrient.rCalcKey],
              percentOfRequired: Number(currentRationCalculations.percentTotalFedOfReqd[nutrient.key].toFixed(2)),
            });
          }
        });
        return null;
      });
    } else {
      feedNutrientsForTable.forEach((nut) => {
        dailyNutrientsTable.push(nut);
      });
    }

    /** extracting data for recommended_products in report  */
    /** const to store the recommended_products data */
    const recommendedProductsData = [];

    if (currentRationList.length > 0) {
      currentRationList.forEach((item) => {
        recommendedProductsData.push({
          name: item.name,
          code: item.code,
          amount: Number(item.amount.toFixed(2)),
          unit: item.unit,
          type: item.type,
        });
      });
    }

    // creating JSON file for uploading to Horse MQ API
    const LOLMQRationCheckerData = {
      nutrients_supplied_graph: nutrientsSuppliedGraph,
      acceptable_ranges_graph: currentRationCalculations.mqKenticoAcceptableGraphData,
      table: dailyNutrientsTable,
      recommended_products: recommendedProductsData,
    };

    try {
      // const to store the response of horse MQ data upload API
      await axios.post(`${process.env.REACT_APP_KENTICO_API_BASE_URL}/Report/RationChecker`, LOLMQRationCheckerData, {
        params: {
          kitID: horseDataForCalculation.name,
        },
        headers: {
          'Content-type': 'application/json',
        },
      });

      // displaying success message on successful upload of Horse MQ data
      message.success('Horse MQ data uploaded successfully');
      setIsUploadHorseMQDataButtonLoading(false);
    } catch (err) {
      logger(err.response.data.errors);
      setIsUploadHorseMQDataButtonLoading(false);
      if (err.response.data.errors) {
        message.error(JSON.stringify(err.response.data.errors));
      } else {
        message.error('Error while fetching data, Please try again later.');
        console.log(JSON.stringify(err.response.data));
      }
    }
  };

  return (
    <div>
      <Modal footer={false} visible={currentDietModal} onCancel={() => setCurrentDietModal(false)}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ textAlign: 'center', fontSize: 18, marginBottom: 18 }}>Save Current Diet</div>
          {currentDietModal && (
            <DietNameSaveForm
              handleDietNameSave={handleSaveRation}
              setRecommendedDietModal={setCurrentDietModal}
              saveButtonLoader={saveButtonLoader}
            />
          )}
        </div>
      </Modal>
      <Modal title="Alert" footer={false} visible={modalOfBackButton} onCancel={() => setModalOfBackButton(false)}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ textAlign: 'center', fontSize: 15, marginBottom: 18, marginLeft: 10, marginRight: 10 }}>
            You can save this analysis using &apos;Save Ration&apos; button.
          </div>
          <div>
            <Button
              style={{ marginRight: 10 }}
              className="button-color"
              onClick={() => {
                setModalOfBackButton(false);
              }}
            >
              Ok
            </Button>
            <Button
              style={{ marginRight: 10 }}
              onClick={() => {
                onClickPrevious();
              }}
            >
              I don&apos;t want to
            </Button>
            <Button
              onClick={() => {
                onClickPrevious();
              }}
            >
              I already did
            </Button>
          </div>
        </div>
      </Modal>
      <Affix>
        <div style={{ backgroundColor: '#FFFFFF' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingTop: 5,
              paddingBottom: 5,
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Button
                style={{ marginLeft: 15 }}
                className="button-color"
                onClick={() => {
                  setModalOfBackButton(true);
                }}
              >
                Back
              </Button>
              <Button
                style={{ marginLeft: 15 }}
                className="button-color"
                onClick={() => {
                  resetRationAnalyzer();
                }}
              >
                New Analysis
              </Button>
            </div>
            <div style={{ fontSize: 22, color: 'black' }}>Ration Analyzer Results</div>
            <div>
              <Button
                style={{ marginRight: 15 }}
                className="button-color"
                loading={isUploadHorseMQDataButtonLoading}
                onClick={() => onClickUploadHorseMQData()}
              >
                Upload Horse MQ data
              </Button>
              <Button
                style={{ marginRight: 15 }}
                className="button-color"
                onClick={() => {
                  onClickGenerateReport('current');
                }}
              >
                Generate Report
              </Button>
            </div>
          </div>
          <hr style={{ marginTop: 1, marginBottom: 20 }} />
        </div>
      </Affix>
      <div style={{ paddingLeft: 40, paddingRight: 40, marginBottom: 20 }}>
        <div className="added-feed-item-list">Ration List</div>
        <hr style={{ marginTop: 1, marginBottom: 20 }} />
        <Table
          size="small"
          dataSource={[
            ...currentRationList,
            {
              totalAmountKey: 'Total Amount',
              totalAmountValue: `${
                userSettings.uom === 'metric'
                  ? `${convertPoundToKg(totalAmount).toFixed(2)} `
                  : `${totalAmount.toFixed(2)} `
              }`,
            },
          ]}
          bordered
          pagination={false}
          rowKey={(record) => record.id}
        >
          <Column
            title="Item Type"
            dataIndex="type"
            key="type"
            render={(text, row, index) => {
              if (index < currentRationList.length) {
                return <div style={{ textTransform: 'capitalize' }}>{row.type}</div>;
              }
              return {
                props: {
                  colSpan: 2,
                },
              };
            }}
          />
          <Column
            title="Code"
            dataIndex="code"
            key="code"
            render={(text, row, index) => {
              if (index < currentRationList.length) {
                return row.code ? row.code : <div>-</div>;
              }
              return {
                children: (
                  <div style={{ fontWeight: 'bold', fontSize: 15, textAlign: 'right' }}>
                    {row.totalAmountKey}
                    {userSettings.uom === 'metric' ? ' (kgs)' : ' (lbs)'}:
                  </div>
                ),
              };
            }}
          />
          <Column
            title="Item Name"
            dataIndex="name"
            key="name"
            render={(value, row, index) => {
              if (index < currentRationList.length) {
                return row.name;
              }
              return {
                children: (
                  <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 15 }}>{row.totalAmountValue}</div>
                ),
              };
            }}
          />
          <Column
            title={
              <div>
                <div style={{ marginBottom: 5 }}>Amount</div>
                <div>(per day)</div>
              </div>
            }
            dataIndex="amount"
            key="amount"
            align="center"
            render={(value, row, index) => {
              const obj = {
                children: renderAmountValueInTable(row),
                props: {},
              };
              if (index === currentRationList.length) {
                obj.props.colSpan = 0;
              }
              return obj;
            }}
          />
        </Table>
      </div>
      <div style={{ padding: 35, paddingTop: 0 }}>
        {/* Nutrient supplied graph */}
        <GraphOfNutrientSupplied
          currentRationCalculationsData={currentRationCalculations}
          nutSuppGraphChartType={nutSuppGraphChartType}
          setNutSuppGraphChartType={setNutSuppGraphChartType}
          nutSuppGraphNutrients={nutSuppGraphNutrients}
          setNutSuppGraphNutrients={setNutSuppGraphNutrients}
          maximumCap={maximumCap}
          setMaximumCap={setMaximumCap}
        />

        {/* Acceptable ranges graph */}
        <AcceptableRangeGraph
          currentRationCalculationsData={currentRationCalculations}
          acceptableDeltaLow={acceptableDeltaLow}
          acceptableDeltaHigh={acceptableDeltaHigh}
          targetDeltaLow={targetDeltaLow}
          targetDeltaHigh={targetDeltaHigh}
          setAcceptableDeltaLow={setAcceptableDeltaLow}
          setAcceptableDeltaHigh={setAcceptableDeltaHigh}
          setTargetDeltaLow={setTargetDeltaLow}
          setTargetDeltaHigh={setTargetDeltaHigh}
          accRangeGraphNutrients={accRangeGraphNutrients}
          setAccRangeGraphNutrients={setAccRangeGraphNutrients}
        />

        {/* Nutrients supplied table */}
        <NutrientsSuppliedTable
          currentRationCalculationsData={currentRationCalculations}
          setNutrientKeys={setNutSuppGraphNutrients}
          nutrientKeys={nutrientKeys}
          totalAmount={userSettings.uom === 'metric' ? totalAmount / 2.20462 : totalAmount}
        />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => setCurrentDietModal(true)} className="button-color" size="large" style={{ margin: 5 }}>
            Save Ration
          </Button>
          <Button
            className="button-color"
            onClick={() => {
              onClickEditRationButton();
            }}
            size="large"
            style={{ margin: 5 }}
          >
            Edit Ration
          </Button>
          <Button
            className="button-color"
            onClick={() => {
              goToRecommendRationPage();
            }}
            size="large"
            style={{ margin: 5, background: '#129e12', borderColor: '#129e12' }}
          >
            Modify Ration
          </Button>
        </div>
      </div>
    </div>
  );
};

RationAnalysisReport.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
  }).isRequired,
  nutSuppGraphChartType: PropTypes.string.isRequired,
  setNutSuppGraphChartType: PropTypes.func.isRequired,
  nutSuppGraphNutrients: PropTypes.array.isRequired,
  setNutSuppGraphNutrients: PropTypes.func.isRequired,
  accRangeGraphNutrients: PropTypes.array.isRequired,
  setAccRangeGraphNutrients: PropTypes.func.isRequired,
  onClickPrevious: PropTypes.func.isRequired,
  currentRationList: PropTypes.array.isRequired,
  horseDataForCalculation: PropTypes.object.isRequired,
  onClickEditRationButton: PropTypes.func.isRequired,
  goToRecommendRationPage: PropTypes.func.isRequired,
  handleSaveOfHorseDiet: PropTypes.func.isRequired,
  targetDeltaLow: PropTypes.object.isRequired,
  targetDeltaHigh: PropTypes.object.isRequired,
  setTargetDeltaLow: PropTypes.func.isRequired,
  setTargetDeltaHigh: PropTypes.func.isRequired,
  acceptableDeltaLow: PropTypes.object.isRequired,
  acceptableDeltaHigh: PropTypes.object.isRequired,
  setAcceptableDeltaLow: PropTypes.func.isRequired,
  setAcceptableDeltaHigh: PropTypes.func.isRequired,
  onClickGenerateReport: PropTypes.func.isRequired,
  maximumCap: PropTypes.number,
  setMaximumCap: PropTypes.func,
  totalAmount: PropTypes.number.isRequired,
};

RationAnalysisReport.defaultProps = {
  maximumCap: null,
  setMaximumCap: null,
};

export default withRouter(Form.create({ name: 'RationAnalysisReport' })(RationAnalysisReport));
