import React from 'react';
import RationAnalysisReport from '../components/RationAnalysisReport';

const feedItemList = [
  {
    adf: 32.8,
    amount: 1,
    ash: 11,
    ca: 1.47,
    category: 'purinaProduct',
    cl: 0.65,
    co: 0.31,
    code: '1-00-023',
    cp: 19.2,
    cu: 9,
    de: 2.43,
    fat: 2.5,
    fe: 19,
    feedId: 'Kg02kRc3HgGiZN97bhTv',
    i: 0.16,
    id: 'Kg02kRc3HgGiZN97bhTv',
    k: 2.37,
    lys: 0.83,
    mg: 0.29,
    mn: 44,
    na: 0.1,
    name: 'Alfalfa Meal, 17% CP',
    ndf: 41.6,
    p: 0.28,
    percentDM: 90.3,
    s: 0.26,
    se: 0.36,
    type: 'forage',
    vitA: 0,
    vitC: 0,
    vitD: 0,
    zn: 28,
    thi: 0,
    ribo: 0,
    vitE: 0,
  },
  {
    adf: 34.5,
    amount: 0.5,
    ash: 7.5,
    ca: 0.48,
    category: 'individualIngredient',
    cl: 0.72,
    co: 0.72,
    code: '3-00-512',
    cp: 12,
    cu: 7,
    de: 2.28,
    fat: 3.5,
    fe: 343,
    feedId: 'rERitdF1Ia5Q8IafLIO6',
    i: 0,
    id: 'rERitdF1Ia5Q8IafLIO6',
    k: 2.43,
    lys: 0.28,
    mg: 0.18,
    mn: 43,
    na: 0.13,
    name: 'Barley Silage, headed',
    ndf: 56.3,
    p: 0.3,
    percentDM: 35.5,
    s: 0.17,
    se: 0.12,
    type: 'forage',
    vitA: 0,
    vitC: 0,
    vitD: 0,
    zn: 30,
    thi: 0,
    ribo: 0,
    vitE: 0,
  },
  {
    adf: 13.5,
    amount: 1,
    ash: 3.3,
    ca: 0.01,
    category: 'purinaProduct',
    cl: 0.1,
    co: 0.06,
    code: '4-03-318',
    cp: 13.6,
    cu: 8.6,
    de: 3.33,
    fat: 5.2,
    fe: 94,
    feedId: 'EOu55WLmyWwIDWYUvoZG',
    i: 0.13,
    id: 'EOu55WLmyWwIDWYUvoZG',
    k: 0.51,
    lys: 0.55,
    mg: 0.16,
    mn: 40,
    na: 0.02,
    name: 'Oats,Grain,whole 38/lb per bu',
    ndf: 29.3,
    p: 0.41,
    percentDM: 89.0,
    s: 0.21,
    se: 0.24,
    type: 'concentrate',
    vitA: 0.2,
    vitC: 15,
    vitD: 0,
    zn: 41,
    thi: 0,
    ribo: 0,
    vitE: 0,
  },

  {
    adf: 13.5,
    amount: 0.5,
    ash: 5.0,
    ca: 0.07,
    category: 'purinaProduct',
    cl: 0.1,
    co: 0.06,
    code: '4-03-318',
    cp: 13.6,
    cu: 6.7,
    de: 3.23,
    fat: 4.9,
    fe: 40,
    feedId: 'iJN6QMaWvuqBddqHs7e7',
    i: 0.13,
    id: 'iJN6QMaWvuqBddqHs7e7',
    k: 0.45,
    lys: 0.55,
    mg: 0.16,
    mn: 40,
    na: 0.6,
    name: 'Oats,Grain,whole 32/lb per  bu',
    ndf: 42.0,
    p: 0.3,
    percentDM: 91.0,
    s: 0.23,
    se: 0.24,
    type: 'concentrate',
    vitA: 0.2,
    vitC: 15,
    vitD: 0,
    zn: 39,
    thi: 0,
    ribo: 0,
    vitE: 0,
  },
];

const horseDataForCalculation = {
  age: 252,
  bodyConditionScore: 5,
  bodyLength: null,
  bodyWeight: 1100,
  breed: 'horse',
  dryMatterIntakeLevel: 2,
  farmId: '1IVd29iGsBFH2GHnbmRt',
  heartGirth: null,
  horseId: '4ZB2reOKLoBPX6RhQ2LW',
  lifeStage: 'Stallion',
  maintenanceLevel: 'Breeding',
  name: 'horse to validate caluclation',
  averageDailyGain: null,
};

const selectedFarmData = {
  addressLine1: 'hjgh',
  addressLine2: 'address 2',
  city: 'Staten Island',
  country: 'USA',
  id: 'Ftrvq1cBRdMfVWWoZ7eN',
  managerEmail: 'fud@j.com',
  managerMobile: '7890767898',
  managerName: 'Manager name 1',
  name: 'Farm 1 by rakhi',
  salesRepEmail: 'sale@gmail.com',
  salesRepName: 'sale rep 1',
  state: 'New York',
  zip: '10309',
};

const DevPage = () => {
  return (
    <div>
      <RationAnalysisReport
        onClickPrevious={() => ''}
        currentRationList={feedItemList}
        horseDataForCalculation={horseDataForCalculation}
        selectedFarmData={selectedFarmData}
      />
    </div>
  );
};

export default DevPage;
