import React from 'react';
import PropTypes from 'prop-types';

const Heading = (props) => {
  const { headingName } = props;
  return (
    <div>
      <h4 style={{ fontSize: 18, color: 'black', paddingLeft: 7, marginBottom: 0 }}>{headingName}</h4>
      <hr style={{ marginTop: 1, marginBottom: 20 }} />
    </div>
  );
};

Heading.propTypes = {
  headingName: PropTypes.string.isRequired,
};

export default Heading;
