import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { firebaseDb } from '../utils/firebase';
import FarmForm from '../forms/FarmForm';
import logger from '../utils/helpers/logger';

const EditFarmPage = (props) => {
  const { match } = props;

  const currentFarmId = match.params.farmid;
  const [itemToEdit, setItemToEdit] = useState(null);

  useEffect(() => {
    firebaseDb
      .collection('Farm')
      .doc(currentFarmId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setItemToEdit(doc.data());
        } else {
          message.error('No such document!');
        }
      })
      .catch((error) => {
        message.error(error.message);
        logger(error);
      });
  }, []);

  return <FarmForm mode="edit" offset={1} span={11} farmDataToEdit={itemToEdit} currentFarmId={currentFarmId} />;
};

EditFarmPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default EditFarmPage;
