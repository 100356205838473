import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { message, Table } from 'antd';
import useAuth from '../utils/auth';
import { firebaseDb } from '../utils/firebase';
import logger from '../utils/helpers/logger';

const { Column } = Table;

const NotificationsPage = () => {
  /*
      notifications = [{
        createdAtMoment: "The moment object of date to be displayed",
        title: "The title of notification",
        message: "The message of notification"
        read: "BOOLEAN | If the notification is read"
      }]
   */
  const [notifications, setNotifications] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    firebaseDb
      .collection('Notification')
      .orderBy('createdAt', 'desc')
      .limit(50)
      .get()
      .then(async (querySnapshot) => {
        const userDoc = await firebaseDb
          .collection('User')
          .doc(user.uid)
          .get();
        const { notificationLastReadAt } = userDoc.data();

        const newNotifications = [];
        querySnapshot.forEach((doc) => {
          const { createdAt, ...restDoc } = doc.data();
          newNotifications.push({
            ...restDoc,
            createdAtMoment: moment.unix(createdAt.seconds),
            read:
              notificationLastReadAt && notificationLastReadAt.seconds
                ? createdAt.seconds <= notificationLastReadAt.seconds
                : true,
          });
        });
        setNotifications(newNotifications);

        firebaseDb
          .collection('User')
          .doc(user.uid)
          .update({
            notificationLastReadAt: new Date(),
          })
          .catch((error) => {
            logger(error);
            message.error(error.message);
          });
      });
  }, [user.uid]);

  const unreadNotification = notifications.filter((notific) => notific.read === false);
  const readNotification = notifications.filter((notific) => notific.read === true);

  return (
    <>
      <div style={{ fontSize: 20, margin: '23px 47px', color: 'black' }}>Notifications</div>
      <div>
        {unreadNotification.length > 0 ? (
          <div style={{ paddingLeft: 40, paddingRight: 40 }}>
            <h4 style={{ fontSize: 18, color: 'black', paddingLeft: 7 }}>Unread</h4>
            <hr style={{ marginTop: 1 }} />
            <Table dataSource={unreadNotification} pagination={10} rowKey="id">
              <Column
                title="Date"
                dataIndex="type"
                key="type"
                render={(text, record) => {
                  return record.createdAtMoment.format('MMMM Do YYYY, h:mm a');
                }}
              />
              <Column
                title="Type"
                dataIndex="title"
                key="title"
                render={(text, record) => {
                  return record.title;
                }}
              />
              <Column
                title="Message"
                dataIndex="message"
                key="message"
                render={(text, record) => {
                  return record.message;
                }}
              />
            </Table>
          </div>
        ) : null}
      </div>
      <div>
        {readNotification.length > 0 ? (
          <div style={{ paddingLeft: 40, paddingRight: 40 }}>
            <h4 style={{ fontSize: 18, color: 'black', paddingLeft: 7 }}>Read</h4>
            <hr style={{ marginTop: 1 }} />
            <Table dataSource={readNotification} pagination={10} rowKey="id">
              <Column
                title="Date"
                dataIndex="type"
                key="type"
                render={(text, record) => {
                  return record.createdAtMoment.format('MMMM Do YYYY, h:mm a');
                }}
              />
              <Column
                title="Type"
                dataIndex="title"
                key="title"
                render={(text, record) => {
                  return record.title;
                }}
              />
              <Column
                title="Message"
                dataIndex="message"
                key="message"
                render={(text, record) => {
                  return record.message;
                }}
              />
            </Table>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default NotificationsPage;
