import React, { useState, useEffect } from 'react';
import { Breadcrumb, Radio, message, Select } from 'antd';
import { Link } from 'react-router-dom';
import './CreateForagePage.scss';
import FeedForm from '../forms/FeedForm';
import { firebaseDb } from '../utils/firebase';
import Heading from '../components/Heading';
import logger from '../utils/helpers/logger';

const { Option } = Select;

const CreateConcentratePage = () => {
  const [radioOptionSelected, setRadioOptionSelected] = useState(1);
  const [concentrateData, setConcentrateData] = useState([]);
  const [itemId, setItemId] = useState('');

  const onChangeRadio = (e) => {
    setRadioOptionSelected(e.target.value);
  };

  useEffect(() => {
    firebaseDb
      .collection('Feed')
      .where('type', '==', 'concentrate')
      .get()
      .then((querySnapshot) => {
        const newFeedData = [];
        querySnapshot.forEach((doc) => {
          newFeedData.push({ id: doc.id, ...doc.data() });
        });

        setConcentrateData(newFeedData);
      })
      .catch((error) => {
        message.error(error.message);
        logger(error);
      });
  }, []);

  const onChangeSelectBox = (value) => {
    setItemId(value);
  };

  let initialValueForForm = null;
  if (itemId) {
    initialValueForForm = concentrateData.find((entry) => entry.id === itemId);
  }

  concentrateData.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  return (
    <div className="create-page-container">
      <Breadcrumb className="breadcrumb">
        <Breadcrumb.Item>
          <Link style={{ color: '#0071cc' }} to="/feeds/forages">
            Feed Library
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link style={{ color: '#0071cc' }} to="/feeds/concentrates">
            Concentrates
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add New Concentrate</Breadcrumb.Item>
      </Breadcrumb>
      <Heading headingName="Add New Concentrate Item" />
      <Radio.Group onChange={onChangeRadio} value={radioOptionSelected}>
        <Radio value={1}>Add individual components</Radio>
        <Radio value={2}>Use values from existing concentrate item</Radio>
      </Radio.Group>
      {radioOptionSelected === 2 ? (
        <div style={{ marginTop: 30, display: 'flex', justifyContent: 'center' }}>
          <span className="select-item-name">Select Concentrate item :</span>
          <Select
            showSearch
            style={{ width: 300 }}
            placeholder="Select Concentrate Item"
            optionFilterProp="children"
            onChange={onChangeSelectBox}
          >
            {concentrateData.map((item) => (
              <Option key={item.id} value={item.id}>
                {`${item.name} ${item.code ? item.code : ''} `}
              </Option>
            ))}
          </Select>
        </div>
      ) : (
        undefined
      )}
      <FeedForm feedType="concentrate" labelName="Concentrate" mode="add" initialValueForForm={initialValueForForm} />
    </div>
  );
};

export default CreateConcentratePage;
