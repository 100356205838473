import React, { useState } from 'react';
import { Form, Input, InputNumber, Button, Row, Col, message, Select } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { firebaseDb, firebaseFunc } from '../utils/firebase';
import useAuth from '../utils/auth';
import { feedNutrients } from '../utils/globals';
import logger from '../utils/helpers/logger';
import '../components/ButtonColor.scss';
import Heading from '../components/Heading';

const { Option } = Select;

const forageCategoryOption = [
  { key: 'customEntry', value: 'Custom Entry' },
  { key: 'bookValues', value: 'Book Values' },
  { key: 'uncategorized', value: 'Uncategorized' },
];
const categoryOption = [
  { key: 'purinaProduct', value: 'Purina Product' },
  { key: 'individualIngredient', value: 'Individual Ingredient' },
  { key: 'customEntry', value: 'Custom Entry' },
  { key: 'otherCommercialProduct', value: 'Other Commercial Product' },
  { key: 'uncategorized', value: 'Uncategorized' },
];

const FeedForm = (props) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const { form, feedType, history, labelName, mode, initialValueForForm, currentItemId, handleModalCancel } = props;
  const { getFieldDecorator, validateFields, resetFields, getFieldValue } = form;
  const { user } = useAuth();

  const handleCancelClick = () => {
    form.resetFields();
    if (feedType === 'forage' && mode !== 'addFromModal') {
      history.push('/feeds/forages');
    }
    if (feedType === 'concentrate' && mode !== 'addFromModal') {
      history.push('/feeds/concentrates');
    }
    if (feedType === 'supplement' && mode !== 'addFromModal') {
      history.push('/feeds/supplements');
    }
    if (mode === 'addFromModal') {
      handleModalCancel();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    validateFields((err, values) => {
      const newValues = {};
      Object.entries(values).forEach((entry) => {
        const [key, value] = entry;
        if (value) {
          newValues[key] = value;
        } else if (key === 'code' || key === 'name' || key === 'category') {
          newValues[key] = null;
        } else {
          newValues[key] = 0;
        }
      });
      if (!err) {
        const thenFunction = () => {
          message.success(
            mode === 'add' || mode === 'addFromModal'
              ? `${labelName} item is succesfully added!`
              : `${labelName} item is succesfully updated!`,
          );
          setSubmitLoading(false);
          resetFields();
          handleCancelClick();
        };

        const catchFunction = (error) => {
          message.error('There is some problem in Adding data');
          logger(error);
          setSubmitLoading(false);
        };

        if (mode === 'add' || mode === 'addFromModal') {
          const check = firebaseFunc.httpsCallable('checkFeedNameCode');
          check({ name: values.name, code: values.code })
            .then((result) => {
              let checkNameCodeValue = false;
              if (result.data.name === true) {
                checkNameCodeValue = true;
              } else if (result.data.code === true) {
                checkNameCodeValue = true;
              } else {
                checkNameCodeValue = false;
              }
              if (!checkNameCodeValue) {
                firebaseDb
                  .collection('Feed')
                  .add({
                    type: feedType,
                    createdAt: new Date(),
                    createdBy: user.uid,
                    ...newValues,
                  })
                  .then(thenFunction)
                  .catch(catchFunction);
              } else {
                setSubmitLoading(false);
                if (result.data.name === true && result.data.code === false) {
                  message.error('Feed by this name already exists.');
                }
                if (result.data.code === true && result.data.name === false) {
                  message.error('Feed by this code already exists.');
                }
                if (result.data.code === true && result.data.name === true) {
                  message.error('Feed by this name and code already exists.');
                }
              }
            })
            .catch((errors) => {
              const { code } = errors;
              const { messages } = errors;
              const { details } = errors;
              console.log('error', code, messages, details);
              logger(errors);
            });
        } else if (mode === 'edit') {
          firebaseDb
            .collection('Feed')
            .doc(currentItemId)
            .update({
              type: feedType,
              updatedAt: new Date(),
              updatedBy: user.uid,
              ...newValues,
            })
            .then(thenFunction)
            .catch(catchFunction);
        }
      } else {
        setSubmitLoading(false);
      }
    });
  };

  const renderOptionsInCategory = () => {
    if (feedType === 'forage') {
      return forageCategoryOption.map((cate) => {
        return (
          <Option key={cate.key} value={cate.key}>
            {cate.value}
          </Option>
        );
      });
    }
    return categoryOption.map((cate) => {
      return (
        <Option key={cate.key} value={cate.key}>
          {cate.value}
        </Option>
      );
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="main-input-item">
        <Form.Item label={`${labelName} Item Name`} labelCol={{ lg: 6, xl: 4 }} wrapperCol={{ lg: 16, xl: 12 }}>
          {getFieldDecorator('name', {
            initialValue: initialValueForForm ? initialValueForForm.name : undefined,
            rules: [{ required: true, message: `Please enter ${labelName} Item Name and try again` }],
          })(<Input placeholder={`Enter name of New ${labelName} item`} />)}
        </Form.Item>
        <Form.Item label={`${labelName} Item Code`} labelCol={{ lg: 6, xl: 4 }} wrapperCol={{ lg: 9, xl: 6 }}>
          {getFieldDecorator('code', {
            initialValue: initialValueForForm ? initialValueForForm.code : undefined,
          })(<Input placeholder={`Enter code of New ${labelName} item`} />)}
        </Form.Item>
        <Form.Item label={`${labelName} Item Category`} labelCol={{ lg: 6, xl: 4 }} wrapperCol={{ lg: 9, xl: 6 }}>
          {getFieldDecorator('category', {
            initialValue: initialValueForForm ? initialValueForForm.category : undefined,
            rules: [{ required: true, message: `Please select ${labelName} Item Category and try again` }],
          })(
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder={`Select category of New ${labelName} item`}
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {renderOptionsInCategory()}
            </Select>,
          )}
        </Form.Item>
      </div>
      <Heading headingName="Item Composition (DM basis)" />
      <Row>
        {feedNutrients.map((nutrient) => {
          const inputNumberProps = {};
          if (nutrient.step) inputNumberProps.step = nutrient.step;
          if (nutrient.max) inputNumberProps.max = nutrient.max;
          if (nutrient.precision) inputNumberProps.precision = nutrient.precision;

          let initialValue = 0;
          if (initialValueForForm && initialValueForForm[nutrient.key]) {
            initialValue = initialValueForForm[nutrient.key];
          }

          return (
            <Col span={6} key={nutrient.key}>
              <Form.Item label={nutrient.label} labelCol={{ lg: 12, xl: 8 }} wrapperCol={{ span: 8 }}>
                {getFieldDecorator(nutrient.key, {
                  initialValue,
                })(<InputNumber min={0} {...inputNumberProps} />)}
              </Form.Item>
            </Col>
          );
        })}
      </Row>
      <Row className="save-forage-button">
        <Form.Item>
          <Button className="button-color" style={{ marginRight: 10 }} loading={submitLoading} onClick={handleSubmit}>
            {mode === 'edit' ? 'Save' : `Add ${labelName} Item`}
          </Button>
        </Form.Item>
        <Form.Item>
          <Button className="button-color" style={{ marginLeft: 10 }} onClick={handleCancelClick}>
            Cancel
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

FeedForm.propTypes = {
  feedType: PropTypes.oneOf(['forage', 'concentrate', 'supplement']).isRequired,
  history: PropTypes.object.isRequired,
  labelName: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['edit', 'add', 'addFromModal']).isRequired,
  initialValueForForm: PropTypes.object,
  currentItemId: PropTypes.string,
  handleModalCancel: PropTypes.func,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
};

FeedForm.defaultProps = {
  initialValueForForm: {},
  currentItemId: '',
  handleModalCancel: null,
};

export default withRouter(Form.create({ name: 'FeedForm' })(FeedForm));
