import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Table, Popconfirm, Icon, Modal } from 'antd';
import moment from 'moment';
import './history.css';
import '../../components/ButtonColor.scss';
import TotalDailyRation from '../../components/TotalDailyRationPage';
import PhysicalInformation from '../../components/PhysicalInformation';
import rationCalculations from '../../utils/rationCalculations';
import NutrientsSuppliedTable from '../../components/NutrientsSuppliedTable';
import GeneralInformation from '../../components/GeneralInformation';
import Heading from '../../components/Heading';
import GraphOfNutrientSupplied from '../../components/GraphOfNutrientSupplied';
import AcceptableRangeGraph from '../../components/AcceptableRangeGraph';
import DietNameSaveForm from '../../forms/DietNameSaveForm';

const { Column } = Table;

const RecommendedDietsHistoryPage = (props) => {
  const {
    farmInfo,
    horseInfo,
    recommendRationType,
    onClickDeleteButton,
    currentHorseId,
    recordOfSelectedDiet,
    selectedOneDietOfHorse,
    nutSuppGraphChartType,
    setNutSuppGraphChartType,
    nutrientKeysToShow,
    setNutrientKeysToShow,
    acceptableDeltaLow,
    acceptableDeltaHigh,
    targetDeltaLow,
    targetDeltaHigh,
    setAcceptableDeltaLow,
    setAcceptableDeltaHigh,
    setTargetDeltaLow,
    setTargetDeltaHigh,
    handleSaveOfUpdateDietName,
    totalAmount,
    maximumCap,
    setMaximumCap,
    viewId,
  } = props;
  const [deleteDietId, setDeleteDietId] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [saveButtonLoader, setSaveButtonLoader] = useState(false);
  const [recordRow, setRecordRow] = useState('');

  let currentRationCalculationsData = null;
  if (recordOfSelectedDiet) {
    currentRationCalculationsData = rationCalculations({
      rationList: recordOfSelectedDiet.rationData,
      horseData: recordOfSelectedDiet.horseData,
      targetDeltaLow,
      targetDeltaHigh,
      acceptableDeltaLow,
      acceptableDeltaHigh,
    });
  }

  const handleDietNameSave = (value) => {
    handleSaveOfUpdateDietName(value, recordRow, setSaveButtonLoader, setOpenSaveModal, 'HorseDiet');
  };

  const handleDeleteButton = (record) => {
    const label = 'Recommended Diet';
    onClickDeleteButton(record, setDeleteDietId, label);
  };

  return (
    <div style={{ margin: 35 }}>
      <Modal footer={false} visible={openSaveModal} onCancel={() => setOpenSaveModal(false)}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ textAlign: 'center', fontSize: 18, marginBottom: 20 }}>Rename Diet</div>
          {openSaveModal && (
            <DietNameSaveForm
              handleDietNameSave={handleDietNameSave}
              setRecommendedDietModal={setOpenSaveModal}
              saveButtonLoader={saveButtonLoader}
              nameToEdit={recordRow.name}
              rename
            />
          )}
        </div>
      </Modal>
      <GeneralInformation farmInfo={farmInfo} horseInfo={horseInfo} />
      {recommendRationType && recommendRationType.length !== 0 && currentHorseId ? (
        <div>
          <Heading headingName="Recommended Diets List" />
          <Table
            rowClassName={(record, index) => {
              if (recordOfSelectedDiet && record.id === recordOfSelectedDiet.id) {
                return 'highlighted-row';
              }
              return null;
            }}
            style={{ margin: 20, width: '50%' }}
            size="small"
            dataSource={recommendRationType}
            bordered
            pagination={{ pageSize: 5 }}
            rowKey="id"
            onRow={(record, index) => {
              return {
                onClick: () => {
                  selectedOneDietOfHorse(record, 'row');
                },
              };
            }}
          >
            <Column
              title="Diet Name"
              dataIndex="name"
              key="name"
              render={(text, record) => {
                return <a>{record.name}</a>;
              }}
            />
            <Column
              title="Analysis Date"
              dataIndex="createdAt"
              key="createdAt"
              render={(text, record) => {
                return <div>{moment(record.createdAt.seconds * 1000).format('L')}</div>;
              }}
            />
            <Column
              title="Action"
              dataIndex="action"
              key="action"
              align="center"
              render={(text, record) => {
                return (
                  <div>
                    <Button
                      className="button-color"
                      onClick={() => {
                        selectedOneDietOfHorse(record, 'viewButton');
                      }}
                      style={{ marginRight: 10 }}
                      loading={record.id === viewId}
                    >
                      View
                    </Button>
                    <Popconfirm
                      title="Delete Recommended Diet. Are you sure?"
                      onConfirm={() => {
                        setDeleteDietId(record.id);
                        handleDeleteButton(record);
                      }}
                      okText="Yes"
                      icon={<Icon type="warning" style={{ color: '#CE0E2D' }} />}
                      cancelText="No"
                    >
                      <Button className="button-delete" loading={record.id === deleteDietId}>
                        Delete
                      </Button>
                    </Popconfirm>
                    <Button
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        setOpenSaveModal(true);
                        setRecordRow(record);
                      }}
                    >
                      Rename
                    </Button>
                  </div>
                );
              }}
            />
          </Table>
        </div>
      ) : null}

      {recommendRationType && recommendRationType.length !== 0 && currentHorseId && recordOfSelectedDiet ? (
        <div>
          <PhysicalInformation farmInfo={farmInfo} horseInfo={recordOfSelectedDiet.horseData} />
          <TotalDailyRation dietDataforTable={recordOfSelectedDiet.rationData} />
          <GraphOfNutrientSupplied
            currentRationCalculationsData={currentRationCalculationsData}
            nutSuppGraphChartType={nutSuppGraphChartType}
            setNutSuppGraphChartType={setNutSuppGraphChartType}
            nutSuppGraphNutrients={nutrientKeysToShow}
            setNutSuppGraphNutrients={setNutrientKeysToShow}
            maximumCap={maximumCap}
            setMaximumCap={setMaximumCap}
          />
          <AcceptableRangeGraph
            currentRationCalculationsData={currentRationCalculationsData}
            acceptableDeltaLow={acceptableDeltaLow}
            acceptableDeltaHigh={acceptableDeltaHigh}
            targetDeltaLow={targetDeltaLow}
            targetDeltaHigh={targetDeltaHigh}
            setAcceptableDeltaLow={setAcceptableDeltaLow}
            setAcceptableDeltaHigh={setAcceptableDeltaHigh}
            setTargetDeltaLow={setTargetDeltaLow}
            setTargetDeltaHigh={setTargetDeltaHigh}
            accRangeGraphNutrients={nutrientKeysToShow}
            setAccRangeGraphNutrients={setNutrientKeysToShow}
          />
          <NutrientsSuppliedTable
            setNutrientKeys={setNutrientKeysToShow}
            nutrientKeys={['percentDM', ...nutrientKeysToShow]}
            currentRationCalculationsData={currentRationCalculationsData}
            totalAmount={totalAmount}
          />
        </div>
      ) : null}
    </div>
  );
};

RecommendedDietsHistoryPage.propTypes = {
  onClickDeleteButton: PropTypes.func.isRequired,
  recommendRationType: PropTypes.array,
  farmInfo: PropTypes.object.isRequired,
  horseInfo: PropTypes.object.isRequired,
  currentHorseId: PropTypes.string.isRequired,
  recordOfSelectedDiet: PropTypes.object,
  selectedOneDietOfHorse: PropTypes.func.isRequired,
  nutSuppGraphChartType: PropTypes.string.isRequired,
  setNutSuppGraphChartType: PropTypes.func.isRequired,
  nutrientKeysToShow: PropTypes.array.isRequired,
  setNutrientKeysToShow: PropTypes.func.isRequired,
  targetDeltaLow: PropTypes.object.isRequired,
  targetDeltaHigh: PropTypes.object.isRequired,
  setTargetDeltaLow: PropTypes.func.isRequired,
  setTargetDeltaHigh: PropTypes.func.isRequired,
  acceptableDeltaLow: PropTypes.object.isRequired,
  acceptableDeltaHigh: PropTypes.object.isRequired,
  setAcceptableDeltaLow: PropTypes.func.isRequired,
  setAcceptableDeltaHigh: PropTypes.func.isRequired,
  handleSaveOfUpdateDietName: PropTypes.func.isRequired,
  totalAmount: PropTypes.number.isRequired,
  maximumCap: PropTypes.number.isRequired,
  setMaximumCap: PropTypes.func.isRequired,
  viewId: PropTypes.string.isRequired,
};

RecommendedDietsHistoryPage.defaultProps = {
  recommendRationType: null,
  recordOfSelectedDiet: null,
};

export default RecommendedDietsHistoryPage;
