import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Popconfirm, Icon, message, Select } from 'antd';
import PropTypes from 'prop-types';
import { firebaseDb } from '../utils/firebase';
import './ViewFarmPage.scss';
import Heading from '../components/Heading';
import '../components/ButtonColor.scss';
import FarmDataInfo from '../components/FarmDataInfo';
import useAuth from '../utils/auth';
import logger from '../utils/helpers/logger';

const { Option } = Select;

const ViewFarmPage = (props) => {
  const [loadingDeleteFarm, setLoadingDeleteFarm] = useState(false);
  const { history } = props;
  const [farmData, setFarmData] = useState([]);
  const [itemId, setItemId] = useState(undefined);
  const { user } = useAuth();

  const onChangeSelect = (value) => {
    setItemId(value);
  };

  useEffect(() => {
    firebaseDb
      .collection('Farm')
      .where('createdBy', '==', user.uid)
      .get()
      .then((querySnapshot) => {
        const newFarmData = [];
        querySnapshot.forEach((doc) => {
          newFarmData.push({ id: doc.id, ...doc.data() });
        });

        setFarmData(newFarmData);
      })
      .catch((error) => {
        message.error(error.message);
        logger(error);
      });
  }, []);

  const handleDelete = (dataToDelete) => {
    setLoadingDeleteFarm(true);
    firebaseDb
      .collection('Farm')
      .doc(dataToDelete.id)
      .delete()
      .then(() => {
        message.success(`Farm "${dataToDelete.name}" is successfully deleted!`);
        setFarmData(farmData.filter((item) => item.id !== dataToDelete.id));
        setItemId(undefined);
        setLoadingDeleteFarm(false);
      })
      .catch((error) => {
        message.error('Error in deleting the item!!');
        logger(error);
        setLoadingDeleteFarm(false);
      });
  };

  let selectedFarmData = null;
  if (itemId) {
    selectedFarmData = farmData.find((entry) => entry.id === itemId);
  }

  return (
    <div style={{ padding: 35 }}>
      <Row style={{ padding: 30 }}>
        <Col offset={2} span={14}>
          <span className="farm-select">Select a Farm:</span>
          <Select
            showSearch
            style={{ width: 350 }}
            placeholder="Search for Farm by Name/City/State/Zipcode"
            optionFilterProp="data"
            onChange={onChangeSelect}
            value={itemId}
          >
            {farmData.map((item) => (
              <Option key={item.id} value={item.id} data={`${item.name}_${item.city}_${item.state}_${item.zip}`}>
                {`${item.name}`}
              </Option>
            ))}
          </Select>
          <span className="help-text-select-farm">Select a farm to see its profile</span>
        </Col>
        {selectedFarmData ? (
          <span>
            <Col span={2}>
              <Button
                size="large"
                icon="edit"
                className="button-color"
                onClick={() => {
                  history.push(`/farms/${itemId}/edit`);
                }}
              >
                Edit
              </Button>
            </Col>
            <Col span={4}>
              <Popconfirm
                title="Delete Farm. Are you sure?"
                onConfirm={() => {
                  handleDelete(selectedFarmData);
                }}
                okText="Yes"
                icon={<Icon type="warning" style={{ color: '#CE0E2D' }} />}
                cancelText="No"
              >
                <Button size="large" icon="delete" loading={loadingDeleteFarm} className="button-delete">
                  Delete
                </Button>
              </Popconfirm>
            </Col>
          </span>
        ) : null}
      </Row>
      <FarmDataInfo selectedFarmData={selectedFarmData} />
    </div>
  );
};

ViewFarmPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default ViewFarmPage;
