import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon, Dropdown, Menu, Badge } from 'antd';
import logo from '../assests/images/Equine RationChecker_no tagline.svg';
import { firebaseAuth } from '../utils/firebase';
import './Header.scss';
import useAppData from '../contexts/appDataContext';
import useAuth from '../utils/auth';

const Header = (props) => {
  const { location, history } = props;
  const { resetRationAnalyzer } = useAppData();
  const { userRole, unreadNotificationsCount } = useAuth();

  const menu = (
    <Menu>
      <Menu.Item>
        <Link className="link-menu" to="/horses">
          Horses
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link className="link-menu" to="/farms">
          Farms
        </Link>
      </Menu.Item>
    </Menu>
  );

  const handleOnClickLogo = () => {
    history.push('/');
    resetRationAnalyzer();
  };

  return (
    <div className="header-container">
      <div
        className="logo"
        style={{ cursor: 'pointer' }}
        onClick={handleOnClickLogo}
        onKeyPress={handleOnClickLogo}
        role="button"
        tabIndex="0"
      >
        <img src={logo} alt="purinaEquine" height="50" width="140" />
      </div>
      <div className="menu-list">
        <Link className={location.pathname === '/' ? 'link-active' : 'link'} to="/">
          Ration Analyzer
        </Link>

        <Dropdown style={{ cursor: 'none' }} overlay={menu}>
          <Link
            className={
              location.pathname.includes('farms') || location.pathname.includes('horses')
                ? 'ant-dropdown-link link-active'
                : 'ant-dropdown-link link'
            }
            to="#"
          >
            Virtual Stable
          </Link>
        </Dropdown>

        <Link
          className={location.pathname.includes('history') ? 'link-active' : 'link'}
          to="/history/horseGrowth/dietHistory"
        >
          Diet Analysis History
        </Link>

        <Link className={location.pathname.includes('feeds') ? 'link-active' : 'link'} to="/feeds/forages">
          Feed Library
        </Link>

        {userRole === 'admin' ? (
          <Link className={location.pathname.includes('users') ? 'link-active' : 'link'} to="/users">
            User Management
          </Link>
        ) : null}

        <Link className={location.pathname.includes('settings') ? 'link-active' : 'link'} to="/settings">
          Settings
        </Link>
        <Badge count={unreadNotificationsCount > 0 ? unreadNotificationsCount : 0}>
          <Link to="/notifications">
            <Icon
              type="bell"
              style={{ fontSize: 25, color: 'white' }}
              theme={location.pathname.includes('notifications') ? 'filled' : 'outlined'}
            />
          </Link>
        </Badge>
      </div>
      <div
        className="logout-container"
        onKeyPress={() => {}}
        role="button"
        tabIndex="0"
        onClick={() => {
          firebaseAuth.signOut();
        }}
      >
        <Icon type="logout" style={{ fontSize: 20 }} />
        <div style={{ fontSize: 11 }}>Logout</div>
      </div>
    </div>
  );
};

Header.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Header);
