import React from 'react';
import './LoadingPage.scss';

const LoadingPage = () => {
  return (
    <div style={{ width: '100%', paddingTop: 150, display: 'flex', alignItem: 'center', justifyContent: 'center' }}>
      <div className="spinner">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    </div>
  );
};

export default LoadingPage;
