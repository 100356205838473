import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Popconfirm, Icon, message, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import moment from 'moment';
import 'react-image-lightbox/style.css';
import { firebaseDb, firebaseStorage } from '../utils/firebase';
import './ViewHorsePage.scss';
import Heading from '../components/Heading';
import { dateFormat } from '../utils/globals';
import '../components/ButtonColor.scss';
import FarmDataInfo from '../components/FarmDataInfo';
import useAuth from '../utils/auth';
import logger from '../utils/helpers/logger';

const { Option } = Select;

const ViewHorsePage = (props) => {
  const { userSettings, convertPoundToKg, convertInchToCm, idFromAuth, setIdFromAuth, user } = useAuth();
  const { history, itemIdFarm, farmData, location } = props;
  const [itemIdHorse, setItemIdHorse] = useState(undefined);
  const [horseData, setHorseData] = useState([]);
  const [horsePhotos, setHorsePhotos] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  console.log('location', location.state);

  let selectedFarmData = null;
  if (itemIdFarm) {
    selectedFarmData = farmData.find((entry) => entry.id === itemIdFarm);
  }

  useEffect(() => {
    firebaseDb
      .collection('Farm')
      .doc(itemIdFarm)
      .collection('horses')
      .where('createdBy', '==', user.uid)
      .get()
      .then((querySnapshot) => {
        const newHorseData = [];
        querySnapshot.forEach((doc) => {
          newHorseData.push({ id: doc.id, ...doc.data() });
        });
        setHorseData(newHorseData);
      })
      .catch((error) => {
        message.error(error.message);
        logger(error);
      });
    if (location.state) {
      horseDataForPhotoDisplay(location.state.horseInfo);
    }
  }, []);

  let selectedHorseData = null;
  if (location.state) {
    selectedHorseData = horseData.find((entry) => entry.id === location.state.horseInfo.id);
  } else if (itemIdHorse) {
    selectedHorseData = horseData.find((entry) => entry.id === itemIdHorse);
  }

  let labelAccordingToLifeStage = 'Maintenance Level';
  if (selectedHorseData && selectedHorseData.lifeStage === 'Adult Maintenance') {
    labelAccordingToLifeStage = 'Maintenance Level';
  }
  if (selectedHorseData && selectedHorseData.lifeStage === 'Working/Training') {
    labelAccordingToLifeStage = 'Work Load';
  }
  if (selectedHorseData && selectedHorseData.lifeStage === 'Pregnant') {
    labelAccordingToLifeStage = 'Gestation Month';
  }
  if (selectedHorseData && selectedHorseData.lifeStage === 'Lactating') {
    labelAccordingToLifeStage = 'Lactation Month';
  }
  if (selectedHorseData && selectedHorseData.lifeStage === 'Stallion') {
    labelAccordingToLifeStage = 'Breeding/Non-Breeding';
  }
  if (selectedHorseData && selectedHorseData.lifeStage === 'Growing') {
    labelAccordingToLifeStage = 'Work Level';
  }

  // let selectedHorseDataforPhoto = null;
  // if (itemIdHorse) {
  //   selectedHorseDataforPhoto = horseData.find((entry) => entry.id === itemIdHorse);
  // } else {
  //   selectedHorseDataforPhoto = idFromAuth ? idFromAuth.horseInfo : null;
  // }

  const horseDataForPhotoDisplay = (itemHorse) => {
    const newArrayUrl = [];
    const promiseArr = [];
    const storageRef = firebaseStorage.ref();
    const birthMonthAndYear = moment().subtract(itemHorse.age, 'months');
    itemHorse.photos.forEach((item) => {
      const newDate = moment(item.date.toDate());
      const photoMonth = newDate.diff(birthMonthAndYear, 'months');
      promiseArr.push(
        storageRef
          .child(item.storageRefPath)
          .getDownloadURL()
          .then((url) => ({
            imageUrl: url,
            date: item.date,
            caption:
              photoMonth <= 23
                ? `${photoMonth} months`
                : `${parseInt(photoMonth / 12, 10)} yrs ${photoMonth % 12} months`,
          })),
      );
    });

    Promise.all(promiseArr).then((results) => {
      setHorsePhotos(results);
    });
    setHorsePhotos(newArrayUrl);
  };

  const onChangeSelectHorse = (value) => {
    setItemIdHorse(value);
    let selectedHorseDataforPhoto = null;
    selectedHorseDataforPhoto = horseData.find((entry) => entry.id === value);
    horseDataForPhotoDisplay(selectedHorseDataforPhoto);
  };

  const handleViewHorsePhotos = () => {
    setIsOpen(true);
  };

  const renderAge = () => {
    if (selectedHorseData.lifeStage === 'Growing' && selectedHorseData.age !== null) {
      if (selectedHorseData.age <= 24) {
        return `${selectedHorseData.age} months `;
      }
      return `${parseInt(selectedHorseData.age / 12, 10)} years ${selectedHorseData.age % 12} months   `;
    }
    return `${selectedHorseData.age > 12 ? parseInt(selectedHorseData.age / 12, 10) : 1} years`;
  };

  const handleDelete = (dataToDelete) => {
    firebaseDb
      .collection('Farm')
      .doc(selectedFarmData.id)
      .collection('horses')
      .doc(dataToDelete.id)
      .delete()
      .then(() => {
        message.success(`Horse "${dataToDelete.name}" is successfully deleted!`);
        setHorseData(horseData.filter((item) => item.id !== dataToDelete.id));
        history.push('horses');
      })
      .catch((error) => {
        message.error('Error in deleting the item!!');
        logger(error);
      });
  };

  return (
    <div>
      {isOpen ? (
        <Lightbox
          mainSrc={horsePhotos[photoIndex].imageUrl}
          nextSrc={horsePhotos[(photoIndex + 1) % horsePhotos.length].imageUrl}
          prevSrc={horsePhotos[(photoIndex + horsePhotos.length - 1) % horsePhotos.length].imageUrl}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + horsePhotos.length - 1) % horsePhotos.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % horsePhotos.length)}
          imageCaption={
            <div>
              <div>Photo Date: {moment(horsePhotos[photoIndex].date.toDate()).format(dateFormat)}</div>
              <div>Photo Age: {horsePhotos[photoIndex].caption}</div>
            </div>
          }
        />
      ) : (
        <div style={{ padding: 20 }}>
          <Row style={{ padding: 10, paddingTop: 0, paddingLeft: 16 }} />
          {selectedFarmData ? (
            <div>
              <Row style={{ padding: 10, paddingTop: 0 }}>
                <Col offset={2} span={14}>
                  <span className="farm-select">Select a Horse:</span>
                  <Select
                    showSearch
                    style={{ width: 350 }}
                    placeholder="Start typing the Name of Horse"
                    optionFilterProp="data"
                    onChange={onChangeSelectHorse}
                    value={location.state && selectedHorseData ? location.state.horseInfo.name : itemIdHorse}
                  >
                    {horseData.map((item) => (
                      <Option key={item.id} value={item.id} data={item.name}>
                        {`${item.name}`}
                      </Option>
                    ))}
                  </Select>
                </Col>
                {selectedHorseData ? (
                  <span>
                    <Col span={2}>
                      <Button
                        size="large"
                        icon="edit"
                        className="button-color"
                        onClick={() => {
                          let idOfHorse = '';
                          if (itemIdHorse) {
                            idOfHorse = itemIdHorse;
                          } else {
                            idOfHorse = location.state.horseInfo.id;
                          }
                          history.push(`/horses/${itemIdFarm}/${idOfHorse}/edit`);
                        }}
                      >
                        Edit
                      </Button>
                    </Col>
                    <Col span={4}>
                      <Popconfirm
                        title="Delete Horse. Are you sure?"
                        onConfirm={() => {
                          handleDelete(selectedHorseData);
                        }}
                        okText="Yes"
                        icon={<Icon type="warning" style={{ color: '#CE0E2D' }} />}
                        cancelText="No"
                      >
                        <Button size="large" icon="delete" className="button-delete">
                          Delete
                        </Button>
                      </Popconfirm>
                    </Col>
                  </span>
                ) : null}
              </Row>
            </div>
          ) : null}
          {selectedHorseData ? (
            <div>
              <Heading headingName="Physical Information" />
              <Row style={{ paddingBottom: 20 }}>
                <Col span={8}>
                  <Row className="item">
                    <Col span={12} className="item-name">
                      Age:
                    </Col>
                    <Col span={6} className="item-value">
                      {renderAge()}
                    </Col>
                  </Row>
                  <Row className="item">
                    <Col span={12} className="item-name">
                      Life Stage:
                    </Col>
                    <Col span={6} className="item-value">
                      {selectedHorseData.lifeStage}
                    </Col>
                  </Row>
                  <Row className="item">
                    <Col span={12} className="item-name">
                      Breed:
                    </Col>
                    <Col span={6} className="item-value">
                      <div style={{ textTransform: 'capitalize' }}>{selectedHorseData.breed}</div>
                    </Col>
                  </Row>
                  {selectedHorseData.heartGirth ? (
                    <Row className="item">
                      <Col span={12} className="item-name">
                        {userSettings.uom === 'metric' ? <> Heart Girth (cms):</> : <> Heart Girth (inches):</>}
                      </Col>
                      <Col span={6} className="item-value">
                        {convertInchToCm(selectedHorseData.heartGirth).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Col>
                    </Row>
                  ) : null}
                  <Row className="item">
                    <Col span={12} className="item-name">
                      {userSettings.uom === 'metric' ? <> Body Weight (kgs):</> : <> Body Weight (lbs):</>}
                    </Col>
                    <Col span={6} className="item-value">
                      {convertPoundToKg(selectedHorseData.bodyWeight).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Col>
                  </Row>
                  {selectedHorseData.averageDailyGain ? (
                    <Row className="item">
                      <Col span={12} className="item-name">
                        {userSettings.uom === 'metric' ? (
                          <> Average Daily Gain (kgs/day):</>
                        ) : (
                          <> Average Daily Gain (lbs/day):</>
                        )}
                      </Col>
                      <Col span={6} className="item-value">
                        {convertPoundToKg(selectedHorseData.averageDailyGain).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Col>
                    </Row>
                  ) : null}
                </Col>
                <Col span={8}>
                  {selectedHorseData.bodyConditionScore ? (
                    <Row className="item">
                      <Col span={12} className="item-name">
                        Body Condition Score (1-9):
                      </Col>
                      <Col span={6} className="item-value">
                        {selectedHorseData.bodyConditionScore}
                      </Col>
                    </Row>
                  ) : null}
                  {selectedHorseData.maintenanceLevel ? (
                    <Row className="item">
                      <Col span={12} className="item-name">
                        {labelAccordingToLifeStage}:
                      </Col>
                      <Col span={6} className="item-value">
                        {selectedHorseData.maintenanceLevel === 'Intense'
                          ? 'Very Heavy'
                          : selectedHorseData.maintenanceLevel}
                      </Col>
                    </Row>
                  ) : null}

                  <Row className="item">
                    <Col span={12} className="item-name">
                      Dry Matter Intake Level (%):
                    </Col>
                    <Col span={6} className="item-value">
                      {selectedHorseData.dryMatterIntakeLevel}
                    </Col>
                  </Row>
                  {selectedHorseData.bodyLength ? (
                    <Row className="item">
                      <Col span={12} className="item-name">
                        {userSettings.uom === 'metric' ? <> Body Length (cms):</> : <> Body Length (inches):</>}
                      </Col>
                      <Col span={6} className="item-value">
                        {convertInchToCm(selectedHorseData.bodyLength).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Col>
                    </Row>
                  ) : null}
                  {selectedHorseData.matureBodyWeight ? (
                    <Row className="item">
                      <Col span={12} className="item-name">
                        {userSettings.uom === 'metric' ? (
                          <> Mature Body Weight (kgs):</>
                        ) : (
                          <> Mature Body Weight (lbs):</>
                        )}
                      </Col>
                      <Col span={6} className="item-value">
                        {convertPoundToKg(selectedHorseData.matureBodyWeight).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Col>
                    </Row>
                  ) : null}
                </Col>
                <Col span={8} className="view-horse-photos">
                  <Button
                    className="button-color"
                    size="large"
                    disabled={horsePhotos.length === 0}
                    onClick={handleViewHorsePhotos}
                  >
                    View Horse Photos
                  </Button>
                </Col>
              </Row>
              <Heading headingName="General Information" />
              <FarmDataInfo selectedFarmData={selectedFarmData} hideHeader showAllFieldTogether />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

ViewHorsePage.propTypes = {
  history: PropTypes.object.isRequired,
  itemIdFarm: PropTypes.string.isRequired,
  farmData: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(ViewHorsePage);
