import React, { useState, useEffect } from 'react';
import { Breadcrumb, Radio, message, Select } from 'antd';
import { Link } from 'react-router-dom';
import './CreateForagePage.scss';
import FeedForm from '../forms/FeedForm';
import { firebaseDb } from '../utils/firebase';
import logger from '../utils/helpers/logger';
import Heading from '../components/Heading';

const { Option } = Select;

const CreateSupplementsPage = () => {
  const [radioOptionSelected, setRadioOptionSelected] = useState(1);
  const [supplementData, setSupplementData] = useState([]);
  const [itemId, setItemId] = useState('');

  const onChangeRadio = (e) => {
    setRadioOptionSelected(e.target.value);
  };

  useEffect(() => {
    firebaseDb
      .collection('Feed')
      .where('type', '==', 'supplement')
      .get()
      .then((querySnapshot) => {
        const newFeedData = [];
        querySnapshot.forEach((doc) => {
          newFeedData.push({ id: doc.id, ...doc.data() });
        });

        setSupplementData(newFeedData);
      })
      .catch((error) => {
        message.error(error.message);
        logger(error);
      });
  }, []);

  const onChangeSelect = (value) => {
    setItemId(value);
  };

  let initialValueForForm = null;
  if (itemId) {
    initialValueForForm = supplementData.find((entry) => entry.id === itemId);
  }

  supplementData.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  return (
    <div className="create-page-container">
      <Breadcrumb className="breadcrumb">
        <Breadcrumb.Item>
          <Link style={{ color: '#0071cc' }} to="/feeds/forages">
            Feed Library
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link style={{ color: '#0071cc' }} to="/feeds/supplements">
            Supplements
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add New Supplement</Breadcrumb.Item>
      </Breadcrumb>
      <Heading headingName="Add New Supplement Item" />
      <Radio.Group onChange={onChangeRadio} value={radioOptionSelected}>
        <Radio value={1}>Add individual components</Radio>
        <Radio value={2}>Use values from existing supplement item</Radio>
      </Radio.Group>
      {radioOptionSelected === 2 ? (
        <div style={{ marginTop: 30, display: 'flex', justifyContent: 'center' }}>
          <span className="select-item-name">Select Supplement item :</span>
          <Select
            showSearch
            style={{ width: 300 }}
            placeholder="Select Supplement Item"
            optionFilterProp="children"
            onChange={onChangeSelect}
          >
            {supplementData.map((item) => (
              <Option key={item.id} value={item.id}>
                {`${item.name}${item.code ? ` (${item.code})` : ''}`}
              </Option>
            ))}
          </Select>
        </div>
      ) : (
        undefined
      )}
      <FeedForm feedType="supplement" labelName="Supplement" mode="add" initialValueForForm={initialValueForForm} />
    </div>
  );
};

export default CreateSupplementsPage;
