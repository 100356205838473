import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import Heading from '../components/Heading';
import './BulkUploadPage.scss';
import BulkUploadComponent from '../components/BulkUploadComponent';

const BulkUploadForagePage = () => {
  return (
    <div style={{ padding: '15px 45px' }}>
      <Breadcrumb style={{ paddingBottom: 30 }}>
        <Breadcrumb.Item>
          <Link style={{ color: '#0071cc' }} to="/feeds/forages">
            Feed Library
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link style={{ color: '#0071cc' }} to="/feeds/forages">
            Forages
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Bulk Add Forages</Breadcrumb.Item>
      </Breadcrumb>
      <Heading headingName="Bulk add Forage items" />
      <BulkUploadComponent
        typeOfData="forage"
        nameOfButton="Add Forages Items"
        columnName="Forage"
        href=" https://firebasestorage.googleapis.com/v0/b/ecp25-production.appspot.com/o/forageUploadTemplate.xlsx?alt=media&token=459de381-e391-470f-b6d4-a489d46a9206"
      />
    </div>
  );
};

export default BulkUploadForagePage;
