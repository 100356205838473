import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import Heading from '../components/Heading';
import './BulkUploadPage.scss';
import BulkUploadComponent from '../components/BulkUploadComponent';

const BulkUploadConcentratePage = () => {
  return (
    <div style={{ padding: '15px 45px' }}>
      <Breadcrumb style={{ paddingBottom: 30 }}>
        <Breadcrumb.Item>
          <Link style={{ color: '#0071cc' }} to="/feeds/forages">
            Feed Library
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link style={{ color: '#0071cc' }} to="/feeds/concentrates">
            Concentrates
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Bulk Add Concentrates</Breadcrumb.Item>
      </Breadcrumb>
      <Heading headingName="Bulk add Concentrate items" />
      <BulkUploadComponent
        typeOfData="concentrate"
        nameOfButton="Add Concentrates Items"
        columnName="Concentrate"
        href="https://firebasestorage.googleapis.com/v0/b/ecp25-production.appspot.com/o/concentrateUploadTemplate.xlsx?alt=media&token=e44533cd-8530-4779-8c09-bbbd2da61f30"
      />
    </div>
  );
};
export default BulkUploadConcentratePage;
