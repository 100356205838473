import React from 'react';
import HorseForm from '../forms/HorseForm';

const CreateHorsePage = (props) => {
  return (
    <div style={{ padding: 35 }}>
      <HorseForm mode="add" {...props} />
    </div>
  );
};

export default CreateHorsePage;
