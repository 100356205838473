import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, message } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { firebaseDb } from '../utils/firebase';
import useAuth from '../utils/auth';
import logger from '../utils/helpers/logger';
import zipCodeData from '../data/usZips.json';
import '../components/ButtonColor.scss';

const FarmForm = (props) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const { form, history, mode, farmDataToEdit, currentFarmId, formIsInModal, handleModalCancel, offset, span } = props;
  const { getFieldDecorator, validateFields, setFieldsValue, resetFields } = form;
  const { user } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    validateFields((err, values) => {
      const valuesToSend = {
        name: values.name,
        addressLine1: values.addressLine1 ? values.addressLine1 : null,
        addressLine2: values.addressLine2 ? values.addressLine2 : null,
        state: values.state ? values.state : null,
        city: values.city ? values.city : null,
        zip: values.zip ? values.zip : null,
        country: 'USA',
        managerName: values.managerName ? values.managerName : null,
        managerEmail: values.managerEmail ? values.managerEmail : null,
        managerMobile: values.managerMobile ? values.managerMobile : null,
        salesRepName: values.salesRepName ? values.salesRepName : null,
        salesRepEmail: values.salesRepEmail ? values.salesRepEmail : null,
      };

      if (!err) {
        if (mode === 'add') {
          firebaseDb
            .collection('Farm')
            .add({
              createdAt: new Date(),
              createdBy: user.uid,
              ...valuesToSend,
            })
            .then(() => {
              message.success('Farm has been successfully added');
              setSubmitLoading(false);
              if (formIsInModal) {
                handleModalCancel();
              } else {
                history.push('/farms');
              }
              resetFields();
            })
            .catch((er) => {
              message.error(er.message);
              setSubmitLoading(false);
              logger(er);
            });
        }
        if (mode === 'edit') {
          firebaseDb
            .collection('Farm')
            .doc(currentFarmId)
            .update({
              updatedAt: new Date(),
              updatedBy: user.uid,
              ...valuesToSend,
            })
            .then(() => {
              message.success('Farm has been successfully updated');
              setSubmitLoading(false);
              history.push('/farms');
            })
            .catch((er) => {
              message.error(er.message);
              setSubmitLoading(false);
              logger(er);
            });
        }
      } else {
        setSubmitLoading(false);
      }
    });
  };

  const onChangeZipField = (e) => {
    const enteredValue = e.target.value;
    if (enteredValue.length === 5 && zipCodeData[enteredValue]) {
      setFieldsValue({
        city: zipCodeData[enteredValue].city,
        state: zipCodeData[enteredValue].state,
      });
    }
  };

  return (
    <div style={{ padding: 35 }}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col offset={offset} span={span}>
            <Form.Item label="Farm Name" labelCol={{ xs: 14, lg: 8, xl: 6 }} wrapperCol={{ xs: 14, lg: 14, xl: 12 }}>
              {getFieldDecorator('name', {
                initialValue: farmDataToEdit ? farmDataToEdit.name : undefined,
                rules: [{ required: true, message: `Please enter Farm Name and try again` }],
              })(<Input placeholder="Please Enter name of New Farm" />)}
            </Form.Item>
            <Form.Item
              label="Address line-1"
              labelCol={{ xs: 14, lg: 8, xl: 6 }}
              wrapperCol={{ xs: 10, lg: 14, xl: 12 }}
            >
              {getFieldDecorator('addressLine1', {
                initialValue: farmDataToEdit ? farmDataToEdit.addressLine1 : undefined,
              })(<Input placeholder="Please enter Address Line-1 of the farm" />)}
            </Form.Item>
            <Form.Item
              label="Address line-2"
              labelCol={{ xs: 14, lg: 8, xl: 6 }}
              wrapperCol={{ xs: 10, lg: 14, xl: 12 }}
            >
              {getFieldDecorator('addressLine2', {
                initialValue: farmDataToEdit ? farmDataToEdit.addressLine2 : undefined,
              })(<Input placeholder="Please enter Address Line-2 of the farm" />)}
            </Form.Item>
            <Form.Item label="ZIP code" labelCol={{ xs: 14, lg: 8, xl: 6 }} wrapperCol={{ xs: 10, lg: 14, xl: 12 }}>
              {getFieldDecorator('zip', {
                initialValue: farmDataToEdit ? farmDataToEdit.zip : undefined,
                rules: [
                  {
                    pattern: /^[0-9]{5}(?:-[0-9]{4})?$/,
                    message: 'Please enter the valid zipcode',
                  },
                ],
              })(<Input onChange={onChangeZipField} placeholder="Please enter ZIP Code of farm" />)}
            </Form.Item>
            <Form.Item label="City" labelCol={{ xs: 14, lg: 8, xl: 6 }} wrapperCol={{ xs: 10, lg: 14, xl: 12 }}>
              {getFieldDecorator('city', {
                initialValue: farmDataToEdit ? farmDataToEdit.city : undefined,
              })(<Input placeholder="Pre filled based on ZIP code" />)}
            </Form.Item>
            <Form.Item label="State" labelCol={{ xs: 14, lg: 8, xl: 6 }} wrapperCol={{ xs: 10, lg: 14, xl: 12 }}>
              {getFieldDecorator('state', {
                initialValue: farmDataToEdit ? farmDataToEdit.state : undefined,
              })(<Input placeholder="Pre filled based on ZIP code" />)}
            </Form.Item>
          </Col>
          <Col offset={offset} span={span}>
            <Form.Item label="Manager Name" labelCol={{ xs: 14, lg: 8, xl: 6 }} wrapperCol={{ xs: 10, lg: 14, xl: 12 }}>
              {getFieldDecorator('managerName', {
                initialValue: farmDataToEdit ? farmDataToEdit.managerName : undefined,
              })(<Input placeholder="Please enter Name of Manager" />)}
            </Form.Item>
            <Form.Item
              label="Manager Email"
              labelCol={{ xs: 14, lg: 8, xl: 6 }}
              wrapperCol={{ xs: 10, lg: 14, xl: 12 }}
            >
              {getFieldDecorator('managerEmail', {
                initialValue: farmDataToEdit ? farmDataToEdit.managerEmail : undefined,
                rules: [
                  {
                    type: 'email',
                    message: 'Please enter a valid email address',
                  },
                ],
              })(<Input placeholder="Please enter Email of Manager" />)}
            </Form.Item>
            <Form.Item
              label="Manager Phone"
              labelCol={{ xs: 14, lg: 8, xl: 6 }}
              wrapperCol={{ xs: 10, lg: 14, xl: 12 }}
            >
              {getFieldDecorator('managerMobile', {
                initialValue: farmDataToEdit ? farmDataToEdit.managerMobile : undefined,
                rules: [
                  {
                    pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                    message: 'Please enter a valid 10-digit phone number',
                  },
                ],
              })(<Input placeholder="Please enter Phone/Cell Number of Manager" />)}
            </Form.Item>
            <Form.Item
              label="Sales Rep Name"
              labelCol={{ xs: 14, lg: 8, xl: 6 }}
              wrapperCol={{ xs: 10, lg: 14, xl: 12 }}
            >
              {getFieldDecorator('salesRepName', {
                initialValue: farmDataToEdit ? farmDataToEdit.salesRepName : undefined,
              })(<Input placeholder="Please enter name of Sales of Rep" />)}
            </Form.Item>
            <Form.Item
              label="Sales Rep Email"
              labelCol={{ xs: 14, lg: 8, xl: 6 }}
              wrapperCol={{ xs: 10, lg: 14, xl: 12 }}
            >
              {getFieldDecorator('salesRepEmail', {
                initialValue: farmDataToEdit ? farmDataToEdit.salesRepEmail : undefined,
                rules: [
                  {
                    type: 'email',
                    message: 'Please enter a valid Email Address',
                  },
                ],
              })(<Input placeholder="Please enter Email of Sales of Rep" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row className="save-forage-button">
          <Form.Item>
            <Button className="button-color" style={{ marginRight: 10 }} loading={submitLoading} onClick={handleSubmit}>
              {mode === 'add' ? 'Add Farm' : 'Save'}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              className="button-color"
              htmlType="submit"
              style={{ marginLeft: 10 }}
              onClick={() => {
                if (formIsInModal) {
                  handleModalCancel();
                  resetFields();
                } else {
                  history.push('/farms');
                }
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

FarmForm.propTypes = {
  history: PropTypes.object.isRequired,
  farmDataToEdit: PropTypes.object.isRequired,
  mode: PropTypes.oneOf(['edit', 'add']).isRequired,
  farmDataToEdit: PropTypes.object,
  currentFarmId: PropTypes.string,
  formIsInModal: PropTypes.string,
  handleModalCancel: PropTypes.func,
  span: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
  }).isRequired,
};

FarmForm.defaultProps = {
  farmDataToEdit: {},
  currentFarmId: '',
  formIsInModal: '',
  handleModalCancel: '',
};

export default withRouter(Form.create({ name: 'FarmForm' })(FarmForm));
