import horseRequirementCalculation from './horseRequirementCalculation';
import { feedNutrients } from './globals';

const acceptableRanges = (
  percentTotalFedOfReqd,
  targetDeltaLow,
  targetDeltaHigh,
  acceptableDeltaLow,
  acceptableDeltaHigh,
) => {
  const acceptableRangesData = [];
  const acceptableRangesComments = {};

  console.log('percentTotalFedOfReqd', percentTotalFedOfReqd);

  // const to store the acceptable_ranges_graph data json file
  const mqKenticoAcceptableGraphData = [];

  Object.entries(percentTotalFedOfReqd).forEach((entry) => {
    const [key, value] = entry;

    const acceptableLow = acceptableDeltaLow[key];
    const targetLow = targetDeltaLow[key];
    const targetHigh = targetDeltaHigh[key];
    const acceptableHigh = acceptableDeltaHigh[key];

    const nutrient = feedNutrients.find((e) => e.key === key);

    // creating acceptable_graph json data for Horse MQ Json file
    mqKenticoAcceptableGraphData.push({
      key: nutrient.key,
      valuePercent: value,
      acceptableLowPercent: acceptableLow,
      targetLowPercent: targetLow,
      targetHighPercent: targetHigh,
      acceptableHighPercent: acceptableHigh,
    });

    let deficient = 0;
    let acceptableBelowTarget = 0;
    let target = 0;
    let acceptableAboveTarget = 0;
    let excessive = 0;
    let comment = 'Deficient';

    if (value !== Infinity && !Number.isNaN(value)) {
      // Deficient
      if (value <= acceptableLow) {
        deficient = Math.ceil((value / acceptableLow) * 5);
      } else {
        deficient = 5;
        comment = 'Acceptable';

        // Acceptable below target
        if (value <= targetLow) {
          acceptableBelowTarget = Math.ceil(((value - acceptableLow) / (targetLow - acceptableLow)) * 5);
        } else {
          acceptableBelowTarget = 5;
          comment = 'Target';

          // Target
          if (value <= targetHigh) {
            target = Math.ceil(((value - targetLow) / (targetHigh - targetLow)) * 5);
          } else {
            target = 5;
            comment = 'Acceptable';

            // Acceptable above target
            if (value <= acceptableHigh) {
              acceptableAboveTarget = Math.ceil(((value - targetHigh) / (acceptableHigh - targetHigh)) * 5);
            } else {
              acceptableAboveTarget = 5;
              comment = 'Excessive';
              excessive = 3;
            }
          }
        }
      }
    }

    acceptableRangesComments[nutrient.key] = comment;

    acceptableRangesData.push({
      key: nutrient.key,
      name: nutrient.xAxisLabelName,
      deficient,
      acceptableBelowTarget,
      target,
      acceptableAboveTarget,
      excessive,
      comment,
    });
  });

  return {
    acceptableRangesData,
    acceptableRangesComments,
    mqKenticoAcceptableGraphData,
  };
};

export default ({
  rationList,
  horseData,
  targetDeltaLow,
  targetDeltaHigh,
  acceptableDeltaLow,
  acceptableDeltaHigh,
}) => {
  // Calculation of horse nutrient requirement
  const horseNutrientRequirement = horseRequirementCalculation(horseData);

  // Calculation of amount fed per nutrient for each feed
  const amountFedPerNutrient = rationList.map((item) => {
    if (item.type === 'forage' || item.type === 'concentrate') {
      const amountInLbs = item.amount;
      const amountInKg = amountInLbs * 0.453592;
      const dmInLbs = (item.percentDM / 100) * amountInLbs;
      const dmInKg = dmInLbs * 0.453592;
      const obj = {};
      const formulaOneKeys = [
        'cp',
        'lys',
        'fat',
        'ndf',
        'adf',
        'ash',
        'ca',
        'p',
        'mg',
        'cl',
        'k',
        'na',
        's',
        'sugar',
        'starch',
      ];
      const formulaTwoKeys = ['cu', 'i', 'fe', 'mn', 'se', 'zn', 'co', 'thi', 'ribo'];
      const formulaThreeKeys = ['vitA', 'vitD', 'vitC', 'vitE'];
      feedNutrients.forEach((nutrient) => {
        if (formulaOneKeys.includes(nutrient.key)) {
          obj[nutrient.key] = (item[nutrient.key] / 100) * dmInLbs || 0;
        } else if (formulaTwoKeys.includes(nutrient.key)) {
          obj[nutrient.key] = item[nutrient.key] * dmInKg || 0;
        } else if (formulaThreeKeys.includes(nutrient.key)) {
          obj[nutrient.key] = item[nutrient.key] * dmInLbs || 0;
        } else if (nutrient.key === 'percentDM') {
          obj[nutrient.key] = (item[nutrient.key] / 100) * amountInLbs || 0;
        } else {
          obj[nutrient.key] = item[nutrient.key] * dmInKg || 0;
        }
      });
      return { id: item.id, type: item.type, name: item.name, ...obj };
    }
    if (item.type === 'supplement') {
      let amountInLbs = null;
      if (item.unit === 'oz/day') {
        amountInLbs = item.amount * 0.0625;
      } else {
        amountInLbs = item.amount;
      }
      const amountInKg = amountInLbs * 0.453592;
      const dmInLbs = (item.percentDM / 100) * amountInLbs;
      const dmInKg = dmInLbs * 0.453592;
      const obj = {};
      const formulaOneKeys = [
        'cp',
        'lys',
        'fat',
        'ndf',
        'adf',
        'ash',
        'ca',
        'p',
        'mg',
        'cl',
        'k',
        'na',
        's',
        'sugar',
        'starch',
      ];
      const formulaTwoKeys = ['cu', 'i', 'fe', 'mn', 'se', 'zn', 'co', 'thi', 'ribo'];
      const formulaThreeKeys = ['vitA', 'vitD', 'vitC', 'vitE'];
      feedNutrients.forEach((nutrient) => {
        if (formulaOneKeys.includes(nutrient.key)) {
          obj[nutrient.key] = (item[nutrient.key] / 100) * dmInLbs || 0;
        } else if (formulaTwoKeys.includes(nutrient.key)) {
          obj[nutrient.key] = item[nutrient.key] * dmInKg || 0;
        } else if (formulaThreeKeys.includes(nutrient.key)) {
          obj[nutrient.key] = item[nutrient.key] * dmInLbs || 0;
        } else if (nutrient.key === 'percentDM') {
          obj[nutrient.key] = (item[nutrient.key] / 100) * amountInLbs || 0;
        } else {
          obj[nutrient.key] = item[nutrient.key] * dmInKg || 0;
        }
      });
      return { id: item.id, type: item.type, name: item.name, ...obj };
    }
    return null;
  });

  // Unit conversion of amount fed per nutrient
  const amountFedPerNutrientAltUnit = amountFedPerNutrient.map((item) => {
    const formulaOneKeys = ['cp', 'lys', 'ca', 'p', 'mg', 'cl', 'k', 'na', 's', 'sugar', 'starch'];
    const unitobj = {};
    feedNutrients.forEach((nutrient) => {
      if (nutrient.key === 'percentDM') {
        unitobj.percentDM = item.percentDM * 0.453592;
      } else if (formulaOneKeys.includes(nutrient.key)) {
        unitobj[nutrient.key] = item[nutrient.key] * 0.453592 * 1000;
      } else {
        unitobj[nutrient.key] = item[nutrient.key];
        unitobj.id = item.id;
        unitobj.type = item.type;
        unitobj.name = item.name;
      }
    });
    return unitobj;
  });

  // Calculation of percent fed of requirement for each nutrient and feed
  const percentFedOfReqd = amountFedPerNutrientAltUnit.map((eachItem) => {
    const everyobj = {};
    feedNutrients.forEach((nutrient) => {
      everyobj[nutrient.key] = (eachItem[nutrient.key] / horseNutrientRequirement[nutrient.rCalcKey]) * 100 || 0;
    });
    return { id: eachItem.id, type: eachItem.type, name: eachItem.name, ...everyobj };
  });

  // Calculation of amount fed per nutrient for all feeds combined
  const amountFedPerNutrientTotal = {};
  feedNutrients.forEach((nutrient) => {
    amountFedPerNutrientTotal[nutrient.key] = 0;
  });
  amountFedPerNutrientAltUnit.forEach((feed) => {
    feedNutrients.forEach((nutrient) => {
      amountFedPerNutrientTotal[nutrient.key] += feed[nutrient.key];
    });
  });

  // Calculation of percent total fed for each nutrient of required
  const percentTotalFedOfReqd = {};
  feedNutrients.forEach((i) => {
    percentTotalFedOfReqd[i.key] = (amountFedPerNutrientTotal[i.key] * 100) / horseNutrientRequirement[i.rCalcKey] || 0;
  });

  return {
    horseNutrientRequirement,
    amountFedPerNutrient,
    amountFedPerNutrientAltUnit,
    percentFedOfReqd,
    amountFedPerNutrientTotal,
    percentTotalFedOfReqd,
    ...acceptableRanges(
      percentTotalFedOfReqd,
      targetDeltaLow,
      targetDeltaHigh,
      acceptableDeltaLow,
      acceptableDeltaHigh,
    ),
  };
};
