import React, { useState } from 'react';
import { Form, Row, Col, Input, Icon, Button, Card, message } from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import firebase, { firebaseAuth, firebaseDb } from '../utils/firebase';
import logo from '../assests/images/LatestLogo.jpg';
import useAuth from '../utils/auth';
import logger from '../utils/helpers/logger';
import './LoginPage.scss';
import '../components/ButtonColor.scss';

const UpdatePasswordPage = (props) => {
  //   const [resetError, setResetError] = useState('');
  const { form, history } = props;
  const { getFieldDecorator, resetFields, validateFields, getFieldValue, setFields } = form;
  const [loadingSave, setLoadingSave] = useState(false);
  const { user, updatePasswordOnLogin, setUpdatePasswordOnLogin } = useAuth();

  // Condition for enable and disable update button

  const enabledUpdateButton =
    getFieldValue('newPassword') !== undefined && getFieldValue('confirmPassword') !== undefined;

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue('newPassword')) {
      callback('The entered passwords do not match. Please check and try again.');
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value && value.length < 6) {
      callback('Password must be atleast 6 characters long.');
    }
    if (value && getFieldValue('confirmPassword')) {
      validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };

  const handleSave = (e) => {
    setLoadingSave(true);
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const { currentUser } = firebaseAuth;
        currentUser
          .updatePassword(values.newPassword)
          .then(() => {
            message.success('Password has been successfully updated.');
            // history.push('/');
            // setLoadingSave(false);
            // resetFields();
            firebaseDb
              .collection('User')
              .doc(user.uid)
              .update({
                updatePasswordOnLogin: false,
              })
              .then(() => {
                setUpdatePasswordOnLogin(false);
                setLoadingSave(false);
                resetFields();
                console.log('this is calling or not');
                // history.push('/');
              })
              .catch((error) => {
                setLoadingSave(false);
                resetFields();
                logger(error);
              });
          })
          .catch((error) => {
            firebaseAuth.signOut();
            message.error('Update password failed! Please login with old password again!');
            setLoadingSave(false);
            resetFields();
            logger(error);
          });
      } else {
        setLoadingSave(false);
      }
    });
  };

  return (
    <Row>
      <Col span={8} offset={8}>
        <Card className="login-card">
          <div className="upper-half-login">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={logo} alt="purinaEquine" width="320px" />
            </div>
          </div>
          <div>
            <hr />
          </div>
          <div className="lower-half-login">
            <h2 style={{ fontSize: 18 }}>This is your first login. Please set a new password.</h2>
          </div>
          <Form onSubmit={handleSave} className="login-form">
            <Form.Item style={{ width: '100%', marginBottom: 5 }}>
              {form.getFieldDecorator('newPassword', {
                rules: [
                  { required: true, message: 'Please enter New Password and try again' },
                  {
                    validator: validateToNextPassword,
                  },
                ],
              })(
                <Input
                  type="password"
                  placeholder="Enter your new password "
                  prefix={<Icon type="lock" style={{ color: '#c7bfbf' }} />}
                />,
              )}
            </Form.Item>
            <Form.Item style={{ width: '100%', marginBottom: 9 }}>
              {form.getFieldDecorator('confirmPassword', {
                rules: [
                  { required: true, message: 'Please Confirm New Password and try again' },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              })(
                <Input
                  type="password"
                  prefix={<Icon type="lock" style={{ color: '#c7bfbf' }} />}
                  placeholder="Confirm your new password"
                />,
              )}
            </Form.Item>
            {/* <div style={{ color: resetError === 'success' ? '#0039FF' : '#CE0E2D', paddingBottom: 51 }}></div> */}
            <Form.Item style={{ width: '100%' }}>
              <Button
                className="button-color"
                htmlType="submit"
                style={{ width: '100%', fontSize: 18, color: !enabledUpdateButton ? 'grey' : 'white' }}
                disabled={!enabledUpdateButton}
                loading={loadingSave}
              >
                Update Password
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

UpdatePasswordPage.propTypes = {
  form: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Form.create({ name: 'UpdatePasswordPage' })(UpdatePasswordPage));
