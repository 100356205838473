import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Col, Drawer, InputNumber, Row } from 'antd';
import { feedNutrientsForAnalysis } from '../utils/globals';
import './GraphOfNutrientSupplied.scss';
import './DrawerDesign.scss';
import AcceptableRangeBarChart from './AcceptableRangeBarChart';

const AcceptableRangeGraph = (props) => {
  const {
    compare,
    recommendedRationCalculationsData,
    currentRationCalculationsData,
    targetDeltaLow,
    targetDeltaHigh,
    acceptableDeltaLow,
    acceptableDeltaHigh,
    setTargetDeltaLow,
    setTargetDeltaHigh,
    setAcceptableDeltaLow,
    setAcceptableDeltaHigh,
    hideHeader,
    accRangeGraphNutrients,
    setAccRangeGraphNutrients,
    compareGenReport,
  } = props;

  const [visibleDrawerForAcceptableGraph, setVisibleDrawerForAcceptableGraph] = useState(false);

  const showDrawerAcceptable = () => {
    setVisibleDrawerForAcceptableGraph(true);
  };

  const onCloseDrawerAcceptable = () => {
    setVisibleDrawerForAcceptableGraph(false);
  };

  const onChangeCheckBoxEnableOfAcceptable = (checkedValues) => {
    setAccRangeGraphNutrients(checkedValues);
  };

  const finalDataForAcceptableGraph = currentRationCalculationsData.acceptableRangesData.filter(
    (data) => accRangeGraphNutrients.includes(data.key) && data.key !== 'sugar' && data.key !== 'starch',
  );

  let finalDataForAcceptableRecommendGraph = null;
  if (recommendedRationCalculationsData) {
    finalDataForAcceptableRecommendGraph = recommendedRationCalculationsData.acceptableRangesData.filter(
      (data) => accRangeGraphNutrients.includes(data.key) && data.key !== 'sugar' && data.key !== 'starch',
    );
  }

  const graphHeight = compare ? 300 : 600;

  return (
    <>
      <Drawer
        width={630}
        title="Target &amp;  Acceptable % value for each nutrient"
        placement="right"
        onClose={onCloseDrawerAcceptable}
        visible={visibleDrawerForAcceptableGraph}
      >
        <Row style={{ fontWeight: 600 }}>
          <Col style={{ textAlign: 'center' }} span={4}>
            Nutrient Name
          </Col>
          <Col style={{ textAlign: 'center' }} span={4}>
            Show/Hide
          </Col>
          <Col span={4}>Acceptable Low(%)</Col>
          <Col span={4}>Target Low(%)</Col>
          <Col span={4}>Target High(%)</Col>
          <Col span={4}>Acceptable High(%)</Col>
        </Row>
        <Checkbox.Group
          style={{ width: '100%' }}
          value={accRangeGraphNutrients}
          onChange={onChangeCheckBoxEnableOfAcceptable}
        >
          <Row>
            {feedNutrientsForAnalysis.map((nutrient) => (
              <div
                key={nutrient.key}
                style={nutrient.key === 'starch' || nutrient.key === 'sugar' ? { display: 'none' } : null}
              >
                <Col span={4} style={{ marginTop: 2, textAlign: 'center' }}>
                  {nutrient.xAxisLabelName}
                </Col>
                <Col span={4} style={{ marginTop: 2, textAlign: 'center' }}>
                  <Checkbox value={nutrient.key} />
                </Col>
                <Col span={4} style={{ marginTop: 2 }}>
                  <InputNumber
                    min={1}
                    value={acceptableDeltaLow[nutrient.key]}
                    onChange={(value) => {
                      const nutrientKey = nutrient.key;
                      setAcceptableDeltaLow(value, nutrientKey);
                    }}
                  />
                </Col>
                <Col span={4} style={{ marginTop: 2 }}>
                  <InputNumber
                    min={1}
                    value={targetDeltaLow[nutrient.key]}
                    onChange={(value) => {
                      const nutrientKey = nutrient.key;
                      setTargetDeltaLow(value, nutrientKey);
                    }}
                  />
                </Col>
                <Col span={4} style={{ marginTop: 2 }}>
                  <InputNumber
                    min={1}
                    value={targetDeltaHigh[nutrient.key]}
                    onChange={(value) => {
                      const nutrientKey = nutrient.key;
                      setTargetDeltaHigh(value, nutrientKey);
                    }}
                  />
                </Col>
                <Col span={4} style={{ marginTop: 2 }}>
                  <InputNumber
                    min={1}
                    value={acceptableDeltaHigh[nutrient.key]}
                    onChange={(value) => {
                      const nutrientKey = nutrient.key;
                      setAcceptableDeltaHigh(value, nutrientKey);
                    }}
                  />
                </Col>
              </div>
            ))}
          </Row>
        </Checkbox.Group>
      </Drawer>

      {compare === true ? (
        <div>
          {/* Header */}
          {hideHeader ? null : (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ fontSize: 18, color: 'black', paddingLeft: 7 }}>Acceptable Ranges</div>
                <Button
                  icon="setting"
                  onClick={showDrawerAcceptable}
                  style={{ background: 'none', color: '#1890ff', border: 'none' }}
                >
                  Options
                </Button>
              </div>
            </>
          )}
          {compareGenReport ? null : (
            <>
              {' '}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ fontSize: 18, color: 'black', paddingLeft: 7 }} />
                <Button
                  icon="setting"
                  onClick={showDrawerAcceptable}
                  style={{ background: 'none', color: '#1890ff', border: 'none' }}
                >
                  Options
                </Button>
              </div>
              <hr style={{ marginTop: 1, marginBottom: 20 }} />
            </>
          )}

          {/* Graph */}
          <div style={{ padding: 5 }}>
            <div style={{ textAlign: 'center', fontWeight: 600, fontSize: 18 }}>Current Ration</div>
            <AcceptableRangeBarChart data={finalDataForAcceptableGraph} compare={compare} />
          </div>

          {/* Graph */}
          <div style={{ padding: 5 }}>
            <div style={{ textAlign: 'center', fontWeight: 600, fontSize: 18 }}>Recommended Ration</div>
            <AcceptableRangeBarChart data={finalDataForAcceptableRecommendGraph} compare={compare} />
          </div>
        </div>
      ) : (
        <div>
          {/* Header */}
          {hideHeader ? null : (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ fontSize: 18, color: 'black', paddingLeft: 7 }}>Acceptable Ranges</div>
                <Button
                  icon="setting"
                  onClick={showDrawerAcceptable}
                  style={{ background: 'none', color: '#1890ff', border: 'none' }}
                >
                  Options
                </Button>
              </div>
              <hr style={{ marginTop: 0, marginBottom: 20 }} />
            </>
          )}

          {/* Graph */}

          <div style={{ paddingTop: 20, paddingBottom: 20, marginBottom: 50 }}>
            <AcceptableRangeBarChart
              data={finalDataForAcceptableGraph}
              compare={compare}
              margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
            />
          </div>
        </div>
      )}
    </>
  );
};

AcceptableRangeGraph.propTypes = {
  currentRationCalculationsData: PropTypes.object,
  recommendedRationCalculationsData: PropTypes.object,
  targetDeltaLow: PropTypes.object.isRequired,
  targetDeltaHigh: PropTypes.object.isRequired,
  acceptableDeltaLow: PropTypes.object.isRequired,
  acceptableDeltaHigh: PropTypes.object.isRequired,
  setTargetDeltaLow: PropTypes.func.isRequired,
  setTargetDeltaHigh: PropTypes.func.isRequired,
  setAcceptableDeltaLow: PropTypes.func.isRequired,
  setAcceptableDeltaHigh: PropTypes.func.isRequired,
  hideHeader: PropTypes.bool,
  compare: PropTypes.bool,
  accRangeGraphNutrients: PropTypes.array.isRequired,
  setAccRangeGraphNutrients: PropTypes.func.isRequired,
  compareGenReport: PropTypes.bool,
};

AcceptableRangeGraph.defaultProps = {
  hideHeader: false,
  currentRationCalculationsData: null,
  compare: false,
  recommendedRationCalculationsData: null,
  compareGenReport: false,
};

export default AcceptableRangeGraph;
