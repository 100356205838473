import React, { useEffect } from 'react';
import { Row, Col, message, Select, Button, Modal, Divider } from 'antd';
import 'react-image-lightbox/style.css';
import { firebaseDb } from '../utils/firebase';
import './ViewHorsePage.scss';
import HorseForm from '../forms/HorseForm';
import './RationAnalyzerPage.scss';
import useAuth from '../utils/auth';
import AddFeedByRationAnalyzer from '../components/AddFeedByRationAnalyzer';
import RationAnalysisReport from '../components/RationAnalysisReport';
import RecommendedRation from '../components/RecommendedRation';
import {
  feedNutrientsForAnalysis,
  targetDeltaInitialValueHigh,
  targetDeltaInitialValueLow,
  acceptableDeltaInitialValueLow,
} from '../utils/globals';
import GenerateReportPage from '../components/GenerateReportPage';
import '../components/ButtonColor.scss';
import Header from '../components/Header';
import useAppData from '../contexts/appDataContext';
import logger from '../utils/helpers/logger';

const { Option } = Select;

const nutrientKeys = feedNutrientsForAnalysis.map((nut) => nut.key);

const RationAnalyzerPage = () => {
  const { user, convertOzToLbs, userSettings, convertPoundToKg } = useAuth();
  const {
    farmData,
    setFarmData,
    itemIdFarm,
    setItemIdFarm,
    itemIdHorse,
    setItemIdHorse,
    horseData,
    setHorseData,
    currentPage,
    setCurrentPage,
    feedItemList,
    setFeedItemList,
    recommendedRationList,
    setRecommendedRationList,
    horseDataForCalculation,
    setHorseDataForCalculation,
    nutSuppGraphChartType,
    setNutSuppGraphChartType,
    nutrientKeysToShow,
    setNutrientKeysToShow,
    targetDeltaLow,
    setTargetDeltaLow,
    targetDeltaHigh,
    setTargetDeltaHigh,
    acceptableDeltaLow,
    setAcceptableDeltaLow,
    acceptableDeltaHigh,
    setAcceptableDeltaHigh,
    visibleAddHorseModal,
    setVisibleAddHorseModal,
    maximumCap,
    setMaximumCap,
    generateReportFor,
    setGenerateReportFor,
    horseDietInLoadRation,
    setHorseDietInLoadRation,
    checkLoadRation,
    setCheckLoadRation,
    isNextButtonClicked,
    setIsNextButtonClicked,
    setLoadRationCheckBox,
    loadRationCheckBox,
    idOfHorseInLoadRation,
    setIdOfHorseInLoadRation,
    idOfDiet,
    setIdOfDiet,
    allFarmHorses,
    setAllFarmHorses,
    idOfFarmForLoadRation,
    setIdOfFarmForLoadRation,
  } = useAppData();

  console.log('allFarmHorses', allFarmHorses);

  const updateTargetDeltaLowForNutrient = (value, nutrientKey) => {
    setTargetDeltaLow({
      ...targetDeltaLow,
      [nutrientKey]: value,
    });
  };

  const updateTargetDeltaHighForNutrient = (value, nutrientKey) => {
    setTargetDeltaHigh({
      ...targetDeltaHigh,
      [nutrientKey]: value,
    });
  };

  const updateAcceptableDeltaLowForNutrient = (value, nutrientKey) => {
    setAcceptableDeltaLow({
      ...acceptableDeltaLow,
      [nutrientKey]: value,
    });
  };

  const updateAcceptableDeltaHighForNutrient = (value, nutrientKey) => {
    setAcceptableDeltaHigh({
      ...acceptableDeltaHigh,
      [nutrientKey]: value,
    });
  };

  useEffect(() => {
    firebaseDb
      .collection('Farm')
      .where('createdBy', '==', user.uid)
      .get()
      .then((querySnapshot) => {
        const newFarmData = [];
        querySnapshot.forEach((doc) => {
          newFarmData.push({ id: doc.id, ...doc.data() });
        });
        setFarmData(newFarmData);
      })
      .catch((error) => {
        message.error(error.message);
        logger(error);
      });
    // firebaseDb
    //   .collectionGroup('horses')
    //   .get()
    //   .then((querySnapshot) => {
    //     const horse = [];
    //     querySnapshot.forEach((doc) => {
    //       if (user.uid === doc.data().createdBy) {
    //         horse.push({ id: doc.id, ...doc.data() });
    //       }
    //     });
    //     setAllFarmHorses(horse);
    //   });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (currentPage === 'addForage') {
      setNutSuppGraphChartType('standard');
      setNutrientKeysToShow(nutrientKeys);
      setTargetDeltaLow(targetDeltaInitialValueLow);
      setTargetDeltaHigh(targetDeltaInitialValueHigh);
      setAcceptableDeltaLow(acceptableDeltaInitialValueLow);
    }
  }, [currentPage]);

  const onChangeSelectFarm = (value) => {
    setItemIdFarm(value);
    setFeedItemList([]);
    setItemIdHorse(undefined);
    firebaseDb
      .collection('Farm')
      .doc(value)
      .collection('horses')
      .onSnapshot((querySnapshot) => {
        const newHorseData = [];
        querySnapshot.forEach((doc) => {
          if (user.uid === doc.data().createdBy) {
            newHorseData.push({ id: doc.id, ...doc.data() });
          }
        });
        setHorseData(newHorseData);
      });
  };

  const onChangeSelectFarmOfLoadRation = (value) => {
    setIdOfFarmForLoadRation(value);
    setAllFarmHorses(undefined);
    setIdOfHorseInLoadRation(undefined);
    setHorseDietInLoadRation(undefined);
    firebaseDb
      .collection('Farm')
      .doc(value)
      .collection('horses')
      .onSnapshot((querySnapshot) => {
        const newHorseData = [];
        querySnapshot.forEach((doc) => {
          if (user.uid === doc.data().createdBy) {
            newHorseData.push({ id: doc.id, ...doc.data() });
          }
        });
        setAllFarmHorses(newHorseData);
      });
  };

  const handleSaveOfCompareDiet = (dietName, rationType, setSaveButtonLoader, setDietModal) => {
    setSaveButtonLoader(true);

    const checkValueAndConvert = (valueReceive, unit, defaultValue) => {
      if (valueReceive) {
        return valueReceive;
      }
      return defaultValue;
    };

    const dataOfHorse = {
      age: horseDataForCalculation.age ? horseDataForCalculation.age : null,
      averageDailyGain: checkValueAndConvert(horseDataForCalculation.averageDailyGain, 'kg', 0),
      bodyConditionScore: horseDataForCalculation.bodyConditionScore
        ? horseDataForCalculation.bodyConditionScore
        : null,
      bodyLength: checkValueAndConvert(horseDataForCalculation.bodyLength, 'cm', null),
      bodyWeight: checkValueAndConvert(horseDataForCalculation.bodyWeight, 'kg', 0),
      breed: horseDataForCalculation.breed,
      dryMatterIntakeLevel: horseDataForCalculation.dryMatterIntakeLevel,
      farmId: horseDataForCalculation.farmId,
      heartGirth: checkValueAndConvert(horseDataForCalculation.heartGirth, 'cm', null),
      horseId: horseDataForCalculation.horseId,
      lifeStage: horseDataForCalculation.lifeStage,
      maintenanceLevel: horseDataForCalculation.maintenanceLevel ? horseDataForCalculation.maintenanceLevel : null,
      matureBodyWeight: checkValueAndConvert(horseDataForCalculation.matureBodyWeight, 'kg', 0),
      name: horseDataForCalculation.name,
    };
    const recommendedRationData = recommendedRationList.map((item) => {
      return {
        ...item,
        amount: item.amount,
        unit: item.unit ? item.unit : 'lbs/day',
      };
    });
    const currentRationData = feedItemList.map((item) => {
      return {
        ...item,
        amount: item.amount,
        unit: item.unit ? item.unit : 'lbs/day',
      };
    });

    firebaseDb
      .collection('CompareDiet')
      .add({
        createdAt: new Date(),
        createdBy: user.uid,
        name: dietName,
        farmId: itemIdFarm,
        horseId: `${horseDataForCalculation ? horseDataForCalculation.horseId : itemIdHorse}`,
        horseData: dataOfHorse,
        rationType,
        currentRationData,
        recommendedRationData,
        selectedNutrients: nutrientKeysToShow,
        targetDeltaLow,
        targetDeltaHigh,
        acceptableDeltaLow,
        acceptableDeltaHigh,
        chartType: nutSuppGraphChartType,
        maximumCap,
      })
      .then(() => {
        message.success('Diet comparison is successfully saved');
        setSaveButtonLoader(false);
        setDietModal(false);
      })
      .catch((er) => {
        message.error(er.message);
        setSaveButtonLoader(false);
        setDietModal(false);
        logger(er);
      });
  };

  const handleSaveOfHorseDiet = (dietName, rationType, setSaveButtonLoader, setDietModal) => {
    setSaveButtonLoader(true);

    const checkValueAndConvert = (valueReceive, unit, defaultValue) => {
      if (valueReceive) {
        return valueReceive;
      }
      return defaultValue;
    };

    const dataOfHorse = {
      age: horseDataForCalculation.age ? horseDataForCalculation.age : null,
      averageDailyGain: checkValueAndConvert(horseDataForCalculation.averageDailyGain, 'kg', 0),
      bodyConditionScore: horseDataForCalculation.bodyConditionScore
        ? horseDataForCalculation.bodyConditionScore
        : null,
      bodyLength: checkValueAndConvert(horseDataForCalculation.bodyLength, 'cm', null),
      bodyWeight: checkValueAndConvert(horseDataForCalculation.bodyWeight, 'kg', 0),
      breed: horseDataForCalculation.breed,
      dryMatterIntakeLevel: horseDataForCalculation.dryMatterIntakeLevel,
      farmId: horseDataForCalculation.farmId,
      heartGirth: checkValueAndConvert(horseDataForCalculation.heartGirth, 'cm', null),
      horseId: horseDataForCalculation.horseId,
      lifeStage: horseDataForCalculation.lifeStage,
      maintenanceLevel: horseDataForCalculation.maintenanceLevel ? horseDataForCalculation.maintenanceLevel : null,
      matureBodyWeight: checkValueAndConvert(horseDataForCalculation.matureBodyWeight, 'kg', 0),
      name: horseDataForCalculation.name,
    };
    const recommendedRationData = recommendedRationList.map((item) => {
      return {
        ...item,
        amount: item.amount,
        unit: item.unit ? item.unit : 'lbs/day',
      };
    });
    const currentRationData = feedItemList.map((item) => {
      return {
        ...item,
        amount: item.amount,
        unit: item.unit ? item.unit : 'lbs/day',
      };
    });

    firebaseDb
      .collection('HorseDiet')
      .add({
        createdAt: new Date(),
        createdBy: user.uid,
        name: dietName,
        farmId: itemIdFarm,
        horseId: `${horseDataForCalculation ? horseDataForCalculation.horseId : itemIdHorse}`,
        horseData: dataOfHorse,
        rationType,
        rationData: rationType === 'current' ? currentRationData : recommendedRationData,
        selectedNutrients: nutrientKeysToShow,
        targetDeltaLow,
        targetDeltaHigh,
        acceptableDeltaLow,
        acceptableDeltaHigh,
        chartType: nutSuppGraphChartType,
        maximumCap,
      })
      .then(() => {
        rationType === 'recommended'
          ? message.success('Recommended Diet has been successfully saved')
          : message.success('Current Diet  has been successfully saved');

        setSaveButtonLoader(false);
        setDietModal(false);
      })
      .catch((er) => {
        message.error(er.message);
        setSaveButtonLoader(false);
        setDietModal(false);
        logger(er);
      });
  };

  const onChangeSelectHorse = (value) => {
    setFeedItemList([]);
    setItemIdHorse(value);
    setCurrentPage('editHorse');
    const selectedHorseData = horseData.find((entry) => entry.id === value);
    setHorseDataForCalculation({ farmId: itemIdFarm, horseId: value, ...selectedHorseData });
  };

  const handleAddNewHorseButton = () => {
    setVisibleAddHorseModal(true);
  };

  const addHorseModalCancel = () => {
    setVisibleAddHorseModal(false);
  };

  const onClickNextOfHorseForm = (farmId, horseId, valuesToSend) => {
    setHorseDataForCalculation({ farmId, horseId, id: horseId, ...valuesToSend });
    setCurrentPage('addForage');
  };

  const selectHorseDietByLoadRation = (value) => {
    setIdOfDiet(value);
    if (horseDietInLoadRation) {
      const diet = horseDietInLoadRation.find((item) => item.id === value);
      setFeedItemList(diet.rationData);
    }
    return null;
  };

  //  crud functions of feed item list

  const addItemInForageList = (values, feedData) => {
    const foragedata = feedData.find((entry) => entry.id === values.feedId);
    if (feedItemList.some((entry) => entry.id === values.feedId)) {
      message.error('Feed item is already added in Ration');
    } else {
      const array = feedItemList;
      const unit = values.unit ? values.unit : 'lbs/day';
      array.push({ amount: values.amount, unit, ...foragedata });
      message.success('Feed item added to Ration');
      setFeedItemList(array);
    }
  };

  const deleteItemFormList = (itemforDelete) => {
    const filteredArray = feedItemList.filter((eachData) => {
      return itemforDelete.id !== eachData.id;
    });
    message.success('Feed item removed from Ration.');
    setFeedItemList(filteredArray);
  };

  const updateItemInList = (value, itemToEdit) => {
    const editedListArray = feedItemList.map((eachEntry) => {
      if (eachEntry.id === itemToEdit.id) {
        message.success('Feed Item is updated in Ration');
        return { ...eachEntry, amount: value.amount, unit: value.unit ? value.unit : 'lbs/day' };
      }
      return eachEntry;
    });
    setFeedItemList(editedListArray);
  };

  const onClickGenerateReport = (reportOf) => {
    setGenerateReportFor(reportOf);
    setCurrentPage('generateReport');
  };

  const totalAmountOfCurrentList = () => {
    let totalAmount = 0;
    feedItemList.forEach((item) => {
      if (item.unit === 'oz/day') {
        totalAmount += convertOzToLbs(item.amount);
      } else {
        totalAmount += item.amount;
      }
    });
    return totalAmount;
  };

  const totalAmountOfRecommendedList = () => {
    let totalAmount = 0;
    recommendedRationList.forEach((item) => {
      if (item.unit === 'oz/day') {
        totalAmount += convertOzToLbs(item.amount);
      } else {
        totalAmount += item.amount;
      }
    });
    return totalAmount;
  };

  const onchangeCheckBox = (e) => {
    setLoadRationCheckBox(e.target.checked);
    setFeedItemList([]);
    setCheckLoadRation(e.target.checked);
    setIdOfDiet(undefined);
    setIdOfHorseInLoadRation(undefined);
    setIdOfFarmForLoadRation(undefined);
    setAllFarmHorses(undefined);
    setHorseDietInLoadRation(undefined);
  };

  const idOfSelectLoadRationHorse = (id) => {
    setIdOfDiet(undefined);
    setIdOfHorseInLoadRation(id);
    setHorseDietInLoadRation(undefined);
    firebaseDb
      .collection('HorseDiet')
      .where('horseId', '==', id)
      .get()
      .then((querySnapshot) => {
        const horseDiet = [];
        querySnapshot.forEach((doc) => {
          horseDiet.push({ id: doc.id, ...doc.data() });
        });
        setHorseDietInLoadRation(horseDiet);
      })
      .catch((error) => {
        message.error(error.message);
        logger(error);
      });
  };

  return (
    <div>
      <Header />
      <div className="ration-analyzer-container">
        <div>
          {currentPage === 'addForage' ? (
            <AddFeedByRationAnalyzer
              onChangeSelectFarmOfLoadRation={onChangeSelectFarmOfLoadRation}
              idOfHorseInLoadRation={idOfHorseInLoadRation}
              idOfDiet={idOfDiet}
              onchangeCheckBox={onchangeCheckBox}
              checkLoadRation={checkLoadRation}
              setCheckLoadRation={setCheckLoadRation}
              selectHorseDietByLoadRation={selectHorseDietByLoadRation}
              horseDietInLoadRation={horseDietInLoadRation}
              horseDataForLoadExisitingRation={allFarmHorses}
              idOfSelectLoadRationHorse={idOfSelectLoadRationHorse}
              onClickNextOfForage={() => {
                setCurrentPage('rationAnalysisReport');
              }}
              onClickPreviousOfForage={() => {
                setCurrentPage('editHorse');
              }}
              farmData={farmData}
              addItemInForageList={addItemInForageList}
              deleteItemFormList={deleteItemFormList}
              updateItemInList={updateItemInList}
              feedItemList={feedItemList}
              loadRationCheckBox={loadRationCheckBox}
              idOfFarmForLoadRation={idOfFarmForLoadRation}
              setIdOfFarmForLoadRation={setIdOfFarmForLoadRation}
            />
          ) : null}
          {currentPage === 'rationAnalysisReport' ? (
            <RationAnalysisReport
              handleSaveOfHorseDiet={handleSaveOfHorseDiet}
              onClickEditRationButton={() => {
                setCurrentPage('addForage');
              }}
              onClickPrevious={() => {
                setCurrentPage('addForage');
              }}
              onClickGenerateReport={onClickGenerateReport}
              currentRationList={feedItemList}
              recommendedRationList={recommendedRationList}
              horseDataForCalculation={horseDataForCalculation}
              selectedFarmData={farmData.find((entry) => entry.id === itemIdFarm)}
              goToRecommendRationPage={() => {
                setRecommendedRationList([...feedItemList]);
                setCurrentPage('recommendRation');
              }}
              targetDeltaLow={targetDeltaLow}
              targetDeltaHigh={targetDeltaHigh}
              setTargetDeltaLow={updateTargetDeltaLowForNutrient}
              setTargetDeltaHigh={updateTargetDeltaHighForNutrient}
              acceptableDeltaLow={acceptableDeltaLow}
              acceptableDeltaHigh={acceptableDeltaHigh}
              setAcceptableDeltaLow={updateAcceptableDeltaLowForNutrient}
              setAcceptableDeltaHigh={updateAcceptableDeltaHighForNutrient}
              nutSuppGraphChartType={nutSuppGraphChartType}
              setNutSuppGraphChartType={setNutSuppGraphChartType}
              nutSuppGraphNutrients={nutrientKeysToShow}
              setNutSuppGraphNutrients={setNutrientKeysToShow}
              accRangeGraphNutrients={nutrientKeysToShow}
              setAccRangeGraphNutrients={setNutrientKeysToShow}
              maximumCap={maximumCap}
              setMaximumCap={setMaximumCap}
              totalAmount={totalAmountOfCurrentList()}
            />
          ) : null}
          {currentPage === 'recommendRation' ? (
            <RecommendedRation
              onClickEditRationButton={() => {
                setCurrentPage('addForage');
              }}
              onClickPrevious={() => {
                setCurrentPage('addForage');
              }}
              onClickGenerateReport={onClickGenerateReport}
              feedItemList={feedItemList}
              recommendedRationList={recommendedRationList}
              setRecommendedRationList={setRecommendedRationList}
              horseDataForCalculation={horseDataForCalculation}
              selectedFarmData={farmData.find((entry) => entry.id === itemIdFarm)}
              onClickBackButton={() => {
                setCurrentPage('rationAnalysisReport');
              }}
              handleSaveOfHorseDiet={handleSaveOfHorseDiet}
              targetDeltaLow={targetDeltaLow}
              targetDeltaHigh={targetDeltaHigh}
              setTargetDeltaLow={updateTargetDeltaLowForNutrient}
              setTargetDeltaHigh={updateTargetDeltaHighForNutrient}
              acceptableDeltaLow={acceptableDeltaLow}
              acceptableDeltaHigh={acceptableDeltaHigh}
              setAcceptableDeltaLow={updateAcceptableDeltaLowForNutrient}
              setAcceptableDeltaHigh={updateAcceptableDeltaHighForNutrient}
              accRangeGraphNutrients={nutrientKeysToShow}
              setAccRangeGraphNutrients={setNutrientKeysToShow}
              nutSuppGraphChartType={nutSuppGraphChartType}
              setNutSuppGraphChartType={setNutSuppGraphChartType}
              nutSuppGraphNutrients={nutrientKeysToShow}
              setNutSuppGraphNutrients={setNutrientKeysToShow}
              maximumCap={maximumCap}
              setMaximumCap={setMaximumCap}
              totalAmount={totalAmountOfCurrentList()}
              totalAmountRecommend={totalAmountOfRecommendedList()}
              handleSaveOfCompareDiet={handleSaveOfCompareDiet}
            />
          ) : null}
          {currentPage === 'generateReport' ? (
            <GenerateReportPage
              onClickGoBack={() => {
                if (generateReportFor === 'current') {
                  return setCurrentPage('rationAnalysisReport');
                }
                return setCurrentPage('recommendRation');
              }}
              compareGenerateReport={generateReportFor}
              recommendedItemList={generateReportFor === 'compare' ? recommendedRationList : feedItemList}
              horseDataForCalculation={horseDataForCalculation}
              feedItemList={generateReportFor === 'recommended' ? recommendedRationList : feedItemList}
              selectedFarmData={farmData.find((entry) => entry.id === itemIdFarm)}
              targetDeltaLow={targetDeltaLow}
              targetDeltaHigh={targetDeltaHigh}
              setTargetDeltaLow={updateTargetDeltaLowForNutrient}
              setTargetDeltaHigh={updateTargetDeltaHighForNutrient}
              acceptableDeltaLow={acceptableDeltaLow}
              acceptableDeltaHigh={acceptableDeltaHigh}
              setAcceptableDeltaLow={updateAcceptableDeltaLowForNutrient}
              setAcceptableDeltaHigh={updateAcceptableDeltaHighForNutrient}
              accRangeGraphNutrients={nutrientKeysToShow}
              setAccRangeGraphNutrients={setNutrientKeysToShow}
              nutSuppGraphChartType={nutSuppGraphChartType}
              setNutSuppGraphChartType={setNutSuppGraphChartType}
              nutSuppGraphNutrients={nutrientKeysToShow}
              setNutSuppGraphNutrients={setNutrientKeysToShow}
              maximumCap={maximumCap}
              totalAmount={
                userSettings.uom === 'metric'
                  ? convertPoundToKg(totalAmountOfCurrentList())
                  : totalAmountOfCurrentList()
              }
              totalAmountRecommend={
                userSettings.uom === 'metric'
                  ? convertPoundToKg(totalAmountOfRecommendedList())
                  : totalAmountOfRecommendedList()
              }
            />
          ) : null}
          {currentPage === '' || currentPage === 'addHorse' || currentPage === 'editHorse' ? (
            <Row style={{ padding: 10, paddingTop: 0, paddingLeft: 16 }}>
              <Col offset={2} span={14}>
                <span className="farm-select">Select a Farm:</span>
                <Select
                  showSearch
                  style={{ width: 350 }}
                  placeholder="Search for Farm by Name/City/State/Zipcode"
                  optionFilterProp="data"
                  onChange={onChangeSelectFarm}
                  value={itemIdFarm}
                >
                  {farmData.map((item) => (
                    <Option key={item.id} value={item.id} data={`${item.name}_${item.city}_${item.state}_${item.zip}`}>
                      {`${item.name}`}
                    </Option>
                  ))}
                </Select>
                {(farmData.find((entry) => entry.id === itemIdFarm) && currentPage === '') ||
                currentPage === 'addHorse' ||
                currentPage === 'editHorse' ? (
                  <span style={{ fontSize: 19, paddingLeft: 167 }}>OR</span>
                ) : null}
              </Col>
              {(farmData.find((entry) => entry.id === itemIdFarm) && currentPage === '') ||
              currentPage === 'addHorse' ||
              currentPage === 'editHorse' ? (
                <Col span={2}>
                  <Button size="large" icon="plus" className="button-color" onClick={handleAddNewHorseButton}>
                    Add New Horse
                  </Button>
                </Col>
              ) : null}
            </Row>
          ) : null}
          {(farmData.find((entry) => entry.id === itemIdFarm) && currentPage === '') ||
          currentPage === 'addHorse' ||
          currentPage === 'editHorse' ? (
            <div>
              <Row style={{ padding: 10, paddingTop: 0 }}>
                <Col offset={2} span={14}>
                  <span className="farm-select">Select a Horse:</span>
                  <Select
                    showSearch
                    style={{ width: 350 }}
                    placeholder="Start typing the Name of Horse"
                    optionFilterProp="data"
                    onChange={onChangeSelectHorse}
                    value={itemIdHorse}
                  >
                    {horseData.map((item) => (
                      <Option key={item.id} value={item.id} data={item.name}>
                        {`${item.name}`}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </div>
          ) : null}
          <Modal width={1500} footer={false} visible={visibleAddHorseModal} onCancel={addHorseModalCancel}>
            <div style={{ fontSize: 20, color: 'black', textAlign: 'center' }}>Add New Horse</div>
            <Divider />
            <div>
              <HorseForm
                mode="rationAdd"
                onClickNextOfHorseForm={onClickNextOfHorseForm}
                addHorseModalCancel={(v) => setVisibleAddHorseModal(v)}
                farmData={farmData}
                farmId={itemIdFarm}
              />
            </div>
          </Modal>
          {currentPage === 'editHorse' ? (
            <div style={{ padding: 35, paddingTop: 0 }}>
              <HorseForm
                isNextButtonClicked={isNextButtonClicked}
                setIsNextButtonClicked={() => {
                  setIsNextButtonClicked(true);
                }}
                mode="rationEdit"
                itemToEdit={horseDataForCalculation}
                currentFarmId={itemIdFarm}
                currentHorseId={itemIdHorse}
                onClickPrevious={() => {
                  setCurrentPage('');
                  setItemIdHorse(undefined);
                  setItemIdFarm(undefined);
                }}
                farmData={farmData}
                farmId={itemIdFarm}
                onClickNextOfHorseForm={onClickNextOfHorseForm}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RationAnalyzerPage;
