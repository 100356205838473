import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PropTypes from 'prop-types';
import AllUsersPage from './AllUsersPage';
import CreateUserPage from './CreateUserPage';
import EditUserPage from './EditUserPage';

const FeedLayoutPage = (props) => {
  const { match, location, history } = props;

  let selectedIndex = 0;

  if (location.pathname.includes('create')) {
    selectedIndex = 1;
  }
  if (location.pathname.includes('edit')) {
    selectedIndex = 2;
  }

  const handleTabchange = (value) => {
    if (value !== selectedIndex && value === 0) {
      history.push(`${match.url}`);
    }
    if (value !== selectedIndex && value === 1) {
      history.push(`${match.url}/create`);
    }
    if (value !== selectedIndex && value === 2 && match.params.userid !== undefined) {
      history.push(`${match.url}/${match.params.userid}/edit`);
    }
  };

  return (
    <Tabs selectedIndex={selectedIndex} onSelect={(value) => handleTabchange(value)}>
      <TabList style={{ padding: '0px 35px' }}>
        <Tab>All Users</Tab>
        <Tab>Add New User</Tab>
        <Tab disabled>Edit User Profile</Tab>
      </TabList>
      <TabPanel>
        <Switch>
          <Route path={`${match.url}`} component={AllUsersPage} />
        </Switch>
      </TabPanel>
      <TabPanel>
        <Switch>
          <Route path={`${match.url}/create`} component={CreateUserPage} />
        </Switch>
      </TabPanel>
      <TabPanel>
        <Switch>
          <Route path={`${match.url}/:userid/edit`} component={EditUserPage} />
        </Switch>
      </TabPanel>
    </Tabs>
  );
};

FeedLayoutPage.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default FeedLayoutPage;
