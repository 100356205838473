import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Form, Row, Col, Input, Affix, message } from 'antd';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import GraphOfNutrientSupplied from './GraphOfNutrientSupplied';
import AcceptableRangeGraph from './AcceptableRangeGraph';
import './GenerateReportPage.scss';
import { feedNutrientsForTable, reportsApiBaseUrl, addressFormat } from '../utils/globals';
import rationCalculations from '../utils/rationCalculations';
import NutrientsSuppliedTable from './NutrientsSuppliedTable';
import TotalDailyRation from './TotalDailyRationPage';
import useAuth from '../utils/auth';
import useAppData from '../contexts/appDataContext';
import Heading from './Heading';
import logger from '../utils/helpers/logger';

const { TextArea } = Input;

const GenerateReportPage = (props) => {
  const {
    form,
    onClickGoBack,
    feedItemList,
    targetDeltaLow,
    targetDeltaHigh,
    setTargetDeltaLow,
    setTargetDeltaHigh,
    acceptableDeltaLow,
    acceptableDeltaHigh,
    setAcceptableDeltaLow,
    setAcceptableDeltaHigh,
    horseDataForCalculation,
    selectedFarmData,
    accRangeGraphNutrients,
    setAccRangeGraphNutrients,
    nutSuppGraphChartType,
    setNutSuppGraphChartType,
    nutSuppGraphNutrients,
    setNutSuppGraphNutrients,
    maximumCap,
    totalAmount,
    recommendedItemList,
    totalAmountRecommend,
    compareGenerateReport,
  } = props;
  const { getFieldDecorator, getFieldValue, validateFields } = form;
  const [generalInformation, setGeneralInformation] = useState(true);
  const [physicalInformation, setPhysicalInformation] = useState(true);
  const [totalDailyRation, setTotalDailyRation] = useState(true);
  const [suppliedGraph, setSuppliedGraph] = useState(true);
  const [acceptableGraph, setAcceptableGraph] = useState(true);
  const [nutrientsTable, setNutrientsTable] = useState(true);
  const [ratiosTable, setRatiosTable] = useState(true);
  const [generalComment, setGeneralComment] = useState(true);
  const [pdfSrc, setPdfSrc] = useState('');
  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdfFilename, setPdfFilename] = useState('report.pdf');
  const [comments, setComments] = useState({});
  const [recommendedComments, setRecommendedComments] = useState({});
  const [generalCommentValue, setGeneralCommentValue] = useState(null);
  const { userSettings, convertPoundToKg, convertInchToCm, convertOzToGram, user } = useAuth();

  const { concCalculate, createKeysAndDataForGraph, dataForRatiosTableData } = useAppData();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let labelAccordingToLifeStage = 'Maintenance Level';
  if (horseDataForCalculation.lifeStage === 'Adult Maintenance') {
    labelAccordingToLifeStage = 'Maintenance Level';
  }
  if (horseDataForCalculation.lifeStage === 'Working/Training') {
    labelAccordingToLifeStage = 'Work Load';
  }
  if (horseDataForCalculation.lifeStage === 'Pregnant') {
    labelAccordingToLifeStage = 'Gestation Month';
  }
  if (horseDataForCalculation.lifeStage === 'Lactating') {
    labelAccordingToLifeStage = 'Lactation Month';
  }
  if (horseDataForCalculation.lifeStage === 'Stallion') {
    labelAccordingToLifeStage = 'Breeding/Non-Breeding';
  }
  if (horseDataForCalculation.lifeStage === 'Growing') {
    labelAccordingToLifeStage = 'Work Level';
  }

  const currentRationCalculations = rationCalculations({
    rationList: feedItemList,
    horseData: horseDataForCalculation,
    targetDeltaLow,
    targetDeltaHigh,
    acceptableDeltaLow,
    acceptableDeltaHigh,
  });

  let recommendedRationCalculationsData = null;
  if (recommendedItemList) {
    recommendedRationCalculationsData = rationCalculations({
      rationList: recommendedItemList,
      horseData: horseDataForCalculation,
      targetDeltaLow,
      targetDeltaHigh,
      acceptableDeltaLow,
      acceptableDeltaHigh,
    });
  }

  const displayAge = () => {
    if (horseDataForCalculation.lifeStage === 'Growing') {
      if (horseDataForCalculation.age <= 24) {
        return `${horseDataForCalculation.age} months `;
      }
      return `${Math.floor(horseDataForCalculation.age / 12)} years ${horseDataForCalculation.age % 12} months   `;
    }
    return `${horseDataForCalculation.age > 12 ? Math.floor(horseDataForCalculation.age / 12) : 1} years`;
  };

  const renderAmountValueInTable = (record) => {
    if (userSettings.uom === 'metric') {
      if (record.unit && record.unit === 'lbs/day') {
        return `${convertPoundToKg(record.amount).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} kgs`;
      }
      if (record.unit && record.unit === 'oz/day') {
        return `${convertOzToGram(record.amount).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} grams`;
      }
      return null;
    }
    return `${
      record.amount
        ? record.amount.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : null
    } ${record.unit === 'lbs/day' ? 'lbs' : 'oz'}`;
  };

  let sumOfAmount = 0;
  feedItemList.forEach((i) => {
    let amountInLbs = 0;
    if (i.unit === 'oz/day') {
      amountInLbs = i.amount * 0.0625;
    } else {
      amountInLbs = i.amount;
    }
    sumOfAmount += amountInLbs;
  });

  const calcPercentofAmount = (data) => {
    let amountInLbs = 0;
    if (data.unit === 'oz/day') {
      amountInLbs = data.amount * 0.0625;
    } else {
      amountInLbs = data.amount;
    }
    const amountIs = (amountInLbs * 100) / sumOfAmount;
    return amountIs.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const sendCategory = (data) => {
    if (data.category === 'purinaProduct') {
      return 'Purina Product';
    }
    if (data.category === 'individualIngredient') {
      return 'Individual Ingredient';
    }
    if (data.category === 'customEntry') {
      return 'Custom Entry';
    }
    if (data.category === 'otherCommercialProduct') {
      return 'Other Commercial Product';
    }
    if (data.category === 'bookValues') {
      return 'Book Values';
    }
    if (data.category === 'uncategorized') {
      return 'Uncategorized';
    }
    return null;
  };

  const filteredNutrients = [];
  if (nutSuppGraphNutrients.length !== 0) {
    feedNutrientsForTable.filter((nutrient) => {
      ['percentDM', ...nutSuppGraphNutrients].forEach((eachNut) => {
        if (nutrient.key === eachNut) {
          filteredNutrients.push(nutrient);
        }
      });
      return null;
    });
  } else {
    feedNutrientsForTable.forEach((nut) => {
      filteredNutrients.push(nut);
    });
  }

  const unitDM = () => {
    if (userSettings.uom === 'imperial') {
      return 'lbs';
    }
    return 'kg';
  };
  const dailySuppliedDM = (dailySupplied) => {
    if (userSettings.uom === 'imperial') {
      return (dailySupplied / 0.453592).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return dailySupplied.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const dailyRequiredDM = (dailyRequired) => {
    if (userSettings.uom === 'imperial') {
      return (dailyRequired / 0.453592).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return dailyRequired.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const nutrientTable = (ration, rationtype) => {
    const dataSourceForTable = [];
    filteredNutrients.forEach((nutrient) => {
      if (nutrient.key === 'sugar' || nutrient.key === 'starch') {
        dataSourceForTable.push({
          key: nutrient.key,
          nutrientName: nutrient.nutrientName,
          units: nutrient.unit,
          dailySupplied: ration.amountFedPerNutrientTotal[nutrient.key].toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          dailyRequired: '-',
          percentRequired: '-',
          concentration: concCalculate(
            nutrient.key,
            ration.amountFedPerNutrientTotal[nutrient.key],
            totalAmount,
            userSettings,
          ),
          comment: rationtype === 'current' ? comments[nutrient.key] : recommendedComments[nutrient.key],
        });
      } else if (nutrient.key === 'percentDM') {
        dataSourceForTable.push({
          key: nutrient.key,
          nutrientName: nutrient.nutrientName,
          units: unitDM(),
          dailySupplied: dailySuppliedDM(ration.amountFedPerNutrientTotal[nutrient.key]),
          dailyRequired: dailyRequiredDM(ration.horseNutrientRequirement[nutrient.rCalcKey]),
          percentRequired:
            ration.percentTotalFedOfReqd[nutrient.key] === Infinity
              ? 0
              : ration.percentTotalFedOfReqd[nutrient.key].toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
          concentration: concCalculate(
            nutrient.key,
            ration.amountFedPerNutrientTotal[nutrient.key],
            totalAmount,
            userSettings,
          ),
          comment: rationtype === 'current' ? comments[nutrient.key] : recommendedComments[nutrient.key],
        });
      } else {
        dataSourceForTable.push({
          key: nutrient.key,
          nutrientName: nutrient.nutrientName,
          units: nutrient.unit,
          dailySupplied: ration.amountFedPerNutrientTotal[nutrient.key].toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          dailyRequired: ration.horseNutrientRequirement[nutrient.rCalcKey].toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          percentRequired:
            ration.percentTotalFedOfReqd[nutrient.key] === Infinity
              ? 0
              : ration.percentTotalFedOfReqd[nutrient.key].toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
          concentration: concCalculate(
            nutrient.key,
            ration.amountFedPerNutrientTotal[nutrient.key],
            totalAmount,
            userSettings,
          ),
          comment: rationtype === 'current' ? comments[nutrient.key] : recommendedComments[nutrient.key],
        });
      }
    });
    return dataSourceForTable;
  };

  const finalDataForAcceptableGraph = currentRationCalculations.acceptableRangesData.filter(
    (data) => accRangeGraphNutrients.includes(data.key) && data.key !== 'starch' && data.key !== 'sugar',
  );

  const finalDataForAcceptableRecommendGraph = recommendedRationCalculationsData.acceptableRangesData.filter(
    (data) => accRangeGraphNutrients.includes(data.key) && data.key !== 'starch' && data.key !== 'sugar',
  );

  const [finalDataForGraph, dataKeyForGraph] = createKeysAndDataForGraph(
    currentRationCalculations,
    nutSuppGraphNutrients.filter((nutrient) => nutrient !== 'sugar' && nutrient !== 'starch'),
    nutSuppGraphChartType,
  );

  const [finalDataForRecommendGraph, dataKeyForRecommendGraph] = createKeysAndDataForGraph(
    recommendedRationCalculationsData,
    nutSuppGraphNutrients.filter((nutrient) => nutrient !== 'sugar' && nutrient !== 'starch'),
    nutSuppGraphChartType,
  );

  const sendConvertedValue = (value, key) => {
    if (userSettings.uom === 'metric') {
      if (key === 'bodyLength' || key === 'heartGirth') {
        return convertInchToCm(value);
      }
      return convertPoundToKg(value);
    }
    return value;
  };

  const handlePreviewClick = () => {
    setPdfLoading(true);
    validateFields((err, values) => {
      const generalInfoKey = [
        'horseName',
        'farmName',
        'address',
        'managerName',
        'managerEmail',
        'managerMobile',
        'salesRepName',
        'salesRepEmail',
      ];
      const physicalInfoKey = [
        'age',
        'breed',
        'lifeStage',
        'maintenanceLevel',
        'bodyConditionScore',
        'dryMatterIntakeLevel',
        'bodyWeight',
        'bodyLength',
        'heartGirth',
        'averageDailyGain',
        'matureBodyWeight',
      ];
      generalInfoKey.map((key) => {
        if (values[key]) {
          setGeneralInformation(true);
        }
        return false;
      });
      physicalInfoKey.map((key) => {
        if (values[key]) {
          setPhysicalInformation(true);
        }
        return false;
      });
      const genInfo = () => {
        if (generalInformation) {
          const genValues = {
            horseName: values.horseName
              ? horseDataForCalculation.name.charAt(0).toUpperCase() + horseDataForCalculation.name.slice(1)
              : null,
            farmName: values.farmName ? selectedFarmData.name : null,
            address: values.address ? addressFormat(selectedFarmData) : null,
            managerName: values.managerName ? selectedFarmData.managerName : null,
            managerEmail: values.managerEmail ? selectedFarmData.managerEmail : null,
            managerMobile: values.managerMobile ? selectedFarmData.managerMobile : null,
            salesRepName: values.salesRepName ? selectedFarmData.salesRepName : null,
            salesRepEmail: values.salesRepEmail ? selectedFarmData.salesRepEmail : null,
          };
          return genValues;
        }
        return null;
      };
      const phyInfo = () => {
        if (physicalInformation) {
          const phyValuesToSend = [];
          const phyInfoKeys1 = [
            { key: 'age', label: 'Age' },
            { key: 'breed', label: 'Breed' },
            { key: 'lifeStage', label: 'Life Stage' },
            { key: 'maintenanceLevel', label: labelAccordingToLifeStage },
            { key: 'bodyConditionScore', label: 'Body Condition Score (1-9)' },
            { key: 'dryMatterIntakeLevel', label: 'Dry Matter Intake Level (in %)' },
          ];
          const phyInfoKeys2 = [
            {
              key: 'bodyWeight',
              label: `Body Weight ${userSettings.uom === 'metric' ? '(kgs)' : '(lbs)'}`,
            },
            { key: 'bodyLength', label: `Body Length ${userSettings.uom === 'metric' ? '(kgs)' : '(lbs)'}` },
            { key: 'heartGirth', label: `Heart Girth ${userSettings.uom === 'metric' ? '(kgs)' : '(lbs)'}` },
            {
              key: 'averageDailyGain',
              label: `Average Daily Gain ${userSettings.uom === 'metric' ? '(kgs/day)' : '(lbs/day)'}`,
            },
            {
              key: 'matureBodyWeight',
              label: `Mature Body Weight ${userSettings.uom === 'metric' ? '(kgs)' : '(lbs)'}`,
            },
          ];
          phyInfoKeys1.map((data) => {
            if (values[data.key]) {
              if (data.key === 'age') {
                phyValuesToSend.push({ label: data.label, value: displayAge() });
              } else {
                phyValuesToSend.push({ label: data.label, value: horseDataForCalculation[data.key] });
              }
            }
            return null;
          });
          phyInfoKeys2.map((data) => {
            if (values[data.key]) {
              phyValuesToSend.push({
                label: data.label,
                value: sendConvertedValue(horseDataForCalculation[data.key], data.key).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
              });
            }
            return null;
          });
          return phyValuesToSend;
        }
        return null;
      };

      const rationListData = feedItemList.map((data) => ({
        type: data.type.charAt(0).toUpperCase() + data.type.slice(1),
        code: data.code,
        name: data.name,
        category: sendCategory(data),
        amount: renderAmountValueInTable(data),
        percentOfTotal: calcPercentofAmount(data),
      }));

      const recommendedRationListData = recommendedItemList.map((data) => ({
        type: data.type.charAt(0).toUpperCase() + data.type.slice(1),
        code: data.code,
        name: data.name,
        category: sendCategory(data),
        amount: renderAmountValueInTable(data),
        percentOfTotal: calcPercentofAmount(data),
      }));

      const recommendTotalAmount = () => {
        if (totalAmountRecommend) {
          return totalAmountRecommend.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        }
        return null;
      };

      if (!err) {
        const reportSchema = {
          createdOn: moment().format('MMMM D, YYYY'),
          preparedBy: user.displayName,
          title: values.reportTitle,
          generalInfo: genInfo(),
          physicalInfo: phyInfo(),
          rationList: totalDailyRation ? rationListData : null,
          rationTotalAmount: totalDailyRation
            ? {
                label: `Total Amount ${userSettings.uom === 'metric' ? '(kgs)' : '(lbs)'}`,
                value: totalAmount.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
              }
            : null,
          nutrientSuppliedBarChart: suppliedGraph
            ? {
                data: finalDataForGraph,
                chartType: nutSuppGraphChartType,
                dataKeys: dataKeyForGraph,
                compare: false,
                maximumCap,
              }
            : null,
          acceptableRangeBarChart: acceptableGraph
            ? {
                data: finalDataForAcceptableGraph,
                compare: false,
              }
            : null,
          nutrientsSuppliedTableData: nutrientsTable ? nutrientTable(currentRationCalculations, 'current') : null,
          ratiosTableData: ratiosTable ? dataForRatiosTableData(currentRationCalculations) : null,
          generalComments: generalComment ? generalCommentValue : null,
        };
        const compareReportSchema = {
          createdOn: moment().format('MMMM D, YYYY'),
          preparedBy: user.displayName,
          title: values.reportTitle,
          generalInfo: genInfo(),
          physicalInfo: phyInfo(),
          currentRationList: totalDailyRation ? rationListData : null,
          recommendedRationList: totalDailyRation ? recommendedRationListData : null,
          currentRationTotalAmount: totalDailyRation
            ? {
                label: `Total Amount ${userSettings.uom === 'metric' ? '(kgs)' : '(lbs)'}`,
                value: totalAmount.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
              }
            : null,
          recommendedRationTotalAmount: totalDailyRation
            ? {
                label: `Total Amount ${userSettings.uom === 'metric' ? '(kgs)' : '(lbs)'}`,
                value: recommendTotalAmount(),
              }
            : null,
          currentNutrientSuppliedBarChart: suppliedGraph
            ? {
                data: finalDataForGraph,
                chartType: nutSuppGraphChartType,
                dataKeys: dataKeyForGraph,
                compare: false,
                maximumCap,
              }
            : null,
          recommendedNutrientSuppliedBarChart: suppliedGraph
            ? {
                data: finalDataForRecommendGraph,
                chartType: nutSuppGraphChartType,
                dataKeys: dataKeyForRecommendGraph,
                compare: false,
                maximumCap,
              }
            : null,
          currentAcceptableRangeBarChart: acceptableGraph
            ? {
                data: finalDataForAcceptableGraph,
                compare: false,
              }
            : null,
          recommendedAcceptableRangeBarChart: acceptableGraph
            ? {
                data: finalDataForAcceptableRecommendGraph,
                compare: false,
              }
            : null,
          currentNutrientsSuppliedTableData: nutrientsTable
            ? nutrientTable(currentRationCalculations, 'current')
            : null,
          recommendedNutrientsSuppliedTableData: nutrientsTable
            ? nutrientTable(recommendedRationCalculationsData, 'recommend')
            : null,
          currentRatiosTableData: ratiosTable ? dataForRatiosTableData(currentRationCalculations) : null,
          recommendedRatiosTableData: ratiosTable ? dataForRatiosTableData(recommendedRationCalculationsData) : null,
          generalComments: generalComment ? generalCommentValue : null,
        };

        if (compareGenerateReport) {
          console.log('compareReportSchema', compareReportSchema);
        } else {
          console.log('reportSchema', reportSchema);
        }

        axios
          .post(
            `${reportsApiBaseUrl}/${compareGenerateReport === 'compare' ? 'compareReport' : 'analysisReport'}`,
            compareGenerateReport === 'compare'
              ? { nJpaUJSK4U: 'oaFT4X4qLbOmYr8WzmLib0OM33Dro4DGWo2Yidtg', ...compareReportSchema }
              : { nJpaUJSK4U: 'oaFT4X4qLbOmYr8WzmLib0OM33Dro4DGWo2Yidtg', ...reportSchema },
            { responseType: 'blob' },
          )
          .then((response) => {
            const blob = new Blob([response.data], {
              type: 'application/pdf',
            });
            setPdfSrc(window.URL.createObjectURL(blob));
            setPdfFilename(`${moment().format('YYYY-MM-DD')}_${values.reportTitle}.pdf`);
            setPdfLoading(false);
          })
          .catch((error) => {
            setPdfLoading(false);
            message.error(error.message);
            logger(error);
          });
      } else {
        setPdfLoading(false);
      }
    });
  };

  /*
    userComments = {
        nutrientKey1: 'comment'
        nutrientKey2: 'comment'
        nutrientKey3: 'comment'
        ...
    }
   */

  const onchangeGeneralInformation = (e) => {
    setGeneralInformation(e.target.checked);
    if (e.target.checked === true) {
      form.setFieldsValue({
        horseName: true,
        managerName: true,
        managerEmail: true,
        managerMobile: true,
        farmName: true,
        address: true,
        salesRepName: true,
        salesRepEmail: true,
      });
    }
    if (e.target.checked === false) {
      form.setFieldsValue({
        horseName: false,
        managerName: false,
        managerEmail: false,
        managerMobile: false,
        farmName: false,
        address: false,
        salesRepName: false,
        salesRepEmail: false,
      });
    }
  };

  const onchangePhysicalInformation = (e) => {
    setPhysicalInformation(e.target.checked);
    if (e.target.checked === true) {
      form.setFieldsValue({
        age: true,
        lifeStage: true,
        breed: true,
        heartGirth: true,
        bodyWeight: true,
        averageDailyGain: true,
        bodyConditionScore: true,
        maintenanceLevel: true,
        dryMatterIntakeLevel: true,
        bodyLength: true,
        matureBodyWeight: true,
      });
    }
    if (e.target.checked === false) {
      form.setFieldsValue({
        age: false,
        lifeStage: false,
        breed: false,
        heartGirth: false,
        bodyWeight: false,
        averageDailyGain: false,
        bodyConditionScore: false,
        maintenanceLevel: false,
        dryMatterIntakeLevel: false,
        bodyLength: false,
        matureBodyWeight: false,
      });
    }
  };

  const onchangetotalDailyRation = (e) => {
    setTotalDailyRation(e.target.checked);
  };

  const handleSubmit = () => {};

  const handleTextArea = (e) => {
    setGeneralCommentValue(e.target.value);
  };

  return (
    <>
      {/* Report preview */}
      <div
        style={{
          height: 'calc(100vh - 80px)',
          width: '100%',
          display: pdfSrc ? 'flex' : 'none',
          flexDirection: 'column',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
          <Button
            className="button-color"
            style={{ marginLeft: 15 }}
            onClick={() => {
              setPdfSrc('');
            }}
          >
            Back
          </Button>
          <Button style={{ marginRight: 10 }} className="button-color" href={pdfSrc} download={pdfFilename}>
            Download Report PDF
          </Button>
        </div>
        <div style={{ flex: 1 }}>
          <object data={`${pdfSrc}#page=1`} type="application/pdf" width="100%" height="100%">
            <iframe src={`${pdfSrc}#page=1`} width="100%" height="100%">
              <p>
                Your browser does not support PDFs.
                <a href={pdfSrc}>Download the PDF</a>.
              </p>
            </iframe>
          </object>
        </div>
      </div>
      {/* Generate report selections */}
      <div style={{ display: pdfSrc ? 'none' : 'block' }}>
        <Affix>
          <div style={{ backgroundColor: '#FFFFFF', paddingTop: 10 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 5,
              }}
            >
              <Button
                className="button-color"
                style={{ marginLeft: 15 }}
                onClick={() => {
                  onClickGoBack();
                }}
                disabled={pdfLoading}
              >
                Back
              </Button>
              <div style={{ textAlign: 'center' }}>
                <div style={{ fontSize: 22, color: 'black', marginBottom: 5 }}>Generate Report</div>
                <div style={{ fontStyle: 'italic', fontSize: 12, color: 'black' }}>
                  Select information that you want to appear in the report
                </div>
              </div>
              <div>
                <Button
                  style={{ marginRight: 10 }}
                  className="button-color"
                  onClick={handlePreviewClick}
                  loading={pdfLoading}
                >
                  Preview
                </Button>
              </div>
            </div>
            <hr style={{ marginTop: 1, marginBottom: 20 }} />
          </div>
        </Affix>

        <div style={{ padding: 35 }}>
          <Form onSubmit={handleSubmit}>
            <Form.Item label="Report Title" labelCol={{ xs: 2, lg: 2, xl: 2 }} wrapperCol={{ xs: 5, lg: 7, xl: 6 }}>
              {getFieldDecorator('reportTitle')(<Input placeholder="Enter a suitable title for report" />)}
            </Form.Item>
            <div>
              <Checkbox
                checked={
                  generalInformation ||
                  getFieldValue('horseName') ||
                  getFieldValue('managerName') ||
                  getFieldValue('managerEmail') ||
                  getFieldValue('managerMobile') ||
                  getFieldValue('farmName') ||
                  getFieldValue('address') ||
                  getFieldValue('salesRepName') ||
                  getFieldValue('salesRepEmail')
                }
                onChange={onchangeGeneralInformation}
                style={{ paddingLeft: 7, marginBottom: 10 }}
              >
                <span style={{ fontSize: 18, color: 'black' }}>General Information</span>
              </Checkbox>
              <hr style={{ marginTop: 1, marginBottom: 20 }} />
              <Row>
                <Col offset={4} span={8}>
                  <Form.Item className="form-item-in-generatePage">
                    {getFieldDecorator('horseName', {
                      initialValue: !!horseDataForCalculation.name && generalInformation,
                      valuePropName: 'checked',
                    })(<Checkbox>Horse Name:</Checkbox>)}
                  </Form.Item>
                  <p className="details-of-label" style={{ textTransform: 'capitalize' }}>
                    {horseDataForCalculation.name}
                  </p>
                  <Form.Item className="form-item-in-generatePage">
                    {getFieldDecorator('managerName', {
                      initialValue: !!selectedFarmData.managerName && generalInformation,
                      valuePropName: 'checked',
                    })(<Checkbox>Manager Name: </Checkbox>)}
                  </Form.Item>
                  <p className="details-of-label" style={{ textTransform: 'capitalize' }}>
                    {selectedFarmData.managerName}
                  </p>
                  <Form.Item className="form-item-in-generatePage">
                    {getFieldDecorator('managerEmail', {
                      initialValue: !!selectedFarmData.managerEmail && generalInformation,
                      valuePropName: 'checked',
                    })(<Checkbox>Manager Email: </Checkbox>)}
                  </Form.Item>
                  <p className="details-of-label">{selectedFarmData.managerEmail}</p>
                  <Form.Item className="form-item-in-generatePage">
                    {getFieldDecorator('managerMobile', {
                      initialValue: !!selectedFarmData.managerMobile && generalInformation,
                      valuePropName: 'checked',
                    })(<Checkbox>Manager Phone: </Checkbox>)}
                  </Form.Item>
                  <p className="details-of-label">{selectedFarmData.managerMobile}</p>
                </Col>
                <Col offset={4} span={8}>
                  <Form.Item className="form-item-in-generatePage">
                    {getFieldDecorator('farmName', { initialValue: !!selectedFarmData.name, valuePropName: 'checked' })(
                      <Checkbox>Farm Name: </Checkbox>,
                    )}
                  </Form.Item>
                  <p className="details-of-label" style={{ textTransform: 'capitalize' }}>
                    {selectedFarmData.name}{' '}
                  </p>
                  <Form.Item className="form-item-in-generatePage">
                    {getFieldDecorator('address', {
                      initialValue:
                        (!!selectedFarmData.addressLine1 ||
                          !!selectedFarmData.addressLine2 ||
                          !!selectedFarmData.city ||
                          !!selectedFarmData.state ||
                          !!selectedFarmData.zip) &&
                        generalInformation,
                      valuePropName: 'checked',
                    })(<Checkbox>Address:</Checkbox>)}
                  </Form.Item>
                  <p className="details-of-label" style={{ textTransform: 'capitalize' }}>
                    {addressFormat(selectedFarmData)}
                  </p>
                  <Form.Item className="form-item-in-generatePage">
                    {getFieldDecorator('salesRepName', {
                      initialValue: !!selectedFarmData.salesRepName && generalInformation,
                      valuePropName: 'checked',
                    })(<Checkbox>Sales Rep Name: </Checkbox>)}
                  </Form.Item>
                  <p className="details-of-label" style={{ textTransform: 'capitalize' }}>
                    {selectedFarmData.salesRepName}
                  </p>
                  <Form.Item className="form-item-in-generatePage">
                    {getFieldDecorator('salesRepEmail', {
                      initialValue: !!selectedFarmData.salesRepEmail && generalInformation,
                      valuePropName: 'checked',
                    })(<Checkbox>Sales Rep Email:</Checkbox>)}
                  </Form.Item>
                  <p className="details-of-label">{selectedFarmData.salesRepEmail}</p>
                </Col>
              </Row>
            </div>
            <div>
              <Checkbox
                checked={
                  physicalInformation ||
                  getFieldValue('age') ||
                  getFieldValue('lifeStage') ||
                  getFieldValue('breed') ||
                  getFieldValue('heartGirth') ||
                  getFieldValue('bodyWeight') ||
                  getFieldValue('averageDailyGain') ||
                  getFieldValue('bodyConditionScore') ||
                  getFieldValue('maintenanceLevel') ||
                  getFieldValue('dryMatterIntakeLevel') ||
                  getFieldValue('bodyLength') ||
                  getFieldValue('matureBodyWeight')
                }
                onChange={onchangePhysicalInformation}
                style={{ paddingLeft: 7, marginBottom: 10 }}
              >
                <span style={{ fontSize: 18, color: 'black' }}>Physical Information</span>
              </Checkbox>
              <hr style={{ marginTop: 1, marginBottom: 20 }} />
              <Row>
                <Col offset={4} span={8}>
                  <Form.Item className="form-item-in-generatePage">
                    {getFieldDecorator('age', {
                      initialValue: !!horseDataForCalculation.age,
                      valuePropName: 'checked',
                    })(<Checkbox>Age: </Checkbox>)}
                  </Form.Item>
                  <p className="details-of-label">{displayAge()}</p>
                  <Form.Item className="form-item-in-generatePage">
                    {getFieldDecorator('lifeStage', {
                      initialValue: !!horseDataForCalculation.lifeStage,
                      valuePropName: 'checked',
                    })(<Checkbox>Life Stage:</Checkbox>)}
                  </Form.Item>
                  <p className="details-of-label"> {horseDataForCalculation.lifeStage}</p>
                  <Form.Item className="form-item-in-generatePage">
                    {getFieldDecorator('breed', {})(<Checkbox>Breed: </Checkbox>)}
                  </Form.Item>
                  <p className="details-of-label" style={{ textTransform: 'capitalize' }}>
                    {horseDataForCalculation.breed}
                  </p>
                  {horseDataForCalculation.heartGirth ? (
                    <>
                      <Form.Item className="form-item-in-generatePage">
                        {getFieldDecorator('heartGirth', {
                          initialValue: !!horseDataForCalculation.heartGirth,
                          valuePropName: 'checked',
                        })(<Checkbox>Heart Girth {userSettings.uom === 'metric' ? '(cms)' : '(inches)'}: </Checkbox>)}
                      </Form.Item>
                      <p className="details-of-label">
                        {userSettings.uom === 'metric'
                          ? convertInchToCm(horseDataForCalculation.heartGirth).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : horseDataForCalculation.heartGirth.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </p>
                    </>
                  ) : null}

                  {horseDataForCalculation.bodyWeight ? (
                    <>
                      <Form.Item className="form-item-in-generatePage">
                        {getFieldDecorator('bodyWeight', {
                          initialValue: !!horseDataForCalculation.bodyWeight,
                          valuePropName: 'checked',
                        })(<Checkbox>Body Weight {userSettings.uom === 'metric' ? '(kgs)' : '(lbs)'}:</Checkbox>)}
                      </Form.Item>
                      <p className="details-of-label">
                        {userSettings.uom === 'metric'
                          ? convertPoundToKg(horseDataForCalculation.bodyWeight).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : horseDataForCalculation.bodyWeight.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </p>
                    </>
                  ) : null}
                  {horseDataForCalculation.averageDailyGain ? (
                    <>
                      <Form.Item className="form-item-in-generatePage">
                        {getFieldDecorator('averageDailyGain', {
                          initialValue: !!horseDataForCalculation.averageDailyGain,
                          valuePropName: 'checked',
                        })(
                          <Checkbox>
                            Average Daily Gain {userSettings.uom === 'metric' ? '(kgs/day)' : '(lbs/day)'}:{' '}
                          </Checkbox>,
                        )}
                      </Form.Item>
                      <p className="details-of-label">
                        {userSettings.uom === 'metric'
                          ? convertPoundToKg(horseDataForCalculation.averageDailyGain).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : horseDataForCalculation.averageDailyGain.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </p>
                    </>
                  ) : null}
                </Col>
                <Col offset={4} span={8}>
                  {horseDataForCalculation.bodyConditionScore ? (
                    <>
                      <Form.Item className="form-item-in-generatePage">
                        {getFieldDecorator('bodyConditionScore', {
                          initialValue: !!horseDataForCalculation.bodyConditionScore,
                          valuePropName: 'checked',
                        })(<Checkbox>Body Condition Score (1-9): </Checkbox>)}
                      </Form.Item>
                      <p className="details-of-label">{horseDataForCalculation.bodyConditionScore}</p>
                    </>
                  ) : null}
                  {horseDataForCalculation.maintenanceLevel ? (
                    <>
                      <Form.Item className="form-item-in-generatePage">
                        {getFieldDecorator('maintenanceLevel', {
                          initialValue: !!horseDataForCalculation.maintenanceLevel,
                          valuePropName: 'checked',
                        })(<Checkbox>{labelAccordingToLifeStage} : </Checkbox>)}
                      </Form.Item>
                      <p className="details-of-label">
                        {horseDataForCalculation.maintenanceLevel === 'Intense'
                          ? 'Very Heavy'
                          : horseDataForCalculation.maintenanceLevel}
                      </p>
                    </>
                  ) : null}

                  <Form.Item className="form-item-in-generatePage">
                    {getFieldDecorator('dryMatterIntakeLevel', {
                      initialValue: !!horseDataForCalculation.dryMatterIntakeLevel,
                      valuePropName: 'checked',
                    })(<Checkbox>Dry Matter Intake Level (in %): </Checkbox>)}
                  </Form.Item>
                  <p className="details-of-label">{horseDataForCalculation.dryMatterIntakeLevel}</p>
                  {horseDataForCalculation.bodyLength ? (
                    <>
                      <Form.Item className="form-item-in-generatePage">
                        {getFieldDecorator('bodyLength', {
                          initialValue: !!horseDataForCalculation.bodyLength,
                          valuePropName: 'checked',
                        })(<Checkbox>Body Length {userSettings.uom === 'metric' ? '(cms)' : '(inches)'}: </Checkbox>)}
                      </Form.Item>
                      <p className="details-of-label">
                        {userSettings.uom === 'metric'
                          ? convertInchToCm(horseDataForCalculation.bodyLength).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : horseDataForCalculation.bodyLength.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </p>
                    </>
                  ) : null}
                  {horseDataForCalculation.matureBodyWeight ? (
                    <>
                      <Form.Item className="form-item-in-generatePage">
                        {getFieldDecorator('matureBodyWeight', {
                          initialValue: !!horseDataForCalculation.matureBodyWeight,
                          valuePropName: 'checked',
                        })(
                          <Checkbox>Mature Body Weight {userSettings.uom === 'metric' ? '(kgs)' : '(lbs)'}: </Checkbox>,
                        )}
                      </Form.Item>
                      <p className="details-of-label">
                        {userSettings.uom === 'metric'
                          ? convertPoundToKg(horseDataForCalculation.matureBodyWeight).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : horseDataForCalculation.matureBodyWeight.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </p>
                    </>
                  ) : null}
                </Col>
              </Row>
            </div>
          </Form>

          <div>
            <Checkbox
              checked={totalDailyRation}
              onChange={onchangetotalDailyRation}
              style={{ paddingLeft: 7, marginBottom: 10 }}
            >
              <span style={{ fontSize: 18, color: 'black' }}>Ration List</span>
            </Checkbox>
            <hr style={{ marginTop: 1, marginBottom: 20 }} />

            {compareGenerateReport === 'compare' ? (
              <div>
                <Heading headingName="Current Ration" />
                <TotalDailyRation dietDataforTable={feedItemList} hideHeader />
                <Heading headingName="Recommended Ration" />
                <TotalDailyRation dietDataforTable={recommendedItemList} hideHeader />
              </div>
            ) : (
              <TotalDailyRation dietDataforTable={feedItemList} hideHeader />
            )}
            <div>
              <Checkbox
                checked={suppliedGraph}
                onChange={(e) => {
                  setSuppliedGraph(e.target.checked);
                }}
                style={{ paddingLeft: 7, marginBottom: 10 }}
              >
                <span style={{ fontSize: 18, color: 'black' }}>Nutrients supplied as % of daily requirement</span>
              </Checkbox>
              <hr style={{ marginTop: 1, marginBottom: 20 }} />
              {compareGenerateReport === 'compare' ? (
                <GraphOfNutrientSupplied
                  currentRationCalculationsData={currentRationCalculations}
                  recommendedRationCalculationsData={recommendedRationCalculationsData}
                  nutSuppGraphChartType={nutSuppGraphChartType}
                  setNutSuppGraphChartType={setNutSuppGraphChartType}
                  nutSuppGraphNutrients={nutSuppGraphNutrients}
                  setNutSuppGraphNutrients={setNutSuppGraphNutrients}
                  hideHeader
                  compare
                  compareGenReport
                  maximumCap={maximumCap}
                />
              ) : (
                <GraphOfNutrientSupplied
                  currentRationCalculationsData={currentRationCalculations}
                  nutSuppGraphChartType={nutSuppGraphChartType}
                  setNutSuppGraphChartType={setNutSuppGraphChartType}
                  nutSuppGraphNutrients={nutSuppGraphNutrients}
                  setNutSuppGraphNutrients={setNutSuppGraphNutrients}
                  hideHeader
                  maximumCap={maximumCap}
                />
              )}
            </div>
            <div>
              <Checkbox
                checked={acceptableGraph}
                onChange={(e) => {
                  setAcceptableGraph(e.target.checked);
                }}
                style={{ paddingLeft: 7, marginBottom: 10 }}
              >
                <span style={{ fontSize: 18, color: 'black' }}>Acceptable Graph</span>
              </Checkbox>
              <hr style={{ marginTop: 1, marginBottom: 20 }} />
              {compareGenerateReport === 'compare' ? (
                <AcceptableRangeGraph
                  currentRationCalculationsData={currentRationCalculations}
                  recommendedRationCalculationsData={recommendedRationCalculationsData}
                  acceptableDeltaLow={acceptableDeltaLow}
                  acceptableDeltaHigh={acceptableDeltaHigh}
                  targetDeltaLow={targetDeltaLow}
                  targetDeltaHigh={targetDeltaHigh}
                  setAcceptableDeltaLow={setAcceptableDeltaLow}
                  setAcceptableDeltaHigh={setAcceptableDeltaHigh}
                  setTargetDeltaLow={setTargetDeltaLow}
                  setTargetDeltaHigh={setTargetDeltaHigh}
                  accRangeGraphNutrients={accRangeGraphNutrients}
                  setAccRangeGraphNutrients={setAccRangeGraphNutrients}
                  compare
                  compareGenReport
                  hideHeader
                />
              ) : (
                <AcceptableRangeGraph
                  currentRationCalculationsData={currentRationCalculations}
                  acceptableDeltaLow={acceptableDeltaLow}
                  acceptableDeltaHigh={acceptableDeltaHigh}
                  targetDeltaLow={targetDeltaLow}
                  targetDeltaHigh={targetDeltaHigh}
                  setAcceptableDeltaLow={setAcceptableDeltaLow}
                  setAcceptableDeltaHigh={setAcceptableDeltaHigh}
                  setTargetDeltaLow={setTargetDeltaLow}
                  setTargetDeltaHigh={setTargetDeltaHigh}
                  accRangeGraphNutrients={accRangeGraphNutrients}
                  setAccRangeGraphNutrients={setAccRangeGraphNutrients}
                  hideHeader
                />
              )}
            </div>
            <div style={{ marginBottom: 20 }}>
              <Checkbox
                checked={nutrientsTable}
                onChange={(e) => {
                  setNutrientsTable(e.target.checked);
                }}
                style={{ paddingLeft: 7, marginBottom: 10 }}
              >
                <span style={{ fontSize: 18, color: 'black' }}>Daily Nutrients Supplied in Total Ration</span>
              </Checkbox>
              <hr style={{ marginTop: 1, marginBottom: 20 }} />
              {compareGenerateReport === 'compare' ? (
                <NutrientsSuppliedTable
                  nutrientKeys={['percentDM', ...nutSuppGraphNutrients]}
                  currentRationCalculationsData={currentRationCalculations}
                  recommendedRationCalculationsData={recommendedRationCalculationsData}
                  hideHeader
                  comments={comments}
                  setComments={setComments}
                  totalAmount={totalAmount}
                  totalAmountRecommend={totalAmountRecommend}
                  compare
                  compareGenReport
                  ratiosTable={ratiosTable}
                  setRatiosTable={setRatiosTable}
                  recommendedComments={recommendedComments}
                  setRecommendedComments={setRecommendedComments}
                  showratioTableCheck
                />
              ) : (
                <NutrientsSuppliedTable
                  nutrientKeys={['percentDM', ...nutSuppGraphNutrients]}
                  currentRationCalculationsData={currentRationCalculations}
                  hideHeader
                  comments={comments}
                  setComments={setComments}
                  totalAmount={totalAmount}
                  ratiosTable={ratiosTable}
                  setRatiosTable={setRatiosTable}
                  showratioTableCheck
                />
              )}
            </div>
            <div>
              <Checkbox
                checked={generalComment}
                onChange={(e) => {
                  setGeneralComment(e.target.checked);
                }}
                style={{ paddingLeft: 7, marginBottom: 10 }}
              >
                <span style={{ fontSize: 18, color: 'black' }}>General Comments</span>
              </Checkbox>
              <hr style={{ marginTop: 1, marginBottom: 20 }} />
              <TextArea
                style={{ height: 140 }}
                placeholder="Please enter your comments here"
                rows={12}
                onChange={handleTextArea}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

GenerateReportPage.propTypes = {
  horseDataForCalculation: PropTypes.object.isRequired,
  onClickGoBack: PropTypes.func.isRequired,
  feedItemList: PropTypes.array.isRequired,
  targetDeltaLow: PropTypes.object.isRequired,
  targetDeltaHigh: PropTypes.object.isRequired,
  acceptableDeltaLow: PropTypes.object.isRequired,
  acceptableDeltaHigh: PropTypes.object.isRequired,
  selectedFarmData: PropTypes.object.isRequired,
  setTargetDeltaLow: PropTypes.func.isRequired,
  setTargetDeltaHigh: PropTypes.func.isRequired,
  setAcceptableDeltaLow: PropTypes.func.isRequired,
  setAcceptableDeltaHigh: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  accRangeGraphNutrients: PropTypes.array.isRequired,
  setAccRangeGraphNutrients: PropTypes.func.isRequired,
  nutSuppGraphChartType: PropTypes.string.isRequired,
  setNutSuppGraphChartType: PropTypes.func.isRequired,
  nutSuppGraphNutrients: PropTypes.array.isRequired,
  setNutSuppGraphNutrients: PropTypes.func.isRequired,
  maximumCap: PropTypes.number,
  totalAmount: PropTypes.number.isRequired,
  recommendedItemList: PropTypes.array,
  totalAmountRecommend: PropTypes.number,
  compareGenerateReport: PropTypes.string,
};

GenerateReportPage.defaultProps = {
  recommendedItemList: [],
  totalAmountRecommend: null,
  compareGenerateReport: '',
  maximumCap: null,
};

export default Form.create({ name: 'GenerateReportPage' })(GenerateReportPage);
