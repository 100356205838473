import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import Root from './Root';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: 'https://f710aed82ff19b88074ec9951535a804@o1204593.ingest.sentry.io/4506704472309760',
  integrations: [],
  environment: process.env.REACT_APP_ENVIRONMENT || 'development',
  release: process.env.REACT_APP_ENVIRONMENT || 'development',
});

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
