import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import '../pages/History/history.css';
import Heading from './Heading';
import useAuth from '../utils/auth';

const HorseInformationPage = (props) => {
  const { horseInfo } = props;
  const { userSettings, convertInchToCm, convertPoundToKg } = useAuth();

  let labelAccordingToLifeStage = 'Maintenance Level';
  if (horseInfo && horseInfo.lifeStage === 'Adult Maintenance') {
    labelAccordingToLifeStage = 'Maintenance Level';
  }
  if (horseInfo && horseInfo.lifeStage === 'Working/Training') {
    labelAccordingToLifeStage = 'Work Load';
  }
  if (horseInfo && horseInfo.lifeStage === 'Pregnant') {
    labelAccordingToLifeStage = 'Gestation Month';
  }
  if (horseInfo && horseInfo.lifeStage === 'Lactating') {
    labelAccordingToLifeStage = 'Lactation Month';
  }
  if (horseInfo && horseInfo.lifeStage === 'Stallion') {
    labelAccordingToLifeStage = 'Breeding/Non-Breeding';
  }
  if (horseInfo && horseInfo.lifeStage === 'Growing') {
    labelAccordingToLifeStage = 'Work Level';
  }

  return (
    <div style={{ marginTop: 20, marginBottom: 20 }} className="container">
      <Heading headingName="Physical Information" />
      <Row>
        <Col span={12}>
          {horseInfo.age ? (
            <Row>
              <Col span={12} className="eachCol labelCol">
                Age:
              </Col>
              {horseInfo.lifeStage === 'Growing' ? (
                <Col span={12} className="eachCol">
                  {horseInfo.age <= 24
                    ? `${horseInfo.age} months`
                    : `${Math.floor(horseInfo.age / 12)} years ${horseInfo.age % 12} months `}
                </Col>
              ) : (
                <Col span={12} className="eachCol">
                  {`${horseInfo.age > 12 ? Math.floor(horseInfo.age / 12) : 1} years `}
                </Col>
              )}
            </Row>
          ) : null}

          <Row>
            <Col span={12} className="eachCol labelCol">
              Life Stage:
            </Col>
            <Col span={12} className="eachCol">
              {horseInfo.lifeStage}
            </Col>
          </Row>

          <Row>
            <Col span={12} className="eachCol labelCol">
              Breed:
            </Col>
            <Col span={12} className="eachCol">
              <div style={{ textTransform: 'capitalize' }}>{horseInfo.breed}</div>
            </Col>
          </Row>
          {horseInfo.heartGirth ? (
            <Row>
              <Col span={12} className="eachCol labelCol">
                {userSettings.uom === 'metric' ? <> Hearth Girth (cms):</> : <> Hearth Girth (inches):</>}
              </Col>
              <Col span={12} className="eachCol">
                {convertInchToCm(horseInfo.heartGirth).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Col>
            </Row>
          ) : null}
          {horseInfo.bodyWeight ? (
            <Row>
              <Col span={12} className="eachCol labelCol">
                {userSettings.uom === 'metric' ? <> Body Weight (kgs):</> : <> Body Weight (lbs):</>}
              </Col>
              <Col span={12} className="eachCol">
                {convertPoundToKg(horseInfo.bodyWeight).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Col>
            </Row>
          ) : null}
          {horseInfo.averageDailyGain ? (
            <Row>
              <Col span={12} className="eachCol labelCol">
                {userSettings.uom === 'metric' ? (
                  <> Average Daily Gain (kgs/day):</>
                ) : (
                  <> Average Daily Gain (lbs/day):</>
                )}
              </Col>
              <Col span={12} className="eachCol">
                {convertPoundToKg(horseInfo.averageDailyGain).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Col>
            </Row>
          ) : null}
        </Col>

        <Col span={12}>
          {horseInfo.bodyConditionScore ? (
            <Row>
              <Col span={12} className="eachCol labelCol">
                Body Condition Score (1-9):
              </Col>
              <Col span={12} className="eachCol">
                {horseInfo.bodyConditionScore}
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col span={12} className="eachCol labelCol">
              {labelAccordingToLifeStage}:
            </Col>
            <Col span={12} className="eachCol">
              {horseInfo.maintenanceLevel === 'Intense' ? 'Very Heavy' : horseInfo.maintenanceLevel}
            </Col>
          </Row>
          {horseInfo.dryMatterIntakeLevel ? (
            <Row>
              <Col span={12} className="eachCol labelCol">
                DM Intake Level (in %):
              </Col>
              <Col span={12} className="eachCol">
                {horseInfo.dryMatterIntakeLevel}
              </Col>
            </Row>
          ) : null}

          {horseInfo.bodyLength ? (
            <Row>
              <Col span={12} className="eachCol labelCol">
                {userSettings.uom === 'metric' ? <> Body Length (cms):</> : <> Body Length (inches):</>}
              </Col>
              <Col span={12} className="eachCol">
                {convertInchToCm(horseInfo.bodyLength).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Col>
            </Row>
          ) : null}
          {horseInfo.matureBodyWeight ? (
            <Row>
              <Col span={12} className="eachCol labelCol">
                {userSettings.uom === 'metric' ? <> Mature Body Weight (kgs):</> : <> Mature Body Weight (lbs):</>}
              </Col>
              <Col span={12} className="eachCol">
                {convertPoundToKg(horseInfo.matureBodyWeight).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

HorseInformationPage.propTypes = {
  horseInfo: PropTypes.object.isRequired,
};

export default HorseInformationPage;
