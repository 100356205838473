import React from 'react';
import { Form, Input, Button } from 'antd';
import PropTypes from 'prop-types';

const DietNameSaveForm = (props) => {
  const { form, handleDietNameSave, saveButtonLoader, setRecommendedDietModal, nameToEdit, rename } = props;

  const handleDietNameSaveInForm = () => {
    form.validateFields((err, values) => {
      if (!err) {
        handleDietNameSave(values);
      }
      return null;
    });
  };
  return (
    <Form>
      <Form.Item label="Diet Name">
        {form.getFieldDecorator('dietName', {
          initialValue: nameToEdit,
          rules: [{ required: true, message: 'Please enter Diet Name and try again.' }],
        })(<Input placeholder="Enter Diet Name" />)}
      </Form.Item>
      <Form.Item style={{ marginLeft: 90 }}>
        <Button
          className="button-color"
          loading={saveButtonLoader}
          htmlType="submit"
          onClick={handleDietNameSaveInForm}
        >
          {rename ? 'Update' : 'Save'}
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          onClick={() => {
            form.resetFields();
            setRecommendedDietModal(false);
          }}
        >
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

DietNameSaveForm.propTypes = {
  handleDietNameSave: PropTypes.func.isRequired,
  saveButtonLoader: PropTypes.bool.isRequired,
  setRecommendedDietModal: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
  }).isRequired,
  nameToEdit: PropTypes.string,
  rename: PropTypes.bool,
};

DietNameSaveForm.defaultProps = {
  nameToEdit: '',
  rename: false,
};

export default Form.create({ name: 'DietNameSaveForm' })(DietNameSaveForm);
