import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import HorseForm from '../forms/HorseForm';
import LoadingPage from './LoadingPage';
import { firebaseDb, firebaseStorage } from '../utils/firebase';
import logger from '../utils/helpers/logger';

function EditHorsePage(props) {
  const { match } = props;
  const currentFarmId = match.params.farmid;
  const currentHorseId = match.params.horseid;
  const [photosToEdit, setPhotosToEdit] = useState([]);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    firebaseDb
      .collection('Farm')
      .doc(currentFarmId)
      .collection('horses')
      .doc(currentHorseId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setItemToEdit(doc.data());
          const docData = doc.data();
          const docPhotos = docData.photos;
          const promiseArr = [];
          const storageRef = firebaseStorage.ref();
          docPhotos.forEach((item, index) => {
            promiseArr.push(
              storageRef
                .child(item.storageRefPath)
                .getDownloadURL()
                .then((url) => ({ id: index - 1000, ...item, localImgUrl: url }))
                .catch(() => null),
            );
          });
          Promise.all(promiseArr).then((results) => {
            setPhotosToEdit(results.filter(Boolean));
            setIsDataLoading(false);
          });
        } else {
          message.error('No such document!');
        }
      })
      .catch((error) => {
        message.error(error.message);
        logger(error);
      });
  }, []);

  if (isDataLoading) {
    return <LoadingPage />;
  }
  return (
    <div style={{ padding: 35 }}>
      <HorseForm
        mode="edit"
        photosToEdit={photosToEdit}
        itemToEdit={itemToEdit}
        currentHorseId={currentHorseId}
        currentFarmId={currentFarmId}
      />
    </div>
  );
}

EditHorsePage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default EditHorsePage;
