import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import RationAnalyzerPage from './pages/RationAnalyzerPage';
import FeedLayoutPage from './pages/FeedLayoutPage';
import Header from './components/Header';
import UserLayoutPage from './pages/UserLayoutPage';
import SettingsPage from './pages/SettingsPage';
import FarmLayoutPage from './pages/FarmLayoutPage';
import HorseLayoutPage from './pages/HorseLayoutPage';
import HorseGrowthDietHistory from './pages/History/HorseGrowthDietHistoryPage';
import NotificationsPage from './pages/NotificationsPage';
import DevPage from './pages/DevPage';
import useAuth from './utils/auth';

const Routes = (props) => {
  const { location } = props;
  const { userRole } = useAuth();
  return (
    <>
      {location.pathname === '/' || location.pathname === '/updatePassword' ? null : <Header />}
      <Switch>
        <Route exact path="/" component={RationAnalyzerPage} />
        <Route exact path="/rationAnalyzer" component={RationAnalyzerPage} />
        <Route exact path="/history/horseGrowth/dietHistory" component={HorseGrowthDietHistory} />
        <Route path="/devpage" component={DevPage} />
        <Route path="/feeds" component={FeedLayoutPage} />
        {userRole === 'admin' ? <Route path="/users" component={UserLayoutPage} /> : null}
        <Route path="/settings" component={SettingsPage} />
        <Route path="/farms" component={FarmLayoutPage} />
        <Route path="/horses" component={HorseLayoutPage} />
        <Route path="/notifications" component={NotificationsPage} />
      </Switch>
    </>
  );
};

Routes.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Routes);
