import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Table, Popconfirm, Icon, Modal } from 'antd';
import moment from 'moment';
import TotalDailyRation from '../../components/TotalDailyRationPage';
import PhysicalInformationPage from '../../components/PhysicalInformation';
import NutrientsSuppliedTable from '../../components/NutrientsSuppliedTable';
import './history.css';
import '../../components/ButtonColor.scss';
import rationCalculations from '../../utils/rationCalculations';
import GeneratInformation from '../../components/GeneralInformation';
import Heading from '../../components/Heading';
import GraphOfNutrientSupplied from '../../components/GraphOfNutrientSupplied';
import AcceptableRangeGraph from '../../components/AcceptableRangeGraph';
import DietNameSaveForm from '../../forms/DietNameSaveForm';
import useAuth from '../../utils/auth';

const { Column } = Table;

const CompareDietHistoryPage = (props) => {
  const {
    compareDietOfHorse,
    farmInfo,
    horseInfo,
    onClickDeleteButtonOfCompare,
    currentHorseId,
    recordOfSelectedDiet,
    selectedOneDietOfHorse,
    nutSuppGraphChartType,
    setNutSuppGraphChartType,
    nutrientKeysToShow,
    setNutrientKeysToShow,
    acceptableDeltaLow,
    acceptableDeltaHigh,
    targetDeltaLow,
    targetDeltaHigh,
    setAcceptableDeltaLow,
    setAcceptableDeltaHigh,
    setTargetDeltaLow,
    setTargetDeltaHigh,
    handleSaveOfUpdateDietName,
    maximumCap,
    setMaximumCap,
    viewId,
  } = props;
  const [deleteDietIdCompare, setDeleteDietIdCompare] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [saveButtonLoader, setSaveButtonLoader] = useState(false);
  const [recordRow, setRecordRow] = useState({});
  const [showComparision, setShowComparision] = useState('suppliedGraph');
  const { userSettings, convertPoundToKg, convertOzToLbs, convertOzToGram } = useAuth();

  let currentRationCalculationsData = null;
  if (recordOfSelectedDiet) {
    currentRationCalculationsData = rationCalculations({
      rationList: recordOfSelectedDiet.currentRationData,
      horseData: recordOfSelectedDiet.horseData,
      targetDeltaLow,
      targetDeltaHigh,
      acceptableDeltaLow,
      acceptableDeltaHigh,
    });
  }

  let recommendedRationCalculationsData = null;
  if (recordOfSelectedDiet) {
    recommendedRationCalculationsData = rationCalculations({
      rationList: recordOfSelectedDiet.recommendedRationData,
      horseData: recordOfSelectedDiet.horseData,
      targetDeltaLow,
      targetDeltaHigh,
      acceptableDeltaLow,
      acceptableDeltaHigh,
    });
  }

  const renderHeadingInCompareMode = () => {
    if (showComparision === 'suppliedGraph') {
      return 'Nutrients supplied as % of daily requirement';
    }
    if (showComparision === 'acceptableGraph') {
      return 'Acceptable Ranges';
    }
    return 'Daily Nutrients Supplied in Total Ration';
  };

  const handleLeftArrow = () => {
    if (showComparision === 'suppliedGraph') {
      setShowComparision('table');
    } else if (showComparision === 'acceptableGraph') {
      setShowComparision('suppliedGraph');
    } else {
      setShowComparision('acceptableGraph');
    }
  };

  const handleRightArrow = () => {
    if (showComparision === 'suppliedGraph') {
      setShowComparision('acceptableGraph');
    } else if (showComparision === 'acceptableGraph') {
      setShowComparision('table');
    } else {
      setShowComparision('suppliedGraph');
    }
  };

  const renderAmountValueInTable = (record) => {
    if (userSettings.uom === 'metric') {
      if (record.unit && record.unit === 'lbs/day') {
        return `${convertPoundToKg(record.amount).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} kgs`;
      }
      if (record.unit && record.unit === 'oz/day') {
        return `${convertOzToGram(record.amount).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} grams`;
      }
      return null;
    }
    return `${
      record.amount
        ? record.amount.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : null
    } ${record.unit === 'lbs/day' ? 'lbs' : 'oz'}`;
  };

  let totalAmount = 0;
  if (recordOfSelectedDiet) {
    recordOfSelectedDiet.currentRationData.forEach((item) => {
      if (item.unit === 'oz/day') {
        totalAmount += convertOzToLbs(item.amount);
      } else {
        totalAmount += item.amount;
      }
    });
  }

  let totalAmountRecommend = 0;
  if (recordOfSelectedDiet) {
    recordOfSelectedDiet.recommendedRationData.forEach((item) => {
      if (item.unit === 'oz/day') {
        totalAmountRecommend += convertOzToLbs(item.amount);
      } else {
        totalAmountRecommend += item.amount;
      }
    });
  }

  const handleDietNameSave = (value) => {
    handleSaveOfUpdateDietName(value, recordRow, setSaveButtonLoader, setOpenSaveModal, 'CompareDiet');
  };

  const handleDeleteButton = (record) => {
    onClickDeleteButtonOfCompare(record, setDeleteDietIdCompare);
  };

  return (
    <div style={{ margin: 35 }}>
      <Modal footer={false} visible={openSaveModal} onCancel={() => setOpenSaveModal(false)}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ textAlign: 'center', fontSize: 18, marginBottom: 20 }}>Rename Diet</div>
          {openSaveModal && (
            <DietNameSaveForm
              handleDietNameSave={handleDietNameSave}
              setRecommendedDietModal={setOpenSaveModal}
              saveButtonLoader={saveButtonLoader}
              nameToEdit={recordRow.name}
              rename
            />
          )}
        </div>
      </Modal>
      <GeneratInformation farmInfo={farmInfo} horseInfo={horseInfo} />
      {compareDietOfHorse && compareDietOfHorse.length !== 0 && currentHorseId ? (
        <div>
          <Heading headingName="Compared Diets List" />
          <Table
            rowClassName={(record) => {
              if (recordOfSelectedDiet && record.id === recordOfSelectedDiet.id) {
                return 'highlighted-row';
              }
              return null;
            }}
            style={{ margin: 20, width: '50%' }}
            size="small"
            dataSource={compareDietOfHorse}
            bordered
            pagination={{ pageSize: 5 }}
            rowKey="id"
            onRow={(record) => {
              return {
                onClick: () => {
                  selectedOneDietOfHorse(record, 'row');
                },
              };
            }}
          >
            <Column
              title="Diet Name"
              dataIndex="name"
              key="name"
              render={(text, record) => {
                return <a>{record.name}</a>;
              }}
            />
            <Column
              title="Analysis Date"
              dataIndex="createdAt"
              key="createdAt"
              render={(text, record) => {
                return <div>{moment(record.createdAt.seconds * 1000).format('L')}</div>;
              }}
            />
            <Column
              title="Action"
              dataIndex="action"
              key="action"
              align="center"
              render={(text, record) => {
                return (
                  <div>
                    <Button
                      className="button-color"
                      onClick={() => {
                        selectedOneDietOfHorse(record, 'viewButton');
                      }}
                      style={{ marginRight: 10 }}
                      loading={record.id === viewId}
                    >
                      View
                    </Button>
                    <Popconfirm
                      title="Delete Compare Diet. Are you sure?"
                      onConfirm={() => {
                        setDeleteDietIdCompare(record.id);
                        handleDeleteButton(record);
                      }}
                      okText="Yes"
                      icon={<Icon type="warning" style={{ color: '#CE0E2D' }} />}
                      cancelText="No"
                    >
                      <Button className="button-delete" loading={record.id === deleteDietIdCompare}>
                        Delete
                      </Button>
                    </Popconfirm>
                    <Button
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        setOpenSaveModal(true);
                        setRecordRow(record);
                      }}
                    >
                      Rename
                    </Button>
                  </div>
                );
              }}
            />
          </Table>
          <div>
            {compareDietOfHorse && compareDietOfHorse.length !== 0 && currentHorseId && recordOfSelectedDiet ? (
              <div>
                <PhysicalInformationPage farmInfo={farmInfo} horseInfo={recordOfSelectedDiet.horseData} />
                <Heading headingName="Ration List" />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 40,
                    marginBottom: 50,
                    marginLeft: 100,
                    marginRight: 100,
                  }}
                >
                  <div>
                    <div className="added-feed-item-list">Current Ration List</div>
                    <hr style={{ marginTop: 1, marginBottom: 20 }} />
                    <Table
                      size="small"
                      dataSource={[
                        ...recordOfSelectedDiet.currentRationData,
                        {
                          totalAmountKey: 'Total Amount',
                          totalAmountValue: `${
                            userSettings.uom === 'metric'
                              ? `${convertPoundToKg(totalAmount).toFixed(2)} `
                              : `${totalAmount.toFixed(2)} `
                          }`,
                        },
                      ]}
                      bordered
                      pagination={false}
                      rowKey="id"
                    >
                      <Column
                        title="Item Type"
                        dataIndex="type"
                        key="type"
                        render={(text, row, index) => {
                          if (index < recordOfSelectedDiet.currentRationData.length) {
                            return <div style={{ textTransform: 'capitalize' }}>{row.type}</div>;
                          }
                          return {
                            props: {
                              colSpan: 2,
                            },
                          };
                        }}
                      />
                      <Column
                        title="Code"
                        dataIndex="code"
                        key="code"
                        render={(text, row, index) => {
                          if (index < recordOfSelectedDiet.currentRationData.length) {
                            return row.code ? row.code : <div>-</div>;
                          }
                          return {
                            children: (
                              <div style={{ fontWeight: 'bold', fontSize: 15, textAlign: 'right' }}>
                                {row.totalAmountKey}
                                {userSettings.uom === 'metric' ? ' (kgs)' : ' (lbs)'}:
                              </div>
                            ),
                          };
                        }}
                      />
                      <Column
                        title="Item Name"
                        dataIndex="name"
                        key="name"
                        render={(value, row, index) => {
                          if (index < recordOfSelectedDiet.currentRationData.length) {
                            return row.name;
                          }
                          return {
                            children: (
                              <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 15 }}>
                                {row.totalAmountValue}
                              </div>
                            ),
                          };
                        }}
                      />
                      <Column
                        title={
                          <div>
                            <div style={{ marginBottom: 5 }}>Amount</div>
                            <div>(per day)</div>
                          </div>
                        }
                        dataIndex="amount"
                        key="amount"
                        align="center"
                        render={(value, row, index) => {
                          const obj = {
                            children: renderAmountValueInTable(row),
                            props: {},
                          };
                          if (index === recordOfSelectedDiet.currentRationData.length) {
                            obj.props.colSpan = 0;
                          }
                          return obj;
                        }}
                      />
                    </Table>
                  </div>
                  <div>
                    <div className="added-feed-item-list">Recommendation Ration List</div>
                    <hr style={{ marginTop: 1, marginBottom: 20 }} />
                    <Table
                      size="small"
                      dataSource={[
                        ...recordOfSelectedDiet.recommendedRationData,
                        {
                          totalAmountKey: 'Total Amount',
                          totalAmountValue: `${
                            userSettings.uom === 'metric'
                              ? `${convertPoundToKg(totalAmountRecommend).toFixed(2)} `
                              : `${totalAmountRecommend.toFixed(2)} `
                          }`,
                        },
                      ]}
                      bordered
                      pagination={false}
                      rowKey="id"
                    >
                      <Column
                        title="Item Type"
                        dataIndex="type"
                        key="type"
                        render={(text, row, index) => {
                          if (index < recordOfSelectedDiet.recommendedRationData.length) {
                            return <div style={{ textTransform: 'capitalize' }}>{row.type}</div>;
                          }
                          return {
                            props: {
                              colSpan: 2,
                            },
                          };
                        }}
                      />
                      <Column
                        title="Code"
                        dataIndex="code"
                        key="code"
                        render={(text, row, index) => {
                          if (index < recordOfSelectedDiet.recommendedRationData.length) {
                            return row.code ? row.code : <div>-</div>;
                          }
                          return {
                            children: (
                              <div style={{ fontWeight: 'bold', fontSize: 15, textAlign: 'right' }}>
                                {row.totalAmountKey}
                                {userSettings.uom === 'metric' ? ' (kgs)' : ' (lbs)'}:
                              </div>
                            ),
                          };
                        }}
                      />
                      <Column
                        title="Item Name"
                        dataIndex="name"
                        key="name"
                        render={(value, row, index) => {
                          if (index < recordOfSelectedDiet.recommendedRationData.length) {
                            return row.name;
                          }
                          return {
                            children: (
                              <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 15 }}>
                                {row.totalAmountValue}
                              </div>
                            ),
                          };
                        }}
                      />
                      <Column
                        title={
                          <div>
                            <div style={{ marginBottom: 5 }}>Amount</div>
                            <div>(per day)</div>
                          </div>
                        }
                        dataIndex="amount"
                        key="amount"
                        align="center"
                        render={(value, row, index) => {
                          const obj = {
                            children: renderAmountValueInTable(row),
                            props: {},
                          };
                          if (index === recordOfSelectedDiet.recommendedRationData.length) {
                            obj.props.colSpan = 0;
                          }
                          return obj;
                        }}
                      />
                    </Table>
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button onClick={handleLeftArrow} icon="caret-left" />
                  <div style={{ width: 500, textAlign: 'center', paddingTop: 5, fontSize: 18 }}>
                    {renderHeadingInCompareMode()}
                  </div>
                  <Button onClick={handleRightArrow} icon="caret-right" />
                </div>
                {/* Nutrient supplied graph */}
                {showComparision === 'suppliedGraph' ? (
                  <GraphOfNutrientSupplied
                    nutSuppGraphChartType={nutSuppGraphChartType}
                    setNutSuppGraphChartType={setNutSuppGraphChartType}
                    nutSuppGraphNutrients={nutrientKeysToShow}
                    setNutSuppGraphNutrients={setNutrientKeysToShow}
                    currentRationCalculationsData={currentRationCalculationsData}
                    recommendedRationCalculationsData={recommendedRationCalculationsData}
                    compare
                    hideHeader
                    maximumCap={maximumCap}
                    setMaximumCap={setMaximumCap}
                  />
                ) : null}
                {/* Acceptable ranges graph */}
                {showComparision === 'acceptableGraph' ? (
                  <AcceptableRangeGraph
                    currentRationCalculationsData={currentRationCalculationsData}
                    recommendedRationCalculationsData={recommendedRationCalculationsData}
                    acceptableDeltaLow={acceptableDeltaLow}
                    acceptableDeltaHigh={acceptableDeltaHigh}
                    targetDeltaLow={targetDeltaLow}
                    targetDeltaHigh={targetDeltaHigh}
                    setAcceptableDeltaLow={setAcceptableDeltaLow}
                    setAcceptableDeltaHigh={setAcceptableDeltaHigh}
                    setTargetDeltaLow={setTargetDeltaLow}
                    setTargetDeltaHigh={setTargetDeltaHigh}
                    compare
                    accRangeGraphNutrients={nutrientKeysToShow}
                    setAccRangeGraphNutrients={setNutrientKeysToShow}
                    hideHeader
                  />
                ) : null}

                {/* Nutrients supplied table */}
                {showComparision === 'table' ? (
                  <NutrientsSuppliedTable
                    currentRationCalculationsData={currentRationCalculationsData}
                    recommendedRationCalculationsData={recommendedRationCalculationsData}
                    compare
                    hideHeader
                    nutrientKeys={['percentDM', ...nutrientKeysToShow]}
                    setNutrientKeys={setNutrientKeysToShow}
                    totalAmount={totalAmount}
                    totalAmountRecommend={totalAmountRecommend}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

CompareDietHistoryPage.propTypes = {
  onClickDeleteButtonOfCompare: PropTypes.func.isRequired,
  compareDietOfHorse: PropTypes.array,
  farmInfo: PropTypes.object.isRequired,
  horseInfo: PropTypes.object.isRequired,
  currentHorseId: PropTypes.string.isRequired,
  recordOfSelectedDiet: PropTypes.object,
  selectedOneDietOfHorse: PropTypes.func.isRequired,
  nutSuppGraphChartType: PropTypes.string.isRequired,
  setNutSuppGraphChartType: PropTypes.func.isRequired,
  nutrientKeysToShow: PropTypes.array.isRequired,
  setNutrientKeysToShow: PropTypes.func.isRequired,
  targetDeltaLow: PropTypes.object.isRequired,
  targetDeltaHigh: PropTypes.object.isRequired,
  setTargetDeltaLow: PropTypes.func.isRequired,
  setTargetDeltaHigh: PropTypes.func.isRequired,
  acceptableDeltaLow: PropTypes.object.isRequired,
  acceptableDeltaHigh: PropTypes.object.isRequired,
  setAcceptableDeltaLow: PropTypes.func.isRequired,
  setAcceptableDeltaHigh: PropTypes.func.isRequired,
  handleSaveOfUpdateDietName: PropTypes.func.isRequired,
  maximumCap: PropTypes.number.isRequired,
  setMaximumCap: PropTypes.func.isRequired,
  viewId: PropTypes.string.isRequired,
};

CompareDietHistoryPage.defaultProps = {
  compareDietOfHorse: null,
  recordOfSelectedDiet: null,
};

export default CompareDietHistoryPage;
