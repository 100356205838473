import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Form,
  DatePicker,
  Input,
  Select,
  Row,
  Col,
  Divider,
  message,
  Button,
  InputNumber,
  Radio,
  Upload,
  Icon,
  Modal,
} from 'antd';
import { withRouter } from 'react-router-dom';
import Heading from '../components/Heading';
import './HorseForm.scss';
import { firebaseDb, firebaseStorage } from '../utils/firebase';
import FarmForm from './FarmForm';
import useAuth from '../utils/auth';
import { dateFormat } from '../utils/globals';
import '../components/ButtonColor.scss';
import FarmDataInfo from '../components/FarmDataInfo';
import logger from '../utils/helpers/logger';

const { Option } = Select;

const maintenanceLevelOption = {
  'Adult Maintenance': ['Low Metabolism', 'Average Metabolism', 'High Metabolism'],
  Stallion: ['Breeding', 'Non-Breeding'],
  'Working/Training': ['Light', 'Moderate', 'Heavy', 'Very Heavy'],
  Lactating: ['Month 1', 'Month 2', 'Month 3', 'Month 4', 'Month 5', 'Month 6'],
  Growing: ['None', 'Light', 'Moderate', 'Heavy', 'Very Heavy'],
  Pregnant: [
    'Early gestation (months 1-4)',
    '5th Month',
    '6th Month',
    '7th Month',
    '8th Month',
    '9th Month',
    '10th Month',
    '11th Month',
  ],
};

const allFields = [
  'bodyWeight',
  'breed',
  'dryMatterIntakeLevel',
  'horseName',
  'lifeStage',
  'maintenanceLevel',
  'matureBodyWeight',
  'name',
  'age',
  'bodyLength',
  'heartGirth',
];

const HorseForm = (props) => {
  const {
    form,
    history,
    mode,
    itemToEdit,
    photosToEdit,
    currentFarmId,
    currentHorseId,
    onClickNextOfHorseForm,
    addHorseModalCancel,
    farmId,
  } = props;

  const valueBodyWeight = () => {
    if (itemToEdit && itemToEdit.heartGirth && itemToEdit.bodyLength) {
      return 2;
    }
    if (itemToEdit && itemToEdit.matureBodyWeight) {
      return 3;
    }
    return 1;
  };

  const { getFieldDecorator, getFieldValue, resetFields, setFields, getFieldsValue, setFieldsValue } = form;
  const [lifeStageData, setLifeStageData] = useState(null);
  const [bodyWeightRadio, setBodyWeightRadio] = useState(itemToEdit ? Number(valueBodyWeight()) : 1);
  const [photosCount, setPhotosCount] = useState(0);
  const [photos, setPhotos] = useState([]);
  const [farmData, setFarmsData] = useState([]);
  const [visibleFarmModal, setVisibleFarmModal] = useState(false);
  const [addHorseLoader, setAddHorseLoader] = useState(false);
  const [updateHorseLoader, setUpdateHorseLoader] = useState(false);
  const [bodyW, setBodyW] = useState(0);

  const { user, userSettings, convertKgToPound, convertCmToInch, convertPoundToKg, convertInchToCm } = useAuth();
  useEffect(() => {
    firebaseDb.collection('Farm').onSnapshot((querySnapshot) => {
      const farm = [];
      querySnapshot.forEach((doc) => {
        if (user.uid === doc.data().createdBy) {
          farm.push({ id: doc.id, ...doc.data() });
        }
      });
      setFarmsData(farm);
    });
    // if (itemToEdit && itemToEdit.heartGirth && itemToEdit.bodyLength) {
    //   console.log('item-----', itemToEdit.bodyWeight);
    //   setBodyW(userSettings.uom === 'metric' ? convertPoundToKg(itemToEdit.bodyWeight) : itemToEdit.bodyWeight);
    //   setFieldsValue({
    //     bodyWeight: userSettings.uom === 'metric' ? convertPoundToKg(itemToEdit.bodyWeight) : itemToEdit.bodyWeight,
    //   });
    // } else {
    //   setBodyW(0);
    // }
  }, []);

  useEffect(() => {
    if (mode === 'edit' || mode === 'rationEdit') {
      setLifeStageData(itemToEdit.lifeStage);
    }
    setPhotos(
      photosToEdit
        ? photosToEdit.map((entry, index) => {
            return {
              id: index,
              ...entry,
            };
          })
        : [],
    );
    setPhotosCount(photosToEdit && photosToEdit.length + 1);
  }, [photosToEdit]);

  let labelAccordingToLifeStage = 'Maintenance Level';
  if (getFieldValue('lifeStage') === 'Adult Maintenance') {
    labelAccordingToLifeStage = 'Maintenance Level';
  }
  if (getFieldValue('lifeStage') === 'Working/Training') {
    labelAccordingToLifeStage = 'Work Load';
  }
  if (getFieldValue('lifeStage') === 'Pregnant') {
    labelAccordingToLifeStage = 'Gestation Month';
  }
  if (getFieldValue('lifeStage') === 'Lactating') {
    labelAccordingToLifeStage = 'Lactation Month';
  }
  if (getFieldValue('lifeStage') === 'Stallion') {
    labelAccordingToLifeStage = 'Breeding/Non-Breeding';
  }
  if (getFieldValue('lifeStage') === 'Growing') {
    labelAccordingToLifeStage = 'Work Level';
  }

  const requiredErrorMessages = {
    bodyWeight: 'Please enter Body Weight and try again',
    breed: 'Please select Breed and try again',
    dryMatterIntakeLevel: 'Please enter Dry Matter Intake Level and try again',
    horseName: 'Please enter Horse Name and try again',
    lifeStage: 'Please select Life Stage and try again',
    maintenanceLevel: `Please select ${labelAccordingToLifeStage} and try again`,
    matureBodyWeight: 'Please enter Mature Body Weight and try again',
    age: 'Please enter Age and try again',
    name: 'Please enter Farm Name and try again',
    bodyLength: 'Please enter Body Length and try again',
    heartGirth: 'Please enter Heart Girth and try again',
  };

  const handleChangeLifeStage = (value) => {
    if (mode === 'rationEdit' || mode === 'edit') {
      setFieldsValue({ maintenanceLevel: undefined });
    } else {
      resetFields(['maintenanceLevel']);
    }
    setLifeStageData(value);
    if (value === 'Growing') {
      setBodyWeightRadio(3);
      setFieldsValue({ bodyWeight: 0 });
    } else {
      setBodyWeightRadio(1);
    }
  };

  const onChangeBodyWeightRadio = (e) => {
    // setBodyW(0);
    if (mode === 'rationEdit' || mode === 'edit') {
      setFieldsValue({
        matureBodyWeight: undefined,
        averageDailyGain: undefined,
        bodyLength: undefined,
        heartGirth: undefined,
      });
    } else {
      resetFields(['matureBodyWeight', 'bodyWeight', 'averageDailyGain', 'bodyLength', 'heartGirth']);
    }
    setBodyWeightRadio(e.target.value);
  };

  const handleCalculateBodyWeight = () => {
    form.validateFieldsAndScroll(['breed', 'bodyLength', 'heartGirth'], (err, values) => {
      if (values.breed === 'horse') {
        form.validateFieldsAndScroll(['lifeStage'], (err11, values11) => {
          if (values11.lifeStage === 'Growing') {
            form.validateFieldsAndScroll(['year', 'month']);
          }
        });
      }

      if (!err) {
        let factor = 0;
        const { bodyLength } = values;
        const { heartGirth } = values;
        const heartGirthSq = heartGirth * heartGirth;
        const { breed } = values;
        if (breed === 'pony') {
          factor = 299;
        } else {
          form.validateFieldsAndScroll(['lifeStage'], (erro, values1) => {
            if (!erro) {
              const { lifeStage } = values1;
              if (lifeStage !== 'Growing') {
                factor = 330;
              } else {
                form.validateFieldsAndScroll(['year', 'month'], (error, values2) => {
                  if (!error) {
                    const year = values2.year * 12 || 0 * 12;
                    const age = year + values2.month || 0;
                    if (age <= 11) {
                      factor = 280;
                    } else if (age >= 12 && age <= 22) {
                      factor = 301;
                    } else {
                      factor = 330;
                    }
                  }
                });
              }
            }
          });
        }
        const bodyWeightCalc =
          userSettings.uom === 'metric'
            ? (0.027679882 * (heartGirthSq * bodyLength)) / factor
            : (heartGirthSq * bodyLength) / factor;
        setFieldsValue({ bodyWeight: bodyWeightCalc });
        setBodyW(bodyWeightCalc);
      }
    });
  };

  const handleAddPhotosButton = () => {
    setPhotos([...photos, { id: photosCount + 1, localImgUrl: null, fileName: null, storageRefPath: null }]);
    setPhotosCount(photosCount + 1);
  };

  const handleModalCancel = (e) => {
    setVisibleFarmModal(false);
    if (mode === 'add') {
      history.push('create');
    }
  };

  const addItem = () => {
    setVisibleFarmModal(true);
  };

  const handlePhotosCross = (indexId) => {
    setPhotos(photos.filter((item, index) => index !== indexId));
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUploadPhoto = (file, fileList, id) => {
    const date = new Date().getMilliseconds();
    const storageRef = firebaseStorage.ref();
    const horsesRef = storageRef.child('horses');
    const fileName = file.name;
    const imageUploadRef = horsesRef.child(`${date}_${fileName}`);
    const path = imageUploadRef.fullPath;
    imageUploadRef.put(file).then(function(snapshot) {
      console.log('Uploaded a blob or file!');
      console.log('snapshot', snapshot);
    });
    getBase64(file, (imageUrl) => {
      const p = photos.map((individualPhoto) => {
        if (individualPhoto.id === id) {
          const d = individualPhoto;
          d.localImgUrl = imageUrl;
          d.fileName = file.name;
          d.storageRefPath = path;
          return d;
        }
        return individualPhoto;
      });
      setPhotos(p);
    });
    return false;
  };

  const disabledDate = (date) => {
    const now = new Date();
    return date.isAfter(now);
  };

  const farmDataToShow = farmData.find((item) => item.id === getFieldValue('name'));

  const renderPhotos = () => {
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const newPhotosArray = photos
      ? photos.map((individualPhoto, index) => {
          return (
            <div className="upload-box" key={individualPhoto.id}>
              <Icon
                style={{ float: 'right', color: '#CE0E2D' }}
                onClick={() => {
                  handlePhotosCross(index);
                }}
                type="close"
              />
              <div className="uploadPhoto-inner-box">
                <div>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={(a, b) => beforeUploadPhoto(a, b, individualPhoto.id)}
                  >
                    {individualPhoto.localImgUrl !== null ? (
                      <img src={individualPhoto.localImgUrl} style={{ width: '100%' }} />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </div>
                <Form.Item label="Photo Date">
                  {getFieldDecorator(`photosDate[${individualPhoto.id}]`, {
                    initialValue: individualPhoto.date ? moment(individualPhoto.date.toDate()) : undefined,
                    rules: [{ required: true, message: `Please enter Photo Date and try again` }],
                  })(<DatePicker disabledDate={disabledDate} format={dateFormat} />)}
                </Form.Item>
              </div>
            </div>
          );
        })
      : null;
    return newPhotosArray;
  };

  const handleCancle = () => {
    if (mode === 'rationAdd') {
      addHorseModalCancel(false);
      resetFields();
    } else {
      history.push('/horses');
      resetFields();
    }
  };

  const saveToFirestore = (valuesToSend, values) => {
    if (mode === 'add' || mode === 'rationAdd') {
      const newPhotos = photos.map((eachPhoto) => {
        return {
          fileName: eachPhoto.fileName,
          storageRefPath: eachPhoto.storageRefPath,
          date: values.photosDate[eachPhoto.id].toDate(),
        };
      });

      firebaseDb
        .collection('Farm')
        .doc(farmDataToShow.id)
        .collection('horses')
        .add({
          createdAt: new Date(),
          createdBy: user.uid,
          ...valuesToSend,
          photos: newPhotos,
        })
        .then((ref) => {
          const farmIdToSend = farmDataToShow.id;
          const horseId = ref.id;
          message.success('Horse has been successfully added');
          setAddHorseLoader(false);
          resetFields();
          if (mode === 'add') {
            history.push('/horses');
          } else {
            onClickNextOfHorseForm(farmIdToSend, horseId, valuesToSend);
            addHorseModalCancel(false);
          }
        })
        .catch((er) => {
          message.error(er.message);
          setAddHorseLoader(false);
          resetFields();
          logger(er);
        });
    }
    if (mode === 'edit' || mode === 'rationEdit') {
      const newPhotos = photos.map((eachPhoto) => {
        return {
          fileName: eachPhoto.fileName,
          storageRefPath: eachPhoto.storageRefPath,
          date: values.photosDate[eachPhoto.id].toDate(),
        };
      });

      firebaseDb
        .collection('Farm')
        .doc(currentFarmId || itemToEdit.farmId)
        .collection('horses')
        .doc(currentHorseId || itemToEdit.horseId)
        .update({
          updatedAt: new Date(),
          updatedBy: user.uid,
          ...valuesToSend,
          photos: newPhotos,
        })
        .then(() => {
          message.success('Horse has been successfully updated');
          setAddHorseLoader(false);
          resetFields();
          if (mode === 'edit') {
            history.push('/horses');
          } else {
            onClickNextOfHorseForm(itemToEdit.farmId, itemToEdit.horseId, valuesToSend);
          }
        })
        .catch((er) => {
          message.error(er.message);
          setAddHorseLoader(false);
          resetFields();
          logger(er);
        });
    }
  };

  const renderCalculateBodyWeight = () => {
    if (bodyW) {
      return `${bodyW.toFixed(2)} ${userSettings.uom === 'metric' ? 'kgs' : 'lbs'}`;
    }
    if (itemToEdit) {
      return `${
        userSettings.uom === 'metric'
          ? convertPoundToKg(itemToEdit.bodyWeight).toFixed(2)
          : itemToEdit.bodyWeight.toFixed(2)
      }  ${userSettings.uom === 'metric' ? 'kgs' : 'lbs'}`;
    }
    return null;
  };

  const validateFormRequired = (formValues, fields = []) => {
    let isValid = true;
    const errorsObj = {};

    allFields.forEach((field) => {
      if (fields.includes(field)) {
        if (field === 'age') {
          if (!formValues.month && !formValues.year) {
            isValid = false;
            errorsObj.year = {
              errors: [new Error(requiredErrorMessages[field])],
            };
          }
        } else if (!formValues[field]) {
          isValid = false;
          errorsObj[field] = {
            // empty value of field and required error message is send to setField without this only error msj is set and value is previous value
            value: formValues[field],
            errors: [new Error(requiredErrorMessages[field])],
          };
        }
      } else {
        const fieldKey = field === 'age' ? 'year' : field;
        errorsObj[fieldKey] = {
          // this runs when there is no any empty field
          value: formValues[fieldKey],
          errors: null,
        };
      }
    });

    // Validate photos dates
    if (photos.length > 0 && Array.isArray(formValues.photosDate) && formValues.photosDate.length > 0) {
      photos.forEach((photo) => {
        if (!formValues.photosDate[photo.id]) {
          isValid = false;
          errorsObj[`photosDate[${photo.id}]`] = {
            errors: [new Error('Please enter Photo Date and try again')],
          };
        }
      });
    }

    if (Object.keys(errorsObj).length > 0) {
      // in antd setFields() is like {value: 'value', errors: 'errors'}
      setFields(errorsObj);
    }
    return isValid;
  };

  const handleSubmitNew = () => {
    setAddHorseLoader(true);
    const formValues = getFieldsValue();

    const yrs = formValues.year || 0;
    const mnt = formValues.month || 0;
    const yearsInMonths = yrs * 12;
    const totalMonths = mnt + yearsInMonths;

    // Basic required validations
    const basicCheckFields = ['horseName', 'name', 'lifeStage', 'bodyWeight', 'dryMatterIntakeLevel'];
    if (bodyWeightRadio === 2) {
      basicCheckFields.push('bodyLength');
      basicCheckFields.push('heartGirth');
    }

    // Life stage not growing
    if (formValues.lifeStage !== 'Growing') {
      // Maintenance level required check
      basicCheckFields.push('maintenanceLevel');
    }
    // Life stage growing
    else {
      basicCheckFields.push('age');
      if (bodyWeightRadio === 3) {
        basicCheckFields.push('matureBodyWeight');
      }
      if (formValues.month || formValues.year) {
        if (totalMonths > 12) {
          basicCheckFields.push('maintenanceLevel');
        }
      }
      // Age is required
      // If age <= 12 set Maintenance level to none
      // If age > 12 maintenance level is required
      // If bodyWeightRadio === 3 then mature body weight is required
    }

    if (!validateFormRequired(formValues, basicCheckFields)) {
      setAddHorseLoader(false);
      return;
    }

    // Add or save horse when no validation errors present

    const checkValueAndConvert = (checkValue, defaultValue, unit) => {
      if (checkValue) {
        if (userSettings.uom === 'metric' && unit === 'cm') {
          return convertCmToInch(checkValue);
        }
        if (userSettings.uom === 'metric' && unit === 'kg') {
          return convertKgToPound(checkValue);
        }
        return checkValue;
      }
      return defaultValue;
    };

    const valuesToSend = {
      name: formValues.horseName,
      age: totalMonths,
      breed: formValues.breed,
      lifeStage: formValues.lifeStage,
      maintenanceLevel: formValues.maintenanceLevel ? formValues.maintenanceLevel : 'None',
      bodyConditionScore: formValues.bodyConditionScore ? formValues.bodyConditionScore : null,
      dryMatterIntakeLevel: formValues.dryMatterIntakeLevel,
      bodyWeight: userSettings.uom === 'metric' ? convertKgToPound(formValues.bodyWeight) : formValues.bodyWeight,
      bodyLength: checkValueAndConvert(formValues.bodyLength, null, 'cm'),
      heartGirth: checkValueAndConvert(formValues.heartGirth, null, 'cm'),
      averageDailyGain: checkValueAndConvert(formValues.averageDailyGain, 0, 'kg'),
      matureBodyWeight: checkValueAndConvert(formValues.matureBodyWeight, 0, 'kg'),
    };
    saveToFirestore(valuesToSend, formValues);
  };

  const handleClickNext = () => {
    setAddHorseLoader(true);
    const formValues = getFieldsValue();

    const yrs = formValues.year || 0;
    const mnt = formValues.month || 0;
    const yearsInMonths = yrs * 12;
    const totalMonths = mnt + yearsInMonths;

    // Basic required validations
    const basicCheckFields = ['horseName', 'name', 'lifeStage', 'bodyWeight', 'dryMatterIntakeLevel'];
    if (bodyWeightRadio === 2) {
      basicCheckFields.push('bodyLength');
      basicCheckFields.push('heartGirth');
    }

    // Life stage not growing
    if (formValues.lifeStage !== 'Growing') {
      // Maintenance level required check
      basicCheckFields.push('maintenanceLevel');
    }
    // Life stage growing
    else {
      basicCheckFields.push('age');
      if (bodyWeightRadio === 3) {
        basicCheckFields.push('matureBodyWeight');
      }
      if (formValues.month || formValues.year) {
        if (totalMonths > 12) {
          basicCheckFields.push('maintenanceLevel');
        }
      }
      // Age is required
      // If age <= 12 set Maintenance level to none
      // If age > 12 maintenance level is required
      // If bodyWeightRadio === 3 then mature body weight is required
    }

    if (!validateFormRequired(formValues, basicCheckFields)) {
      setAddHorseLoader(false);
      return;
    }

    // Add or save horse when no validation errors present

    const checkValueAndConvert = (checkValue, defaultValue, unit) => {
      if (checkValue) {
        if (userSettings.uom === 'metric' && unit === 'cm') {
          return convertCmToInch(checkValue);
        }
        if (userSettings.uom === 'metric' && unit === 'kg') {
          return convertKgToPound(checkValue);
        }
        return checkValue;
      }
      return defaultValue;
    };

    const valuesToSend = {
      name: formValues.horseName,
      age: totalMonths,
      breed: formValues.breed,
      lifeStage: formValues.lifeStage,
      maintenanceLevel: formValues.maintenanceLevel ? formValues.maintenanceLevel : 'None',
      bodyConditionScore: formValues.bodyConditionScore ? formValues.bodyConditionScore : null,
      dryMatterIntakeLevel: formValues.dryMatterIntakeLevel,
      bodyWeight: userSettings.uom === 'metric' ? convertKgToPound(formValues.bodyWeight) : formValues.bodyWeight,
      bodyLength: checkValueAndConvert(formValues.bodyLength, null, 'cm'),
      heartGirth: checkValueAndConvert(formValues.heartGirth, null, 'cm'),
      averageDailyGain: checkValueAndConvert(formValues.averageDailyGain, 0, 'kg'),
      matureBodyWeight: checkValueAndConvert(formValues.matureBodyWeight, 0, 'kg'),
    };
    onClickNextOfHorseForm(currentFarmId, currentHorseId, valuesToSend);
  };

  const ageValidation = (rule, value, callback) => {
    const monthValue = form.getFieldValue('month');
    const yearValue = form.getFieldValue('year');

    if (!monthValue && !yearValue) {
      callback('Please enter Age and try again');
    } else {
      setFields({
        year: {
          value: yearValue,
          errors: null,
        },
        month: {
          value: monthValue,
          errors: null,
        },
      });
      callback();
    }
  };

  const disableMaintenenceLevel = () => {
    const age = getFieldValue('year') * 12 + getFieldValue('month');
    if (lifeStageData === null) {
      return true;
    }
    if (lifeStageData === 'Growing' && (age <= 12 && age !== 0)) {
      return true;
    }
    return false;
  };

  const matureBodyWeightCalculation = () => {
    form.validateFieldsAndScroll(['year', 'month', 'matureBodyWeight'], (err, values) => {
      if (!err) {
        const { matureBodyWeight } = values;
        const months = values.month ? values.month : 0;
        const years = values.year ? values.year : 0;
        const age = years * 12 + months;

        setFields({
          bodyWeight: {
            value: (matureBodyWeight * (9.7 + 90.3 * (1.0 - Math.exp(-0.0772 * age)))) / 100,
          },
          averageDailyGain: {
            value: (matureBodyWeight * (6.97121 * Math.exp(-0.0772 * age))) / (30.4 * 100),
          },
        });
      }
      return err;
    });
  };

  const optionsOfMaintenanceLevel = () => {
    const age = getFieldValue('year') * 12 + getFieldValue('month');
    const arrayGrowing = ['None', 'Light', 'Moderate'];
    if (lifeStageData === 'Growing' && (age > 12 && age <= 18)) {
      const array = arrayGrowing.map((individualLevel) => {
        return (
          <Option key={individualLevel} value={individualLevel}>
            {individualLevel}
          </Option>
        );
      });
      return array;
    }
    if (lifeStageData !== null) {
      const array = maintenanceLevelOption[lifeStageData].map((individualLevel) => {
        return (
          <Option key={individualLevel} value={individualLevel === 'Very Heavy' ? 'Intense' : individualLevel}>
            {individualLevel}
          </Option>
        );
      });
      return array;
    }
    return null;
  };

  const farmName = () => {
    if (mode === 'rationEdit') {
      return itemToEdit.farmId;
    }
    if (mode === 'edit') {
      return currentFarmId;
    }
    if (farmId) {
      return farmId;
    }
    return undefined;
  };

  const itemToEditValue = (valueReceive, unit, defaultValue) => {
    if (valueReceive) {
      if (userSettings.uom === 'metric' && unit === 'cm') {
        return convertInchToCm(valueReceive);
      }
      if (userSettings.uom === 'metric' && unit === 'kg') {
        return convertPoundToKg(valueReceive);
      }
      return valueReceive;
    }
    return defaultValue;
  };

  return (
    <>
      <Modal
        onCancel={handleModalCancel}
        title="Add New Farm"
        visible={visibleFarmModal}
        footer={false}
        formIsInModal="farmIsInModal"
        width="54%"
      >
        <FarmForm
          mode="add"
          formIsInModal
          span={24}
          offset={0}
          visibleButton="no"
          handleModalCancel={handleModalCancel}
        />
      </Modal>
      <Form onSubmit={handleSubmitNew}>
        <Heading headingName="General Information" />
        <Row>
          <Col span={12}>
            <Form.Item label="Horse Name" labelCol={{ lg: 8, xl: 6 }} wrapperCol={{ lg: 14, xl: 12 }}>
              {getFieldDecorator('horseName', {
                initialValue: itemToEdit ? itemToEdit.name : undefined,
                rules: [{ required: true, message: `Please enter Horse Name and try again` }],
              })(<Input placeholder="Enter Name of Horse" disabled={mode === 'rationEdit'} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Farm Name" labelCol={{ lg: 8, xl: 6 }} wrapperCol={{ lg: 14, xl: 12 }}>
              {getFieldDecorator('name', {
                initialValue: farmName(),
                rules: [{ required: true, message: `Please select Farm Name and try again` }],
              })(
                <Select
                  disabled={mode === 'edit' || mode === 'rationEdit' || mode === 'add'}
                  showSearch
                  optionFilterProp="data"
                  placeholder="Search for Farm by Name/City/State/Zipcode"
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{ padding: '4px 8px', cursor: 'pointer' }}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={addItem}
                        role="button"
                        tabIndex="0"
                        onKeyPress={addItem}
                      >
                        <Icon type="plus" /> ADD NEW FARM
                      </div>
                    </div>
                  )}
                >
                  {farmData !== null &&
                    farmData.map((farmitem) => {
                      return (
                        <Option
                          key={farmitem.id}
                          value={farmitem.id}
                          data={`${farmitem.name}_${farmitem.city}_${farmitem.state}_${farmitem.zip}`}
                        >
                          {farmitem.name}
                        </Option>
                      );
                    })}
                </Select>,
              )}
            </Form.Item>
          </Col>
        </Row>
        <FarmDataInfo selectedFarmData={farmDataToShow} hideHeader showAllFieldTogether />
        <Heading headingName="Physical Information" />
        <Row>
          <Col span={12}>
            <Form.Item
              className="age-form-item"
              label="Age"
              labelCol={{ lg: 8, xl: 6 }}
              wrapperCol={{ lg: 14, xl: 12 }}
            >
              {getFieldDecorator('year', {
                initialValue: itemToEdit ? parseInt(itemToEdit.age / 12, 10) : null,
                rules: [{ validator: ageValidation }],
              })(
                <InputNumber
                  min={0}
                  precision={0}
                  onChange={() => {
                    if (mode === 'rationEdit' || mode === 'edit') {
                      setFieldsValue({
                        bodyWeight: undefined,
                        matureBodyWeight: undefined,
                        averageDailyGain: undefined,
                        bodyLength: undefined,
                        heartGirth: undefined,
                        maintenanceLevel: undefined,
                      });
                    } else {
                      resetFields([
                        'maintenanceLevel',
                        'matureBodyWeight',
                        'bodyWeight',
                        'averageDailyGain',
                        'bodyLength',
                        'heartGirth',
                      ]);
                    }
                  }}
                />,
              )}
              &nbsp;
              <span style={{ marginLeft: 3, marginRight: 10 }}>years</span>
              {getFieldDecorator('month', {
                initialValue: itemToEdit ? itemToEdit.age % 12 : null,
                rules: [{ validator: ageValidation }],
              })(
                <InputNumber
                  min={0}
                  precision={0}
                  onChange={() => {
                    if (mode === 'rationEdit' || mode === 'edit') {
                      setFieldsValue({
                        bodyWeight: undefined,
                        matureBodyWeight: undefined,
                        averageDailyGain: undefined,
                        bodyLength: undefined,
                        heartGirth: undefined,
                        maintenanceLevel: undefined,
                      });
                    } else {
                      resetFields([
                        'maintenanceLevel',
                        'matureBodyWeight',
                        'bodyWeight',
                        'averageDailyGain',
                        'bodyLength',
                        'heartGirth',
                      ]);
                    }
                  }}
                />,
              )}
              &nbsp;
              <span style={{ marginLeft: 3 }}>months</span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Body Condition Score" labelCol={{ lg: 8, xl: 6 }} wrapperCol={{ lg: 14, xl: 12 }}>
              {getFieldDecorator('bodyConditionScore', {
                initialValue: itemToEdit ? itemToEdit.bodyConditionScore : undefined,
              })(<InputNumber min={1} max={9} precision={0} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="Life Stage" labelCol={{ lg: 8, xl: 6 }} wrapperCol={{ lg: 14, xl: 12 }}>
              {getFieldDecorator('lifeStage', {
                initialValue: itemToEdit ? itemToEdit.lifeStage : undefined,
                rules: [{ required: true, message: `Please select Life Stage and try again` }],
              })(
                <Select onChange={handleChangeLifeStage} placeholder="Select Life Stage of horse">
                  <Option value="Adult Maintenance">Adult Maintenance</Option>
                  <Option value="Stallion">Stallion</Option>
                  <Option value="Working/Training">Working/Training</Option>
                  <Option value="Lactating">Lactating</Option>
                  <Option value="Pregnant">Pregnant</Option>
                  <Option value="Growing">Growing</Option>
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={labelAccordingToLifeStage} labelCol={{ lg: 8, xl: 6 }} wrapperCol={{ lg: 14, xl: 12 }}>
              {getFieldDecorator('maintenanceLevel', {
                initialValue: itemToEdit ? itemToEdit.maintenanceLevel : undefined,
                rules: [{ required: true, message: <>{`Please select ${labelAccordingToLifeStage} and try again`}</> }],
              })(
                <Select
                  placeholder={`Select ${labelAccordingToLifeStage} of Horse`}
                  disabled={disableMaintenenceLevel()}
                >
                  {optionsOfMaintenanceLevel()}
                </Select>,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="Breed" labelCol={{ lg: 8, xl: 6 }} wrapperCol={{ lg: 14, xl: 12 }}>
              {getFieldDecorator('breed', {
                initialValue: itemToEdit ? itemToEdit.breed : 'horse',
                rules: [{ required: true, message: `Please enter Breed and try again` }],
              })(
                <div>
                  <Radio.Group defaultValue={itemToEdit ? itemToEdit.breed : 'horse'}>
                    <Radio value="horse">Horse Breed</Radio>
                    <Radio value="pony">Pony Breed</Radio>
                  </Radio.Group>
                </div>,
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Dry Matter Intake Level" labelCol={{ lg: 9, xl: 6 }} wrapperCol={{ lg: 13, xl: 12 }}>
              {getFieldDecorator('dryMatterIntakeLevel', {
                initialValue: itemToEdit ? itemToEdit.dryMatterIntakeLevel : 2,
                rules: [{ required: true, message: `Please enter Dry Matter Intake Level and try again` }],
              })(<InputNumber min={0.5} max={6} precision={2} step={0.5} formatter={(value) => `${value} %`} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col offset={2}>
            <div>
              <Radio.Group value={bodyWeightRadio} onChange={onChangeBodyWeightRadio}>
                <Radio value={1}>Enter Body Weight</Radio>
                <Radio value={2}>Calculate Body Weight</Radio>
                {getFieldValue('lifeStage') === 'Growing' ? <Radio value={3}>From Mature Body Weight</Radio> : null}
              </Radio.Group>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          {bodyWeightRadio === 1 ? (
            <div>
              <Col span={12}>
                <Form.Item label="Body Weight" labelCol={{ lg: 8, xl: 6 }} wrapperCol={{ lg: 14, xl: 12 }}>
                  {getFieldDecorator('bodyWeight', {
                    initialValue: itemToEdit ? itemToEditValue(itemToEdit.bodyWeight, 'kg', undefined) : undefined,
                    rules: [{ required: true, message: `Please enter Body Weight and try again` }],
                  })(<InputNumber min={0} precision={2} />)}
                  &nbsp; {userSettings.uom === 'metric' ? <>kgs</> : <>lbs</>}
                </Form.Item>
                {getFieldValue('lifeStage') === 'Growing' ? (
                  <Form.Item label="Average Daily Gain" labelCol={{ lg: 8, xl: 6 }} wrapperCol={{ lg: 14, xl: 12 }}>
                    {getFieldDecorator('averageDailyGain', {
                      initialValue: itemToEdit
                        ? itemToEditValue(itemToEdit.averageDailyGain, 'kg', undefined)
                        : undefined,
                    })(<InputNumber min={0} precision={2} />)}
                    &nbsp; {userSettings.uom === 'metric' ? <>kgs/day</> : <>lbs/day</>}
                  </Form.Item>
                ) : null}
              </Col>
            </div>
          ) : null}
          {bodyWeightRadio === 2 ? (
            <div>
              <div>
                <Col span={12}>
                  <Form.Item label="Heart Girth" labelCol={{ lg: 8, xl: 6 }} wrapperCol={{ lg: 14, xl: 12 }}>
                    {getFieldDecorator('heartGirth', {
                      initialValue: itemToEdit ? itemToEditValue(itemToEdit.heartGirth, 'cm', undefined) : undefined,
                      rules: [{ required: true, message: `Please enter Heart Girth and try again` }],
                    })(<InputNumber min={0} precision={2} />)}
                    &nbsp; {userSettings.uom === 'metric' ? <>cms</> : <>inches</>}
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item label="Body Length" labelCol={{ lg: 8, xl: 6 }} wrapperCol={{ lg: 14, xl: 12 }}>
                    {getFieldDecorator('bodyLength', {
                      initialValue: itemToEdit ? itemToEditValue(itemToEdit.bodyLength, 'cm', undefined) : undefined,
                      rules: [{ required: true, message: `Please enter Body Length and try again` }],
                    })(<InputNumber min={0} precision={2} />)}
                    &nbsp; {userSettings.uom === 'metric' ? <>cms</> : <>inches</>}
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Button className="button-color" onClick={handleCalculateBodyWeight}>
                    Calculate
                  </Button>
                </Col>
                <Col span={24}>
                  <Col span={12}>
                    <Form.Item label="Body Weight" labelCol={{ lg: 8, xl: 6 }} wrapperCol={{ lg: 14, xl: 12 }}>
                      {getFieldDecorator('bodyWeight', {
                        initialValue: itemToEdit ? itemToEditValue(itemToEdit.bodyWeight, 'kg', undefined) : undefined,
                        rules: [{ required: true, message: `Please calculate Body Weight and try again` }],
                      })(
                        <div style={{ color: 'blue' }}>
                          {getFieldValue('bodyWeight') && getFieldValue('bodyWeight') === 'Infinity' ? (
                            <div style={{ color: '#CE0E2D' }}>Please resolve above error and try again</div>
                          ) : null}
                          {renderCalculateBodyWeight()}
                        </div>,
                      )}
                    </Form.Item>
                  </Col>
                </Col>
              </div>
              <Col span={12} style={{ marginTop: 20 }}>
                {getFieldValue('lifeStage') === 'Growing' ? (
                  <Form.Item label="Average Daily Gain" labelCol={{ lg: 8, xl: 6 }} wrapperCol={{ lg: 14, xl: 12 }}>
                    {getFieldDecorator('averageDailyGain', {
                      initialValue: itemToEdit
                        ? itemToEditValue(itemToEdit.averageDailyGain, 'kg', undefined)
                        : undefined,
                    })(<InputNumber min={0} precision={2} />)}
                    &nbsp;{userSettings.uom === 'metric' ? <>kgs/day</> : <>lbs/day</>} &nbsp;
                  </Form.Item>
                ) : null}
              </Col>
            </div>
          ) : null}
          {bodyWeightRadio === 3 && getFieldValue('lifeStage') === 'Growing' ? (
            <div>
              <div>
                <Col span={12}>
                  <Form.Item label="Mature Body weight" labelCol={{ lg: 8, xl: 6 }} wrapperCol={{ lg: 14, xl: 12 }}>
                    {getFieldDecorator('matureBodyWeight', {
                      initialValue: itemToEdit
                        ? itemToEditValue(itemToEdit.matureBodyWeight, 'kg', undefined)
                        : undefined,
                      rules: [{ required: true, message: `Please enter Mature Body Weight and try again` }],
                    })(<InputNumber min={0} precision={2} onBlur={matureBodyWeightCalculation} />)}
                    &nbsp; {userSettings.uom === 'metric' ? <>kgs</> : <>lbs</>}
                  </Form.Item>
                  <Form.Item label="Body Weight" labelCol={{ lg: 8, xl: 6 }} wrapperCol={{ lg: 14, xl: 12 }}>
                    {getFieldDecorator('bodyWeight', {
                      initialValue: itemToEdit ? itemToEditValue(itemToEdit.bodyWeight, 'kg', undefined) : undefined,
                      rules: [{ required: true, message: `Please enter Body Weight and try again` }],
                    })(<InputNumber min={0} precision={2} />)}
                    &nbsp; {userSettings.uom === 'metric' ? <>kgs</> : <>lbs</>}
                  </Form.Item>

                  <Form.Item label="Average Daily Gain" labelCol={{ lg: 8, xl: 6 }} wrapperCol={{ lg: 14, xl: 12 }}>
                    {getFieldDecorator('averageDailyGain', {
                      initialValue: itemToEdit ? itemToEditValue(itemToEdit.averageDailyGain, 'kg', null) : null,
                    })(<InputNumber min={0} precision={2} />)}
                    &nbsp; {userSettings.uom === 'metric' ? <>kgs/day</> : <>lbs/day</>}
                  </Form.Item>
                </Col>
              </div>
            </div>
          ) : null}
        </Row>
        {mode === 'rationEdit' ? (
          <div style={{ marginTop: 20, display: 'flex' }}>
            <Col offset={11} span={12} className="next-previous-buttons">
              <Button className="button-color" loading={updateHorseLoader} onClick={handleSubmitNew}>
                Update Horse
              </Button>
            </Col>
            <Col>
              <Button
                className="button-color"
                onClick={() => {
                  handleClickNext();
                }}
              >
                Next
              </Button>
            </Col>
          </div>
        ) : (
          <div>
            {mode === 'rationAdd' ? null : (
              <div>
                <Heading headingName="Photos" />
                <Form.Item>
                  {renderPhotos()}
                  <Button className="button-color" style={{ marginTop: 20 }} onClick={handleAddPhotosButton}>
                    Add Photos
                  </Button>
                </Form.Item>
              </div>
            )}

            <Row className="save-forage-button">
              <Form.Item>
                <Button
                  className="button-color"
                  onClick={handleSubmitNew}
                  loading={addHorseLoader}
                  style={{ marginRight: 10 }}
                >
                  {mode === 'edit' || mode === 'rationEdit' ? 'Save' : 'Add New Horse'}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button className="button-color" style={{ marginLeft: 10 }} onClick={handleCancle}>
                  Cancel
                </Button>
              </Form.Item>
            </Row>
          </div>
        )}
      </Form>
    </>
  );
};

HorseForm.propTypes = {
  history: PropTypes.object.isRequired,
  itemToEdit: PropTypes.object,
  mode: PropTypes.oneOf(['edit', 'add', 'rationEdit', 'rationAdd']).isRequired,
  photosToEdit: PropTypes.array,
  currentFarmId: PropTypes.string,
  currentHorseId: PropTypes.string,
  onClickNextOfHorseForm: PropTypes.func,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    getFieldsValue: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
  }).isRequired,
  addHorseModalCancel: PropTypes.func,
  farmId: PropTypes.string.isRequired,
};

HorseForm.defaultProps = {
  photosToEdit: [],
  currentFarmId: '',
  currentHorseId: '',
  onClickNextOfHorseForm: null,
  itemToEdit: null,
  addHorseModalCancel: null,
};

export default withRouter(Form.create({ name: 'HorseForm' })(HorseForm));
