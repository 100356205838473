import React from 'react';
import PropTypes from 'prop-types';
import { Bar, BarChart, CartesianGrid, Legend, ReferenceLine, Tooltip, XAxis, YAxis, AxisLabel } from 'recharts';

const NutrientSuppliedBarChart = ({ data, chartType, dataKeys, margin, compare, maximumCap }) => {
  const height = compare ? 300 : 600;

  const domainArray = [0];
  if (maximumCap) {
    domainArray.push(maximumCap);
  } else {
    domainArray.push('auto');
  }

  return (
    <BarChart width={1200} height={height} data={data} margin={margin}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="name"
        interval={0}
        orientation="bottom"
        label={{ value: 'Nutrients', position: 'insideBottom', offset: -16 }}
        padding={{ top: 20, bottom: 20 }}
        tick={{ fontSize: 12 }}
      />
      <YAxis
        label={{ value: '% Daily Recommendation', angle: -90, position: 'insideBottomLeft' }}
        domain={chartType === '100%' ? [0, 100] : domainArray}
        allowDataOverflow={chartType === '100%' || !!maximumCap}
      />
      <Tooltip />
      <Legend margin={{ top: 0, right: 0, left: 20, bottom: -40 }} />
      {dataKeys.map((dataKey) => (
        <Bar dataKey={dataKey.id} stackId="a" key={dataKey.id} fill={dataKey.color} name={dataKey.name} />
      ))}
      {chartType !== '100%' ? (
        <ReferenceLine
          y={100}
          label={(props) => {
            return (
              <text
                fontSize="14"
                fontWeight="600"
                x="1183"
                /* eslint-disable-next-line react/prop-types */
                y={props.viewBox.y}
                className="recharts-text recharts-label"
                textAnchor="middle"
                fill="#E539FF"
              >
                <tspan x="1183" dy="0.355em">
                  100
                </tspan>
              </text>
            );
          }}
          stroke="#E539FF"
        />
      ) : null}
    </BarChart>
  );
};

NutrientSuppliedBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  chartType: PropTypes.oneOf(['100%', 'standard']),
  dataKeys: PropTypes.array.isRequired,
  margin: PropTypes.object,
  compare: PropTypes.bool,
  maximumCap: PropTypes.number,
};

NutrientSuppliedBarChart.defaultProps = {
  chartType: 'standard',
  margin: {},
  compare: false,
  maximumCap: null,
};

export default NutrientSuppliedBarChart;
