import React, { useState } from 'react';
import { Form, Input, Row, Button, Col, message } from 'antd';
import PropTypes from 'prop-types';
import firebase, { firebaseAuth } from '../utils/firebase';
import logger from '../utils/helpers/logger';
import './ChangePasswordForm.scss';
import '../components/ButtonColor.scss';

const ChangePasswordForm = (props) => {
  const { form } = props;
  const { getFieldDecorator, resetFields, validateFields, getFieldValue, setFields } = form;
  const [loadingSave, setLoadingSave] = useState(false);

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue('newPassword')) {
      callback('The entered passwords do not match. Please check and try again.');
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value && value.length < 6) {
      callback('Password must be atleast 6 characters long.');
    }
    if (value && getFieldValue('confirmNewPassword')) {
      validateFields(['confirmNewPassword'], { force: true });
    }
    callback();
  };

  const handleSave = (e) => {
    setLoadingSave(true);
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const { currentUser } = firebaseAuth;
        const credential = firebase.auth.EmailAuthProvider.credential(currentUser.email, values.currentPassword);
        currentUser
          .reauthenticateWithCredential(credential)
          .then(() => {
            currentUser
              .updatePassword(values.newPassword)
              .then(() => {
                message.success('Password has been successfully updated.');
                setLoadingSave(false);
                resetFields();
              })
              .catch((error) => {
                logger(error);
                setLoadingSave(false);
                resetFields();
              });
          })
          .catch((error) => {
            logger(error);
            if (error.code === 'auth/wrong-password') {
              setFields({
                currentPassword: {
                  errors: [new Error('The Current Password you have entered is incorrect')],
                },
              });
              resetFields('currentPassword');
            }
            setLoadingSave(false);
          });
      } else {
        setLoadingSave(false);
      }
    });
  };

  return (
    <div id="change-password-container">
      <Form>
        <Form.Item label="Current Password" labelCol={{ span: 3 }} wrapperCol={{ span: 10 }}>
          {getFieldDecorator('currentPassword', {
            rules: [{ required: true, message: 'Please enter Current Password and try again' }],
          })(<Input.Password placeholder="Enter Current Password" />)}
        </Form.Item>
        <Form.Item label="New Password" labelCol={{ span: 3 }} wrapperCol={{ span: 10 }}>
          {getFieldDecorator('newPassword', {
            rules: [
              { required: true, message: 'Please enter New Password and try again' },
              {
                validator: validateToNextPassword,
              },
            ],
          })(<Input.Password placeholder="Enter New Password" />)}
        </Form.Item>
        <Form.Item label="Confirm New Password" labelCol={{ span: 3 }} wrapperCol={{ span: 10 }}>
          {getFieldDecorator('confirmNewPassword', {
            rules: [
              { required: true, message: 'Please Confirm New Password and try again' },
              {
                validator: compareToFirstPassword,
              },
            ],
          })(<Input.Password placeholder="Enter Confirm New Password" />)}
        </Form.Item>
        <Row style={{ marginTop: 20 }}>
          <Col offset={3}>
            <Form.Item>
              <Button className="button-color" style={{ marginRight: 10 }} loading={loadingSave} onClick={handleSave}>
                Save
              </Button>
              <Button
                className="button-color"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  resetFields();
                }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

ChangePasswordForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
  }).isRequired,
};

export default Form.create({ name: 'ChangePasswordForm' })(ChangePasswordForm);
