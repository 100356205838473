import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Form,
  Icon,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  Affix,
} from 'antd';
import PropTypes from 'prop-types';
import GraphOfNutrientSupplied from './GraphOfNutrientSupplied';
import AcceptableRangeGraph from './AcceptableRangeGraph';
import { feedNutrients } from '../utils/globals';
import { firebaseDb } from '../utils/firebase';
import rationCalculations from '../utils/rationCalculations';
import NutrientsSuppliedTable from './NutrientsSuppliedTable';
import DietNameSaveForm from '../forms/DietNameSaveForm';
import './DrawerDesign.scss';
import './ButtonColor.scss';
import useAuth from '../utils/auth';
import useAppData from '../contexts/appDataContext';

const { Option, OptGroup } = Select;
const { Column } = Table;

const RecommendedRation = (props) => {
  const {
    form,
    handleSaveOfHorseDiet,
    onClickBackButton,
    feedItemList,
    recommendedRationList,
    setRecommendedRationList,
    targetDeltaLow,
    targetDeltaHigh,
    setTargetDeltaLow,
    setTargetDeltaHigh,
    acceptableDeltaLow,
    acceptableDeltaHigh,
    setAcceptableDeltaLow,
    setAcceptableDeltaHigh,
    accRangeGraphNutrients,
    setAccRangeGraphNutrients,
    nutSuppGraphChartType,
    setNutSuppGraphChartType,
    nutSuppGraphNutrients,
    setNutSuppGraphNutrients,
    horseDataForCalculation,
    maximumCap,
    setMaximumCap,
    onClickGenerateReport,
    handleSaveOfCompareDiet,
  } = props;

  const [feedInfoData, setFeedInfoData] = useState([]);
  const [addRationType, setAddRationType] = useState('forage');
  const [saveButtonLoader, setSaveButtonLoader] = useState(false);
  const [visibleDrawerForRecommended, setVisibleDrawerForRecommended] = useState(false);
  const [recommendedDietModal, setRecommendedDietModal] = useState(false);
  const [addRecommendedRationModal, setAddRecommendedRationModal] = useState(false);
  const [checkCompare, setCheckCompare] = useState(false);
  const [showComparision, setShowComparision] = useState('suppliedGraph');
  const [modalOfBackButton, setModalOfBackButton] = useState(false);
  const {
    userSettings,
    convertOzToGram,
    convertPoundToKg,
    convertGramToOz,
    convertKgToPound,
    convertOzToLbs,
  } = useAuth();
  const { resetRationAnalyzer } = useAppData();

  useEffect(() => {
    firebaseDb.collection('Feed').onSnapshot((querySnapshot) => {
      const newFeedInfoData = [];
      querySnapshot.forEach((doc) => {
        newFeedInfoData.push({ id: doc.id, ...doc.data() });
      });
      setFeedInfoData(newFeedInfoData);
    });
  }, []);

  const onCloseDrawerRecommended = () => {
    setVisibleDrawerForRecommended(false);
  };

  const currentRationCalculations = rationCalculations({
    rationList: feedItemList,
    horseData: horseDataForCalculation,
    targetDeltaLow,
    targetDeltaHigh,
    acceptableDeltaLow,
    acceptableDeltaHigh,
  });

  const recommendedRationCalculations = rationCalculations({
    rationList: recommendedRationList,
    horseData: horseDataForCalculation,
    targetDeltaLow,
    targetDeltaHigh,
    acceptableDeltaLow,
    acceptableDeltaHigh,
  });

  const onChangeCompareCheckBox = (e) => {
    setCheckCompare(e.target.checked);
  };

  const handleRightArrow = () => {
    if (showComparision === 'suppliedGraph') {
      setShowComparision('acceptableGraph');
    } else if (showComparision === 'acceptableGraph') {
      setShowComparision('table');
    } else {
      setShowComparision('suppliedGraph');
    }
  };

  const handleLeftArrow = () => {
    if (showComparision === 'suppliedGraph') {
      setShowComparision('table');
    } else if (showComparision === 'acceptableGraph') {
      setShowComparision('suppliedGraph');
    } else {
      setShowComparision('acceptableGraph');
    }
  };

  const renderHeadingInCompareMode = () => {
    if (showComparision === 'suppliedGraph') {
      return 'Nutrients supplied as % of daily requirement';
    }
    if (showComparision === 'acceptableGraph') {
      return 'Acceptable Ranges';
    }
    return 'Daily Nutrients Supplied in Total Ration';
  };

  const handleDietNameSave = (value) => {
    if (checkCompare) {
      const rationType = 'compareDiet';
      handleSaveOfCompareDiet(value.dietName, rationType, setSaveButtonLoader, setRecommendedDietModal);
    } else {
      const rationType = 'recommended';
      handleSaveOfHorseDiet(value.dietName, rationType, setSaveButtonLoader, setRecommendedDietModal);
    }
  };

  const renderSelectOption = (item) => (
    <Option key={item.id} value={item.id}>
      {`${item.name}${item.code ? ` (${item.code})` : ''}`}
    </Option>
  );

  const renderAmountValueInTable = (record) => {
    if (userSettings.uom === 'metric') {
      if (record.unit && record.unit === 'lbs/day') {
        return `${convertPoundToKg(record.amount).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} kgs`;
      }
      if (record.unit && record.unit === 'oz/day') {
        return `${convertOzToGram(record.amount).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} grams`;
      }
      return null;
    }
    return `${
      record.amount
        ? record.amount.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : null
    } ${record.unit === 'lbs/day' ? 'lbs' : 'oz'}`;
  };

  const drawerAmount = (amount, unit) => {
    if (unit === 'oz/day') {
      return convertOzToGram(amount);
    }
    return convertPoundToKg(amount);
  };

  const displayUnitInDrawer = (unit) => {
    if (userSettings.uom === 'metric') {
      if (unit === 'oz/day') {
        return 'grams';
      }
      return 'kgs';
    }
    return `${unit === 'lbs/day' ? 'lbs' : 'oz'}`;
  };

  feedInfoData.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  let totalAmount = 0;
  feedItemList.forEach((item) => {
    if (item.unit === 'oz/day') {
      totalAmount += convertOzToLbs(item.amount);
    } else {
      totalAmount += item.amount;
    }
  });

  let totalAmountRecommend = 0;
  recommendedRationList.forEach((item) => {
    if (item.unit === 'oz/day') {
      totalAmountRecommend += convertOzToLbs(item.amount);
    } else {
      totalAmountRecommend += item.amount;
    }
  });

  return (
    <>
      <Modal title="Alert" footer={false} visible={modalOfBackButton} onCancel={() => setModalOfBackButton(false)}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{ textAlign: 'center', fontSize: 15, marginBottom: 18, marginLeft: 10, marginRight: 10 }}>
            You can save the recommended ration using &apos;Save&apos; button.
          </div>
          <div>
            <Button
              style={{ marginRight: 10 }}
              className="button-color"
              onClick={() => {
                setModalOfBackButton(false);
              }}
            >
              Ok
            </Button>
            <Button
              style={{ marginRight: 10 }}
              onClick={() => {
                onClickBackButton(false);
              }}
            >
              I don&apos;t want to
            </Button>
            <Button
              onClick={() => {
                onClickBackButton(false);
              }}
            >
              I already did
            </Button>
          </div>
        </div>
      </Modal>
      {checkCompare ? (
        <div>
          <Drawer
            width={450}
            title="Compare Ration"
            placement="left"
            onClose={onCloseDrawerRecommended}
            visible={visibleDrawerForRecommended}
          >
            <div style={{ fontSize: 14, color: 'black', marginTop: 0, marginBottom: 10 }}>Current Ration</div>
            <table>
              <thead>
                <tr style={{ borderBottomWidth: 1, borderBottomColor: '#ccc', borderBottomStyle: 'solid' }}>
                  <td style={{ paddingRight: 15 }}>Ration Item Name</td>
                  <td style={{ paddingLeft: 15, paddingRight: 15 }}>Amount (per day)</td>
                  <td style={{ paddingLeft: 15, paddingRight: 15 }}>Units</td>
                  <td style={{ paddingLeft: 20 }}>{}</td>
                </tr>
              </thead>
              <tbody>
                {feedItemList.map((feed) => {
                  return (
                    <tr key={feed.id}>
                      <td>{feed.name}</td>
                      <td style={{ padding: 15 }}>
                        {drawerAmount(feed.amount, feed.unit).toLocaleString('en-US', {
                          minimumFractionDigits: 3,
                          maximumFractionDigits: 3,
                        })}
                      </td>
                      <td style={{ paddingLeft: 15, paddingRight: 15 }}>{displayUnitInDrawer(feed.unit)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Form>
              <div style={{ fontSize: 14, color: 'black', marginTop: 30, marginBottom: 10 }}>Recommended Ration</div>
              <table>
                <thead>
                  <tr style={{ borderBottomWidth: 1, borderBottomColor: '#ccc', borderBottomStyle: 'solid' }}>
                    <td>Ration Item Name</td>
                    <td style={{ paddingLeft: 15, paddingRight: 15 }}>Amount (per day)</td>
                    <td>Units</td>
                    <td style={{ paddingLeft: 20 }}>{}</td>
                  </tr>
                </thead>
                <tbody>
                  {recommendedRationList.map((feed) => {
                    return (
                      <tr key={feed.id}>
                        <td>{feed.name}</td>
                        <td style={{ paddingLeft: 15, paddingRight: 15, position: 'relative', top: 15 }}>
                          <Form.Item>
                            {form.getFieldDecorator(`rationAmounts[${feed.id}].amount`, {
                              initialValue: drawerAmount(feed.amount, feed.unit),
                            })(
                              <InputNumber
                                placeholder="Amount"
                                size="small"
                                style={{ width: 100 }}
                                min={0}
                                precision={3}
                                onChange={() => {
                                  form.validateFields((err, values) => {
                                    const newFeedItemList = recommendedRationList.map((f) => {
                                      const updatedFeedAmount = values.rationAmounts[f.id].amount;
                                      const updatedFeedUnit = f.unit;
                                      let amountValue = 0;
                                      if (userSettings.uom === 'metric') {
                                        if (updatedFeedUnit && updatedFeedUnit === 'oz/day') {
                                          amountValue = convertGramToOz(updatedFeedAmount);
                                        } else {
                                          amountValue = convertKgToPound(updatedFeedAmount);
                                        }
                                      } else {
                                        amountValue = updatedFeedAmount;
                                      }
                                      const newFeed = { ...f, amount: amountValue };
                                      return newFeed;
                                    });
                                    setRecommendedRationList(newFeedItemList);
                                  });
                                }}
                              />,
                            )}
                          </Form.Item>
                        </td>
                        <td>{displayUnitInDrawer(feed.unit)}</td>
                        <td style={{ paddingLeft: 20 }}>
                          <Icon
                            type="minus-circle"
                            width="20"
                            height="20"
                            style={{ color: '#CE0E2D' }}
                            onClick={() => {
                              const newFeedItemList = recommendedRationList.filter((f) => f.id !== feed.id);
                              setRecommendedRationList(newFeedItemList);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Form.Item>
                <Button
                  className="button-color"
                  onClick={() => {
                    setAddRecommendedRationModal(true);
                  }}
                >
                  <span style={{ fontWeight: 'bold', marginRight: 10 }}>+</span> Add
                </Button>

                <span style={{ marginLeft: 10 }}>new items to recommended diet.</span>
              </Form.Item>
            </Form>
          </Drawer>
        </div>
      ) : (
        <div>
          <Drawer
            width={500}
            title="Recommended Ration"
            placement="left"
            onClose={onCloseDrawerRecommended}
            visible={visibleDrawerForRecommended}
          >
            <Form>
              <table style={{ width: '100%' }}>
                <col />
                <col width="100" />
                <col width="30" />
                <col width="20" />
                <thead>
                  <tr style={{ borderBottomWidth: 1, borderBottomColor: '#ccc', borderBottomStyle: 'solid' }}>
                    <td>Ration Item Name</td>
                    <td>
                      Amount
                      <br />
                      (per day)
                    </td>
                    <td>Units</td>
                    <td />
                  </tr>
                </thead>
                <tbody>
                  {recommendedRationList.map((feed) => {
                    return (
                      <tr key={feed.id}>
                        <td>{feed.name}</td>
                        <td style={{ paddingLeft: 5, paddingRight: 5, position: 'relative', top: 12 }}>
                          <Form.Item>
                            {form.getFieldDecorator(`rationAmounts[${feed.id}.amount]`, {
                              initialValue: drawerAmount(feed.amount, feed.unit),
                            })(
                              <InputNumber
                                placeholder="Amount"
                                size="small"
                                style={{ width: 100 }}
                                min={0}
                                precision={3}
                                onChange={() => {
                                  form.validateFields((err, values) => {
                                    const newFeedItemList = recommendedRationList.map((f) => {
                                      const updatedFeedAmount = values.rationAmounts[f.id].amount;
                                      const updatedFeedUnit = f.unit;
                                      let amountValue = 0;
                                      if (userSettings.uom === 'metric') {
                                        if (updatedFeedUnit && updatedFeedUnit === 'oz/day') {
                                          amountValue = convertGramToOz(updatedFeedAmount);
                                        } else {
                                          amountValue = convertKgToPound(updatedFeedAmount);
                                        }
                                      } else {
                                        amountValue = updatedFeedAmount;
                                      }
                                      const newFeed = { ...f, amount: amountValue };
                                      return newFeed;
                                    });
                                    setRecommendedRationList(newFeedItemList);
                                  });
                                }}
                              />,
                            )}
                          </Form.Item>
                        </td>
                        <td>{displayUnitInDrawer(feed.unit)}</td>
                        <td style={{ paddingLeft: 20 }}>
                          <Icon
                            type="minus-circle"
                            width="20"
                            height="20"
                            style={{ color: '#CE0E2D' }}
                            onClick={() => {
                              const newFeedItemList = recommendedRationList.filter((f) => f.id !== feed.id);
                              setRecommendedRationList(newFeedItemList);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Form.Item>
                <Button
                  className="button-color"
                  onClick={() => {
                    setAddRecommendedRationModal(true);
                  }}
                >
                  <span style={{ fontWeight: 'bold', marginRight: 10 }}>+</span> Add
                </Button>

                <span style={{ marginLeft: 10 }}>new items to recommended diet.</span>
              </Form.Item>
            </Form>
          </Drawer>
        </div>
      )}

      <div>
        <Modal footer={false} visible={recommendedDietModal} onCancel={() => setRecommendedDietModal(false)}>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <div style={{ textAlign: 'center', fontSize: 18, marginBottom: 20 }}>
              {checkCompare ? 'Save Diet Comparison' : 'Save Recommended Diet'}
            </div>
            {recommendedDietModal && (
              <DietNameSaveForm
                handleDietNameSave={handleDietNameSave}
                setRecommendedDietModal={setRecommendedDietModal}
                saveButtonLoader={saveButtonLoader}
              />
            )}
          </div>
        </Modal>
        <Modal
          width={700}
          onCancel={() => setAddRecommendedRationModal(false)}
          footer={false}
          visible={addRecommendedRationModal}
        >
          <div>
            <div style={{ fontSize: 18 }}>Add Ration Item</div>
            <Divider />
            <Radio.Group
              onChange={(e) => {
                setAddRationType(e.target.value);
              }}
              value={addRationType}
            >
              <Radio value="forage">Forage</Radio>
              <Radio value="concentrate">Concentrate</Radio>
              <Radio value="supplement">Supplement</Radio>
            </Radio.Group>
            {addRationType === 'forage' && (
              <Form style={{ marginTop: 30 }}>
                <Form.Item label="Select Forage Item:" labelCol={{ lg: 8, xl: 8 }}>
                  {form.getFieldDecorator('feedId', {
                    initialValue: undefined,
                    rules: [{ required: true, message: `Please Select Forage Item and try again` }],
                  })(
                    <Select
                      showSearch
                      style={{ width: 300 }}
                      placeholder="Start typing the name of Forage"
                      optionFilterProp="children"
                    >
                      <OptGroup label="Purina Products">
                        {feedInfoData
                          .filter((e) => e.type === 'forage')
                          .filter((item) => item.category === 'purinaProduct')
                          .map((item) => renderSelectOption(item))}
                      </OptGroup>

                      <OptGroup label="Book Values">
                        {feedInfoData
                          .filter((e) => e.type === 'forage')
                          .filter((item) => item.category === 'bookValues')
                          .map((item) => renderSelectOption(item))}
                      </OptGroup>

                      <OptGroup label="Individual Ingredients">
                        {feedInfoData
                          .filter((e) => e.type === 'forage')
                          .filter((item) => item.category === 'individualIngredient')
                          .map((item) => renderSelectOption(item))}
                      </OptGroup>

                      <OptGroup label="Custom Entries">
                        {feedInfoData
                          .filter((e) => e.type === 'forage')
                          .filter((item) => item.category === 'customEntry')
                          .map((item) => renderSelectOption(item))}
                      </OptGroup>

                      <OptGroup label="Other Commercial Products">
                        {feedInfoData
                          .filter((e) => e.type === 'forage')
                          .filter((item) => item.category === 'otherCommercialProduct')
                          .map((item) => renderSelectOption(item))}
                      </OptGroup>

                      <OptGroup label="Uncategorized">
                        {feedInfoData
                          .filter((e) => e.type === 'forage')
                          .filter((item) => item.category === 'uncategorized' || !item.category)
                          .map((item) => renderSelectOption(item))}
                      </OptGroup>
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Amount" labelCol={{ lg: 8, xl: 8 }}>
                  {form.getFieldDecorator('amount', {
                    initialValue: undefined,
                    rules: [{ required: true, message: `Please enter Amount and try again` }],
                  })(<InputNumber min={0} precision={3} />)}
                  &nbsp;{userSettings.uom === 'metric' ? <>kgs/day</> : <>lbs/day</>}
                </Form.Item>
                <Form.Item style={{ marginLeft: 150 }}>
                  <Button
                    className="button-color "
                    htmlType="submit"
                    onClick={() => {
                      form.validateFields((error, values) => {
                        let amountValue = 0;
                        if (userSettings.uom === 'metric') {
                          if (values.unit && values.unit === 'oz/day') {
                            amountValue = convertGramToOz(values.amount);
                          } else {
                            amountValue = convertKgToPound(values.amount);
                          }
                        } else {
                          amountValue = values.amount;
                        }
                        if (!error) {
                          if (values.amount <= 0) {
                            form.setFields({
                              amount: {
                                value: amountValue,
                                errors: [new Error('Amount should be greater then 0')],
                              },
                            });
                          }
                          if (recommendedRationList.some((entry) => entry.id === values.feedId)) {
                            message.error('Feed item is already added in Ration');
                            form.resetFields();
                          } else {
                            const eachRation = feedInfoData.find((entry) => entry.id === values.feedId);
                            const checkRecommendRationList = recommendedRationList;
                            checkRecommendRationList.push({
                              amount: amountValue,
                              unit: values.unit ? values.unit : 'lbs/day',
                              ...eachRation,
                            });
                            setRecommendedRationList(checkRecommendRationList);
                            message.success('Forage Item is successfully added to Recommended Ration List.');
                            form.resetFields();
                          }
                        }
                        return null;
                      });
                    }}
                  >
                    Add to Ration
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      form.resetFields();
                      setAddRecommendedRationModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Form>
            )}
            {addRationType === 'concentrate' && (
              <Form style={{ marginTop: 30 }}>
                <Form.Item label="Select Concentrate Item:" labelCol={{ lg: 10, xl: 10 }}>
                  {form.getFieldDecorator('concentrateId', {
                    initialValue: undefined,
                    rules: [{ required: true, message: `Please Select Concentrate Item and try again` }],
                  })(
                    <Select
                      showSearch
                      style={{ width: 300 }}
                      placeholder="Start typing the name of Concentrate"
                      optionFilterProp="children"
                    >
                      <OptGroup label="Purina Products">
                        {feedInfoData
                          .filter((e) => e.type === 'concentrate')
                          .filter((item) => item.category === 'purinaProduct')
                          .map((item) => renderSelectOption(item))}
                      </OptGroup>

                      <OptGroup label="Book Values">
                        {feedInfoData
                          .filter((e) => e.type === 'concentrate')
                          .filter((item) => item.category === 'bookValues')
                          .map((item) => renderSelectOption(item))}
                      </OptGroup>

                      <OptGroup label="Individual Ingredients">
                        {feedInfoData
                          .filter((e) => e.type === 'concentrate')
                          .filter((item) => item.category === 'individualIngredient')
                          .map((item) => renderSelectOption(item))}
                      </OptGroup>

                      <OptGroup label="Custom Entries">
                        {feedInfoData
                          .filter((e) => e.type === 'concentrate')
                          .filter((item) => item.category === 'customEntry')
                          .map((item) => renderSelectOption(item))}
                      </OptGroup>

                      <OptGroup label="Other Commercial Products">
                        {feedInfoData
                          .filter((e) => e.type === 'concentrate')
                          .filter((item) => item.category === 'otherCommercialProduct')
                          .map((item) => renderSelectOption(item))}
                      </OptGroup>

                      <OptGroup label="Uncategorized">
                        {feedInfoData
                          .filter((e) => e.type === 'concentrate')
                          .filter((item) => item.category === 'uncategorized' || !item.category)
                          .map((item) => renderSelectOption(item))}
                      </OptGroup>
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Amount" labelCol={{ lg: 10, xl: 10 }}>
                  {form.getFieldDecorator('amount', {
                    initialValue: undefined,
                    rules: [{ required: true, message: `Please enter Amount and try again` }],
                  })(<InputNumber min={0} precision={3} />)}
                  &nbsp; {userSettings.uom === 'metric' ? <>kgs/day</> : <>lbs/day</>}
                </Form.Item>
                <Form.Item style={{ marginLeft: 190 }}>
                  <Button
                    className="button-color"
                    htmlType="submit"
                    onClick={() => {
                      form.validateFields((error, values) => {
                        let amountValue = 0;
                        if (userSettings.uom === 'metric') {
                          if (values.unit && values.unit === 'oz/day') {
                            amountValue = convertGramToOz(values.amount);
                          } else {
                            amountValue = convertKgToPound(values.amount);
                          }
                        } else {
                          amountValue = values.amount;
                        }
                        if (!error) {
                          if (values.amount <= 0) {
                            form.setFields({
                              amount: {
                                value: amountValue,
                                errors: [new Error('Amount should be greater then 0')],
                              },
                            });
                          }
                          if (recommendedRationList.some((entry) => entry.id === values.concentrateId)) {
                            message.error('Concentrate item is already added in Ration');
                            form.resetFields();
                          } else {
                            const eachRation = feedInfoData.find((entry) => entry.id === values.concentrateId);
                            const checkRecommendRationList = recommendedRationList;
                            checkRecommendRationList.push({
                              amount: amountValue,
                              unit: values.unit ? values.unit : 'lbs/day',
                              ...eachRation,
                            });
                            setRecommendedRationList(checkRecommendRationList);
                            message.success('Concentrate Item is successfully added to Recommended Ration List.');
                            form.resetFields();
                          }
                        }
                        return null;
                      });
                    }}
                  >
                    Add to Ration
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      form.resetFields();
                      setAddRecommendedRationModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Form>
            )}

            {addRationType === 'supplement' && (
              <Form style={{ marginTop: 30 }}>
                <Form.Item label="Select Supplement Item:" labelCol={{ lg: 10, xl: 10 }}>
                  {form.getFieldDecorator('supplementId', {
                    initialValue: undefined,
                    rules: [{ required: true, message: `Please Select Supplement Item and try again` }],
                  })(
                    <Select
                      showSearch
                      style={{ width: 300 }}
                      placeholder="Start typing the name of Supplement"
                      optionFilterProp="children"
                    >
                      <OptGroup label="Purina Products">
                        {feedInfoData
                          .filter((e) => e.type === 'supplement')
                          .filter((item) => item.category === 'purinaProduct')
                          .map((item) => renderSelectOption(item))}
                      </OptGroup>

                      <OptGroup label="Book Values">
                        {feedInfoData
                          .filter((e) => e.type === 'supplement')
                          .filter((item) => item.category === 'bookValues')
                          .map((item) => renderSelectOption(item))}
                      </OptGroup>

                      <OptGroup label="Individual Ingredients">
                        {feedInfoData
                          .filter((e) => e.type === 'supplement')
                          .filter((item) => item.category === 'individualIngredient')
                          .map((item) => renderSelectOption(item))}
                      </OptGroup>

                      <OptGroup label="Custom Entries">
                        {feedInfoData
                          .filter((e) => e.type === 'supplement')
                          .filter((item) => item.category === 'customEntry')
                          .map((item) => renderSelectOption(item))}
                      </OptGroup>

                      <OptGroup label="Other Commercial Products">
                        {feedInfoData
                          .filter((e) => e.type === 'supplement')
                          .filter((item) => item.category === 'otherCommercialProduct')
                          .map((item) => renderSelectOption(item))}
                      </OptGroup>

                      <OptGroup label="Uncategorized">
                        {feedInfoData
                          .filter((e) => e.type === 'supplement')
                          .filter((item) => item.category === 'uncategorized' || !item.category)
                          .map((item) => renderSelectOption(item))}
                      </OptGroup>
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item label="Amount" labelCol={{ lg: 10, xl: 10 }}>
                  {form.getFieldDecorator('amount', {
                    initialValue: undefined,
                    rules: [{ required: true, message: `Please enter Amount and try again` }],
                  })(<InputNumber min={0} precision={3} />)}
                  &nbsp;
                  {form.getFieldDecorator('unit', {
                    initialValue: 'lbs/day',
                  })(
                    <Radio.Group>
                      <Radio value="lbs/day">{userSettings.uom === 'metric' ? <>kgs/day</> : <>lbs/day</>}</Radio>
                      <Radio value="oz/day">{userSettings.uom === 'metric' ? <>grams/day</> : <>oz/day</>}</Radio>
                    </Radio.Group>,
                  )}
                </Form.Item>
                <Form.Item style={{ marginLeft: 190 }}>
                  <Button
                    className="button-color"
                    htmlType="submit"
                    onClick={() => {
                      form.validateFields((error, values) => {
                        let amountValue = 0;
                        if (userSettings.uom === 'metric') {
                          if (values.unit && values.unit === 'oz/day') {
                            amountValue = convertGramToOz(values.amount);
                          } else {
                            amountValue = convertKgToPound(values.amount);
                          }
                        } else {
                          amountValue = values.amount;
                        }
                        if (!error) {
                          if (values.amount <= 0) {
                            form.setFields({
                              amount: {
                                value: amountValue,
                                errors: [new Error('Amount should be greater then 0')],
                              },
                            });
                          }
                          if (recommendedRationList.some((entry) => entry.id === values.supplementId)) {
                            message.error('Supplement item is already added in Ration');
                            form.resetFields();
                          } else {
                            const eachRation = feedInfoData.find((entry) => entry.id === values.supplementId);
                            const checkRecommendRationList = recommendedRationList;
                            checkRecommendRationList.push({
                              amount: amountValue,
                              unit: values.unit ? values.unit : 'lbs/day',
                              ...eachRation,
                            });
                            setRecommendedRationList(checkRecommendRationList);
                            message.success('Supplement Item is successfully added to Recommended Ration List.');
                            form.resetFields();
                          }
                        }
                        return null;
                      });
                    }}
                  >
                    Add to Ration
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      form.resetFields();
                      setAddRecommendedRationModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Form>
            )}
          </div>
        </Modal>
      </div>
      <Affix>
        <div style={{ paddingTop: 10, backgroundColor: '#FFFFFF' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingTop: 5,
              paddingBottom: 5,
              alignItems: 'center',
            }}
          >
            <div>
              <Button
                style={{ marginLeft: 15 }}
                className="button-color"
                onClick={() => {
                  setModalOfBackButton(true);
                }}
              >
                Back
              </Button>
              <Button
                style={{ marginLeft: 15 }}
                className="button-color"
                onClick={() => {
                  resetRationAnalyzer();
                }}
              >
                New Analysis
              </Button>
            </div>

            <div style={{ fontSize: 22, color: 'black' }}>Ration Recommendation</div>
            <div>
              <Button
                className="button-color"
                onClick={() => setRecommendedDietModal(true)}
                style={{ marginLeft: 10, marginRight: 10 }}
              >
                Save
              </Button>
              <Button
                className="button-color"
                onClick={() => {
                  if (checkCompare) {
                    onClickGenerateReport('compare');
                  } else {
                    onClickGenerateReport('recommended');
                  }
                }}
                style={{ marginLeft: 10, marginRight: 10 }}
                // disabled={checkCompare}
              >
                Generate Report
              </Button>
              <Checkbox onChange={onChangeCompareCheckBox} style={{ marginLeft: 10, marginRight: 10 }}>
                Compare recommended diet with current diet
              </Checkbox>
            </div>
          </div>
          <hr style={{ marginTop: 1, marginBottom: 20 }} />
        </div>
      </Affix>

      <div style={{ display: 'flex' }}>
        <Affix offsetTop={70}>
          <Button
            onClick={() => {
              setVisibleDrawerForRecommended(true);
            }}
            icon="double-right"
            style={{ background: '#6ff36f', color: 'black', border: '#6ff36f', marginBottom: 10 }}
          >
            Modify Ration
          </Button>
        </Affix>
      </div>
      <div className="container-big">
        <Drawer width={320} title="Options" placement="right" closable={false} onClose={onCloseDrawerRecommended}>
          <div style={{ fontSize: 18, color: 'black', marginTop: 5 }}>Chart Type</div>
          <hr style={{ marginTop: 1, marginBottom: 10 }} />
          <Radio.Group>
            <Radio value="standard">Standard Stacked</Radio>
            <Radio value="100%">100% Stacked</Radio>
          </Radio.Group>
          <div style={{ fontSize: 18, color: 'black', marginTop: 5 }}>Nutrients</div>
          <hr style={{ marginTop: 1, marginBottom: 10 }} />
          <Checkbox.Group style={{ width: '100%' }}>
            <Row>
              {feedNutrients.map((nutrient) => {
                if (nutrient.key !== 'percentDM') {
                  return (
                    <Col key={nutrient.key} span={8} style={{ marginTop: 5 }}>
                      <Checkbox value={nutrient.key}>{nutrient.xAxisLabelName}</Checkbox>
                    </Col>
                  );
                }
                return null;
              })}
            </Row>
          </Checkbox.Group>
        </Drawer>
        <Drawer width={320} title="Options" placement="right" closable={false}>
          <div style={{ fontSize: 18, color: 'black', marginTop: 5 }}>Acceptable Ranges (%)</div>
          <hr style={{ marginTop: 1, marginBottom: 10 }} />
          <Row>
            <Col span={12}>Target</Col>
            <Col span={12}>
              ±
              <InputNumber />
            </Col>
          </Row>
          <Row>
            <Col span={12}>Accptable</Col>
            <Col span={12}>
              ±
              <InputNumber />
            </Col>
          </Row>
          <div style={{ fontSize: 18, color: 'black', marginTop: 5 }}>Nutrients</div>
          <hr style={{ marginTop: 1, marginBottom: 10 }} />
          <Checkbox.Group style={{ width: '100%' }}>
            <Row>
              {feedNutrients.map((nutrient) => {
                if (nutrient.key !== 'percentDM') {
                  return (
                    <Col key={nutrient.key} span={8} style={{ marginTop: 5 }}>
                      <Checkbox value={nutrient.key}>{nutrient.xAxisLabelName}</Checkbox>
                    </Col>
                  );
                }
                return null;
              })}
            </Row>
          </Checkbox.Group>
        </Drawer>
      </div>

      <div style={{ paddingLeft: 40, paddingRight: 40, marginBottom: 40 }}>
        {checkCompare ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 60, marginRight: 60 }}>
            <div>
              <div className="added-feed-item-list">Current Ration List</div>
              <hr style={{ marginTop: 1, marginBottom: 20 }} />
              <Table
                size="small"
                dataSource={[
                  ...feedItemList,
                  {
                    totalAmountKey: 'Total Amount',
                    totalAmountValue: `${
                      userSettings.uom === 'metric'
                        ? `${convertPoundToKg(totalAmount).toFixed(2)} `
                        : `${totalAmount.toFixed(2)} `
                    }`,
                  },
                ]}
                bordered
                pagination={false}
                rowKey="id"
              >
                <Column
                  title="Item Type"
                  dataIndex="type"
                  key="type"
                  render={(text, row, index) => {
                    if (index < feedItemList.length) {
                      return <div style={{ textTransform: 'capitalize' }}>{row.type}</div>;
                    }
                    return {
                      props: {
                        colSpan: 2,
                      },
                    };
                  }}
                />
                <Column
                  title="Code"
                  dataIndex="code"
                  key="code"
                  render={(text, row, index) => {
                    if (index < feedItemList.length) {
                      return row.code ? row.code : <div>-</div>;
                    }
                    return {
                      children: (
                        <div style={{ fontWeight: 'bold', fontSize: 15, textAlign: 'right' }}>
                          {row.totalAmountKey}
                          {userSettings.uom === 'metric' ? ' (kgs)' : ' (lbs)'}:
                        </div>
                      ),
                    };
                  }}
                />
                <Column
                  title="Item Name"
                  dataIndex="name"
                  key="name"
                  render={(value, row, index) => {
                    if (index < feedItemList.length) {
                      return row.name;
                    }
                    return {
                      children: (
                        <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 15 }}>
                          {row.totalAmountValue}
                        </div>
                      ),
                    };
                  }}
                />
                <Column
                  title={
                    <div>
                      <div style={{ marginBottom: 5 }}>Amount</div>
                      <div>(per day)</div>
                    </div>
                  }
                  dataIndex="amount"
                  key="amount"
                  align="center"
                  render={(value, row, index) => {
                    const obj = {
                      children: renderAmountValueInTable(row),
                      props: {},
                    };
                    if (index === feedItemList.length) {
                      obj.props.colSpan = 0;
                    }
                    return obj;
                  }}
                />
              </Table>
            </div>
            <div>
              <div className="added-feed-item-list">Recommendation Ration List</div>
              <hr style={{ marginTop: 1, marginBottom: 20 }} />
              <Table
                size="small"
                dataSource={[
                  ...recommendedRationList,
                  {
                    totalAmountKey: 'Total Amount',
                    totalAmountValue:
                      userSettings.uom === 'metric'
                        ? convertPoundToKg(totalAmountRecommend).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : totalAmountRecommend.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }),
                  },
                ]}
                bordered
                pagination={false}
                rowKey="id"
              >
                <Column
                  title="Item Type"
                  dataIndex="type"
                  key="type"
                  render={(text, row, index) => {
                    if (index < recommendedRationList.length) {
                      return <div style={{ textTransform: 'capitalize' }}>{row.type}</div>;
                    }
                    return {
                      props: {
                        colSpan: 2,
                      },
                    };
                  }}
                />
                <Column
                  title="Code"
                  dataIndex="code"
                  key="code"
                  render={(text, row, index) => {
                    if (index < recommendedRationList.length) {
                      return row.code ? row.code : <div>-</div>;
                    }
                    return {
                      children: (
                        <div style={{ fontWeight: 'bold', fontSize: 15, textAlign: 'right' }}>
                          {row.totalAmountKey}
                          {userSettings.uom === 'metric' ? ' (kgs)' : ' (lbs)'}:
                        </div>
                      ),
                    };
                  }}
                />
                <Column
                  title="Item Name"
                  dataIndex="name"
                  key="name"
                  render={(value, row, index) => {
                    if (index < recommendedRationList.length) {
                      return row.name;
                    }
                    return {
                      children: (
                        <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 15 }}>
                          {row.totalAmountValue}
                        </div>
                      ),
                    };
                  }}
                />

                <Column
                  title={
                    <div>
                      <div style={{ marginBottom: 5 }}>Amount</div>
                      <div>(per day)</div>
                    </div>
                  }
                  dataIndex="amount"
                  key="amount"
                  align="center"
                  render={(value, row, index) => {
                    const obj = {
                      children: renderAmountValueInTable(row),
                      props: {},
                    };
                    if (index === recommendedRationList.length) {
                      obj.props.colSpan = 0;
                    }
                    return obj;
                  }}
                />
              </Table>
            </div>
          </div>
        ) : (
          <div>
            <div className="added-feed-item-list">Ration List</div>
            <hr style={{ marginTop: 1, marginBottom: 20 }} />
            <Table
              size="small"
              dataSource={[
                ...recommendedRationList,
                {
                  totalAmountKey: 'Total Amount',
                  totalAmountValue:
                    userSettings.uom === 'metric'
                      ? convertPoundToKg(totalAmountRecommend).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : totalAmountRecommend.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }),
                },
              ]}
              bordered
              pagination={false}
              rowKey="id"
            >
              <Column
                title="Item Type"
                dataIndex="type"
                key="type"
                render={(text, row, index) => {
                  if (index < recommendedRationList.length) {
                    return <div style={{ textTransform: 'capitalize' }}>{row.type}</div>;
                  }
                  return {
                    props: {
                      colSpan: 2,
                    },
                  };
                }}
              />
              <Column
                title="Code"
                dataIndex="code"
                key="code"
                render={(text, row, index) => {
                  if (index < recommendedRationList.length) {
                    return row.code ? row.code : <div>-</div>;
                  }
                  return {
                    children: (
                      <div style={{ fontWeight: 'bold', fontSize: 15, textAlign: 'right' }}>
                        {row.totalAmountKey}
                        {userSettings.uom === 'metric' ? ' (kgs)' : ' (lbs)'}:
                      </div>
                    ),
                  };
                }}
              />
              <Column
                title="Item Name"
                dataIndex="name"
                key="name"
                render={(value, row, index) => {
                  if (index < recommendedRationList.length) {
                    return row.name;
                  }
                  return {
                    children: (
                      <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 15 }}>
                        {row.totalAmountValue}
                      </div>
                    ),
                  };
                }}
              />
              <Column
                title={
                  <div>
                    <div style={{ marginBottom: 5 }}>Amount</div>
                    <div>(per day)</div>
                  </div>
                }
                dataIndex="amount"
                key="amount"
                align="center"
                render={(value, row, index) => {
                  const obj = {
                    children: renderAmountValueInTable(row),
                    props: {},
                  };
                  if (index === recommendedRationList.length) {
                    obj.props.colSpan = 0;
                  }
                  return obj;
                }}
              />
            </Table>
          </div>
        )}
      </div>

      {checkCompare ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleLeftArrow} icon="caret-left" />
          <div style={{ width: 500, textAlign: 'center', paddingTop: 5, fontSize: 18 }}>
            {renderHeadingInCompareMode()}
          </div>
          <Button onClick={handleRightArrow} icon="caret-right" />
        </div>
      ) : null}

      <div style={{ padding: 35, paddingTop: 10 }}>
        {checkCompare ? (
          <div>
            {/* Nutrient supplied graph */}
            {showComparision === 'suppliedGraph' ? (
              <GraphOfNutrientSupplied
                nutSuppGraphChartType={nutSuppGraphChartType}
                setNutSuppGraphChartType={setNutSuppGraphChartType}
                nutSuppGraphNutrients={nutSuppGraphNutrients}
                setNutSuppGraphNutrients={setNutSuppGraphNutrients}
                currentRationCalculationsData={currentRationCalculations}
                recommendedRationCalculationsData={recommendedRationCalculations}
                compare={checkCompare}
                hideHeader
                maximumCap={maximumCap}
                setMaximumCap={setMaximumCap}
              />
            ) : null}

            {/* Acceptable ranges graph */}
            {showComparision === 'acceptableGraph' ? (
              <AcceptableRangeGraph
                currentRationCalculationsData={currentRationCalculations}
                recommendedRationCalculationsData={recommendedRationCalculations}
                acceptableDeltaLow={acceptableDeltaLow}
                acceptableDeltaHigh={acceptableDeltaHigh}
                targetDeltaLow={targetDeltaLow}
                targetDeltaHigh={targetDeltaHigh}
                setAcceptableDeltaLow={setAcceptableDeltaLow}
                setAcceptableDeltaHigh={setAcceptableDeltaHigh}
                setTargetDeltaLow={setTargetDeltaLow}
                setTargetDeltaHigh={setTargetDeltaHigh}
                compare={checkCompare}
                accRangeGraphNutrients={accRangeGraphNutrients}
                setAccRangeGraphNutrients={setAccRangeGraphNutrients}
                hideHeader
              />
            ) : null}

            {/* Nutrients supplied table */}
            {showComparision === 'table' ? (
              <NutrientsSuppliedTable
                currentRationCalculationsData={currentRationCalculations}
                recommendedRationCalculationsData={recommendedRationCalculations}
                compare={checkCompare}
                hideHeader
                nutrientKeys={['percentDM', ...nutSuppGraphNutrients]}
                setNutrientKeys={setNutSuppGraphNutrients}
                totalAmount={userSettings.uom === 'metric' ? totalAmount / 2.20462 : totalAmount}
                totalAmountRecommend={
                  userSettings.uom === 'metric' && totalAmountRecommend
                    ? totalAmountRecommend / 2.20462
                    : totalAmountRecommend
                }
              />
            ) : null}
          </div>
        ) : (
          <div>
            {/* Nutrient supplied graph */}
            <GraphOfNutrientSupplied
              nutSuppGraphChartType={nutSuppGraphChartType}
              setNutSuppGraphChartType={setNutSuppGraphChartType}
              nutSuppGraphNutrients={nutSuppGraphNutrients}
              setNutSuppGraphNutrients={setNutSuppGraphNutrients}
              currentRationCalculationsData={recommendedRationCalculations}
              compare={checkCompare}
              maximumCap={maximumCap}
              setMaximumCap={setMaximumCap}
            />

            {/* Acceptable ranges graph */}
            <AcceptableRangeGraph
              currentRationCalculationsData={recommendedRationCalculations}
              acceptableDeltaLow={acceptableDeltaLow}
              acceptableDeltaHigh={acceptableDeltaHigh}
              targetDeltaLow={targetDeltaLow}
              targetDeltaHigh={targetDeltaHigh}
              setAcceptableDeltaLow={setAcceptableDeltaLow}
              setAcceptableDeltaHigh={setAcceptableDeltaHigh}
              setTargetDeltaLow={setTargetDeltaLow}
              setTargetDeltaHigh={setTargetDeltaHigh}
              compare={checkCompare}
              accRangeGraphNutrients={accRangeGraphNutrients}
              setAccRangeGraphNutrients={setAccRangeGraphNutrients}
            />

            {/* Nutrients supplied table */}
            <NutrientsSuppliedTable
              currentRationCalculationsData={recommendedRationCalculations}
              nutrientKeys={['percentDM', ...nutSuppGraphNutrients]}
              setNutrientKeys={setNutSuppGraphNutrients}
              compare={checkCompare}
              totalAmount={userSettings.uom === 'metric' ? totalAmount / 2.20462 : totalAmount}
            />
          </div>
        )}
      </div>
    </>
  );
};

RecommendedRation.propTypes = {
  feedItemList: PropTypes.array.isRequired,
  handleSaveOfHorseDiet: PropTypes.func.isRequired,
  recommendedRationList: PropTypes.array.isRequired,
  setRecommendedRationList: PropTypes.func.isRequired,
  horseDataForCalculation: PropTypes.object.isRequired,
  onClickBackButton: PropTypes.func.isRequired,
  targetDeltaLow: PropTypes.object.isRequired,
  targetDeltaHigh: PropTypes.object.isRequired,
  setTargetDeltaLow: PropTypes.func.isRequired,
  setTargetDeltaHigh: PropTypes.func.isRequired,
  acceptableDeltaLow: PropTypes.object.isRequired,
  acceptableDeltaHigh: PropTypes.object.isRequired,
  setAcceptableDeltaLow: PropTypes.func.isRequired,
  setAcceptableDeltaHigh: PropTypes.func.isRequired,
  nutSuppGraphChartType: PropTypes.string.isRequired,
  setNutSuppGraphChartType: PropTypes.func.isRequired,
  nutSuppGraphNutrients: PropTypes.array.isRequired,
  setNutSuppGraphNutrients: PropTypes.func.isRequired,
  accRangeGraphNutrients: PropTypes.array.isRequired,
  setAccRangeGraphNutrients: PropTypes.func.isRequired,
  maximumCap: PropTypes.number,
  setMaximumCap: PropTypes.func,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
  }).isRequired,
  onClickGenerateReport: PropTypes.func.isRequired,
  handleSaveOfCompareDiet: PropTypes.func.isRequired,
};

RecommendedRation.defaultProps = {
  maximumCap: null,
  setMaximumCap: null,
};

export default Form.create({ name: 'RecommendedRation' })(RecommendedRation);
