import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import Heading from './Heading';
import { addressFormat } from '../utils/globals';

const FarmDataInfo = (props) => {
  const { selectedFarmData, hideHeader, showAllFieldTogether } = props;

  return (
    <div>
      {selectedFarmData &&
      (selectedFarmData.managerName ||
        selectedFarmData.managerEmail ||
        selectedFarmData.managerMobile ||
        selectedFarmData.addressLine1 ||
        selectedFarmData.addressLine2 ||
        selectedFarmData.city ||
        selectedFarmData.state ||
        selectedFarmData.zip ||
        selectedFarmData.salesRepName ||
        selectedFarmData.salesRepEmail) ? (
        <div>
          {hideHeader ? null : <Heading headingName="Contact Information" />}
          <Row>
            {selectedFarmData.managerName || selectedFarmData.managerEmail || selectedFarmData.managerMobile ? (
              <Col span={10}>
                {selectedFarmData.managerName ? (
                  <Row className="field">
                    <Col span={12} className="field-name">
                      Manager Name:
                    </Col>
                    <Col span={12} className="field-value">
                      <div style={{ textTransform: 'capitalize' }}>{selectedFarmData.managerName}</div>
                    </Col>
                  </Row>
                ) : null}
                {selectedFarmData.managerEmail ? (
                  <Row className="field">
                    <Col span={12} className="field-name">
                      Manager Email:
                    </Col>
                    <Col span={12} className="field-value">
                      {selectedFarmData.managerEmail}
                    </Col>
                  </Row>
                ) : null}
                {selectedFarmData.managerMobile ? (
                  <Row className="field">
                    <Col span={12} className="field-name">
                      Manager Phone:
                    </Col>
                    <Col span={12} className="field-value">
                      {selectedFarmData.managerMobile}
                    </Col>
                  </Row>
                ) : null}
              </Col>
            ) : null}
            {selectedFarmData.addressLine1 ||
            selectedFarmData.addressLine2 ||
            selectedFarmData.city ||
            selectedFarmData.state ||
            selectedFarmData.zip ? (
              <Col span={10}>
                <Row className="field">
                  <Col span={12} className="field-name">
                    Address:
                  </Col>
                  <Col span={12} className="field-value">
                    {addressFormat(selectedFarmData)}
                  </Col>
                </Row>
                {selectedFarmData.salesRepName && showAllFieldTogether ? (
                  <Row className="field">
                    <Col span={12} className="field-name">
                      Sales Rep Name:
                    </Col>
                    <Col span={12} className="field-value">
                      <div style={{ textTransform: 'capitalize' }}>{selectedFarmData.salesRepName}</div>
                    </Col>
                  </Row>
                ) : null}
                {selectedFarmData.salesRepEmail && showAllFieldTogether ? (
                  <Row className="field">
                    <Col span={12} className="field-name">
                      Sales Rep Email:
                    </Col>
                    <Col span={12} className="field-value">
                      {selectedFarmData.salesRepEmail}
                    </Col>
                  </Row>
                ) : null}
              </Col>
            ) : null}
          </Row>
          {(selectedFarmData.salesRepName || selectedFarmData.salesRepEmail) && showAllFieldTogether === false ? (
            <div>
              <Heading headingName="Sales Rep Information" />
              <Row>
                {selectedFarmData.salesRepName ? (
                  <Col span={10}>
                    <Row className="field">
                      <Col span={12} className="field-name">
                        Sales Rep Name:
                      </Col>
                      <Col span={12} className="field-value">
                        <div style={{ textTransform: 'capitalize' }}>{selectedFarmData.salesRepName}</div>
                      </Col>
                    </Row>
                  </Col>
                ) : null}
                {selectedFarmData.salesRepEmail ? (
                  <Col span={10}>
                    <Row className="field">
                      <Col span={12} className="field-name">
                        Sales Rep Email:
                      </Col>
                      <Col span={12} className="field-value">
                        {selectedFarmData.salesRepEmail}
                      </Col>
                    </Row>
                  </Col>
                ) : null}
              </Row>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

FarmDataInfo.propTypes = {
  selectedFarmData: PropTypes.object,
  hideHeader: PropTypes.bool,
  showAllFieldTogether: PropTypes.bool,
};

FarmDataInfo.defaultProps = {
  hideHeader: false,
  showAllFieldTogether: false,
  selectedFarmData: null,
};

export default FarmDataInfo;
